/*

This page is shown to users when they have been invited to sign up to moonsift pro / tells them the benefits of it.
It then allows users to attempt the upgrade and returns the results.

TODO: typescript, camelCase, make functional, non-default exports, redux hooks, design system, split container / component ...

*/
import React from 'react';
import { Component } from 'react';
import {AddHelmetTitle} from "src/mvp22/core-components/helmet";
import LoadingDisplay from 'src/mvp22/image-components/LoadingDisplay';
import styled from 'styled-components';
import {M} from 'src/mvp22/constants';
import MEDIA from 'src/mvp22/media';
import R, {EXTERNAL} from 'src/routes';
import {withFirebase} from 'src/mvp22/Firebase';
import TopMenu from 'src/mvp22/menu-components/TopMenu';
import {TEXTSTYLE} from 'src/mvp22/style-components/Text';
import get_user_profile_public from 'src/mvp22/firebase-functions/get_user_profile_public';
import { connect } from 'react-redux';
import Button from 'src/mvp22/form-components/Button';
import windowSize from 'src/mvp22/WindowSize';

import Cookies from 'universal-cookie';
const cookies = new Cookies();

const Container = styled.div`
  background-color:${M.COL.BG.WHITE};
  width:100%;
  display:flex;
  flex-direction: column;
  height: 100vh;
`;

const MainContent = styled.div`
  display:block;
  flex-direction: column;
  background-color:${M.COL.BG.WHITE};
  color:${M.COL.TEXT.BLACK};
  width:100%;
  margin-top:126px;
`;

const CenteredBox = styled.div`
  display: flex;
  max-width: 409px;
  flex-direction: column;
  text-align: center;
  padding-left: 15px;
  padding-right: 15px;
  align-items: center;
  margin: 0 auto;
`;

const ProBadge = styled.img`
  width: 24px;
  height: 24px;
  position: relative;
  bottom: 9px;
  margin-left: 8px;
`;

const LogoAndProBadge = styled.div`
  display: flex;
  justify-content: center;
`;

const Seperator = styled.div`
  height:${props=>props.height};
`;

const GreyMail = styled.img`
  display: flex;
  width: 132px;
  height: 71px;
  align-self: center;
`;

const MoonsiftLogoText = styled(TEXTSTYLE.BRANDED1)`
  font-size: ${props => props.isDesktop === true ? '42px' : '18px'};
  line-height: ${props => props.isDesktop === true ? '50px' : '26px'};
`;

const SubtitleText = styled(TEXTSTYLE.BODY1)`
  font-size: ${props => props.isDesktop === true ? '26px' : '16px'};
  line-height: ${props => props.isDesktop === true ? '38px' : '24px'};
`;

const SubtitleContainer = styled.div`
color:default;
`;

const BodyText = styled(TEXTSTYLE.BODY2)`
  display: flex;
  flex-direction: column;
  text-align: center;
  font-size: ${props => props.isDesktop === true ? '20px' : '16px'};
  line-height: ${props => props.isDesktop === true ? '30px' : '24px'};
`;

const Box = styled.div`
  border: 1px solid;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: ${M.COL.LINE.MID};
`;

const MoreInfoBoxOuter = styled(Box)`
  margin: 0 auto;
  padding: 5px;
  max-width: 405px;
  display: block;
`;

const MoreInfoBoxPadding = styled.div`
  margin-left: 5px;
  margin-right: 5px;
`;

const MoreInfoBox = styled(Box)`
color:default;
`;

const MoreInfoBoxInner = styled.div`
  padding: 20px;
  color: ${M.COL.TEXT.BLACK};
`;

const BottomLink = styled(TEXTSTYLE.LINK)`
  align-self: flex-start;
`;

const BottomLinkExternal = styled(TEXTSTYLE.LINKA)`
  align-self: flex-start;
`;

const BottomLinkText = styled(TEXTSTYLE.BODY2)`
  font-size: ${props => props.isDesktop === true ? '16px' : '14px'};
  line-height: ${props => props.isDesktop === true ? '24px' : '20px'};
  text-decoration: underline;
  display: inline;
`;

const SmallerText = styled(TEXTSTYLE.BODY3)`
  font-size: ${props => props.isDesktop === true ? '16px' : '12px'};
  line-height: ${props => props.isDesktop === true ? '24px' : '18px'};
`;

const MoreInfoText = styled(TEXTSTYLE.BODY3)`
  font-size: ${props => props.isDesktop === true ? '16px' : '14px'};
  line-height: ${props => props.isDesktop === true ? '24px' : '20px'};
`;

const RedirectToHome = styled.a`
  text-decoration:underline;
  color:inherit;
`;

const UpgradeStatusHeading = styled(TEXTSTYLE.HEADING3)`
  font-size: ${props => props.isDesktop === true ? '' : '26px'};
  line-height: ${props => props.isDesktop === true ? '' : '38px'};
`;

const FailureMessageBox = styled(CenteredBox)`
  max-width: 557px;
`;

class ProLanding extends Component {

  constructor(){
    super();
    this.state = {
      loaded:false,
      requested_pro:false
    };
    this.requestPro = this.requestPro.bind(this);
    this.goBack = this.goBack.bind(this);
  }

  componentDidMount(){
    this._isMounted = true;
    this.componentDidUpdate();
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  requestPro(){
    if (this.props.firebaseState.loaded === true && !this.props.authUserUID) {
      return(this.props.history.push(R.SIGNUP));
    }

    this.setState(
      {
        requested_pro:true
      }
    );
    this.props.firebase.fast_api()(
      {
        api:"users_pro_request",
        pro_code:this.state.pro_code,
      }
    ).then(
      (result)=>{
        if (result.data && result.data.success===true){
          this._isMounted && this.setState(
            {
              pro_request_success:true
            }
          );
        }
        else{
          this._isMounted && this.setState(
            {
              pro_request_success:false
            }
          );
        }
      }
    ).catch(
      (err)=>{
        console.error("Failure",err);
        this._isMounted && this.setState(
          {
            pro_request_success:false
          }
        );
      }
    );
  }

  componentDidUpdate(){
    const pro_code = this.props.match.params.pro_referral_code;
    // Update the referral cookie:
    cookies.set(M.PRO_CODE_COOKIE_KEY,pro_code,{maxAge:60*60*24*90,path:M.COOKIEPATH});
    this.setState(
      prevState=>{
        // Only show this page if not pro already!
        if(prevState.pro_code!==pro_code){
          var is_loaded = false;
          // update the user invite name:
          const referred_by = pro_code.split("_")[0].slice(2,); // may not be a valid user, but let's check:
          // if referred by a user:
          if (pro_code.slice(0,2)==="pr" && referred_by.length>0){
            get_user_profile_public(referred_by,this.props.firebase).then(
              response=>{
                if(this._isMounted === true){
                  if(Object.entries(response).length === 0){
                    this.setState(
                      {
                        user_exists:false,
                        is_loaded:true
                      }
                    );
                  } else {
                    this.setState(
                      {
                        public_info:response,
                        user_exists:true,
                        is_loaded:true,
                      }
                    );
                  }
                }
              }
            );
          } else{
            is_loaded = true;
            // In future we may want to look up a name of a referral programme in the database... but not for now.
          }
          return(
            {
              pro_code,
              is_loaded
            }
          );
        }
      }
    );
    this.setState(
      (prevState)=>{
        if (this.props.owner.pro!==undefined && prevState.is_pro_already===undefined){
          return(
            {
              is_pro_already:this.props.owner.pro
            }
          );
        }
      }
    );
  }

  goBack(event){
    event.preventDefault();
    this.props.history.goBack();
  }

  renderLogo(ISDESKTOP){
    return(
      <LogoAndProBadge>
        <MoonsiftLogoText isDesktop={ISDESKTOP}>
          MOONSIFT PRO
        </MoonsiftLogoText>
        <Seperator height='23px'/>
        <ProBadge src={MEDIA.PRO_BADGE}/>
      </LogoAndProBadge>
    );
  }

  renderYouAreProAlready(ISDESKTOP){
    if(this.state.is_pro_already === true){
      return(
        <CenteredBox>
          <UpgradeStatusHeading isDesktop={ISDESKTOP}>You are already pro!</UpgradeStatusHeading>
          <SubtitleContainer>
            <SubtitleText isDesktop={ISDESKTOP}>Please  <RedirectToHome href={R.HOME}>refresh</RedirectToHome> this page</SubtitleText>
          </SubtitleContainer>
        </CenteredBox>
      );
    }
  }

  renderLoadingScreen(){
    return(
      <LoadingDisplay message = "" />
    );
  }

  renderInvitation(ISDESKTOP){
    if(this.state.is_loaded === true){
      if(this.props.firebaseState.loaded === true){
        return(
          <>
            <CenteredBox>
              <GreyMail src={MEDIA.GREY_MAIL}/>
              <Seperator height='23px'/>
              <SubtitleContainer>
                <SubtitleText isDesktop={ISDESKTOP}>Your Invitation to</SubtitleText>
              </SubtitleContainer>
              <Seperator height='16px'/>
              {this.renderLogo(ISDESKTOP)}
              <Seperator height='16px'/>
                {
                  (this.state.user_exists)?
                    <BodyText isDesktop={ISDESKTOP}>
                      <span><b>{this.state.public_info.name}</b> has invited you to get a free Moonsift Pro account</span>
                    </BodyText>
                  :
                    <BodyText isDesktop={ISDESKTOP}>
                      You have been invited to get a free Moonsift Pro account
                    </BodyText>
                }
              <Seperator height='30px'/>
                <Button
                  text='Accept invite to Moonsift Pro'
                  msstyle="cta_alternative_post_signup"
                  needsMaxWidth={false}
                  onClick={this.requestPro}
                />
                <Seperator height='16px'/>
                <SmallerText isDesktop={ISDESKTOP}>
                  This invitation will expire and become invalid in 7 days.
                </SmallerText>
            </CenteredBox>
            <Seperator height='46px'/>
            {this.renderMoreInfoBox(ISDESKTOP)}
          </>
        );
      }
    } else {
      return(
        this.renderLoadingScreen()
      );
    }
  }

  renderUpgradeStatus(ISDESKTOP){
    if(this.state.is_loaded === true){
      if(this.state.pro_request_success===undefined){
        return(
          <CenteredBox>
            <SubtitleContainer>
              <SubtitleText isDesktop={ISDESKTOP}>Upgrading your account to</SubtitleText>
            </SubtitleContainer>
            <Seperator height='10px'/>
            {this.renderLogo(ISDESKTOP)}
          </CenteredBox>
        );
        } else if(this.state.pro_request_success===true){
          return(
            <CenteredBox>
            <UpgradeStatusHeading isDesktop={ISDESKTOP}>Upgrade Successful</UpgradeStatusHeading>
            <Seperator height='10px'/>
              <SubtitleContainer>
                <SubtitleText isDesktop={ISDESKTOP}>Please  <RedirectToHome href={R.HOME}>refresh</RedirectToHome> this page</SubtitleText>
              </SubtitleContainer>
            </CenteredBox>
          );
        } else if (this.state.pro_request_success===false){
          return(
            <FailureMessageBox>
              <UpgradeStatusHeading isDesktop={ISDESKTOP}>Upgrade to Pro unsuccessful</UpgradeStatusHeading>
              <Seperator height='13px'/>
                <SubtitleContainer isDesktop={ISDESKTOP}>
                    <SubtitleText isDesktop={ISDESKTOP}>
                      The Pro Pass link you used is no longer valid.
                      Email hello@moonsift.com to request a new one.
                    </SubtitleText>
                </SubtitleContainer>
                <Seperator height='28px'/>
                <Button
                  msstyle='option_positive'
                  text='Back to my collections'
                  width='242px'
                  onClick={this.goBack}
                />
            </FailureMessageBox>
          );
      }
    } else {
      return(
        this.renderLoadingScreen()
      );
    }
  }

  renderMoreInfoBox(ISDESKTOP){
    return(
      <MoreInfoBoxPadding>
        <MoreInfoBoxOuter>
          <MoreInfoBox>
            <MoreInfoBoxInner>
                <TEXTSTYLE.HEADING4>
                  More info
                </TEXTSTYLE.HEADING4>
                <Seperator height='10px'/>
                <MoreInfoText isDesktop={ISDESKTOP}>
                  A Moonsift Pro account gives you access to advanced features, priority on new store requests as well as <BottomLinkExternal href={EXTERNAL.PRO_EXPLANATION} target="_blank"><BottomLinkText isDesktop={ISDESKTOP}>other benefits</BottomLinkText></BottomLinkExternal>.
                </MoreInfoText>
                <Seperator height='26px'/>
                <BottomLink to={R.LANDING} target="_blank">
                  <BottomLinkText isDesktop={ISDESKTOP}>Why is everyone using Moonsift?</BottomLinkText>
                </BottomLink>
                <Seperator height='26px'/>
                <BottomLink to={R.TASTEMAKERLANDING} target="_blank">
                  <BottomLinkText isDesktop={ISDESKTOP}>Why do curators and stylists use Moonsift?</BottomLinkText>
                </BottomLink>
              </MoreInfoBoxInner>
            </MoreInfoBox>
        </MoreInfoBoxOuter>
      </MoreInfoBoxPadding>
    );
  }

  renderContent(ISDESKTOP){
    if(this.state.is_pro_already === true){
      return(
        this.renderYouAreProAlready(ISDESKTOP)
      );
    } else {
      return(
        this.state.requested_pro === true ? this.renderUpgradeStatus(ISDESKTOP) : this.renderInvitation(ISDESKTOP)
      );
    }
  }

  render(){
    const ISDESKTOP = this.props.windowWidth>=M.MOBILESWITCH;
    return(
      <Container className='container'>
        <TopMenu/>
        {AddHelmetTitle("Pro")}
        <MainContent>
          {this.renderContent(ISDESKTOP)}
        </MainContent>
      </Container>
    );
  }
}

const mapStateToProps = (state) => {
  return ({
    firebaseState:state.firebasestate,
    authUserUID:state.auth.id,
    owner:state.firestore_user_owner
  });
};

const mapDispatchToProps = () => {
  return {};
};

export default connect(mapStateToProps,mapDispatchToProps)(
    withFirebase(
      windowSize(
        ProLanding
      )
    )
);
