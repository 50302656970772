import React from 'react';
import styled, { createGlobalStyle } from 'styled-components';
import { AppProps } from './App.types';
import { Helmet } from 'react-helmet';
import { FooterPopup } from 'src/components/popups/FooterPopup';
import { CookieMessage } from 'src/components/popups/CookieMessage';

// These components need to be refactored for specific imports:
import GetNotifications from 'src/mvp22/popup-components/GetNotifications';
import RefreshPrompt from 'src/mvp22/menu-components/RefreshPrompt';
import GlobalFonts from 'src/mvp22/fonts/fonts';
import Feedback from 'src/mvp22/popup-components/Feedback';
import { Modal } from 'src/mvp22/modal-components/Modal';
import { Constants } from 'src/constants';
import { RouterSwitch } from 'src/components/core/RouterSwitch';

// TODO: check this still works on mobile!
// position: ${props=>props.isScrollable?'absolute':'fixed'};
const GlobalStyle = createGlobalStyle<{ isScrollable: boolean }>`
  body {
    overflow-y: ${(props) => (props.isScrollable ? 'scroll' : 'hidden')};
    width:100%;
    height:100%;
  }
  img {
    margin-bottom:0rem;
  }
`;

const Component: React.FC<AppProps> = ({ windowWidth, isScrollable }) => {
  return (
    <div className="EverythingContainer">
      <GlobalFonts />
      <GlobalStyle isScrollable={isScrollable} />
      <Helmet>
        <title>Moonsift</title>
      </Helmet>
      <Feedback />
      <CookieMessage />
      <RefreshPrompt />
      <Modal />
      <FooterPopup />
      <div className="MainBGContainer">
        <RouterSwitch />
      </div>
      {windowWidth >= Constants.MOBILESWITCH ? <GetNotifications /> : null}
    </div>
  );
};

export const App = styled(Component)`
  .MainBGContainer {
    color: default;
  }

  .EverythingContainer {
    width: 100%;
    height: 100%;
    font-size: 0px;
    text-align: center;
    top: 0px;
    left: 0px;
    background-color: white;
    color: black;
  }
`;
