import React, { useState, useEffect, useCallback, useContext } from 'react';
import { useSelector } from 'react-redux';
import { Constants } from 'src/constants';
import { ProductTileList } from 'src/mvp22/tile-components/ProductTileList';
import {
  ProductTileListContainerProps,
  ViewsDict,
} from './ProductTileList.types';
import { DBContext, FirebaseContext, RootState } from 'src/index';
import queryString from 'query-string';
import { useHistory, useLocation } from 'react-router-dom';
// DB:
import update_collection_product_user_views from 'src/mvp22/firebase-functions/update_collection_product_user_views';
// Hooks:
import { collectionItemParser } from 'src/store/parsers/collectionItem';
import { useDBRetrieveQuery } from 'src/hooks/useDBRetrieveQuery';
import { collectionViewsParser } from 'src/store/parsers/collectionViews';
import { useDBRetrieveDoc } from 'src/hooks/useDBRetrieveDoc';
import { PseudoProductElement } from 'src/components/collection/PseudoProductElement';
import { getCollectionItemStoreAs } from 'src/utils/getCollectionItemStoreAs';
import { ModalTypes } from 'src/types';
import { useSetModal } from 'src/hooks/useSetModal';

const Container: React.FC<ProductTileListContainerProps> = ({
  collectionUID,
  collectionUserUID,
  organisingHook,
}) => {
  // For the product popups:
  const [nextItemIDToShow, setNextItemIDToShow] = useState<string | null>('');
  const [currentItemIDToShow, setCurrentItemIDToShow] = useState('');
  // Selectors:
  const modalType = useSelector<RootState, ModalTypes | null>(
    (state) => state.modal.type,
  );
  const windowWidth = useSelector<RootState, number>(
    (state) => state.ui.windowWidth,
  );
  const authUserUID = useSelector<RootState, string | null>(
    (state) => state.auth.id,
  );
  //TODO: change these on refactoring:
  const firebase = useContext(FirebaseContext);
  const isPro = useSelector<RootState, boolean>((state) =>
    state.firestore_user_owner.pro ? true : false,
  );

  const renderOrder = organisingHook.renderOrder;

  // OTHER HOOKS:
  const history = useHistory();
  const location = useLocation();
  const [setModal, closeModal] = useSetModal();
  // Data from database, namely the product documents in the collection list of products:
  const dB = useContext(DBContext);
  useDBRetrieveQuery<'single_collection_item_list'>(
    dB,
    dB.collectionItemsQuery,
    'single_collection_item_list',
    collectionItemParser,
    undefined,
    [collectionUserUID, collectionUID],
    'listen',
  );
  const singleCollectionItemListData = useSelector(
    (state: RootState) => state.db.single_collection_item_list.data,
  );
  const singleCollectionItemListDataLoaded = useSelector(
    (state: RootState) => state.db.single_collection_item_list.set,
  );
  // Also get the user views doc:
  useDBRetrieveDoc<'single_collection_views'>(
    dB,
    dB.collectionViewsDoc,
    'single_collection_views',
    collectionViewsParser,
    undefined,
    [authUserUID, collectionUserUID, collectionUID],
    'listen',
  );
  const userViews = useSelector(
    (state: RootState) => state.db.single_collection_views.data,
  );

  const isMine = authUserUID === collectionUserUID;

  // Fetch all the associated collection doc items in a pseudo-element (so we can don't have to deal with subsections etc.)
  // This allows us to detect when the data is all loaded as opposed to loading this data in the ProductTile component.
  // This will populate redux:
  const renderPseudoCollectionItems = () =>
    singleCollectionItemListData.map((collectionItem) => (
      <PseudoProductElement
        key={collectionItem.id}
        {...{
          collectionUID,
          collectionUserUID,
          collectionItem,
        }}
      />
    ));
  // Get the data from redux for the pseudo elements:
  const productMap = useSelector((state: RootState) => state.productData);
  const loadedProducts =
    singleCollectionItemListDataLoaded &&
    productMap &&
    singleCollectionItemListData
      .map((collectionItem) =>
        productMap[
          getCollectionItemStoreAs(
            collectionUID,
            collectionUserUID,
            collectionItem.id,
          )
        ]
          ? true
          : false,
      )
      .reduce((a, b) => a && b, true);
  const nonReceived =
    singleCollectionItemListDataLoaded &&
    singleCollectionItemListData.length === 0;

  // EVENT FUNCTIONS:
  // Show or hide the Product modal, based on input product doc ids:
  const showProductModal = useCallback(
    (display: boolean, thisItemID: string | null, increment: number = 0) => {
      var newNextItemIDToShow = null;
      if (display === true && thisItemID) {
        // get order which may depend on if in organising mode or not:
        // (although you shouldn't be able to open it in organising mode!)
        const thisOrder = renderOrder().itemOrder;
        var oldIndex = thisOrder.indexOf(thisItemID);
        var newIndex = oldIndex + increment;
        // Loop around!:
        if (newIndex < 0) {
          newIndex = thisOrder.length - 1;
        } else if (newIndex >= thisOrder.length) {
          newIndex = 0;
        }
        newNextItemIDToShow = thisOrder[newIndex];
      }
      // CLEAR IF REQUIRED from history:
      // could be from mobile view OR from notification link:
      const currentItemIDFromHistory = queryString.parse(
        location.search,
      ).product;
      if (display !== true) {
        if (currentItemIDFromHistory) {
          history.push({ search: `` });
        }
        setNextItemIDToShow(newNextItemIDToShow); //null
      }
      const ISDESKTOP = windowWidth >= Constants.MOBILESWITCH;
      // If is desktop mode then just set via the state:
      if (ISDESKTOP) {
        setNextItemIDToShow(newNextItemIDToShow);
      } else {
        // Otherwise set this via the history so that user pressing back does not remove them from the current collection.
        if (display === true) {
          history.push({ search: `?product=${newNextItemIDToShow}` });
        }
      }
    },
    [history, location.search, renderOrder, windowWidth],
  );

  const closeProductModal = useCallback(() => {
    showProductModal(false, null);
  }, [showProductModal]);

  // This is here as it redoes the whole view document dictionary and removes deleted products to save space in the DB.
  // It is passed to the product-modal.
  const setProductModalViewed = useCallback(
    (productUID: string, collectionUID: string, collectionUserUID: string) => {
      // for security(!):
      // we're copying it so as not to end up with loads of redundant deleted products...:
      if (
        authUserUID &&
        productUID &&
        collectionUID &&
        collectionUserUID &&
        singleCollectionItemListData
      ) {
        const newViewsDict: ViewsDict = {};
        singleCollectionItemListData.forEach((productEntry) => {
          if (userViews && userViews[productEntry.id]) {
            newViewsDict[productEntry.id] = userViews[productEntry.id];
          }
        });
        newViewsDict[productUID] = dB.serverTimestamp();
        // And set the value:
        update_collection_product_user_views(
          collectionUID,
          collectionUserUID,
          newViewsDict,
          firebase,
          authUserUID,
        );
      }
    },
    [authUserUID, dB, firebase, singleCollectionItemListData, userViews],
  );

  // Open the product modal from the URL:
  // E.g. from links and from notifcations about price drops
  useEffect(() => {
    // get current prop from params:
    const newNextItemIDToShowFromHistory = queryString.parse(
      location.search,
    ).product;
    const newNextItemIDToShow = newNextItemIDToShowFromHistory
      ? newNextItemIDToShowFromHistory
      : nextItemIDToShow;
    //// update the popup data regardless!
    if (
      newNextItemIDToShow &&
      typeof newNextItemIDToShow === 'string' &&
      productMap &&
      productMap[
        getCollectionItemStoreAs(
          collectionUID,
          collectionUserUID,
          newNextItemIDToShow,
        )
      ]
    ) {
      // NOTE: As updates regardless, will in future need to change if product settings changes to more than one type...
      // i.e. if it becomes a group of modals.
      setModal({
        type: ModalTypes.product_settings,
        itemUID: newNextItemIDToShow,
        collectionUID,
        collectionUserUID,
        showProductModal,
        setProductModalViewed,
      });
    }
    // if does not exist in collection anymore (e.g. deleted) or bad product ID:
    else if (newNextItemIDToShow && loadedProducts) {
      setModal({
        type: ModalTypes.product_does_not_exist,
        closeProductModal,
      });
    } else {
      // do not show anything if we haven't anything or haven't loaded the products yet, ensure the popup is closed:
      if (
        modalType &&
        (modalType === ModalTypes.product_settings ||
          modalType === ModalTypes.product_does_not_exist)
      ) {
        closeModal();
      }
    }
    if (
      currentItemIDToShow !== newNextItemIDToShow &&
      typeof newNextItemIDToShow === 'string'
    ) {
      // check if it is expected:
      // If we are setting from history now then remove any state setting to prevent this reappearing on a resize etc.:
      if (newNextItemIDToShow) {
        setNextItemIDToShow(newNextItemIDToShow);
      }
      setCurrentItemIDToShow(newNextItemIDToShow);
    }
  }, [
    authUserUID,
    closeModal,
    closeProductModal,
    collectionUID,
    collectionUserUID,
    currentItemIDToShow,
    loadedProducts,
    location.search,
    nextItemIDToShow,
    modalType,
    productMap,
    setModal,
    setProductModalViewed,
    showProductModal,
  ]);

  return (
    <>
      {renderPseudoCollectionItems()}
      <ProductTileList
        {...{
          authUserUID,
          isPro,
          collectionUID,
          collectionUserUID,
          isMine,
          loadedProducts,
          nonReceived,
          renderOrder,
          userViews,
          // Opening modals:
          showProductModal,
          // Organising:
          isOrganising: organisingHook.isOrganising,
          // Organising - ordering:
          moveItem: organisingHook.moveItem,
          // Organising - subsections:
          addSubsection: organisingHook.addSubsection,
          moveSubsection: organisingHook.moveSubsection,
          deleteSubsection: organisingHook.deleteSubsection,
          updateSubsectionName: organisingHook.updateSubsectionName,
          addOrRemoveItemToSubsection:
            organisingHook.addOrRemoveItemToSubsection,
          focusSubsectionTextbox: organisingHook.focusSubsectionTextbox,
          // Organising - covers
          choosingCoverImage: organisingHook.choosingCoverImage, // choosing cover?
          setChoosingCoverImage: organisingHook.setChoosingCoverImage, // open choosing cover etc.
          setOrganisingCoverImageURL: organisingHook.setOrganisingCoverImageURL,
          deleteCoverByIndex: organisingHook.deleteCoverByIndex,
          selectCover: organisingHook.selectCover,
        }}
      />
    </>
  );
};

export { Container as ProductTileList };
