/*

If not signed in show this at the bottom of collections.

todo: functional, typescript

*/
import React, { Component } from 'react';
import styled from 'styled-components';
import {M} from 'src/mvp22/constants';
import MEDIA from 'src/mvp22/media';
import {TEXTSTYLE} from 'src/mvp22/style-components/Text';
import Button from 'src/mvp22/form-components/Button';
import R from 'src/routes';

const Container = styled.div `
  margin-top:36px;
  background-color:${M.COL.BG.LIGHT};
  display:flex;
  flex-direction:column;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
  height: 407px;
`;

const PromptBox = styled.div`
  display:flex;
  flex-direction:column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 280px;
  background: #FFFFFF;
  border: 1px solid ${M.COL.LINE.MID};
  box-sizing: border-box;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
  padding: 10px;
  min-width: 296px;
`;

const PromptHeading = styled(TEXTSTYLE.HEADING4)`
  color: ${M.COL.TEXT.BLACK};
  margin-bottom: 14px;
`;

const PromptText = styled(TEXTSTYLE.BODY2)`
  color: ${M.COL.TEXT.BLACK};
  max-width: 356px;
`;

const RetailerLogosContainer = styled.div`
  width: 100%;
`;

const RetailerLogos = styled.div`
  height: 70px;
  background-image:url(${MEDIA.RETAILER_LOGOS});
  background-repeat: no-repeat;
  background-position: center;
`;

const BottomText = styled(TEXTSTYLE.LINK)`
  color: ${M.COL.TEXT.BLACK};
  border-bottom: 1px solid ${M.COL.LINE.BLACK};
  margin-top: 16px;
  margin-bottom: 56px;
  width: 200px;
  align-self: center;
`;

export const ButtonBox = styled.div`
  margin-top: 35px;
  display: flex;
  justify-content: center;
  width: 100%;
`;

const PromptBoxOuter = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  bottom: 18px;
  max-width: 536px;
  width: 90%;
`;

export const ButtonSpacer = styled.div`
  width: 16px;
`;

class SignUpPrompt extends Component {
  render() {
    const signup_params = this.props.signup_params?this.props.signup_params:"";
    return (
      <Container>
        <PromptBoxOuter>
            <PromptBox>
              <PromptHeading>
                You are signed out
              </PromptHeading>
              <PromptText>
                Log in or Sign up to curate your own collections
              </PromptText>
              <ButtonBox>
                <TEXTSTYLE.LINK to={R.SIGNIN}>
                  <Button
                    isDesktop={this.props.isDesktop}
                    text="Login"
                    msstyle="prompt_button_light"
                    width="100%"
                  />
                </TEXTSTYLE.LINK>
                <ButtonSpacer/>
                <TEXTSTYLE.LINK to={R.SIGNUP+`?post=redirect_path&redirect_path=${signup_params}`}>
                  <Button
                    isDesktop={this.props.isDesktop}
                    text="Signup"
                    msstyle="prompt_button_dark"
                    width="100%"
                  />
                </TEXTSTYLE.LINK>
              </ButtonBox>
            </PromptBox>
            <BottomText to={R.LANDING}>
              <TEXTSTYLE.BODY4>
                  Why’s everyone using Moonsift?
              </TEXTSTYLE.BODY4>
            </BottomText>
        </PromptBoxOuter>
        <RetailerLogosContainer>
          <RetailerLogos/>
        </RetailerLogosContainer>
      </Container>
    );
  }

}

export default SignUpPrompt;
