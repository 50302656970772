/*

REDUX db

There is a document that details notification views etc. as well as a list of actual notifications.

This is for the notifications viewed in the dropdown (bell) NOT the notifications page which pulls it's own notifications.

TODO: move to new DB hooks...!
*/
import {
  UPDATE_NOTIFICATIONS,
  FIRESTORE_REDUX_REMOVE_ALL,
  REMOVE_NOTIFICATIONS
} from 'src/mvp22/redux-components/actions';

const DEFAULT_FIREBASE = {
  notification_list:[],
  notification_doc:null,
  new_notifications_count:0,
  time_at_last_view:{seconds:0},
  show_get_notifications:false
};

// As separate function for aesthetic reasons only:
const update_notications = (state,action)=>{
  const data_out = Object.assign({},state,action.data);
  if (data_out.notification_list.length>0 && data_out.notification_doc){
    const last_viewed_seconds = (data_out.notification_doc.time_at_last_view)?data_out.notification_doc.time_at_last_view.seconds:0;
    data_out.new_notifications_count = data_out.notification_list.filter(
      doc=>last_viewed_seconds<=((doc.created_at)?doc.created_at.seconds:-1)
    ).length;
  }
  if (data_out.notification_doc){
    data_out.time_at_last_view = (data_out.notification_doc.time_at_last_view)?data_out.notification_doc.time_at_last_view:{seconds:0};
    data_out.show_get_notifications = (data_out.notification_doc.show_get_notifications===true)?true:false;
  }
  return data_out;
};

function firestoreNotifications(state = DEFAULT_FIREBASE, action) {
  //console.log(action.type,action.data)
  switch (action.type) {
    case UPDATE_NOTIFICATIONS:
      return update_notications(state,action);
    case REMOVE_NOTIFICATIONS:
    case FIRESTORE_REDUX_REMOVE_ALL:
      if (state.notification_doc_channel){
        state.notification_doc_channel.close();
      }
      if (state.notification_list_channel){
        state.notification_list_channel.close();
      }
      return DEFAULT_FIREBASE;
    default:
      return state;
  }
}


export default firestoreNotifications;
