import React from 'react';
import { useHistory } from 'react-router-dom';

import R from 'src/routes';
import { PostinstallTwoContainerProps } from './PostinstallTwo.types';
import { PostinstallTwo } from './PostinstallTwo.component';

const PostinstallTwoContainer: React.FC<PostinstallTwoContainerProps> = (
  props,
) => {
  const history = useHistory();
  const handleBack = React.useCallback(
    (event) => {
      event.preventDefault();
      history.goBack();
    },
    [history],
  );
  const handleForth = React.useCallback(
    (event) => {
      event.preventDefault();
      history.push(R.POSTINSTALL_THREE);
    },
    [history],
  );
  return (
    <PostinstallTwo onBack={handleBack} onForth={handleForth} {...props} />
  );
};

export { PostinstallTwoContainer as PostinstallTwo };
