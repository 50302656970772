import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from 'src';
import { useAuth } from 'src/hooks/useAuth';
import { useAuthUserData } from 'src/hooks/useAuthUserData';
import { useReactWindowSize } from 'src/hooks/useReactWindowSize';
import { useScrollToTopOnLocationChange } from 'src/hooks/useScrollToTopOnLocationChange';
import { Action, ActionNames, ModalTypes } from 'src/types';
import { App } from './App.component';
import { AppContainerProps } from './App.types';

const Container: React.FC<AppContainerProps> = () => {
  const windowWidth = useSelector<RootState, number>(
    (state) => state.ui.windowWidth,
  );
  const modalType = useSelector<RootState, ModalTypes | null>(
    (state) => state.modal.type,
  );
  useReactWindowSize();
  useScrollToTopOnLocationChange();
  useAuth();
  useAuthUserData();
  // do not allow scrolling if modal is open:
  const isScrollable = modalType ? false : true;
  // Remove all redux data if unmounting the app:
  const dispatch = useDispatch();
  useEffect(() => {
    return () => {
      dispatch<Action>({
        type: ActionNames.UNSET_ALL,
        payload: undefined,
      });
    };
  }, [dispatch]);
  return (
    <App
      {...{
        isScrollable,
        windowWidth,
      }}
    />
  );
};

export { Container as App };
