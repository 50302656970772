/*
Product Tile rendering component, renders the products tiles in producttilelist / subsections in collections.

For logic see:
react-app/src/components/collection/ProductTile/ProductTile.container.tsx

Renders only
TODO:typescript, design-system and move to:
react-app/src/components/collection/ProductTile/ProductTile.component.tsx

*/

import React from 'react';
import styled, {css} from 'styled-components';
import {M} from 'src/mvp22/constants';
import {TEXTSTYLE} from 'src/mvp22/style-components/Text';
import MEDIA from 'src/mvp22/media';
import {UpDownVote} from './UpDownVote';
import {withFirebase} from 'src/mvp22/Firebase';
import LoadCheck from 'src/mvp22/image-components/LoadCheck';
import WorkingDots from 'src/mvp22/image-components/WorkingDots';
import ReactionsInfo from 'src/mvp22/menu-components/ReactionsInfo';
import {Coathanger} from './Coathanger';

const TileContainer = styled.div`
  width: 250px;
  height: 421px;
  background-color:${M.COL.BG.WHITE};
  border: ${props => props.isOrganising ? '0.5px solid transparent' : `0.5px solid ${M.COL.LINE.MID}`};
  border-radius: 5px;
  display:flex;
  flex-direction:column;
  justify-content:space-between;
  position:relative;
`;

const TileContainerBorder = styled.div`
  background-color:${M.COL.BG.WHITE};
  border: ${props => props.isOrganising ? '2px solid black' : '0.5px transparent #CECFD1'};
  border-radius: 7px;
  display:flex;
  margin:${props => props.isOrganising ? '14px' : '16px'};
  margin-bottom: ${props => props.isOrganising ? '16px' : '26px'};
`;

const MetaContainer = styled.div`
  height: 101px;
  width:100%;
  padding:14px 24px;
  display:flex;
  flex-direction:column;
  justify-content:space-between;
  box-sizing:border-box;
`;

const BottomContainer = styled.div`
  height:40px;
  display:flex;
  flex-direction:row;
  flex-wrap: nowrap;
  width:100%;
  align-items:center;
`;

const RightIcon = styled.img`
  height: 16px;
  padding-left:5px;
  cursor:pointer;
  display:inline-block;
  flex-shrink:0;
`;

const LeftIconContainer = styled.div`
  display:flex;
  flex-direction:row;
  flex-wrap: nowrap;
  align-items:center;
  flex-shrink:0;
`;

const RightLinkContainer = styled.span`
  display:flex;
  width:100%;
  flex-direction:row;
  flex-wrap: nowrap;
  align-items:center;
  justify-content:flex-end;
  position: relative;
  flex-shrink:1;
  overflow: hidden;
`;

const ExternalRetailerLink = styled(TEXTSTYLE.LINKABOLD)`
  display:flex;
  width:100%;
  justify-content:flex-end;
`;

const DomainText = styled(TEXTSTYLE.OTHER4)`
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  flex-shrink:1;
  width:100%;
  text-align:right;
`;

const LeftIconCSS = css`
  height:30px;
  width:30px;
  margin:6px 0px;
  cursor:pointer;
  background-image:url("${props=>props.bGURL}");
  background-repeat:no-repeat;
  background-position:center;
  background-size:16px;
  border-radius:15px;
  &:hover {
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
  }
`;

const LeftIcon = styled.div`
  &:focus{
    outline:none;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
  }
  ${LeftIconCSS}
`;

const LeftIconWorking = styled(WorkingDots)`
  ${LeftIconCSS}
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
  position: relative;
  right: 7px;
`;

const ProductImage = styled.img`
  width:auto;
  height:auto;
  max-height:302px;
  max-width:248px;
  cursor:pointer;
`;

const ImageContainer = styled.div`
  height:320px;
  &:focus{
    outline:none;
  }
`;

const ProductImageClickContainer = styled(TEXTSTYLE.LINKA)`
  width:100%;
  height:100%;
  cursor:pointer;
  display:flex;
  align-items:flex-end;
  justify-content:center;
  position:relative;
`;

const Product = styled.div`
  position:relative;
`;

const ProductFlex = styled.div`
  display:flex;
  flex-direction:column;
  flex-wrap:nowrap;
  text-align: left;
`;

const SidewaysBurgerContainer = styled(TEXTSTYLE.LINKA)`
  width:48px;
  height:48px;
  position:absolute;
  top:0px;
  right:0px;
  border-radius:24px;
  box-sizing:border-box;
  background-color: ${M.COL.BG.WHITE};
  display:flex;
  justify-content:center;
  align-items:center;
`;

const CoverSelectorBox = styled(TEXTSTYLE.LINKA)`
  width:26px;
  height:26px;
  position:absolute;
  top:16px;
  left:16px;
  border-radius: 5px;
  box-sizing:border-box;
  background-color: ${props=>props.used?M.COL.BG.BLACK:M.COL.BG.WHITE};
  display:flex;
  justify-content:center;
  align-items:center;
  border: 2px solid ${M.COL.BUTTON.BLACK};
`;

const CoverSelectorText = styled(TEXTSTYLE.HEADING5)`
  color:${M.COL.TEXT.WHITE};
`;

const SidewaysBurger = styled.div`
  height:30px;
  width:30px;
  background-image:url(${props=>props.srcMouseOver});
  background-size: 40px 40px;
  background-position: center;
  background-repeat:no-repeat;
  border-radius:24px;
  box-sizing:border-box;
  &:hover {
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
  }
  &:active{
    outline:none;
  }
  cursor:pointer;
`;

const ReactionsInfoContainer = styled.div`
  width:100%;
  position: absolute;
  bottom: -10px;
`;

const CollectionNameContainer = styled(TEXTSTYLE.BODY5)`
  width: 100%;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  flex-shrink:0;
`;

const SidewaysBurgerWorking = styled(LeftIconWorking)`
  right:auto;
`;

const PriceContainer = styled(TEXTSTYLE.LINKA)`
  display:flex;
  flex-direction:row;
  cursor:pointer;
  width: max-content;
`;
const PriceContainerContainer = styled.div`
  width:100%;
  overflow: hidden;
`;

const UpdatedSymbol = styled.img`
  height:16px;
  padding:0px 6px;
  opacity:0.7;
`;

const PriceSymbol = styled.img`
  height:16px;
  padding:0px 6px;
`;

const IconContainer = styled(TEXTSTYLE.LINKA)`
  position: relative;
  right: 7px;
`;

const RightArrow = styled.img`
  position: absolute;
  top: 36%;
  right: 9px;
  color: #111;
  cursor: pointer;
  opacity: .75;
  transition: opacity .15s cubic-bezier(.4, 0, 1, 1);
  z-index: 10;
  &:focus {
    outline: 0;
  }
  &:hover {
    opacity: .5;
  }
  display: ${props => props.visible ? '' : 'none'};
`;

const LeftArrow = styled(RightArrow)`
  transform: scaleX(-1);
  left: 9px;
`;




export const ProductTile = (
  {
    authUserUID,
    isMine,
    viewingData,
    priceChange,
    isWorkingPurchased,
    isWorkingUpvote,
    isWorkingDownvote,
    isPurchased,
    showArrows,
    isLoaded,
    showReactionsBar,
    isOrganising,
    selectedCoverImage,
    choosingCoverImage,
    description,
    lastUpdated,
    price,
    domain,
    isSaved,
    thisProductImage,
    loadingPopup,
    collectionItem,
    collectionUID,
    // Functions:
    deleteProduct,
    reactProduct,
    addOrRemoveProductToPurchased,
    setArrowsVisibility,
    link,
    selectCover,
    changeViewingProductImage,
    showProductModal,
    loadProductMenu,
    loadProductUpdateMenu,
    collectionUserUID,
    itemUID,
    isDeleting,
    reaction
  }
)=>{

  const renderAddToPurchased=()=>{
    if(isMine===true && collectionUID!=="purchased"){
      if (
        isWorkingPurchased
      ){
        return(<LeftIconWorking/>);
      }
      else{
        return(
          <Coathanger
           addOrRemoveProductToPurchased={()=>addOrRemoveProductToPurchased(!isPurchased)}
           isPurchased={isPurchased}
          />
        );
      }
    }
  };

  const renderIconButtons=()=>{
    if(isMine===true){
      if (
        isDeleting
      ){
        return(<LeftIconWorking/>);
      }
      else{
        return(
            <IconContainer
              onClick={deleteProduct}
              href="#"
            >
              <LeftIcon
               bGURL={MEDIA.SMALL_BIN}
               alt="Remove product from this collection"
              />
            </IconContainer>
        );
      }
    }
    else{
      return(
        <>
          {
            (isWorkingUpvote)?
              <LeftIconWorking/>
            :
              <UpDownVote
                isLoggedIn={authUserUID?true:false}
                type='upvote'
                enabledClick={(event)=>reactProduct(event,reaction,'upvote')}
                selected={'upvote'===reaction}
                alt="Like this item in this collection"
              />
          }
          {
            (isWorkingDownvote)?
              <LeftIconWorking/>
            :
              <UpDownVote
                isLoggedIn={authUserUID?true:false}
                type='downvote'
                enabledClick={(event)=>reactProduct(event,reaction,'downvote')}
                selected={'downvote'===reaction}
                alt="Downvote this item in this collection"
              />
          }
        </>
      );
    }
  };

  const renderUpdatedSymbol=()=>{

    if (lastUpdated!==undefined){
      if(lastUpdated[1]==="orange" || lastUpdated[1]==="red"){
        return(
          <UpdatedSymbol src={MEDIA.RECENCY_GREY}/>
        );
      }
    }
    else{
      return "";
    }
  };

  const renderPriceSymbol=()=>{
    if (priceChange!==undefined){
      if(priceChange.sign==="-"){
        return(
          <PriceSymbol src={MEDIA.PRICE_DOWN}/>
        );
      }
    }
    return "";
  };


  const renderTile = ()=>{
    return(
      <TileContainerBorder isOrganising={isOrganising}>
        <TileContainer
          isOrganising={isOrganising}
        >
          <Product>
            <ProductFlex>
              <ImageContainer
                onMouseOver={()=>setArrowsVisibility(true)}
                onMouseOut={()=>setArrowsVisibility(false)}
              >
                <RightArrow
                  onClick={() => changeViewingProductImage(1)}
                  visible={showArrows}
                  src={MEDIA.RIGHT_ARROW_SMALL}
                />
                <LeftArrow
                  onClick={() => changeViewingProductImage(-1)}
                  visible={showArrows}
                  src={MEDIA.RIGHT_ARROW_SMALL}
                />
                <ProductImageClickContainer
                  href="#"
                  onClick={showProductModal}
                >
                  <LoadCheck src={thisProductImage}>
                    <ProductImage/>
                  </LoadCheck>
                  <ReactionsInfoContainer className="ReactionsInfoContainer">
                    <ReactionsInfo
                      collection_entry={collectionItem}
                      mouseover_tile={showReactionsBar}
                      viewing_data={viewingData}
                      auth_user_uid={authUserUID}
                    />
                  </ReactionsInfoContainer>
                </ProductImageClickContainer>
              </ImageContainer>
              <MetaContainer>
              <CollectionNameContainer>{description}</CollectionNameContainer>
              <PriceContainerContainer>
                <PriceContainer href="#" onClick={loadProductUpdateMenu}>
                <TEXTSTYLE.OTHER4>{price}</TEXTSTYLE.OTHER4>
                {renderUpdatedSymbol()}
                {renderPriceSymbol()}
                </PriceContainer>
              </PriceContainerContainer>
                <BottomContainer>
                  <LeftIconContainer>
                    {
                      renderIconButtons()
                    }
                    {
                      renderAddToPurchased()
                    }
                  </LeftIconContainer>
                  <RightLinkContainer>
                    <ExternalRetailerLink href={link} target="_blank">
                        <DomainText>{domain}</DomainText>
                        <RightIcon src={MEDIA.OPEN_RETAILER}/>
                    </ExternalRetailerLink>
                  </RightLinkContainer>
                </BottomContainer>
              </MetaContainer>
            </ProductFlex>
            <SidewaysBurgerContainer onClick={loadProductMenu} href="#">
              {(loadingPopup)?
                <SidewaysBurgerWorking/>
              :
                <SidewaysBurger srcMouseOver={(isMine)?MEDIA.SIDEWAYS_BURGER:(isSaved)?MEDIA.BLACK_TICK_CIRCLE:MEDIA.BLACK_PLUS_CIRCLE}/>
              }
            </SidewaysBurgerContainer>
            {
              (choosingCoverImage)?
                <CoverSelectorBox
                  onClick={(event)=>selectCover(event,itemUID)}
                  href="#"
                  used={selectedCoverImage?true:false}
                >
                  {
                    selectedCoverImage?
                      <CoverSelectorText>
                        {selectedCoverImage}
                      </CoverSelectorText>
                    :
                      null
                  }

                </CoverSelectorBox>
              :
                null
            }

          </Product>
          </TileContainer>
      </TileContainerBorder>
    );
  };
  return(
    (isLoaded)?renderTile():<></>
  );
};




export default withFirebase(ProductTile);
