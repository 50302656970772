/*

View for the ability to change password and close account.

TODO: typescript, camelCase, make functional, non-default exports, design system...

*/
import React from 'react';
import {AddHelmetTitle} from "src/mvp22/core-components/helmet";
import { Component } from 'react';
import styled from 'styled-components';
import {M} from 'src/mvp22/constants';
import Button from 'src/mvp22/form-components/Button';
import { withFirebase } from 'src/mvp22/Firebase';
import {TEXTSTYLE} from 'src/mvp22/style-components/Text';
import {FullBlank} from 'src/mvp22/menu-components/TopMenu';
import TopMenu from 'src/mvp22/menu-components/TopMenu';
import {withRouter} from 'react-router-dom';
import R from 'src/routes';
import {requiresAuth} from 'src/mvp22/redux-components/requiresAuth';
import segmentEvent from "src/mvp22/segment-components/SegmentEvent";

const Container = styled.div`
  background-color:${M.COL.BUTTON.WHITE};
  color:${M.COL.TEXT.BLACK};
  padding-left:30px;
  padding-right:30px;
  text-align:center;
  display:flex;
  justify-content:center;
  align-items:center;
`;

const FormContainer = styled.div`
  width:100%;
  max-width:368px;
  margin-top: 120px;
`;

const FormHeadingContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  text-align:left;
`;

const Seperator = styled.div `
  height:${props=>props.height};
`;

const PreferenceTitle = styled(TEXTSTYLE.BODY2)`
  font-weight: bold;
  text-align: left;
  font-size: 14px;
  line-height: 20px;
  margin-bottom: 6px;
`;

const PreferenceTitleRed = styled(PreferenceTitle)`
  color: ${M.COL.TEXT.ERROR};
`;

const PreferenceDesc = styled(TEXTSTYLE.BODY2)`
  color: ${M.COL.TEXT.BLACK};
  text-align: left;
  font-size: 14px;
  line-height: 20px;
`;

const CancelAndConfirmButtonsContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  padding-right: 20px;
`;

const PreferenceContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: baseline;
  margin-bottom: 16px;
`;

const PreferenceDescContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const UnsubscribeLink = styled(TEXTSTYLE.BODY2)`
  font-size: 14px;
  line-height: 20px;
  text-decoration-line: underline;
  color: ${M.COL.TEXT.MID};
  text-align: left;
  cursor: default;
  &:hover{
    color: ${M.COL.TEXT.BLACK}
  }
`;

class EditAccountPreference extends Component {

  constructor() {
    super();
    this.goBack = this.goBack.bind(this);
  }

  goBack(event){
    this.props.history.goBack();
    event.preventDefault();
    return false;
  }

  componentDidMount(){
    segmentEvent("Viewed Edit Account Preferences");
  }

  render(){
    return(
      <FullBlank className='fullBlank'>
        <TopMenu hug="edge"/>
        {AddHelmetTitle("Account Settings")}
          <Container className='container'>
          <FormContainer>
              <FormHeadingContainer>
                <TEXTSTYLE.HEADING2>Account Settings</TEXTSTYLE.HEADING2>
              </FormHeadingContainer>

              <Seperator height='19px'/>

              <PreferenceContainer>
                <PreferenceDescContainer>
                  <PreferenceTitle>Reset password</PreferenceTitle>
                  <PreferenceDesc>Please use the following link to change your password:</PreferenceDesc>
                  <UnsubscribeLink>
                    <TEXTSTYLE.LINK to={R.FORGOTPASSWORD}>
                      Change password
                    </TEXTSTYLE.LINK>
                  </UnsubscribeLink>
                </PreferenceDescContainer>
              </PreferenceContainer>

              <Seperator height='10px'/>

              <PreferenceContainer>
                <PreferenceDescContainer>
                  <PreferenceTitleRed>Close account</PreferenceTitleRed>
                    <PreferenceDesc>
                      Closing your account is irreversible. It will delete all your saved items and collections.<br/><br/>
                      Please email us at support@moonsift.com from the email associated with your account instructing us to delete it and we will do so.
                    </PreferenceDesc>
                </PreferenceDescContainer>
              </PreferenceContainer>

              <Seperator height='22px'/>

              <CancelAndConfirmButtonsContainer>
                <TEXTSTYLE.LINKA href="#" onClick={this.goBack}>
                  <Button
                    text="Back"
                    msstyle="option_positive"
                    width="123px"
                    tabIndex={3}
                  />
                </TEXTSTYLE.LINKA>
              </CancelAndConfirmButtonsContainer>
          </FormContainer>
          </Container>
      </FullBlank>
    );
  }

}

export default withRouter(withFirebase(requiresAuth(EditAccountPreference)));
