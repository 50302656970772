import React from 'react';
import { useHistory } from 'react-router-dom';

import { CollectionNotFoundContainerProps } from './CollectionNotFound.types';
import { CollectionNotFound } from './CollectionNotFound.component';

const CollectionNotFoundContainer: React.FC<
  CollectionNotFoundContainerProps
> = (props) => {
  const history = useHistory();
  const handleBack = React.useCallback(() => {
    history.goBack();
  }, [history]);
  return <CollectionNotFound onBack={handleBack} {...props} />;
};

export { CollectionNotFoundContainer as CollectionNotFound };
