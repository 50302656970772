import 'react-app-polyfill/stable';
// To support .allSettled explicitly since this is in the ECMA2020 spec, which createreactapp's polyfill doesn't detect yet:
import 'core-js/features/promise';
import React from 'react';
import ReactDOM from 'react-dom';
import registerServiceWorker from './registerServiceWorker';
import { createBrowserHistory } from 'history';
import { Provider } from 'react-redux';
import { createStore, applyMiddleware } from 'redux';
import reducers from 'src/store/reducers';
import { App } from 'src/components/core/App';
import { BrowserRouter as Router } from 'react-router-dom';
import createSagaMiddleware from 'redux-saga';
import rootSaga from 'src/store/sagas';
import { DB } from 'src/services/DB';
import { Auth } from './services/Auth';
import { Cloud } from './services/Cloud';
import { Notifications } from './services/Notifications';
import { Firebase } from './services/Firebase';
import { ThemeProvider } from 'styled-components';
import {
  batmanTheme,
  CssBaseline,
  setAppElement,
} from '@moonsifttech/design-system';
import { composeWithDevTools } from 'redux-devtools-extension';
import '@fontsource/roboto/700.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/300.css';
import '@fontsource/josefin-sans/700.css';
import '@fontsource/josefin-sans/300.css';
import '@moonsifttech/font-juana';

// Redux-thunk
// TODO: remove this when all converted to sagas
import thunkMiddleware from 'redux-thunk';
import FirebaseMVP22 from './mvp22/Firebase';
import { Mobile } from './services/Mobile';

// This helps keep a11y on modal components that use react-modal
// http://reactcommunity.org/react-modal/accessibility/
setAppElement('#root');

const history = createBrowserHistory();
require('dotenv').config();

// Set up Redux store:
const sagaMiddleware = createSagaMiddleware();
const store = createStore(
  reducers,
  composeWithDevTools(
    applyMiddleware(
      thunkMiddleware, // TODO: Remove this when all converted to Sagas.
      sagaMiddleware,
      //    loggerMiddleware // apparently "neat middleware that logs actions"!
    ),
  ),
);
sagaMiddleware.run(rootSaga);
export type RootState = ReturnType<typeof store.getState>;

// Set up services:
const app = new Firebase();
const dB = new DB(app.app);
const auth = new Auth(app.app);
const cloud = new Cloud(app.app);
const notifications = new Notifications(app.app);
const mobile = new Mobile();
export const DBContext = React.createContext<DB>(dB);
export const AuthContext = React.createContext<Auth>(auth);
export const CloudContext = React.createContext<Cloud>(cloud);
export const NotificationsContext =
  React.createContext<Notifications>(notifications);
export const MobileContext = React.createContext<Mobile>(mobile);

//TODO: Remove when all firebase functions replaced with new contexts:
const firebaseMVP22 = new FirebaseMVP22(app, dB, auth, cloud, notifications);
export const FirebaseContext = React.createContext<Firebase>(firebaseMVP22);

// A and B below are for sending page load calls to segment. they are a necessary part of making the tools downstream from segment work, e.g. mixpanel.
// A. captures cases where a user visits a page after their initial loading of our web app. for example when they land on moonsift.com and then go to moonsift.com/retailers.
history.listen((location) => {
  window.analytics.page(location.pathname);
});

// B. captures all initial page loads / reloads / when addresses are typed in the url bar
window.analytics.page();

//<Router history={history}>

// Render:
ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <Router>
        <ThemeProvider theme={batmanTheme}>
          <CssBaseline />
          <App />
        </ThemeProvider>
      </Router>
    </Provider>
  </React.StrictMode>,
  document.getElementById('root'),
);

// Service worker:
registerServiceWorker();

// Message:
console.log(
  "%c\n      MOONSIFT\n%c\n ** WE'RE HIRING ** Help us build Moonsift - join our groovy team! Get in touch: davidw [at] moonsift.com%c\nCurrently recruiting react ninjas, javascript extension gurus, ace data scientists, dev-op divas\nThis is version COTTONTAIL-2.1.6%c\nPlease report any bugs to us using the feedback button.",
  "font-family:Arial;font-size:32px;background-repeat:none;background-image:url('https://www.moonsift.com/static/logos/logo.svg');background-size:contain;background-position:0% 50%;background-repeat:no-repeat;background-color:#FEDF91;color:black;",
  'font-size:12px;font-family:Arial;background-color:lightgreen',
  'font-size:12px;font-family:Times New Roman;margin-top:4px',
  'font-size:12px;font-family:Arial;background-color:pink;margin-top:4px',
);
