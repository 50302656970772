/*

Order the image list for remaining images of a product:

Move to utils

*/

export function order_image_list(original_list,extra_list,original_image){
  // first image is the currently specified one (if any):
  const first_part_of_list = (original_list.slice(0,1));
  const list_to_sort = [...original_list.slice(1,),...extra_list];
  // move first of this list to end as is often the same!
  const end_item = list_to_sort.shift();
  if (end_item){
    list_to_sort.push(end_item);
  }
  const full_list_ordered = [...first_part_of_list, ...list_to_sort];
  // make sure there are no duplicate urls:
  const unique_list = [...new Set(full_list_ordered)]; // maintains order!
  if (original_image){
    return unique_list.filter(x=>x!==original_image);
  }
  // This function basically exists in case we want to do any sorting on the image list.
  // Consider for deletion.
  return unique_list;
}
