/*

Reports events to Segment to feed into other analytics tools e.g. Google
TODO: typescript
*/
const segmentEvent = (eventName) => {
   window.analytics.track(eventName, {
     event_name: eventName
   });
};

export default segmentEvent;
