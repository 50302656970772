import React from 'react';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';

import { DBContext, RootState } from 'src';
import R from 'src/routes';
import { useDBUpdateDoc } from 'src/hooks/useDBUpdateDoc';
import segmentEvent from 'src/mvp22/segment-components/SegmentEvent';
import { PostinstallOneContainerProps } from './PostinstallOne.types';
import { PostinstallOne } from './PostinstallOne.component';

const PostinstallOneContainer: React.FC<PostinstallOneContainerProps> = (
  props,
) => {
  const db = React.useContext(DBContext);
  const history = useHistory();

  const authUserUID = useSelector<RootState, string>((state) => state.auth.id!);
  const [dbUpdateDoc, working] = useDBUpdateDoc(
    db,
    db.userOwnerDoc(authUserUID),
  );

  const handlePin = React.useCallback(
    (event) => {
      event.preventDefault();
      dbUpdateDoc({ pinned: true })
        .then(() => {
          // When, and only when, the update comes through, we move to the next page, to avoid
          // complains from the useDBUpdateDoc's inner useState being called after unmounting.
          history.push(R.POSTINSTALL_TWO);
        })
        .catch((error) => console.error(error));
    },
    [dbUpdateDoc, history],
  );

  React.useEffect(() => {
    segmentEvent('Viewed Postinstall Page');
  }, []);

  return <PostinstallOne pinning={working} onPin={handlePin} {...props} />;
};

export { PostinstallOneContainer as PostinstallOne };
