/*

This page is essentially a static page promoting moonsift to stylsits (formerly tastemakers.)

TODO: typescript, make functional, non-default exports, design system ...
Change names from tastemakers to stylists

*/
import React from 'react';
import { Component } from 'react';
import clsx from 'clsx';
import {AddHelmetTitle} from "src/mvp22/core-components/helmet";
import styled from 'styled-components';
import {M} from 'src/mvp22/constants';
import MEDIA from 'src/mvp22/media';
import TopMenu from 'src/mvp22/menu-components/TopMenu';
import BottomBar from 'src/mvp22/menu-components/BottomBar';
import {TEXTSTYLE} from 'src/mvp22/style-components/Text';
import Button from 'src/mvp22/form-components/Button';
import ReviewsCarousel from 'src/mvp22/tile-components/ReviewsCarousel';
import R from 'src/routes';
import windowSize from 'src/mvp22/WindowSize';
import segmentEvent from "src/mvp22/segment-components/SegmentEvent";
import _ from 'lodash';

const Container = styled.div`
  background-color:#FFDDDD;
  width:100%;
  overflow:visible;
  min-width:${M.MINWIDTH};
  display:flex;
  flex-direction:column;
  align-items: center;

  *, *::before, *::after {
    box-sizing: border-box;
  }

  -webkit-font-smoothing: subpixel-antialiased;
`;

const MainContent = styled.div`
  display:flex;
  flex-direction:column;
  background-color:${M.COL.BG.WHITE};
  color:${M.COL.TEXT.BLACK};
  box-sizing:border-box;
  width:100%;
  overflow:hidden;
`;

const Seperator = styled.div`
  height:${props=>props.height};
`;

const GreySeperator = styled(Seperator)`
  background-color:${M.COL.BG.LIGHT};
  width:100%;
`;


//{ISDESKTOP?MEDIA.TASTEMAKERS_TOP:MEDIA.TASTEMAKERS_TOP}
const TopRowContainer = styled.div`
  background-image:url(${MEDIA.TASTEMAKERS_TOP});
  background-size: cover;
  width:100%;
`;

const RowContainer = styled.div`
  display:flex;
  align-items: center;
  width:100%;
  overflow:visible;
  justify-content: center;
`;

const GreyRowContainer = styled(RowContainer)`
  background-color:${M.COL.BG.LIGHT};
`;
const WhiteRowContainer = styled(RowContainer)`
  background-color:${M.COL.BG.WHITE};
`;
const BlackRowContainer = styled(RowContainer)`
  background-color:${M.COL.BG.BLACK+"88"};
`;

const Row = styled.div`
  display:flex;
  align-items: center;
  justify-content: center;
  width:100%;
  max-width:1022px;
  flex-wrap:wrap;
`;

const TopRow = styled(Row)`
  flex-direction:row-reverse;
  color:${M.COL.TEXT.WHITE};
  height:${props=>(props.isDesktop)?'585px':'auto'};
`;

const MiddleRow = styled(Row)`
  flex-direction:row-reverse;
  max-width: ${props => props.isDesktop? '1200px' : '420px'};
`;

const BottomRow = styled(Row)`
  flex-direction:row;
  max-width: ${props => props.isDesktop? '1200px' : '420px'};
`;

const ItemBox = styled.div`
  font-size:20px;
`;

const ImageContainer = styled(ItemBox)`
  width:${props => props.isDesktop ? '50%' : '100%'};
`;

const TextContainer = styled(ItemBox)`
  width:${props => props.isDesktop ? '50%' : '100%'};
  display:flex;
  align-items:center;
  flex-direction:column;
`;

const ItemImage = styled.img`
  font-size:20px;
`;

const MiddleImage = styled(ItemImage)`
  width:100%;
  box-sizing:border-box;
  max-width: ${props => props.isDesktop ? '442px' : '384px'};
  @media(min-width: 429px){
    position: relative;
    right: 10px;
  }
`;

const BottomImage = styled(ItemImage)`
  width: 100%;
  max-width: ${props => props.isDesktop ? '449px' : '384px'};
  margin-bottom:  ${props => props.isDesktop ? '' : '49px'};
  @media(min-width: 429px){
    position: relative;
    right: 10px;
  }
`;

const ItemText = styled.div`
  font-size:20px;
  margin:0px 16px;
`;

const TopText = styled(ItemText)`
  margin-top:68px;
  margin-bottom:42px;
  text-align:center;
`;

const MiddleText = styled(ItemText)`
  max-width:440px;
  margin-top: ${props => props.isDesktop ? '68px' : '51px'};
  margin-bottom: ${props => props.isDesktop ? '68px' : '42px'};
  text-align:left;
`;

const BottomText = styled(ItemText)`
  max-width:440px;
  margin-top: ${props => props.isDesktop ? '89px' : '50px'};
  margin-bottom: ${props => props.isDesktop ? '114px' : '35px'};
  text-align:left;

`;

const LinkBody = styled(TEXTSTYLE.LINKUNDERLINE)`
  font-weight:normal;
`;


const BrandRow = styled.div`
  display:flex;
  justify-content:center;
  padding:0px;
  overflow:hidden;
`;

const BrandContainer = styled.div`
  width:100%;
  background-color:${M.COL.BG.WHITE};
  display:flex;
  flex-direction:row;
  justify-content:space-evenly;
  max-width:1022px;
  overflow:hidden;
  flex-wrap:wrap;
  max-height:${props=>(props.isDesktop)?'100px':'auto'};

  *, *::before, *::after {
    box-sizing: initial;
  }
`;

const BrandImageContainer = styled.div`
  display:flex;
  justify-content:center;
  align-items: center;
`;

const BrandImageX = styled.img`
  width:${props=>(props.isDesktop)?'165px':'140px'};
  height:auto;
  display:initial;
  object-fit:contain;
  padding:10px 10px;
`;

const MaxWidthContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-self: center;
  width: 100%;
  max-width: ${props =>(props.isDesktop===true) ? '1116px' : '420px'};
`;

const SecondRowTitle = styled.div`
  color: ${M.COL.TEXT.BLACK};
  display: flex;
  justify-content: flex-start;
  align-items: center;
  text-align: left;
  margin-left: 11px;
  align-self: flex-start;
`;

const SecondRow = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
`;

const SecondRowInner = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  max-width: 990px;
`;

const CTAButtonTextBold = styled(TEXTSTYLE.BODY3)`
  color: ${M.COL.TEXT.WHITE};
  font-weight: bold;
  font-size: ${props=>(props.isDesktop===true)?'26px':'18px'};
  line-height: 38px;
`;

const CTAButtonTextLight = styled(TEXTSTYLE.BODY3)`
  color: ${M.COL.TEXT.WHITE};
  font-weight: light;
  font-size: ${props=>(props.isDesktop===true)?'26px':'18px'};
  line-height: 38px;
`;

const ButtonContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  flex-direction: column;
  align-items: center;
`;

const ButtonContainerInner = styled.div`
  display: flex;
  align-self: center;
  width: ${props => props.isDesktop ? '394px' : '90%'};
`;

const SubSubtitle = styled(TEXTSTYLE.BODY5)`
  color: ${M.COL.TEXT.MID};
  display: flex;
`;

const ChromeIcon = styled.img`
  width: 13px;
  margin-right:6px;
`;

const Star = styled.img`
  width: 23px;
`;

const StarContainer = styled.div`
  margin-left: 16px;
  display: inline;
`;

class UnstyledTastemakers extends Component {

  CtaButtonText(ISDESKTOP) {
    return[
      <CTAButtonTextBold key='bold-button-text' isDesktop={ISDESKTOP}>Get started&nbsp;</CTAButtonTextBold>,
      <CTAButtonTextLight isDesktop={ISDESKTOP}  key='light-button-text'>- it&apos;s free</CTAButtonTextLight>
    ];
  }

  renderStars(){
    return(
      <StarContainer>
        {
          _.times(5, (i) => {
            return(
                <Star key={i} src={MEDIA.RATING_STAR}/>
            );
          })
        }
      </StarContainer>
    );
  }

  render(){
    const ISDESKTOP = this.props.windowWidth>=M.MOBILESWITCH;
    const SEPERATOR = <Seperator height="42px"/>;
    return(
      <Container className={this.props.className}>
        <TopMenu selected={R.TASTEMAKERLANDING}/>
        {AddHelmetTitle("Curators and Stylists")}
        <Seperator height="43px"/>
        <MainContent>
          <TopRowContainer>
            <BlackRowContainer>
              <TopRow isDesktop={ISDESKTOP}>
                <TextContainer>
                  <TopText isDesktop={ISDESKTOP}>
                    <TEXTSTYLE.HEADING2>
                      Curators and Stylists
                    </TEXTSTYLE.HEADING2>
                    <Seperator height="10px"/>
                    <TEXTSTYLE.BODY1>
                      Curate and share your own virtual edits from 1000’s of UK brands
                    </TEXTSTYLE.BODY1>
                    <Seperator height="30px"/>
                    <TEXTSTYLE.LINK to={R.TASTEMAKERSAPPLY}>
                      <Button
                       text="Get started"
                       msstyle="cta1st_dark_adjustable"
                       width="100%"
                       maxWidth="1185px"
                      />
                    </TEXTSTYLE.LINK>
                  </TopText>
                </TextContainer>
              </TopRow>
            </BlackRowContainer>
          </TopRowContainer>
          {(ISDESKTOP)?SEPERATOR:""}
          <BrandRow>
            <BrandContainer isDesktop={ISDESKTOP}>
              {MEDIA.TASTEMAKERS_BRANDS.map(
                (src, index) =>
                  <BrandImageContainer key={index}>
                    <BrandImageX src={src}/>
                  </BrandImageContainer>
                )
              }
            </BrandContainer>
          </BrandRow>
          {(ISDESKTOP)?SEPERATOR:""}

          <GreyRowContainer className='greyRowContainer'>
            <MiddleRow isDesktop={ISDESKTOP} className='middleRow'>
            <GreySeperator height={ISDESKTOP ? "89px" : ""}/>
              <TextContainer isDesktop={ISDESKTOP} className={clsx('textContainer', ISDESKTOP && 'left')}>
                <MiddleText isDesktop={ISDESKTOP} className='MiddleText'>
                  <TEXTSTYLE.HEADING3>
                  Curate and share inspiring virtual edits
                  </TEXTSTYLE.HEADING3>
                  <Seperator height="10px"/>
                  <TEXTSTYLE.BODY2>
                    Moonsift makes it simple to curate your own shoppable online edits from UK stores.
                  </TEXTSTYLE.BODY2>
                  <Seperator height="10px"/>
                  <TEXTSTYLE.BODY2>
                    The clean layout provides the perfect canvas to highlight your choices - which clients can then explore on any device.
                  </TEXTSTYLE.BODY2>
                  <Seperator height="10px"/>
                  <TEXTSTYLE.BODY2>
                    <LinkBody to={R.TASTEMAKERSAPPLY}>
                      Get started - it&#39;s free
                    </LinkBody>
                  </TEXTSTYLE.BODY2>
                </MiddleText>
              </TextContainer>
              <ImageContainer isDesktop={ISDESKTOP} className={clsx('ImageContainer', ISDESKTOP && 'center')}>
                <MiddleImage className='MiddleImage' isDesktop={ISDESKTOP} src={MEDIA.STYLISTS_IMG_1}/>
              </ImageContainer>
            </MiddleRow>

          </GreyRowContainer>
          <GreySeperator height={ISDESKTOP ? "71px" : "46px"}/>

          <WhiteRowContainer className='whiteRowContainer'>
            <BottomRow className='bottomRow' isDesktop={ISDESKTOP}>
              <Seperator height="68px"/>
              <TextContainer isDesktop={ISDESKTOP} className='TextContainer'>
                <BottomText isDesktop={ISDESKTOP}>
                  <TEXTSTYLE.HEADING3>
                    Spend more time helping clients and less on admin
                  </TEXTSTYLE.HEADING3>
                  <Seperator height="10px"/>
                  <TEXTSTYLE.BODY2>
                    No more copying and pasting links and images or emailing files. Save the pieces you want with a single click and organise your choices digitally.                   </TEXTSTYLE.BODY2>
                  <Seperator height="10px"/>
                  <TEXTSTYLE.BODY2>
                    Stylists, personal shoppers, designers, bloggers and buyers all use Moonsift to make their lives easier and free up more time to add value.                  </TEXTSTYLE.BODY2>
                  <Seperator height="10px"/>
                  <TEXTSTYLE.BODY2>
                    <LinkBody to={R.TASTEMAKERSAPPLY}>
                      Get started - it&#39;s free
                    </LinkBody>
                  </TEXTSTYLE.BODY2>
                </BottomText>
              </TextContainer>
              <ImageContainer isDesktop={ISDESKTOP} className='ImageContainer'>
                <BottomImage src={MEDIA.STYLISTS_IMG_2} isDesktop={ISDESKTOP}/>
              </ImageContainer>
            </BottomRow>
          </WhiteRowContainer>

          <GreyRowContainer className='greyRowContainer'>
            <MiddleRow isDesktop={ISDESKTOP} className='middleRow'>
            <GreySeperator height={ISDESKTOP ? "80px" : ""}/>
              <TextContainer isDesktop={ISDESKTOP} className={clsx('textContainer', 'left')}>
                <MiddleText isDesktop={ISDESKTOP} className='MiddleText'>
                  <TEXTSTYLE.HEADING3>
                    Annotate your choices and get feedback
                  </TEXTSTYLE.HEADING3>
                  <Seperator height="10px"/>
                  <TEXTSTYLE.BODY2>
                    Discuss your choices with clients’ and other collaborators all in one place.
                  </TEXTSTYLE.BODY2>
                  <Seperator height="10px"/>
                  <TEXTSTYLE.BODY2>
                    Get direct feedback from clients on the items you have selected. Find out what items they have purchased and how their wardrobe is evolving.
                  </TEXTSTYLE.BODY2>
                  <Seperator height="10px"/>
                  <TEXTSTYLE.BODY2>
                    <LinkBody to={R.TASTEMAKERSAPPLY}>
                      Get started - it&#39;s free
                    </LinkBody>
                  </TEXTSTYLE.BODY2>
                </MiddleText>
              </TextContainer>
              <ImageContainer isDesktop={ISDESKTOP} className={clsx('ImageContainer', 'center')}>
                <MiddleImage className='MiddleImage' isDesktop={ISDESKTOP} src={MEDIA.STYLISTS_IMG_3}/>
              </ImageContainer>
            </MiddleRow>

          </GreyRowContainer>
          <GreySeperator height={ISDESKTOP ? "80px" : "44px"}/>

          <MaxWidthContainer isDesktop={ISDESKTOP} className='MaxWidthContainer'>
            <SecondRow isDesktop={ISDESKTOP} className='secondRow'>
              <SecondRowInner className='secondRowInner'>
              <Seperator height={ISDESKTOP ? "89px" : "44px"}/>
                <SecondRowTitle isDesktop={ISDESKTOP}>
                  <TEXTSTYLE.HEADING3>Read what people are saying{this.renderStars()}</TEXTSTYLE.HEADING3>
                </SecondRowTitle>
                <Seperator height="28px"/>
                <ReviewsCarousel/>
                <Seperator height="74px"/>
              </SecondRowInner>
            </SecondRow>
              <TEXTSTYLE.LINK to={R.TASTEMAKERSAPPLY}>
                <ButtonContainer>
                  <ButtonContainerInner isDesktop={ISDESKTOP}>
                    <Button
                      isDesktop={ISDESKTOP}
                      text={this.CtaButtonText(ISDESKTOP)}
                      onClick={() => {segmentEvent("Clicked Get Started Button 2 On Stylists Landing Page");}}
                      msstyle={(ISDESKTOP===true)?"cta_alternative_desktop" : "cta_alternative_mobile"}
                      >
                    </Button>
                  </ButtonContainerInner>
                  <Seperator height="18px"/>
                  <SubSubtitle>
                    <ChromeIcon src={MEDIA.CHROME_ICON}/>
                    Designed for Chrome, other browsers coming soon
                  </SubSubtitle>
                </ButtonContainer>
              </TEXTSTYLE.LINK>
            <Seperator height='89px'/>
          </MaxWidthContainer>

        </MainContent>
        <BottomBar/>
      </Container>
    );
  }
}

const Tastemakers = styled(UnstyledTastemakers)`
  .center {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .left {
    display: flex;
    align-items: flex-start;
  }
`;

export default windowSize(Tastemakers);
