/*

Viewing a user's profile (authUser or another user when logged in or not.)

TODO: (the usual whole hog) typescript, camelCase, make functional, non-default exports, design system, split container / component ...

*/
import React from 'react';
import { Component } from 'react';
import {AddHelmetTitle} from "src/mvp22/core-components/helmet";
import styled, {keyframes} from 'styled-components';
import {M} from 'src/mvp22/constants';
import MEDIA from 'src/mvp22/media';
import R from 'src/routes';
import Button from 'src/mvp22/form-components/Button';
import {withRouter} from 'react-router-dom';
import {EXTERNAL} from 'src/routes';
import {TEXTSTYLE} from 'src/mvp22/style-components/Text';
import TopMenu from 'src/mvp22/menu-components/TopMenu';
import {FullBlank} from 'src/mvp22/menu-components/TopMenu';
import {withFirebase} from 'src/mvp22/Firebase';
import queryString from 'query-string';
import LoadingDisplay from 'src/mvp22/image-components/LoadingDisplay';
import get_user_profile_public from 'src/mvp22/firebase-functions/get_user_profile_public';
import get_user_uid from 'src/mvp22/firebase-functions/get_user_uid';
import segmentEvent from "src/mvp22/segment-components/SegmentEvent";
import {SharePopup} from 'src/mvp22/popup-components/SharePopup';
import windowSize from 'src/mvp22/WindowSize';
import LogoAndText from 'src/mvp22/image-components/LogoAndText';
import Cookies from 'universal-cookie';
import CollectionTileList from 'src/mvp22/tile-components/CollectionTileList';
import ProfileImage from "src/mvp22/image-components/ProfileImage";
import { connect } from 'react-redux';

const cookies = new Cookies();

const Container = styled.div`
  background-color:${M.COL.BUTTON.WHITE};
  padding-top:65px;
  display:flex;
  flex-direction:column;
  align-items: center;
  justify-content: center;
  color:${M.COL.TEXT.BLACK};
  text-align:left;
  width:100%;
  box-sizing:border-box;
  margin-bottom: 178px;
`;

const WithinContainer = styled.div`
  display:flex;
  flex-direction:column;
  align-items: center;
  justify-content: center;
  width:100%;
  max-width:1200px;
  box-sizing: border-box;
`;

const Seperator = styled.div`
  height:${props=>props.height};
`;

const TilesContainer = styled.div`
  display:flex;
  flex-direction:row;
  flex-wrap:wrap;
  align-items: flex-start;
  justify-content: center;
  max-width:1200px;

  *, *::before, *::after {
    box-sizing: initial;
  }

  margin-bottom: 32px;
`;

const Selector = styled.div`
  width:100%;
  height:2px;
  background-color:${M.COL.BG.BLACK};
  display: ${props => props.active === true ? '' : 'none'};
`;

const CloseMessage = styled.img`
  position:absolute;
  height:16px;
  width:16px;
  right:18px;
  top:18px;
`;

const MoonMessageContainer = styled.div`
  padding:32px;
  display:flex;
  flex-direction:column;
  align-items: center;
  justify-content: center;
  width:80%;
  max-width:682px;
  border: 2px solid ${M.COL.BUTTON.YELLOW};
  box-sizing: border-box;
  border-radius: 5px;
  position:relative;
`;

const Heading4Central = styled(TEXTSTYLE.HEADING4)`
  text-align:center;
`;

const Body2Central = styled(TEXTSTYLE.BODY2)`
  text-align:center;
`;

const Other4AllCaps = styled(TEXTSTYLE.OTHER4)`
  letter-spacing: 0.05em;
  font-size: 14px;
  line-height: 16px;
  color: ${props => props.active ? M.COL.TEXT.BLACK : M.COL.TEXT.LIGHT};
`;

const ShareContainer = styled.div`
  display: inline-block;
  justify-content: flex-end;
  align-items: baseline;
  top: 5px;
  margin-left: ${props => (props.windowWidth >= props.tileWidthSwitch) ? '22px' : '0px'};
`;

const EditButton = styled.div`
  width:18px;
  height:18px;
  background-image:url(${MEDIA.EDIT});
  background-size: cover;
  background-position: center;
  box-sizing:border-box;
  opacity:0.7;
  &:hover {
    opacity:1.0;
  }
  display: inline-block;
  position: relative;
  bottom: 4px;
`;

const CollectionHeadingContainer = styled.div `
  width:100%;
  max-width:1045px;
  display: block;
  padding-left: ${props => (props.windowWidth >= props.tileWidthSwitch) ? '33px' : '20px'};
  padding-right: ${props => (props.windowWidth >= props.tileWidthSwitch) ? '33px' : '20px'};
  box-sizing: border-box;
  @media(min-width: 1080px){
    padding-left: 11px;
  }
  overflow:scroll;
  scrollbar-width: none; /* For Firefox */
  -ms-overflow-style: none; /* For Internet Explorer and Edge */
  ::-webkit-scrollbar {
    display: none;
  }
`;

const HeadingTitleContainer = styled(TEXTSTYLE.HEADING2)`
  text-align: left;
  display: flex;
  white-space: wrap;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  overflow:hidden;
`;

const EditAndShareContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
`;

const NameContainer = styled.div`
  width: ${props => (props.windowWidth >= props.tileWidthSwitch) ? '' : '100%'};
  position: relative;
  margin-bottom: ${props => (props.windowWidth >= props.tileWidthSwitch) ? '0px' : '5px'};
  text-overflow: ellipsis;
  overflow:hidden;
`;

const UsernameContainer = styled.div`
  box-sizing:border-box;
  padding-right:12px;
`;

const SelectorBarContainer = styled.div`
  width: 100%;
  display: flex;
`;

const EditButtonLinkContainer = styled.div`
  margin-left:${props => props.is_pro ? '19px' : '27px'};
`;

const EditButtonLink = styled(TEXTSTYLE.LINK)`
  display: flex;
  position: relative;
  top: 4px;
`;

const ProfileBlurb = styled(TEXTSTYLE.BODY5)`
  padding-right: 20px;
  justify-content: flex-start;
  color: ${M.COL.TEXT.MID};
  margin-top: ${props => (props.windowWidth >= props.tileWidthSwitch ? '10px' : '14px')};
  white-space: pre-wrap;
  box-sizing:border-box;
  text-overflow: ellipsis;
  overflow:hidden;
`;

const ProfileBlurbBelow = styled(ProfileBlurb)`
  display:flex;
`;
const UserErrorContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width:70%;
  max-width:1200px;
  flex-direction: column;
  position: absolute;
  top: 30%;
`;

const ErrorTextContainer = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 445px;
  text-align: center;
`;

const rotate = keyframes`
  0% {opacity: 0;}
  50% {opacity: 1;}
  100% {opacity: 0;}
`;

const MoonsiftLogo = styled.div`
  width:200px;
  animation: ${rotate} 3s linear infinite;
`;

const ToggleContainer = styled(TEXTSTYLE.LINK)`
  display: flex;
  flex-direction: column;
  margin-right: 24px;
  cursor: pointer;
  &:hover {
    img{
      opacity: 1.0;
    }
    div{
      color: ${M.COL.TEXT.BLACK};
    }
  }
`;

const Bookmark = styled.img`
  opacity: ${props => props.active ? '1.0' : '0.5'};
  margin-right: 6px;
  height: 12px;
`;

const CoatHanger = styled.img`
  opacity: ${props => props.active ? '1.0' : '0.5'};
  margin-right: 6px;
  height: 15px;
  position: relative;
  bottom: 2px;
`;

const IconAndTitleContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const ProBadge = styled.img`
  width: 24px;
  position: relative;
  bottom: 9px;
  margin-left: 8px;
`;

const ProfileWebsiteLink = styled(TEXTSTYLE.LINKA)`
  text-decoration:underline;
  text-overflow: ellipsis;
  overflow: hidden;
`;

const ProfileTitle = styled.div`
  display:flex;
  flex-direction:row;
  width:100%;
`;

const ProfileImageContainer = styled.div`
  padding-right: ${props => (props.is_desktop ? '36px' : '16px')};
  box-sizing:border-box;
`;

const RestOfHeading = styled.div`
  display:flex;
  justify-content: flex-start;
  flex-wrap: nowrap;
  flex-direction:column;
  flex-shrink:1;
  position:relative;
  overflow:hidden;
`;

const TILE_WIDTH_SWITCH = 678;

class Profile extends Component {

  constructor(){
    super();
    this.state = {
      profile_user_uid : null,
      auth_user_uid : null,
      profile_is_own : false, // Is the profile the loggedin user
      profile_has_bio: false,
      user_exists:true,
      active_tab: "all_user_collections"
    };
  }

  componentDidMount() {
    this._isMounted = true;
    this.updateViewingState();
  }

  componentWillUnmount() {
    this._isMounted = false;
    if(this.state.collection_liked_listener_array!==undefined){
      this.state.collection_liked_listener_array.forEach(x=>x());
    }
  }

  componentDidUpdate(){
    this.updateViewingState();
  }

  // Get user UID from input "username" using an async db lookup:
  update_user_uid(profile_username){
    // Now check if we need to convert the URL:
    const displayUsername = (this.props.firestore_user_owner)?this.props.firestore_user_owner.display_username:undefined;
    get_user_uid(profile_username,displayUsername,this.props.firebase,this.props.authUserUID).then(
      (profile_user_uid)=>{
        this.setState(
          (prevState)=>{
            const profile_is_own = profile_user_uid===prevState.auth_user_uid;
            const is_loaded = profile_is_own;
            const profile_is_pro = (profile_is_own)?this.props.firestore_user_owner.pro:false;
            console.log("%cMoonviewing:","background-color:lightskyblue;",profile_user_uid);
            // CASE is viewing own collection and is logged in:
            if (profile_is_own){
              segmentEvent("Viewed Own User Profile Page");
            }
            // CASE is viewing someone else's collections:
            else if (profile_user_uid!=="" && profile_user_uid){
              segmentEvent("Viewed Another User's Profile Page");
              // Get the public user data:
              get_user_profile_public(profile_user_uid,this.props.firebase).then(
                response=>{
                  if(this._isMounted === true){
                    if(response.exists!==true){
                      this.setState(
                        {
                          user_exists:false,
                        }
                      );
                    } else {
                      this.setState(
                        {
                          public_info:response,
                          is_loaded:true,
                          profile_is_pro: response.pro
                        }
                      );
                    }
                  }
                }
              );
            }
            return(
              {
                  is_loaded,
                  profile_is_own,
                  profile_user_uid,
                  profile_is_pro
              }
            );
          }
        );
        // Update the referral cookie:
        if (!cookies.get(M.REFERRAL_COOKIE_KEY)){
          cookies.set(M.REFERRAL_COOKIE_KEY,profile_user_uid,{maxAge:60*60*24*30,path:M.COOKIEPATH});
        }
      }
    );
  }

  updateViewingState(){
    // Figure out the user_uid:
    const auth_user_uid = this.props.authUserUID;
    const active_tab = (this.props.type)?this.props.type:"all_user_collections";
    // e.g. @fewtrem or /profile/fewtrem - both are valid:
    var profile_username = (this.props.match.params.user_map)?this.props.match.params.user_map:this.props.location.pathname.split("/")[2];
    profile_username = (profile_username==="" && auth_user_uid!==null)?auth_user_uid:profile_username;
    this.setState(
      (prevState,props)=>{
        // see if there are any changes in this data (like an effect hook!!)
        const username_change = (profile_username !== prevState.profile_username);
        const user_auth_change = (auth_user_uid !== prevState.auth_user_uid);
        const active_tab_change = (active_tab !== prevState.active_tab);
        // Don't do anything until we know if the user is logged in or not:
        if ((user_auth_change || username_change || active_tab_change) && props.firebaseState.loaded){
          var is_loaded = false;
          var profile_is_own = undefined;
          var user_exists = true;
          switch(active_tab){
            case "all_user_collections":
              this.update_user_uid(profile_username);
              break;
            case "purchased":
              segmentEvent("Viewed Purchased Collection Tile");
              if(auth_user_uid){
                this.update_user_uid(auth_user_uid);
              }
              profile_is_own = true;
              break;
            case "liked":
              segmentEvent("Viewed Liked Collections Page");
              is_loaded = (auth_user_uid)?true:false;
              profile_is_own = true;
              break;
            default:
          }
          const profile_is_pro = (profile_is_own && this.props.firestore_user_owner.pro)?true:false;
          return(
            {
                auth_user_uid,
                profile_username,
                is_loaded,
                profile_is_own,
                active_tab,
                user_exists,
                profile_is_pro
            }
          );
        }
      }
    );
  }

  clickedOnExistingCollection() {
    segmentEvent("Clicked On Existing Collection");
  }

  renderShareButton(windowWidth, TILE_WIDTH_SWITCH){
    const USER_UID = (this.state.profile_is_own)?this.props.firestore_user_owner.display_username:this.state.public_info.display_username;
      return(
      <ShareContainer windowWidth={windowWidth} tileWidthSwitch={TILE_WIDTH_SWITCH} className='ShareContainer'>
        <SharePopup
         shareURL={process.env.REACT_APP_HOMEPAGEURL+"/@"+USER_UID}
         shareText="Share this profile"
        />
      </ShareContainer>
      );
  }

  renderEditButton(){
    if(this.state.profile_is_own){
      return(
        <EditButtonLinkContainer is_pro={this.props.firestore_user_owner.pro}>
          <EditButtonLink to={R.EDIT_PROFILE}>
            <EditButton/>
          </EditButtonLink>
        </EditButtonLinkContainer>
      );
    }else{
      return "";
    }
  }

  renderProBadge(){
    if(this.state.profile_is_pro){
      return(
        <TEXTSTYLE.LINK to={R.PRO_SHARE}>
          <ProBadge src={MEDIA.PRO_BADGE}/>
        </TEXTSTYLE.LINK>
      );
    }
  }

  getMoonMessage(){
    switch(queryString.parse(this.props.location.search).message){
      case "postinstall":
        return (
          <MoonMessageContainer>
            <TEXTSTYLE.LINK tabIndex="4" link={R.HOME}>
              <CloseMessage src={MEDIA.EXIT_CROSS_THIN}/>
            </TEXTSTYLE.LINK>
            <Heading4Central>Moonsift successfully added to your browser</Heading4Central>
            <Seperator height="11px"/>
            <Body2Central>Head over to your favourite UK fashion retailers to begin saving potential purchases to your collection.</Body2Central>
          </MoonMessageContainer>
        );
      case "welcome":
      return (
        <MoonMessageContainer>
          <TEXTSTYLE.LINK tabIndex="4" link={R.HOME}>
            <CloseMessage src={MEDIA.EXIT_CROSS_THIN}/>
          </TEXTSTYLE.LINK>
          <Heading4Central>Welcome to Moonsift!</Heading4Central>
          <Seperator height="11px"/>
          <Body2Central>Get the browser button to start saving items from across the web. </Body2Central>
          <Seperator height="24px"/>
          <TEXTSTYLE.LINKA tabIndex="3" href={EXTERNAL.CHROMESTORE} target="_blank">
          <Button
            onClick={segmentEvent("Clicked Get Browser")}
            text="Get browser button"
            msstyle="cta2nd"
          />
          </TEXTSTYLE.LINKA>
        </MoonMessageContainer>
      );
      default:
        return "";
    }
  }

  // renders buttons in different order depending on windowWidth
  // still needs to conditionally check if the user is looking at their own profile or someone elses (if not, don't render edit button)
  renderShareAndEditButtons(windowWidth, TILE_WIDTH_SWITCH){
    if(TILE_WIDTH_SWITCH > windowWidth){
      return(
        <EditAndShareContainer windowWidth={windowWidth} tileWidthSwitch={TILE_WIDTH_SWITCH}>
          {this.renderShareButton(windowWidth, TILE_WIDTH_SWITCH)}
          {this.renderEditButton()}
        </EditAndShareContainer>
      );
    } else {
      return(
        <EditAndShareContainer windowWidth={windowWidth} tileWidthSwitch={TILE_WIDTH_SWITCH}>
          {this.renderEditButton()}
          {this.renderShareButton(windowWidth, TILE_WIDTH_SWITCH)}
        </EditAndShareContainer>
      );
    }
  }

  // inside ProfileBlurb we need to use real data for bio (remove example blurb text)
  renderProfileHeadingContainer(TILE_WIDTH_SWITCH){
    const THIS_BIO = (this.state.profile_is_own)?this.props.firestore_user_owner.snapshot.bio:this.state.public_info.bio;
    const THIS_LINK = (this.state.profile_is_own)?this.props.firestore_user_owner.snapshot.link:this.state.public_info.link;
    const THIS_NAME = (this.state.profile_is_own)?this.props.firestore_user_owner.snapshot.name:this.state.public_info.name;
    const THIS_PROFILE_IMAGE_CODE = (this.state.profile_is_own)?this.props.firestore_user_owner.snapshot.profile_image_code:this.state.public_info.profile_image_code;
    const THIS_BIO_DISPLAY = (THIS_BIO)?THIS_BIO:"";
    const THIS_LINK_DISPLAY = (THIS_LINK)?THIS_LINK:"";
    const IS_HTTP = THIS_LINK_DISPLAY.toLowerCase().slice(0,7)==="http://";
    const IS_HTTPS = THIS_LINK_DISPLAY.toLowerCase().slice(0,8)==="https://";
    const THIS_LINK_HREF = (IS_HTTP || IS_HTTPS)?THIS_LINK_DISPLAY:"//"+THIS_LINK_DISPLAY;
    const THIS_USERNAME = "@"+((this.state.profile_is_own)?this.props.firestore_user_owner.display_username:this.state.public_info.display_username);
    const THIS_USER_UID = (this.state.profile_is_own)?this.props.authUserUID:this.state.profile_user_uid;
    const IS_DESKTOP = this.props.windowWidth >= TILE_WIDTH_SWITCH;
    const PROFILE_IMAGE =
    <ProfileImage
      profile_image_code={THIS_PROFILE_IMAGE_CODE}
      user_uid={THIS_USER_UID}
      name = {THIS_NAME}
      scale={IS_DESKTOP?110:72}
    />;
    return(
      <CollectionHeadingContainer windowWidth={this.props.windowWidth} tileWidthSwitch={TILE_WIDTH_SWITCH} className='CollectionHeadingContainer'>
        <ProfileTitle>
          <ProfileImageContainer
            is_desktop={IS_DESKTOP}
          >
            {
              (this.state.profile_is_own)?
                <TEXTSTYLE.LINK to={R.EDIT_PROFILE}>
                  {PROFILE_IMAGE}
                </TEXTSTYLE.LINK>
              :
                PROFILE_IMAGE
            }
          </ProfileImageContainer>
          <RestOfHeading>
            <HeadingTitleContainer className='HeadingTitleContainer'>
              <NameContainer windowWidth={this.props.windowWidth} tileWidthSwitch={TILE_WIDTH_SWITCH}>
                {THIS_NAME}
                {this.renderProBadge()}
              </NameContainer>
              {this.renderShareAndEditButtons(this.props.windowWidth, TILE_WIDTH_SWITCH)}
            </HeadingTitleContainer>
            <ProfileBlurb tileWidthSwitch={TILE_WIDTH_SWITCH} windowWidth={this.props.windowWidth} className='ProfileBlurb'>
              {THIS_BIO_DISPLAY}
            </ProfileBlurb>
            <ProfileBlurbBelow tileWidthSwitch={TILE_WIDTH_SWITCH} windowWidth={this.props.windowWidth}>
              <UsernameContainer>
              {
                (this.state.profile_is_own && this.props.firestore_user_owner.pro===true)?
                  <TEXTSTYLE.LINK to={R.EDIT_USERNAME}>
                    {THIS_USERNAME}
                  </TEXTSTYLE.LINK>
                :
                  THIS_USERNAME
              }
              </UsernameContainer>
              {
                (THIS_LINK_DISPLAY.length>0)?
                  <ProfileWebsiteLink href={THIS_LINK_HREF} target="_blank">
                    {THIS_LINK_DISPLAY}
                  </ProfileWebsiteLink>
                :
                  ""
              }
            </ProfileBlurbBelow>
          </RestOfHeading>
        </ProfileTitle>
        <Seperator height={(THIS_BIO_DISPLAY.length>0) ? '40px' : '42px'}/>
        {this.renderCollectionToggles()}
        <Seperator height={'12px'}/>
      </CollectionHeadingContainer>
    );
  }

  renderCollectionToggles(){
    if (this.state.profile_is_own){
      return(
        <SelectorBarContainer className="SelectorBarContainer">
          <ToggleContainer to={"/@"+this.props.firestore_user_owner.display_username} className="toggleContainer">
            <Other4AllCaps active={this.state.active_tab === "all_user_collections" ? true : false} className='other4AllCaps'>SAVED</Other4AllCaps>
            <Seperator height="6px"/>
            <Selector active={this.state.active_tab === "all_user_collections" ? true : false} className='Selector'/>
          </ToggleContainer>

          <ToggleContainer to={R.LIKED} className="toggleContainer">
            <IconAndTitleContainer>
              <Bookmark active={this.state.active_tab === "liked" ? true : false} src={this.state.active_tab === "liked" ? MEDIA.BLACK_BOOKMARK : MEDIA.WHITE_BOOKMARK}/>
              <Other4AllCaps active={this.state.active_tab === "liked" ? true : false} className='other4AllCaps'>BOOKMARKED</Other4AllCaps>
            </IconAndTitleContainer>
            <Seperator height="6px"/>
            <Selector active={this.state.active_tab === "liked" ? true : false} className='Selector'/>
          </ToggleContainer>

          <ToggleContainer to={R.PURCHASED} className="toggleContainer">
            <IconAndTitleContainer>
              <CoatHanger active={this.state.active_tab === "purchased" ? true : false} src={MEDIA.COAT_HANGER}/>
              <Other4AllCaps active={this.state.active_tab === "purchased" ? true : false} className='other4AllCaps'>PURCHASED</Other4AllCaps>
            </IconAndTitleContainer>
            <Seperator height="6px"/>
            <Selector active={this.state.active_tab === "purchased" ? true : false} className='Selector'/>
          </ToggleContainer>

          <Seperator height='13px'/>
        </SelectorBarContainer>
      );
    }
    else return("");
  }

  renderInside(){
    // ensure if loading from scratch have all data we need!
    if (this.state.is_loaded!==true || !this.props.firestore_user_owner.snapshot){
      return(
        <>
          {AddHelmetTitle("Loading")}
          <LoadingDisplay message = "Loading User Profile..." />
        </>
      );
    }else{
      return(
        <WithinContainer className='WithinContainer'>
        <Seperator height='52px'/>
        {
          this.renderProfileHeadingContainer(TILE_WIDTH_SWITCH)
        }
          <TilesContainer className='tilesContainer'>
          <Seperator height="22px"/>
          {this.getMoonMessage()}
          <Seperator height="22px"/>
            {
              (this.state.profile_is_own)?
                AddHelmetTitle("My Collections")
              :
                AddHelmetTitle(this.state.public_info.name)
            }
            <CollectionTileList
              user_uid={this.state.profile_user_uid}
              auth_user_uid={this.state.auth_user_uid}
              type={this.state.active_tab}
              firestore_user_collection_list={this.props.firestore_user_collection_list}
              public_info={this.state.public_info}
            />
          </TilesContainer>
        </WithinContainer>
      );
    }
  }

  renderUserError(){
    return(
      <UserErrorContainer>
        {AddHelmetTitle("User not found")}
        <MoonsiftLogo>
          <LogoAndText heighttype="A"/>
        </MoonsiftLogo>
        <Seperator height='48px'/>
        <ErrorTextContainer>
          <TEXTSTYLE.BODY3>
            We&apos;re sorry, we couldn&apos;t find this user profile.
          </TEXTSTYLE.BODY3>
          <Seperator height='30px'/>
        </ErrorTextContainer>
      </UserErrorContainer>
    );
  }

  render(){
    return(
      <FullBlank>
        <TopMenu hug="edge" selected={R.HOME}/>
          <Container className='Container'>
            {this.state.user_exists ? this.renderInside() : this.renderUserError()}
          </Container>
      </FullBlank>
    );
  }
}

// Pass the state values into the component:
const mapStateToProps = (state) => {
  return ({
    firestore_user_collection_list:state.firestore_user_collection_list,
    firestore_user_owner:state.firestore_user_owner,
    firebaseState:state.firebasestate,
    authUserUID:state.auth.id
  });
};

const mapDispatchToProps = () => {
  return {};
};

export default connect(mapStateToProps,mapDispatchToProps)(withFirebase(withRouter(windowSize(Profile))));
