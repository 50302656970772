import React from 'react';
import styled from 'styled-components';
import { Helmet } from 'react-helmet';
import {
  Typography,
  TextField,
  StandardButton,
} from '@moonsifttech/design-system';

import TopMenu from 'src/mvp22/menu-components/TopMenu';
import { ReportProblemProps } from './ReportProblem.types';

const UnstyledReportProblem: React.FC<ReportProblemProps> = ({
  className,
  style,
  description,
  working,
  reported,
  onChange,
  onReport,
}) => {
  return (
    <>
      <Helmet>
        <title>Report A Problem - Moonsift</title>
      </Helmet>
      <div className={className} style={style}>
        <TopMenu className="ReportProblem-topMenu" />
        <div className="ReportProblem-content">
          {reported ? (
            <Typography variant="primary.b26" component="h1">
              Thank you!
            </Typography>
          ) : (
            <form onSubmit={onReport}>
              <Typography variant="primary.b26" component="h1">
                Please, describe the problem
              </Typography>
              <TextField
                className="ReportProblem-descriptionInput"
                name="description"
                placeholder="Problem"
                defaultValue="Random"
                value={description}
                onChange={onChange}
                autoFocus
                fullWidth
                multiline
                maxLength={4000}
                rows={4}
              />
              <StandardButton
                type="submit"
                variant="primary-white"
                loading={working}
                disabled={description.length === 0}
                fullWidth
              >
                Report!
              </StandardButton>
            </form>
          )}
        </div>
      </div>
    </>
  );
};

export const ReportProblem = styled(UnstyledReportProblem)`
  .ReportProblem-topMenu {
    height: 56px;
    margin-bottom: 28px;
  }

  .ReportProblem-content {
    margin: 0 auto;
    max-width: 576px;

    ${({ theme }) => theme.fns.getMediaQuery({ maxWidth: 608 })} {
      margin: 0 16px;
    }
  }

  .ReportProblem-descriptionInput {
    margin-bottom: 40px;
  }
`;
