export const Constants = {
  HOMEPAGEURL: process.env.REACT_APP_HOMEPAGEURL,
  APIADDRESS: process.env.REACT_APP_MOONSIFTAPI_URL,
  COOKIEMESSAGEKEY: 'cookie_message',
  COOKIEPATH: '/',
  REFERRAL_COOKIE_KEY: 'referral_code',
  PRO_CODE_COOKIE_KEY: 'pro_code',
  MOBILESWITCH: 922, // Pixels below which is mobile.
  EXTENSION_ID: 'hgbbfkafecdbocpcdkoahfkbkoodcaon',
};
