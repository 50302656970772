import React, { useContext } from 'react';
import { useSelector } from 'react-redux';
import { MobileContext, RootState } from 'src';
import { RouterSwitch } from './RouterSwitch.component';
import { RouterSwitchContainerProps } from './RouterSwitch.types';

const Container: React.FC<RouterSwitchContainerProps> = () => {
  const isApp = useContext(MobileContext).isApp;
  const authUserUID = useSelector<RootState, string | null>(
    (state) => state.auth.id,
  );
  const authUserSet = useSelector<RootState, boolean>(
    (state) => state.auth.set,
  );
  const displayUsername = useSelector<RootState, string>((state) =>
    state.firestore_user_owner
      ? state.firestore_user_owner.display_username
      : undefined,
  );
  const userDataLoaded = useSelector<RootState, true | undefined>(
    (state) => state.firebasestate.loaded,
  );
  const provisioned = useSelector<RootState, true | undefined>((state) =>
    state.firestore_user_owner
      ? state.firestore_user_owner.provisioned
      : undefined,
  );
  const showNotProvisioned =
    authUserUID !== null && userDataLoaded === true && provisioned !== true;

  return (
    <RouterSwitch
      {...{
        authUserUID,
        authUserSet,
        displayUsername,
        showNotProvisioned,
        isApp,
      }}
    />
  );
};

export { Container as RouterSwitch };
