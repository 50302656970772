/*

Gets all of the authuser's collections.

TODO: move to a db hook and invoke in react-app/src/hooks/useAuthUserData/useAuthUserData.ts

NOTE: also passes data to react-app/src/mvp22/redux-components/reducers/firestore_product_membership.js
if collection list updates whilst the product-collection membership modal is open!

We want to access the product collection data very frequently by looking up page_urls.
But we are going to store user collections in the database as follows:
/user_uid/collection/collection_uid:
products:{product_uid}

We need collections of product_urls.
Most flexible is to store something unique for the extension (which means changing it back AGAIN!)
/user_uid/site_uid/:

Load in all the collections and store in a page_url > collection dict.
Update this as and when a collection is modified.... (which should not be toooo often, at least not as often as loading a new product would be!)


*/
import collection_data_proc from 'src/mvp22/core-components/collection_data_proc';
import {FIRESTORE_REDUX_REMOVE_ALL} from 'src/mvp22/redux-components/actions';
import {product_membership_collection_list_redux_set} from './firestore_product_membership';
const FIRESTORE_REDUX_SET_COLLECTIONLIST = "FIRESTORE_REDUX_ADD_COLLECTIONLIST";
const FIRESTORE_REDUX_REMOVE_COLLECTIONLIST = "FIRESTORE_REDUX_REMOVE_COLLECTIONLIST";
const FIRESTORE_REDUX_SET_COLLECTIONLIST_LISTENER = "FIRESTORE_REDUX_SET_COLLECTIONLIST_LISTENER";
// Future-considerations: other things might go here for tracking....
const DEFAULT_FIRESTORE = {
  collection_list_listener:undefined,
  collections_sorted:[]
};

export function sort_dict_to_list(dict_in){
  const list_out = [];
  for (let this_key in dict_in){
    const this_value = dict_in[this_key];
    list_out.push(this_value);
  }
  // SORTING by last updated!
  list_out.sort((a,b)=>(a.last_updated>b.last_updated)?-1:1);
  return list_out;
}

export default function firestoreState(state = DEFAULT_FIRESTORE, action) {
  switch (action.type) {
    // Update the scratch with the firebase collection:
    case FIRESTORE_REDUX_SET_COLLECTIONLIST:
      return Object.assign(
        {},
        state,
        action.data
      );

    case FIRESTORE_REDUX_SET_COLLECTIONLIST_LISTENER:
      if (state.collection_list_listener!==undefined){
        state.collection_list_listener();
      }
      return Object.assign(
        {},
        state,
        action.data
      );
    case FIRESTORE_REDUX_REMOVE_ALL:
    case FIRESTORE_REDUX_REMOVE_COLLECTIONLIST:
      if (state.collection_list_listener!==undefined){
        state.collection_list_listener();
      }
      return DEFAULT_FIRESTORE;
    default:
      return state;
  }
}

/*

REDUX db

*/
// Action creator is here now:!
export function firestore_user_collection_list_redux_set(data_in){
  return (dispatch) => {
    // remove all regardless:
    dispatch(
      {
        type:FIRESTORE_REDUX_REMOVE_COLLECTIONLIST,
      }
    );
    // Get the new listeners if logged in:
    if(data_in.auth_user_uid!==null){
      return new Promise(
        (resolve)=>{
          const collection_list_listener = data_in.firebase.user_collection_list(
            data_in.auth_user_uid
          ).onSnapshot(
            snapshot => {
              // first store the listener and collection list:
              const collections_by_uid = {}; // does not seem to be used ever...!
              snapshot.forEach(
                doc=>{
                  collections_by_uid[doc.id] = collection_data_proc(data_in.auth_user_uid,doc);
                }
              );
              const collections_sorted = sort_dict_to_list(collections_by_uid);
              // SYNCHRONOUS dispatch:
              dispatch(
                {
                  type:FIRESTORE_REDUX_SET_COLLECTIONLIST,
                  data:{
                    collections_by_uid,
                    collections_sorted
                  }
                }
              );
              // update the product memebership popup lists as required:
              dispatch(product_membership_collection_list_redux_set(collections_sorted,false));
              resolve();
            }// end onsnapshot
          );
          dispatch(
            {
              type:FIRESTORE_REDUX_SET_COLLECTIONLIST_LISTENER,
              data:{
                collection_list_listener
              }
            }
          );
        }
      );
    }
  };
}
