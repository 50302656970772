/*

Various messages that occur when a collection is empty.

TODO: typescript! non-default export...

*/
import React, { useState, useEffect, useCallback } from 'react';
import styled from 'styled-components';
import Bowser from 'bowser';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import {
  Typography,
  typographyClassNames,
  StandardButton,
  StandardButtonRoot,
  Stack,
} from '@moonsifttech/design-system';

import { M } from 'src/mvp22/constants';
import R, { EXTERNAL } from 'src/routes';
import MEDIA from 'src/mvp22/media';
import segmentEvent from 'src/mvp22/segment-components/SegmentEvent';
import Button from 'src/mvp22/form-components/Button';
import { TEXTSTYLE } from 'src/mvp22/style-components/Text';
import { useHasExtension } from 'src/hooks/useHasExtension';
/*

Component that just displays user ID.

*/

const EmptyMessageContainer3 = styled.div`
  max-width: 330px;
  display: block;
  flex-direction: column;
  background-color: ${M.COL.BG.WHITE};
  box-sizing: border-box;
  position: relative;
  align-items: center;
  margin: ${(props) =>
    props.isDesktop === true ? '20px 20px 0px 20px' : '0px 20px 0px 20px'};
`;

const PlainLink = styled.a`
  text-decoration: none;
  width: 100%;
  max-width: 358px;
  height: 42px;
`;

const SubSubtitle = styled(TEXTSTYLE.BODY4)`
  color: ${M.COL.TEXT.MID};
  display: flex;
  align-items: flex-start;
`;

const ChromeIcon = styled.img`
  width: 13px;
  margin-right: 6px;
  position: relative;
  top: 4px;
`;

const UnderlinedText = styled(TEXTSTYLE.LINK)`
  border-bottom: 1px solid black;
`;

const UnderlinedTextExternalLink = styled(TEXTSTYLE.LINKA)`
  border-bottom: 1px solid black;
`;
const Seperator = styled.div`
  height: ${(props) => props.height};
`;

const NoPurchasedItems = styled.img`
  display: block;
  margin: 0 auto;
`;

export const EmptyMessageContainer = styled.div`
  display: block;
  flex-direction: column;
  background-color: ${M.COL.BG.WHITE};
  box-sizing: border-box;
  position: relative;
  max-width: 360px;
  height: 345px;
  align-items: center;
  margin: ${(props) =>
    props.isDesktop === true ? '20px 20px 0px 20px' : '0px 20px 0px 20px'};
`;

const EmptyMessageContainer2 = styled(EmptyMessageContainer)`
  max-width: 320px;
  margin-top: 60px;
  height: auto;

  button {
    &:nth-of-type(1) {
      margin-bottom: 12px;
    }

    &:nth-of-type(2) {
      margin-bottom: 20px;
    }
  }
`;

export const CentralHeading3 = styled(TEXTSTYLE.HEADING3)`
  text-align: center;
  max-width: 305px;
  padding: 12px;
  margin: 0 auto;
`;

export const CentralText3 = styled(TEXTSTYLE.BODY3)`
  text-align: center;
  margin-bottom: 10px;
`;

export const BoldLink = styled(TEXTSTYLE.LINK)`
  padding: 0 0 2px;
  font-weight: normal;
  border-bottom: 1px solid black;
`;

const RetailerLinkButtonContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 13px;
`;

const RetailerLink = styled.a`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100px;
  height: 42px;
  border: 1px solid var(--grey-2);
  border-radius: 6px;
  margin-bottom: 12px;

  &:hover {
    border-color: var(--common-black);
  }

  img {
    width: 80%;
  }
`;

const RetailerButtons = ({ isDesktop }) => {
  return (
    <RetailerLinkButtonContainer
      isDesktop={isDesktop}
      className="retailerlinkbuttoncontainer"
    >
      {MEDIA.RETAILERS.map((retailer, i) => {
        return (
          <RetailerLink key={i} href={retailer.link}>
            <img
              style={retailer.style}
              src={retailer.image}
              alt={retailer.name}
            />
          </RetailerLink>
        );
      })}
    </RetailerLinkButtonContainer>
  );
};

const MobileEmptyMessage = styled(
  ({ className, style, goToHowToAddNotionPage, goToPopularStores }) => {
    return (
      <Stack
        className={className}
        style={style}
        direction="column"
        justifyContent="center"
        alignItems="center"
      >
        <Typography variant="primary.b26" color="grey.2" component="h2">
          Collection empty
        </Typography>
        <StandardButton
          variant="primary-white"
          fullWidth
          onClick={goToHowToAddNotionPage}
        >
          How to add items
        </StandardButton>
        <StandardButton
          variant="primary-black"
          fullWidth
          onClick={goToPopularStores}
        >
          Browse stores on Moonsift
        </StandardButton>
      </Stack>
    );
  },
)`
  width: 100%;

  .${typographyClassNames.root} {
    margin-bottom: 12px;
  }

  ${StandardButtonRoot}:first-of-type {
    margin-bottom: 16px;
  }
`;

const Component = ({ isPurchased, isMine }) => {
  const windowWidth = useSelector((state) => state.ui.windowWidth);
  const [browserName, setBrowserName] = useState(null);
  const history = useHistory();

  const goToHowToAddNotionPage = useCallback((event) => {
    event.preventDefault();
    window.open(EXTERNAL.HOW_TO_ADD, '_blank');
  }, []);

  const goToPopularStores = useCallback(
    (event) => {
      event.preventDefault();
      history.push(R.RETAILERS);
    },
    [history],
  );

  //console.log(extensionInstalled,browserName);
  const extensionInstalled = useHasExtension();

  useEffect(() => {
    const browser = Bowser.getParser(window.navigator.userAgent);
    setBrowserName(
      browser.parsedResult.browser ? browser.parsedResult.browser.name : null,
    );
  }, []);

  const isDesktop = windowWidth >= M.MOBILESWITCH;

  if (browserName === 'Chrome' && extensionInstalled === false) {
    if (isDesktop !== true) {
      // Mobile, no extension, on chrome for android (allows users to add to desktop from chrome android)
      return (
        <MobileEmptyMessage
          goToHowToAddNotionPage={goToHowToAddNotionPage}
          goToPopularStores={goToPopularStores}
        />
      );
    } else {
      // Desktop Chrome, no extension installed
      return (
        <>
          <EmptyMessageContainer3 isDesktop={isDesktop}>
            <CentralHeading3>Collection empty</CentralHeading3>
            <CentralText3>
              Two ways to save items to your collection:
              <br />
            </CentralText3>
            <CentralText3>
              1. Add Moonsift to your Chrome browser, then save items directly
              from <UnderlinedText to={R.RETAILERS}>any store.</UnderlinedText>
              <br />
            </CentralText3>
            <CentralText3>
              2. Save items from other people’s collections by clicking the +
              icon in the top right corner of each product.
            </CentralText3>
            <Seperator height="10px" />
            <PlainLink
              href={EXTERNAL.CHROMESTORE}
              target="_blank"
              onClick={() => {
                segmentEvent('Clicked Add To Chrome on Empty Collection Page');
              }}
            >
              <Button
                needsMaxWidth={true}
                isDesktop={isDesktop}
                text="Add Moonsift to Chrome"
                msstyle="cta_alternative_post_signup"
              />
            </PlainLink>
            <Seperator height="16px" />
          </EmptyMessageContainer3>
          <SubSubtitle>
            <ChromeIcon src={MEDIA.CHROME_ICON} />
            Designed for Chrome, other browsers coming soon
          </SubSubtitle>
          <Seperator height="40px" />
        </>
      );
    }
  } else if (browserName !== 'Chrome') {
    // Not chrome,
    if (isDesktop !== true) {
      return (
        <MobileEmptyMessage
          goToHowToAddNotionPage={goToHowToAddNotionPage}
          goToPopularStores={goToPopularStores}
        />
      );
    } else {
      // Not chrome,
      return (
        <EmptyMessageContainer3 isDesktop={isDesktop}>
          <CentralHeading3>Collection empty</CentralHeading3>
          <CentralText3>
            The magic of Moonsift is saving directly from our{' '}
            <UnderlinedText to={R.RETAILERS}>
              1,000+ supported stores.
            </UnderlinedText>{' '}
            But to do so you will need our{' '}
            <UnderlinedTextExternalLink href={EXTERNAL.CHROMESTORE}>
              Chrome browser plug-in.
            </UnderlinedTextExternalLink>
            <br />
          </CentralText3>
          <CentralText3>
            If you only want to save from other Moonsift collections, you can do
            this by clicking the + icon in the top right corner of the products.
            <br />
          </CentralText3>
          <CentralText3>
            Save from{' '}
            <UnderlinedText to={R.EXPLORE_FEATURED}>featured</UnderlinedText>{' '}
            Moonsift collections.
          </CentralText3>
        </EmptyMessageContainer3>
      );
    }
  } else {
    // Extension installed (must be chrome):
    if (isPurchased === true && isMine === true) {
      // case is own purchsed collection:
      return (
        <EmptyMessageContainer className="EmptyMessageContainer">
          <NoPurchasedItems src={MEDIA.NO_PURCHASED_ITEMS} />
          <CentralHeading3>No purchased items yet</CentralHeading3>
          <>
            <CentralText3>
              To mark an item you’ve saved as purchased, click the coathanger
              icon below the item’s price.
            </CentralText3>
          </>
        </EmptyMessageContainer>
      );
    } else {
      // case is any other own collection:
      return (
        <EmptyMessageContainer2
          isDestop={isDesktop}
          className="EmptyMessageContainer2"
        >
          <Typography variant="primary.b26" component="h2" align="center">
            Collection empty
          </Typography>
          {isMine ? (
            <>
              <StandardButton
                variant="primary-white"
                fullWidth
                onClick={goToHowToAddNotionPage}
              >
                How to add items
              </StandardButton>
              <StandardButton
                variant="primary-black"
                fullWidth
                onClick={goToPopularStores}
              >
                Browse popular stores
              </StandardButton>
              <RetailerButtons isDesktop={isDesktop} />
            </>
          ) : (
            ''
          )}
        </EmptyMessageContainer2>
      );
    }
  }
};

export const EmptyMessage = Component;
