import React from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { RootState, CloudContext } from 'src/index';
import R from 'src/routes';
import { Collection } from 'src/types/models/collection.model';
import { CollectionBookmark } from 'src/types/models/collectionBookmark.model';
import {
  BookmarkButtonProps,
  BookmarkButtonContainerProps,
} from './BookmarkButton.types';
import { BookmarkButton } from './BookmarkButton.component';

const BookmarkButtonContainer: React.FC<BookmarkButtonContainerProps> = (
  props,
) => {
  const cloud = React.useContext(CloudContext);
  const history = useHistory();

  // Global state
  const authUserUID = useSelector<RootState, string>(
    ({ auth }) => auth.id ?? '',
  );
  const collectionDocument = useSelector<
    RootState,
    Collection | null | undefined
  >(({ db }) => db.single_collection.data);
  const collectionBookmarkDocument = useSelector<
    RootState,
    CollectionBookmark | null | undefined
  >(({ db }) => db.single_collection_bookmark.data);

  const collectionUserUID = collectionDocument?.user_uid ?? '';
  const collectionUID = collectionDocument?.id ?? '';

  const isBookmarked = Boolean(collectionBookmarkDocument);

  // Local state
  const [working, setWorking] = React.useState(false);

  const handleBookmark = React.useCallback<
    NonNullable<BookmarkButtonProps['onClick']>
  >(
    (event) => {
      event.preventDefault();

      if (authUserUID) {
        setWorking(true);

        cloud
          .fastAPI({
            api: 'likes_saved_likes_change',
            user_uid: collectionUserUID,
            collection_uid: collectionUID,
            action: isBookmarked ? 'delete' : 'create',
          })
          .then(() => setWorking(false))
          .catch((error) => {
            console.error("Couldn't update the bookmark", error);
            setWorking(false);
          });
      } else {
        // Send the user to the sign up page if it's not logged in
        history.push(R.SIGNIN, { redirectTo: window.location.href });
      }
    },
    [
      authUserUID,
      cloud,
      collectionUID,
      collectionUserUID,
      history,
      isBookmarked,
    ],
  );

  return (
    <BookmarkButton
      isBookmarked={isBookmarked}
      working={working}
      onClick={handleBookmark}
      {...props}
    />
  );
};

export { BookmarkButtonContainer as BookmarkButton };
