/*

The base carousel component.  We made our own because we couldn't find one that exactly suited what we wanted.

TODO: make into a hook?, typescript

*/
import { Component } from 'react';
import debounce from 'lodash.debounce';

export default class BasicCarousel extends Component {

  constructor() {
    super();

    this.state = {
      hasOverflow: false,
      canScrollBack: false,
      canScrollForward: true,
    };
    // limit calls with debounce:
    this.debounceComponentDidUpdate = debounce(this.componentDidUpdate,200);
    this.debounceComponentDidUpdate = this.debounceComponentDidUpdate.bind(this);

    this.container = null;
  }

  componentDidMount() {
    this.componentDidUpdate();
    this.setState(
      {
        listener:this.container.addEventListener(
          'scroll',
          this.debounceComponentDidUpdate
        )
      }
    );
  }

  // calculate scrolling arrow properties:
  componentDidUpdate(){
    this.setState(
      prevState=>{
        // NOTE: can be verical or horizontal scrolling:
        const { scrollTop, scrollHeight, clientHeight, scrollLeft, scrollWidth, clientWidth} = this.container;
        var hasOverflow = false;
        var canScrollForward = false;
        var canScrollBack = false;

        if (scrollWidth > clientWidth){
          hasOverflow = true;
          canScrollForward = scrollLeft !== scrollWidth - clientWidth;
          canScrollBack = scrollLeft > 0;
        }
        else if (scrollHeight > clientHeight){
          hasOverflow = true;
          canScrollForward = scrollTop > 0;
          canScrollBack = scrollTop !== scrollHeight - clientHeight;
        }

        if (
          prevState.hasOverflow!==hasOverflow
        ||
          prevState.canScrollBack!==canScrollBack
        ||
          prevState.canScrollForward!==canScrollForward
        ){
          return(
            {
              hasOverflow,
              canScrollForward,
              canScrollBack
            }
          );
        }
      }
    );
  }

  componentWillUnmount(){
    if(this.state.listener){
      this.state.listener();
    }
  }

}
