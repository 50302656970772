/*

Master Modal component that renders all the other modals depending on modal redux state.

TODO: separate as container / component,

*/
import React,{useContext,useState,useEffect} from 'react';
import styled from 'styled-components';
import { FirebaseContext, RootState} from 'src/index';
import R from 'src/routes';
import {TEXTSTYLE} from 'src/mvp22/style-components/Text';
import {GeneralOverlayWithinContainer} from './ModalComponents';
import {ProductModal} from './ProductModal';
import CollectionMembershipModal from './CollectionMembershipModal';
import CollectionPropertiesModal from './CollectionPropertiesModal';
import {ProductUpdatesModal} from './ProductUpdatesModal';
import {SignUpToViewModal} from './SignUpToViewModal';
import ProductNotFoundModal from './ProductNotFoundModal';
import DeleteCollectionModal from './DeleteCollectionModal';
import { useSelector, useDispatch } from 'react-redux';
import {useWorkingDynamic} from 'src/hooks/useWorkingDynamic';
import {actionCreator,
} from 'src/mvp22/redux-components/actions';
import {
  FIRESTORE_REMOVE_PRODUCT_MEMBERSHIP
} from 'src/mvp22/redux-components/reducers/firestore_product_membership';
import update_product_opinion from 'src/mvp22/firebase-functions/update_product_opinion';
import { useHistory } from 'react-router-dom';
import { ModalState, ModalTypes, ProductData } from 'src/types';
import { useSetModal } from 'src/hooks/useSetModal';
import { CollectionItem } from 'src/types/models/collectionItem.model';
import { getCollectionItemStoreAs } from 'src/utils/getCollectionItemStoreAs';

const GeneralOverlayBG = styled.div`
  z-index:3000;
  display:block;
  position:fixed;
  width:100%;
  height:100%;
  top:0px;
  left:0px;
  box-sizing:border-box;
  background-color:#000000CC;
  justify-content:center;
  align-self:center;
  align-items:center;
`;

const GeneralOverlayBGNext = styled.div`
  position:absolute;
  width:100%;
  height:100%;
  top:0px;
  left:0px;
  justify-content:center;
  align-self:center;
  align-items:center;
  display:flex;
`;
/*

Still to do for refactoring:

- typescript
- camelCase
- get values of product data's collection info from live db redux states rather than set ones (so they will close on deletion)
- in general if values are in redux there is no point in passing them down BUT we do that for productData as we store it locally.

*/

export const Modal = ()=>{
  const dispatch = useDispatch();
  const history = useHistory();
  const firebase = useContext(FirebaseContext);
  const [setModal,closeModal] = useSetModal();
  const modal = useSelector<RootState,ModalState>(state=>state.modal);
  const [setWorking,unsetWorking,workingDict] = useWorkingDynamic();
  const authUserUID = useSelector<RootState,string|null>(state=>state.auth.id);

  // TODO: update these:
  const removeProductMembershipListener=()=>dispatch(actionCreator(FIRESTORE_REMOVE_PRODUCT_MEMBERSHIP));
  const rawMembershipInfo = useSelector<RootState>(state=>state.firestore_product_membership.raw_membership_info);
  const displayUsername = useSelector<RootState>(state=>state.firestore_user_owner?state.firestore_user_owner.display_username:undefined);

  // Set membership things locally because removing it from the current collection could mess things up!
  // Do it here because can jump between sub-modals:
  const [productDataLocal,setProductDataLocal] = useState<ProductData | undefined>(undefined);
  const [modalLocal,setModalLocal] = useState<ModalTypes | null>(null);
  const productDataStoreAs = (modal.type === ModalTypes.new_collection_product || modal.type === ModalTypes.collection_membership)?getCollectionItemStoreAs(modal.collectionUID,modal.collectionUserUID,modal.collectionItem.id):"";
  const productData = useSelector<RootState,ProductData|undefined>((state) => state.productData?state.productData[productDataStoreAs]:undefined);
  useEffect(
    ()=>{
      // Only run when loading the product-membership modal from nothing:
      // Here due to jumping between New collection + product membership
      // Means that if product is deleted / removed from current collection it will still be possible to use that data to save it elsewhere / view the image on the left.
      if (
        (modalLocal===null || modal.type===null) &&
        modal.type!==modalLocal
      ){
        setModalLocal(modal.type);
        setProductDataLocal(productData);
      }
    }
    ,
    [modal.type, modalLocal, productData]
  );


  // For jumping around within modal groups and keeping other data in redux:
  const goToModalCollectionSettingsGroup = (event:undefined|React.MouseEvent,newModalType:string)=>{
    if (
        (modal.type === ModalTypes.collection_settings || modal.type === ModalTypes.delete_collection)
        &&
        (newModalType === ModalTypes.collection_settings || newModalType === ModalTypes.delete_collection)
    ){
      setModal(
        {
          ...modal,
          type:newModalType
        }
      );
    }
    if (event){
      event.preventDefault();
      return false;
    }
  };
  const goToModalCollectionMembership = (event:undefined|React.MouseEvent,newModalType:string)=>{
    if(
      (modal.type === ModalTypes.new_collection_product || modal.type === ModalTypes.collection_membership)
      &&
      (newModalType === ModalTypes.new_collection_product || newModalType === ModalTypes.collection_membership)
    ){
      setModal(
        {
          ...modal,
          type:newModalType
        }
      );
    }
    if (event){
      event.preventDefault();
      return false;
    }
  };

  // This is here as used by multiple modals (collection membership + create new collection product for setting initial membership)
  // TODO: consider moving some of these functions into modals?
  const setCollectionMembership = (
    settingCollectionUID:string,
    opinion:boolean
  )=>{
    if ((modal.type === ModalTypes.collection_membership || modal.type === ModalTypes.new_collection_product) && authUserUID!==null){
      const currentCollectionUID = modal.collectionUID;
      const itemUID = modal.collectionItem.id;
      const working_code = settingCollectionUID+"__"+currentCollectionUID+"__"+itemUID+"__set_collection_membership";
      if (
        workingDict[working_code]===undefined
      ){
        setWorking(working_code,opinion);
        update_product_opinion(
          opinion,
          settingCollectionUID,
          modal.collectionItem,
          productDataLocal?productDataLocal.price:"", // price when product loaded in - required to be saved in case removed from current collection!
          rawMembershipInfo,
          firebase,
          authUserUID,
        ).then(
          ()=>unsetWorking(working_code)
        ).catch(
          ()=>unsetWorking(working_code)
        );
      }
    }
  };
  const afterMakingProductNewCollection = (collectionUID:string,productData:ProductData,collectionItem:CollectionItem)=>{
    goToModalCollectionMembership(undefined,ModalTypes.collection_membership);
    if (collectionUID){
      setCollectionMembership(
        collectionUID,
        true
      );
    }
  };
  // after new collection not in product membership group:
  const afterMakingNewCollection = (collectionUID:string,productData:ProductData,collectionItem:CollectionItem)=>{
    if (modal.type===ModalTypes.new_collection){ // for typescript
      const dont_redirect = modal.dontRedirect;
      if (dont_redirect!==true){
        history.push(R.COLLECTION+"/"+displayUsername+"/"+collectionUID);
      }
      closeModal();
    }
  };
  const afterUpdatingCollection = ()=>{
    closeModal();
  };

  // RENDER:
  const renderContent = ()=>{
    // COLLECTIONS ONLY
    if(modal.type===ModalTypes.new_collection){
      return(
        <CollectionPropertiesModal
          modal_type="new_collection"
          closeFunction={closeModal}
          afterAction={afterMakingNewCollection}
        />
      );
    }else if(modal.type===ModalTypes.collection_settings){
      return(
        <CollectionPropertiesModal
          modal_type="collection_settings"
          collectionUID={modal.collectionUID}
          collection_name_input={modal.collectionName}
          collection_description_input={modal.collectionDescription}
          closeFunction={closeModal}
          afterAction={afterUpdatingCollection}
          openDeleteCollectionModal={(event:React.MouseEvent)=>goToModalCollectionSettingsGroup(event,ModalTypes.delete_collection)}
        />
      );
    }else if(modal.type===ModalTypes.delete_collection){
      return(
        <DeleteCollectionModal
          collection_name={modal.collectionName}
          collection_uid={modal.collectionUID}
          closeFunction={closeModal}
          backFunction={(event:React.MouseEvent)=>goToModalCollectionSettingsGroup(event,ModalTypes.collection_settings)}
        />
      );
    // PRODUCTS
    }else if(modal.type===ModalTypes.collection_membership){
      return(
        <CollectionMembershipModal
          collectionUID={modal.collectionUID}
          collectionUserUID={modal.collectionUserUID}
          collectionItemUID={modal.collectionItem.id}
          productImage={modal.productImage}
          productData={productDataLocal}
          workingDict={workingDict}
          closeFunction={
            (event?:React.MouseEvent)=>{
              if (event){
                event.preventDefault();
              }
              closeModal();
              removeProductMembershipListener();
            }
          }
          openAddToNewCollection={(event:React.MouseEvent)=>goToModalCollectionMembership(event,ModalTypes.new_collection_product)}
          setCollectionMembership={setCollectionMembership}
        />
      );
    }else if(modal.type===ModalTypes.new_collection_product){
      return(
        <CollectionPropertiesModal
          modal_type="new_collection_product"
          collection_name_input={""}
          collection_description_input={""}
          closeFunction={closeModal}
          collectionUID={null}// DO NOT PASS IN collectionUID as this will edit current collection not create a new one!  Ask if this doesn't make sense, it is weird.
          // FROM PRODUCT MEMBERSHIP modal (also used here)
          collectionUserUID={modal.collectionUserUID}
          collectionItem={modal.collectionItem}
          productData={productDataLocal}
          productImage={modal.productImage}
          backToMembershipProperties={(event:React.MouseEvent)=>goToModalCollectionMembership(event,ModalTypes.collection_membership)}
          afterAction={afterMakingProductNewCollection}
        />
      );
    }else if(modal.type===ModalTypes.product_updates){
      return(
        <ProductUpdatesModal
          collectionUID={modal.collectionUID}
          collectionUserUID={modal.collectionUserUID}
          itemUID={modal.itemUID}
          productImage = {modal.productImage}
          closeFunction = {closeModal}
        />
      );
    }else if(modal.type===ModalTypes.product_settings){
      return(
        <ProductModal
         collectionUID={modal.collectionUID}
         collectionUserUID={modal.collectionUserUID}
         itemUID={modal.itemUID}
         showProductModal={modal.showProductModal}
         setProductModalViewed={modal.setProductModalViewed}
        />
      );
    }else if(modal.type===ModalTypes.product_does_not_exist){
      return(
        <ProductNotFoundModal
          closeFunction={modal.closeProductModal}
        />
      );
    // GENERIC:
    }else if(modal.type===ModalTypes.sign_up_to_view){
      return(
        <SignUpToViewModal
          closeFunction={closeModal}
        />
      );
    } else{
      return(
        <GeneralOverlayWithinContainer>
          <TEXTSTYLE.HEADING2>Invalid Modal Type!</TEXTSTYLE.HEADING2>
        </GeneralOverlayWithinContainer>
      );
    }
  };

  return(
    (modal.type!==null)?
      <GeneralOverlayBG>
        <GeneralOverlayBGNext>
          {renderContent()}
        </GeneralOverlayBGNext>
      </GeneralOverlayBG>
    :
      null
  );
};
