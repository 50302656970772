/*

For bookmarking collections (used to be "like")

TODO: Replace with design system.

*/


import React, { useState } from 'react';
import styled from 'styled-components';
import { useHistory } from 'react-router-dom';

import R from 'src/routes';
import {TEXTSTYLE} from 'src/mvp22/style-components/Text';
import MEDIA from 'src/mvp22/media';
import {HeartIconWorking} from 'src/mvp22/image-components/WorkingDots';
import update_like from 'src/mvp22/firebase-functions/update_like';
import {withFirebase} from 'src/mvp22/Firebase';

const BookmarkNumber = styled(TEXTSTYLE.BODY5)`
  margin-left: 7px;
  display: inline-block;
`;

const BookmarkContainer = styled(TEXTSTYLE.LINKA)`
  &:hover{
    img{
      opacity: 0.8
    }
  }
  cursor:pointer;
  bottom: 18px;
  right: 15px;
  display: flex;
  align-items: center;
  padding: 10px;
  margin: -10px;
`;

const Bookmark = styled.img`
color:default;
`;

const BookmarkButton = props => {
  const history = useHistory();
  const [bookmarking_is_working, setBookmarkingIsWorking] = useState(false);
  const [like_count_modifier, setLikeCountModifier] = useState(0);

  function handleClick(event) {
    event.preventDefault();
    if (props.auth_user_uid!==null){
      if (bookmarking_is_working!==true && props.collection_liked_is_liked!==undefined){
        setBookmarkingIsWorking(true);

        const new_state = (props.collection_liked_is_liked===true)?false:true;

        update_like(
          (props.collection_entry_user_uid)?props.collection_entry_user_uid:props.auth_user_uid,
          props.collection_entry_uid,
          new_state,
          props.firebase
        ).then(
          // unset working:
          ()=>{
              setBookmarkingIsWorking(false);
              // If a listener will probably want to make this from this current value not prev state...
              setLikeCountModifier(prevState => prevState+((new_state)?1:-1));
          }
        );
      }
    } else {
      // Send the user to the sign up page if it's not logged in
      history.push(R.SIGNIN, { redirectTo: window.location.href });
    }
  }
  return (
    <BookmarkContainer href="#" onClick={handleClick} className='bookmarkContainer'>
      {
        (bookmarking_is_working===true)?
          <HeartIconWorking/>
        :
          <Bookmark src={props.collection_liked_is_liked === true ? MEDIA.BLACK_BOOKMARK : MEDIA.WHITE_BOOKMARK }/>
      }
      <BookmarkNumber>{props.like_count+like_count_modifier}</BookmarkNumber>
    </BookmarkContainer>
  );
};

export default withFirebase(BookmarkButton);
