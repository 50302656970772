import React from 'react';
import styled from 'styled-components';
import {
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  EditIcon,
  ShareIcon,
} from '@moonsifttech/design-system';

import { modalMenuClassNames } from 'src/components/core/ModalMenu';
import { MenuContent } from 'src/components/core/MenuContent';
import {
  MoreCollectionOptionsMenuRef,
  MoreCollectionOptionsMenuProps,
} from './MoreCollectionOptionsMenu.types';

const UnstyledMoreCollectionOptionsMenu = React.forwardRef<
  MoreCollectionOptionsMenuRef,
  MoreCollectionOptionsMenuProps
>(({ className, style, isMine, onShare, onEdit }, ref) => {
  return (
    <MenuContent ref={ref} className={className} style={style}>
      <List>
        {isMine && (
          <ListItem onClick={onEdit}>
            <ListItemIcon>
              <EditIcon />
            </ListItemIcon>
            <ListItemText>Edit Collection</ListItemText>
          </ListItem>
        )}
        <ListItem onClick={onShare}>
          <ListItemIcon>
            <ShareIcon />
          </ListItemIcon>
          <ListItemText>Share Collection</ListItemText>
        </ListItem>
      </List>
    </MenuContent>
  );
});

export const MoreCollectionOptionsMenu = styled(
  UnstyledMoreCollectionOptionsMenu,
)`
  .${modalMenuClassNames.isDesktop} & {
    width: 180px;
  }
`;
