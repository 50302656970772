/*

General ability to enable notifications about price drops, comments etc.

Enables notifcations from this panel that can be in the GetNotifications popup or the settings view.

TODO: camelCase, typescript.

*/
import React,{useState} from 'react';
import MEDIA from 'src/mvp22/media';
import styled from 'styled-components';
import {TEXTSTYLE} from 'src/mvp22/style-components/Text';
import LoadingDots from 'src/mvp22/image-components/LoadingDots';
import {M} from 'src/mvp22/constants';
const EnableNotificationsButtonContainer = styled.div`
  flex-grow: 0;
  display: flex;
`;

const EnableNotificationsButton = styled(TEXTSTYLE.LINKA)`
  padding:0px 12px;
  display: flex;
  align-items: center;
  align-content: center;
  height: 36px;
  background: ${M.COL.BUTTON.WHITE};
  border: 1px solid ${M.COL.BUTTON.BLACK};
  box-sizing: border-box;
  border-radius: 2px;
  justify-content: space-evenly;
  cursor:pointer;
  opacity:${props=>props.disabled===true?0.5:1.0};
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.25);
  &:hover{
    box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.25);
  }
`;

const EnableNotificationsText = styled(TEXTSTYLE.OTHER4)`
  display: inline-block;
  width:200px;
`;

const EnableNotificationsIcon = styled.img`
  display: inline-block;
  height:16px;
  margin-right:12px;
`;

const ErrorNotificationsEnable = styled(TEXTSTYLE.BODY4)`
  color:${M.COL.TEXT.ERROR};
  margin-top:12px;
  text-align:left;
`;


const NotificationEnablerContainer = styled.div`
  display:flex;
  margin:12px 0px;
  flex-direction:column;
`;

const NotificationNotification = styled(TEXTSTYLE.BODY4)`
  background: ${M.COL.BUTTON.LIGHTYELLOW};
  border: 1px solid ${M.COL.BUTTON.YELLOW};
  box-sizing: border-box;
  border-radius: 10px;
  padding:10px 12px;
  text-align:left;
  margin-top:16px;
`;

const EnableNotifications = (props)=>{
  const [requesting_notifications,setRequestingNotifications] = useState(false);
  const [notifications_enabled_message,setNotificationsEnabledMessage] = useState("");
  const [enable_clicked_once,setEnableClickedOnce] = useState(false);
  const enableNotifications = async function(event){
    event.preventDefault();
    if (requesting_notifications===false){
      setRequestingNotifications(true);
      var new_notifications_enabled_message = "";
      var new_notifications_enabled = false;
      try{
        setEnableClickedOnce(true);
        //await props.firebase.messaging.requestPermission();
        // Above line now done by below line?
        const token = await props.firebase.getToken();
        await props.firebase.fast_api()(
          {
            api:"update_notification_token",
            token
          }
        ).then(
          (response)=> {
            if (response.data===true){
              new_notifications_enabled = true;
            }else{
              new Error("Fast API failed");
            }
          }
        );
      }catch(err){
        console.log("Failed to set up notifications",err);
        new_notifications_enabled_message='Failed to enable browser notifications. Currently you can only enable Chrome notifications on your Desktop or Android devices. Also check that you have not blocked them in your browser.';
      }
      setRequestingNotifications(false);
      setNotificationsEnabledMessage(new_notifications_enabled_message);
      props.setNotificationsEnabled(new_notifications_enabled);
    }
    return false;
  };
  return(
    <NotificationEnablerContainer>
      <EnableNotificationsButtonContainer>
        <EnableNotificationsButton onClick={enableNotifications}>
          <EnableNotificationsIcon src={MEDIA.BELL}/>
          <EnableNotificationsText>
            {
              (requesting_notifications===true)?
                <LoadingDots msstyle="dark"/>
              :
                "Enable notifications on this device"
            }
          </EnableNotificationsText>
        </EnableNotificationsButton>
      </EnableNotificationsButtonContainer>
      {
        (notifications_enabled_message!=="")?
        <ErrorNotificationsEnable>
          {notifications_enabled_message}
        </ErrorNotificationsEnable>
        :
        ""
      }
      {
        (props.show_notification_notification===true)?
          <NotificationNotification>
          {
          (enable_clicked_once===true)?
            <>Click <b>Allow</b> in your browser’s pop-up to enable notifications (you can turn these off any time).</>
            :
            <>Enable notifications so you’ll know about price drops and comments on items you’ve saved.</>
          }
          </NotificationNotification>
        :
          null
      }
    </NotificationEnablerContainer>
  );
};

export default EnableNotifications;
