/*

NOT A REDUCER!

Gets the user data when the site loads and / or they are marked as logged in

Data got is:
user_owner
user_verified_settings
collection_list

See those reducers for more info.

TODO: fetch the above data in the useAuthUserData hook:
react-app/src/hooks/useAuthUserData
Use the DBhooks to do this and appropriate parsers (so we don't need a pseudo doc).

*/
import {
  actionCreator,
  FIREBASE_SET_AUTH,
  LISTEN_FOR_NOTIFICATIONS,
} from 'src/mvp22/redux-components/actions';

import {
  firestore_user_owner_redux_set
} from './firestore_user_owner';
import {
  firestore_user_collection_list_redux_set
} from './firestore_user_collection_list';


export function firestore_user_set_up_redux(data_in){
  const auth_user_uid = data_in.auth_user_uid;
  const firebase = data_in.firebase;
  return (dispatch) => {
    // sync:
    dispatch(
      actionCreator(
        FIREBASE_SET_AUTH,
        {
          loaded:false
        }
      )
    );
    // async:
    const promises_to_get = [
      dispatch(
        firestore_user_owner_redux_set(
          {
            auth_user_uid,
            firebase
          }
        )
      ),
      dispatch(
        firestore_user_collection_list_redux_set(
          {
            auth_user_uid,
            firebase
          }
        )
      )
    ];
    dispatch(
      actionCreator(
        LISTEN_FOR_NOTIFICATIONS,
        {
          auth_user_uid,
          firebase
        }
      )
    );
    //Note: Add here any other populating that needs to be done when logged in...!
    Promise.all(promises_to_get).then(
      ()=>{
        // sync:
        dispatch(
          actionCreator(
            FIREBASE_SET_AUTH,
            {
              loaded:true
            }
          )
        );
      }
    );
  };
}
