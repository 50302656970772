import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { Avatar, Stack, Typography, Link } from '@moonsifttech/design-system';

import { UserProps } from './User.types';

export const User: React.FC<UserProps> = ({
  className,
  style,
  src,
  alt,
  link,
  isPro,
  children,
}) => {
  return (
    <Stack
      className={className}
      style={style}
      direction="row"
      alignItems="center"
      spacing={6}
    >
      <Avatar alt={alt} src={src} isPro={isPro}>
        {children}
      </Avatar>
      <Typography variant="primary.l14">
        By{' '}
        <Link to={link} component={RouterLink}>
          {alt}
        </Link>
      </Typography>
    </Stack>
  );
};
