/*

Probably can stay broadly in this format but some refactoring?

*/

import {useState} from 'react';
import {M} from 'src/mvp22/constants';
import Cookies from 'universal-cookie';
const cookies = new Cookies();

// actions:
const useProvisionAccount = (
  firebase
) => {
  // useState
  const [is_provisioning, setIsProvisioning] = useState(false); // working flag
  const [provisioning_success,setProvisioningSuccess] = useState(null); // success flag
  const [started_provisioning_once,setStartedProvisioningOnce] = useState(false); // ensures we only run it once on load?

  // actions:
  const provisionAccount = (
    name
  )=>{
    // This gives you a Facebook Access Token. You can use it to access the Facebook API. commented out for now
    // var token = result.credential.accessToken;
    // The signed-in user info. commented out for now because unused
    // var user = result.user;
    if (is_provisioning!==true){
      setIsProvisioning(true);
      setStartedProvisioningOnce(true);
      const referred_by = (cookies.get(M.REFERRAL_COOKIE_KEY))?cookies.get(M.REFERRAL_COOKIE_KEY):null;
      const procode = (cookies.get(M.PRO_CODE_COOKIE_KEY))?cookies.get(M.PRO_CODE_COOKIE_KEY):null;
      firebase.fast_api()(
        {
          api:"users_provision_account",
          referred_by,
          procode,
          name
        }
      ).then(
        (result)=>{
          if (result.data.provision_success===true){
            setProvisioningSuccess(true);
            cookies.set(M.PRO_CODE_COOKIE_KEY,"",{maxAge:60*60*24*90,path:M.COOKIEPATH});
          }
          else{
            setProvisioningSuccess(false);
            console.log("Failed to provision!",result);
          }
          setIsProvisioning(false);
        }
      ).catch(
        (err)=>{
          setProvisioningSuccess(false);
          console.log("Failed to provision!",err);
          setIsProvisioning(false);
        }
      );
    }
  };
  return(
    {
      is_provisioning,
      started_provisioning_once,
      provisioning_success,
      provisionAccount
    }
  );
};

export default useProvisionAccount;
