/*

This loads and stores the user-specifc data for configuring the app.

REDUX db

TODO: move to a db hook and invoke in react-app/src/hooks/useAuthUserData/useAuthUserData.ts
A little bit of parsing will be needed

Fetch data from user_owner on a listener,
user_pro_settings on a get (readonly)
user_verified_settings on a listener (readonly)
*/
import {FIRESTORE_REDUX_REMOVE_ALL} from 'src/mvp22/redux-components/actions';
const FIRESTORE_REDUX_SET_USER_OWNER = "FIRESTORE_REDUX_SET_USER_OWNER";
const FIRESTORE_REDUX_SET_USER_OWNER_LISTENER = "FIRESTORE_REDUX_SET_USER_OWNER_LISTENER";
const FIRESTORE_REDUX_REMOVE_USER_OWNER = "FIRESTORE_REDUX_REMOVE_USER_OWNER";

const DEFAULT_FIRESTORE = {
  uid:null,
  snapshot:{}
};

export default function firestoreState(state = DEFAULT_FIRESTORE, action) {
  switch (action.type) {
    case FIRESTORE_REDUX_SET_USER_OWNER:
      return Object.assign({},state,action.data);
    case FIRESTORE_REDUX_SET_USER_OWNER_LISTENER:
      // 1) Remove the old listener to avoid any more updates...
      if (state.listener!==undefined){
        state.listener();
      }
      return Object.assign({},state,action.data);
    case FIRESTORE_REDUX_REMOVE_ALL:
    case FIRESTORE_REDUX_REMOVE_USER_OWNER:
      if (state.listener!==undefined){
        state.listener();
      }
      return DEFAULT_FIRESTORE;
    default:
      return state;
  }
}

// Action creator is here now:!
export function firestore_user_owner_redux_set(data_in){
  // We are returning a function because there is ASYNC operation coming...
  // ...this means that we need redux-thunk!
  // We are doing this checking state, getting firestore async etc.
  // as part of the action since we expect it to be a cleaner way to do it across
  // multiple components.
  return (dispatch) => {
    // remove all regardless:
    dispatch(
      {
        type:FIRESTORE_REDUX_REMOVE_USER_OWNER,
      }
    );
    // WE need to do an update, so:
    if (data_in.auth_user_uid!==null){
      // dispatch a function returns that returns a promise
      const promise_list = [
        new Promise(
          (resolve_sub)=>{
            // 2) Request and then save the data:
            const listener = data_in.firebase.user_owner(data_in.auth_user_uid).onSnapshot(
              snapshot=>{
                // THIS DISPATCH IS SYNCHRONOUS!
                dispatch(
                  {
                    type:FIRESTORE_REDUX_SET_USER_OWNER,
                    data:{
                      snapshot:snapshot.data(),
                      uid:data_in.auth_user_uid
                    }
                  }
                );
                resolve_sub();
              }
            );
            // set / replace listener:
            dispatch(
              {
                type:FIRESTORE_REDUX_SET_USER_OWNER_LISTENER,
                data:{
                  listener
                }
              }
            );
          }
        ),
        data_in.firebase.user_pro_settings(data_in.auth_user_uid).get().then(
          (snapshot)=>{
            dispatch(
              {
                type:FIRESTORE_REDUX_SET_USER_OWNER,
                data:{
                  pro:(snapshot.exists())?snapshot.data().pro===true:false,
                  pro_data:(snapshot.exists())?snapshot.data():{}
                }
              }
            );
          }
        ).catch(
          (err)=>{
            console.log("Failed to get username, using fallback",err);
            dispatch(
              {
                type:FIRESTORE_REDUX_SET_USER_OWNER,
                data:{
                  pro:false
                }
              }
            );
          }
        ),
        data_in.firebase.user_verified_settings(data_in.auth_user_uid).get().then(
          (snapshot)=>{
            if (snapshot.exists()){
              const vs_data = snapshot.data();
              const data_out = {
                display_username:data_in.auth_user_uid,
                provisioned:vs_data.provisioned
              };
              if (vs_data.original_username){
                data_out.original_username = vs_data.original_username;
                data_out.display_username = vs_data.original_username;
              }
              if (vs_data.alias_username){
                data_out.alias_username = vs_data.alias_username;
                data_out.display_username = vs_data.alias_username;
              }
              dispatch(
                {
                  type:FIRESTORE_REDUX_SET_USER_OWNER,
                  data:data_out
                }
              );
            }
          }
        ).catch(
          (err)=>{
            console.log("Failed to get username, using fallback",err);
            dispatch(
              {
                type:FIRESTORE_REDUX_SET_USER_OWNER,
                data:{
                  display_username:data_in.auth_user_uid
                }
              }
            );
          }
        )
      ];
      return Promise.all(promise_list);
    }
  };
}
