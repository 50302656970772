import React from 'react';
import styled from 'styled-components';
import {
  Typography,
  TextField,
  StandardButton,
  Stack,
} from '@moonsifttech/design-system';

import TopMenu from 'src/mvp22/menu-components/TopMenu';
import { CreateCollectionProps } from './CreateCollection.types';

export const CreateCollectionRoot = styled.div`
  margin-bottom: 40px;

  .topMenu {
    height: 56px;
    margin-bottom: 28px;
  }
`;

export const CreateCollectionInnerContainer = styled.div`
  margin: 0 auto;
  max-width: 328px;

  form > div:first-child {
    margin-bottom: 16px;
  }

  form > div:nth-last-child(2) {
    margin-bottom: 40px;
  }
`;

export const CreateCollection: React.FC<CreateCollectionProps> = ({
  isFirst,
  isPro,
  working,
  onCollectionCreation,
}) => {
  const [collectionName, setCollectionName] = React.useState('');

  const handleChange = React.useCallback<
    React.ChangeEventHandler<HTMLInputElement>
  >((event) => {
    setCollectionName(event.target.value);
  }, []);

  return (
    <CreateCollectionRoot>
      <TopMenu className="topMenu" showRight={false} />
      <CreateCollectionInnerContainer>
        <Typography variant="primary.b26" component="h1">
          {isFirst ? 'Create your first collection' : 'Create new collection'}
        </Typography>
        <form onSubmit={onCollectionCreation}>
          <TextField
            name="name"
            placeholder="Name your collection"
            fullWidth
            defaultValue=""
            maxLength={100}
            onChange={handleChange}
          />
          {isPro && (
            <TextField
              name="description"
              placeholder="Add a description"
              fullWidth
              multiline
              defaultValue=""
              minRows={4}
              maxLength={400}
            />
          )}
          <Stack direction="row" justifyContent="flex-end">
            <StandardButton
              type="submit"
              variant="primary-white"
              disabled={!collectionName}
              loading={working}
            >
              Create
            </StandardButton>
          </Stack>
        </form>
      </CreateCollectionInnerContainer>
    </CreateCollectionRoot>
  );
};
