/*

Flash below button to say the link has been copied to clipboard.

TODO: Typescript + camelCase

*/
import React from 'react';
import { Component } from 'react';
import styled, {keyframes} from 'styled-components';
import {M} from 'src/mvp22/constants';
import PropTypes from 'prop-types';
import {TEXTSTYLE} from 'src/mvp22/style-components/Text';

const rotate = keyframes`
  0% {opacity: 1;}
  100% {opacity: 0;}
`;

const AllContaining = styled.div`
  height:68px;
  width:100%;
  display:flex;
  align-items: center;
  justify-content: center;
`;

const LinkCopiedOverlay = styled.div`
  animation: ${rotate} 1s linear 1;
  animation-fill-mode: forwards;
  color:${M.COL.TEXT.MID};
`;

class LinkCopied extends Component {
  static propTypes = {
    /** Height of field (text area) */
    visible: PropTypes.bool,
  }

/*
Link copied class
*/
  render(){
    // start timer if false and true in props...!

    const TORETURN = (this.props.visible===true)?
    <LinkCopiedOverlay>
      <TEXTSTYLE.OTHER2>
        Copied to clipboard!
      </TEXTSTYLE.OTHER2>
    </LinkCopiedOverlay>
    :"";
    return(
      <AllContaining>
        {TORETURN}
      </AllContaining>
    );
  }
}
export default LinkCopied;
