/*
Login page.

TODO: typescript, camelCase, make functional, non-default exports, redux hooks, design system, split container / component ...

*/
import React, {useState, useEffect, useContext} from 'react';
import styled from 'styled-components';
import {TEXTSTYLE} from 'src/mvp22/style-components/Text';
import {withRouter} from 'react-router-dom';
import useSignUpForm from 'src/mvp22/form-components/UseSignUpForm';
import {FullBlank} from 'src/mvp22/menu-components/TopMenu';
import {AddHelmetTitle} from "src/mvp22/core-components/helmet";
import {M} from 'src/mvp22/constants';
import Button from 'src/mvp22/form-components/Button';
import TextInput from 'src/mvp22/form-components/TextInput';
import R from 'src/routes';
import AuthProviderButton from 'src/mvp22/form-components/AuthProviderButton';
import segmentEvent from "src/mvp22/segment-components/SegmentEvent";
import LogoAndText from 'src/mvp22/image-components/LogoAndText.js';
import { connect } from 'react-redux';
import { AuthContext} from 'src/index';
import {actionCreator,
  SET_LOGIN_STARTED_TIMER
} from 'src/mvp22/redux-components/actions';

const Container = styled.div`
  background-color:${M.COL.BG.LOGIN};
  color:${M.COL.TEXT.BLACK};
  display:flex;
  flex-direction:column;
  align-items:center;
  justify-content: center;
  min-height:100vh;
  box-sizing:border-box;
  width:100%;
`;

const CenteredBoxOuter = styled.div`
  display:flex;
  justify-content:center;
  align-items:center;
  background-color:${M.COL.BG.WHITE};
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 5px;
  padding:36px 12px;
  box-sizing:border-box;
  width:491px;
  max-width:100%;
`;

const CenteredBoxInner = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 332px;
`;

const TitleContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const OrDivider = styled.div`
  width: 100%;
  display: flex;
`;

const Or = styled(TEXTSTYLE.HEADING5)`
  margin-right: 21px;
  margin-left: 21px;
`;

const Line = styled.div`
  display: flex;
  align-self: center;
  height: 1px;
  background-color: ${M.COL.BUTTON.BLACK};
  width: 100%;
  top: 50%;
  position: relative;
`;

const LeftLine = styled(Line)`
color:default;
`;

const RightLine = styled(Line)`
color:default;
`;

const Seperator = styled.div`
  height:${props=>props.height};
`;

export const GeneralOverlayBG = styled.div`
  z-index:3000;
  display:block;
  position:fixed;
  width:100%;
  height:100%;
  top:0px;
  left:0px;
  box-sizing:border-box;
  background-color:#000000CC;
  justify-content:center;
  align-self:center;
  align-items:center;
`;

export const GeneralOverlayBGNext = styled.div`
  position:absolute;
  width:100%;
  height:100%;
  top:0px;
  left:0px;
  justify-content:center;
  align-self:center;
  align-items:center;
  display:flex;
`;

const OverallFeedback = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: flex-start;
  margin-left: 2px;
  text-align: left;
  margin-bottom: 3px;
  color: ${M.COL.TEXT.ERROR};
`;

const ForgotPassword = styled(TEXTSTYLE.OTHER6)`
  display:flex;
  justify-content:flex-end;
  align-items:center;
  color:${M.COL.TEXT.MID};
`;

const Login = (props) => {
  const auth = useContext(AuthContext);
  const [working, setWorking] = useState(false);
  const [messageOut, setMessageOut] = useState("");

  useEffect(
    () => {
      segmentEvent("Viewed Log in Page");
    },
    []
  );

  const handleLoginWithEmail = () => {
    segmentEvent("Submitted log in form on log in page");
    const email = inputs.email;
    const password = inputs.password;
    setWorking(true);
    props.setLoginInitiated();
    setTimeout(
      ()=>props.resetLoginInitiated(),
      5000
    );
    auth.signInWithEmailAndPassword(
      email,
      password
    ).catch( // will log in automatically!
      error => {
        var message_out;
        switch (error.code){
          case "auth/invalid-email":
            message_out = "Sorry, that email doesn't look valid";
            break;
          case "auth/user-not-found":
            message_out = "The email address you've entered doesn't match any account. Please sign up for an account";
            break;
          case "auth/wrong-password":
            message_out = "Sorry, that password is not correct. Please try again or try logging in with Facebook or Google below. ";
            break;
          default:
            message_out = error.message;
        }
        setMessageOut(message_out);
        setWorking(false);
      }
    );
  };

  const {inputs, handleInputChange, handleSubmit} = useSignUpForm(
    {email: '', password: ''},
    handleLoginWithEmail
  );

  const handleSignInWithProvider = (provider_name) => {
    segmentEvent("Clicked on Continue with Authprovider on log in Page");
    var provider;
    if(provider_name === "Facebook"){
      provider = "facebook";
    } else if (provider_name === "Google"){
      provider = "google";
    }
    props.setLoginInitiated();
    setTimeout(
      ()=>props.resetLoginInitiated(),
      20000
    );
    auth.signInWithProvider(provider).catch(
      // will log in automatically!
      // If they sign in without an account it will have to create a new one so need to provision one...
      // This is done in the main app to basically ask the user to provision an account if one has not been provisioned properly
      (err)=>{
        setMessageOut("Sorry, sign in via "+provider_name+" failed.  Error: "+err.message);
      }
    );
  };

  return (
    <FullBlank>
      <Container>
        {AddHelmetTitle("Login")}
        <CenteredBoxOuter>
          <CenteredBoxInner className="CenteredBox">
          <TitleContainer>
              <TitleContainer>
                <Seperator height='5px'/>
                <LogoAndText heighttype="A"/>
                <Seperator height='36px'/>
              </TitleContainer>
          </TitleContainer>
          <OverallFeedback>
            <TEXTSTYLE.BODY5>
              {messageOut}
            </TEXTSTYLE.BODY5>
          </OverallFeedback>
          <form onSubmit={handleSubmit} noValidate autoComplete="off">
            <TextInput msstyle="sign_up" width="100%" placeholder='Email' className="input" type="email" name="email" onChange={handleInputChange} value={inputs.email} required/>
            <Seperator height='6px'/>
            <TextInput msstyle="sign_up" width="100%" placeholder='Password' className="input" type="password" name="password" onChange={handleInputChange} value={inputs.password}/>
            <ForgotPassword>
              <TEXTSTYLE.LINK to={R.FORGOTPASSWORD}>
                Forgot password?
              </TEXTSTYLE.LINK>
            </ForgotPassword>
            <Seperator height='11px'/>
              <Button
                working_overlay={working===true}
                msstyle={"join_page_signup_button"}
                type="submit"
                text="Log In"
              />
          </form>
          <Seperator height='11px'/>
          <OrDivider><LeftLine/><Or>OR</Or><RightLine/></OrDivider>
          <Seperator height='11px'/>
          <AuthProviderButton
            type="submit"
            handle_authorise={() => (handleSignInWithProvider("Facebook"))}
            provider="Facebook"
          />
          <Seperator height='6px'/>
          <AuthProviderButton
            type="submit"
            handle_authorise={() => (handleSignInWithProvider("Google"))}
            provider="Google"
          />
          <Seperator height='21px'/>
          <TEXTSTYLE.BODY5>By continuing, you agree to Moonsift’s Terms of Service, Privacy policy. </TEXTSTYLE.BODY5>
          <Seperator height='11px'/>
          <TEXTSTYLE.BODY5>No account?&nbsp; <TEXTSTYLE.LINK to={R.SIGNUP}><b>Sign up</b></TEXTSTYLE.LINK></TEXTSTYLE.BODY5>
          </CenteredBoxInner>
        </CenteredBoxOuter>
      </Container>
    </FullBlank>
  );
};


export default connect(
  ()=>({}),
  dispatch => {
    return {
      setLoginInitiated:()=>dispatch(
        actionCreator(
          SET_LOGIN_STARTED_TIMER,
          true
        )
      ),
      resetLoginInitiated:()=>dispatch(
        actionCreator(
          SET_LOGIN_STARTED_TIMER,
          false
        )
      )
    };
  }
)(
  withRouter(
    Login
  )
);
