import React, { useState, useEffect, useCallback, useContext } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ProductTile } from 'src/mvp22/tile-components/ProductTile';
import { ProductTileContainerProps } from './ProductTile.types';
import R from 'src/routes';
import { product_membership_redux_set } from 'src/mvp22/redux-components/reducers/firestore_product_membership';
import { CloudContext, FirebaseContext, RootState } from 'src/index';
import update_product_opinion from 'src/mvp22/firebase-functions/update_product_opinion';
import get_site_uid from 'src/mvp22/core-components/get_site_uid';
import { getCollectionItemStoreAs } from 'src/utils/getCollectionItemStoreAs';
import { CollectionItem } from 'src/types/models/collectionItem.model';
import { ActionNames, ModalTypes, ProductData } from 'src/types';
import { useHistory } from 'react-router-dom';
import { Reaction } from 'src/types/models/reaction.model';
import {
  FooterPopupAction,
  FooterPopupState,
} from 'src/types/models/footerPopup.model';
import { useWorking } from 'src/hooks/useWorking';
import { useSetModal } from 'src/hooks/useSetModal';

const Container: React.FC<ProductTileContainerProps> = ({
  isOrganising,
  itemUID,
  collectionUserUID,
  collectionUID,
  showProductModal: showProductModalProps,
  viewingData,
  choosingCoverImage,
  coverImageDict,
  selectCover,
}) => {
  // STATE
  const [loadingPopup, setLoadingPopup] = useState(false);
  const [isPurchased, setIsPurchased] = useState(false); // immediate update state set.
  const [showArrows, setShowArrows] = useState(false);
  const [showReactionsBar, setShowReactionsBar] = useState(false);
  const [imageIndex, setImageIndex] = useState(0);
  // REDUX
  const authUserUID = useSelector<RootState, string | null>(
    (state) => state.auth.id,
  );
  const dispatch = useDispatch();
  const [setModal] = useSetModal();
  const setFooterPopup = useCallback(
    (payload: FooterPopupState) =>
      dispatch<FooterPopupAction>({
        type: ActionNames.FOOTER_POPUP_SET,
        payload,
      }),
    [dispatch],
  );
  // Working dict in redux in case remounting:
  const productMembershipSet = (...data: any) =>
    dispatch(product_membership_redux_set(...data));
  // OTHER HOOKS:
  const firebase = useContext(FirebaseContext);
  const cloud = useContext(CloudContext);
  const history = useHistory();
  const productDataStoreAs = getCollectionItemStoreAs(
    collectionUID,
    collectionUserUID,
    itemUID,
  );
  const [setDeleteWorking, unsetDeleteWorking, deleteWorkingValue] = useWorking(
    productDataStoreAs + '__delete',
  );
  const [setPurchasedWorking, unsetPurchasedWorking, purchasedWorkingValue] =
    useWorking(productDataStoreAs + '__purchased');
  const [setUpvoteWorking, unsetUpvoteWorking, upvoteWorkingValue] = useWorking(
    productDataStoreAs + '__upvote',
  );
  const [setDownvoteWorking, unsetDownvoteWorking, downvoteWorkingValue] =
    useWorking(productDataStoreAs + '__downvote');
  const productData = useSelector<RootState, ProductData | undefined | null>(
    (state) =>
      state.productData ? state.productData[productDataStoreAs] : undefined,
  );
  const collectionItem = useSelector<RootState, CollectionItem | null>(
    (state) => {
      const listOfOneOrNone = state.db.single_collection_item_list.data.filter(
        (x) => x.id === itemUID,
      );
      return listOfOneOrNone.length === 1 ? listOfOneOrNone[0] : null;
    },
  );
  const reactionQueryResult = useSelector<RootState, Reaction[] | undefined>(
    (state) =>
      state.db.single_collection_product_is_liked_or_disliked.map[
        productDataStoreAs
      ],
  );
  const reaction =
    reactionQueryResult && reactionQueryResult.length === 1
      ? reactionQueryResult[0].type
      : undefined;
  const isPurchasedItem = useSelector<RootState, CollectionItem[] | undefined>(
    (state) =>
      state.db.single_collection_product_is_purchased.map[productDataStoreAs],
  );
  const isPurchasedDB =
    isPurchasedItem && isPurchasedItem.length > 0 ? true : false;
  const isSavedItem = useSelector<RootState, CollectionItem[] | undefined>(
    (state) =>
      state.db.single_collection_product_is_saved.map[productDataStoreAs],
  );
  const isSaved = isSavedItem && isSavedItem.length > 0 ? true : false;
  useEffect(() => {
    setIsPurchased(isPurchasedDB);
  }, [isPurchasedDB, setIsPurchased]);
  const isMine = authUserUID === collectionUserUID;

  // If the specified default image changes, we want to change the selected image:
  // if the specified default image changes (i.e. in product popup), then move to that one so that it looks like we actually have done something!
  // image index is defaulted to the one selected by arrows.
  useEffect(() => {
    if (collectionItem && collectionItem.product_image && productData) {
      const setToIndex = productData.imageList.findIndex(
        (x) => collectionItem.product_image === x,
      );
      if (setToIndex >= 0) setImageIndex(setToIndex);
    }
  }, [collectionItem, productData]);

  const loadProductMenu = async (event: MouseEvent) => {
    event.preventDefault();
    if (authUserUID !== null) {
      if (
        collectionItem &&
        productData &&
        productData.imageList &&
        productData.imageList[imageIndex]
      ) {
        setLoadingPopup(true);
        const callback = () => {
          setModal({
            type: ModalTypes.collection_membership,
            collectionUID,
            collectionUserUID,
            productImage: productData.imageList[imageIndex],
            collectionItem,
          });
        };
        // Set the data in redux:
        productMembershipSet(
          collectionItem.page_url,
          firebase,
          authUserUID,
          callback,
        );
        setLoadingPopup(false);
      }
    } else {
      history.push(R.SIGNUP);
    }
    return false;
  };

  const showProductModal = (event: MouseEvent) => {
    if (!isOrganising) {
      showProductModalProps(true, itemUID);
    }
    event.preventDefault();
    return false;
  };

  const setArrowsVisibility = (value: boolean) => {
    // Only show more images if there is likely more than one that is NOT the saved image!
    if (!isOrganising) {
      setShowReactionsBar(value);
      if (
        productData &&
        productData.imageList &&
        productData.imageList.filter((x) => !x.includes('moonimage_')).length >
          0
      ) {
        setShowArrows(value);
      }
    }
  };

  const changeViewingProductImage = (indexModifier: number) => {
    if (productData && productData.imageList) {
      var imageIndex_out = imageIndex + indexModifier;
      if (imageIndex_out >= productData.imageList.length) {
        imageIndex_out = 0;
      } else if (imageIndex_out < 0) {
        imageIndex_out = productData.imageList.length - 1;
      }
      setImageIndex(imageIndex_out);
    }
  };

  const addOrRemoveProductToPurchased = (opinion: boolean) => {
    // Ensure input data is used in promise .thens not later defined properties...:
    if (
      purchasedWorkingValue === undefined &&
      productData &&
      authUserUID !== null
    ) {
      setPurchasedWorking(true);
      update_product_opinion(
        opinion,
        'purchased',
        collectionItem,
        productData.price,
        null, // no membership info known so will need to lookup in function
        firebase,
        authUserUID,
      )
        .then(() => {
          unsetPurchasedWorking();
          if (opinion === true) {
            setFooterPopup({
              type: 'added_to_purchased',
              uid: productDataStoreAs + Date.now(),
            });
          }
          setIsPurchased(opinion);
        })
        .catch(unsetPurchasedWorking);
    }
  };

  // e.g. PRICE info etc.:
  const loadProductUpdateMenu = (event: MouseEvent) => {
    event.preventDefault();
    if (
      productData &&
      productData.imageList &&
      productData.imageList[imageIndex]
    ) {
      setModal({
        type: ModalTypes.product_updates,
        collectionUID,
        collectionUserUID,
        itemUID,
        productImage: productData.imageList[imageIndex],
      });
    }
    return false;
  };

  const reactProduct = (
    event: MouseEvent,
    currentType: string,
    newType: string,
  ) => {
    event.preventDefault();
    const working =
      newType === 'upvote'
        ? upvoteWorkingValue
        : newType === 'downvote'
        ? downvoteWorkingValue
        : false;
    const setWorking =
      newType === 'upvote'
        ? setUpvoteWorking
        : newType === 'downvote'
        ? setDownvoteWorking
        : false;
    const unsetWorking =
      newType === 'upvote'
        ? unsetUpvoteWorking
        : newType === 'downvote'
        ? unsetDownvoteWorking
        : false;
    if (
      working === undefined &&
      setWorking !== false &&
      unsetWorking !== false
    ) {
      setWorking(true);
      const aPIToCall =
        currentType !== newType ? 'set_reaction' : 'unset_reaction';
      cloud
        .fastAPI({
          api: aPIToCall,
          collection_user_uid: collectionUserUID,
          collection_uid: collectionUID,
          product_uid: itemUID,
          reaction: newType,
        })
        .then(() => {
          unsetWorking();
        })
        .catch(() => {
          unsetWorking();
        });
    }
    return false;
  };

  const deleteProduct = (event: MouseEvent) => {
    if (deleteWorkingValue === undefined) {
      setDeleteWorking(true);
      // ASYNC (but doesn't seem to cause a problem as no reference to this component after async.)
      cloud
        .fastAPI({
          api: 'delete_product',
          // assumes is own collection!
          collection_uid: collectionUID,
          product_uid: itemUID,
        })
        .then((result: any) => {
          unsetDeleteWorking();
          if (result.data.success === true) {
            setFooterPopup({
              type: 'item_deletion',
              data: {
                collectionUID,
                collectionUserUID,
                itemUID,
              },
              uid: productDataStoreAs + Date.now(),
            });
          }
        })
        .catch(unsetDeleteWorking);
    }
    event.preventDefault();
    return false;
  };

  const invertCoverImageDict = () => {
    const newDict: {
      [x: string]: number;
    } = {};
    if (choosingCoverImage) {
      // Keys of objects are strings...
      Object.keys(coverImageDict)
        .map(Number)
        .forEach((numberKey) => {
          newDict[coverImageDict[numberKey]] = numberKey;
        });
    }
    return newDict;
  };

  const selectedCoverImage = invertCoverImageDict()[itemUID];
  const productImageList = productData ? productData.imageList : [];
  const thisProductImage = productImageList ? productImageList[imageIndex] : '';
  const description = productData ? productData.description : null;
  const lastUpdated = productData ? productData.lastUpdated : null;
  const domain = collectionItem ? get_site_uid(collectionItem.page_url) : null;
  const price = productData ? productData.price : null;
  const priceChange = productData ? productData.priceChange : null;
  const link = productData ? productData.url : null;
  const isLoaded = productData && collectionItem;
  const isWorkingPurchased = purchasedWorkingValue !== undefined;
  const isDeleting = deleteWorkingValue !== undefined;
  const isWorkingUpvote = upvoteWorkingValue !== undefined;
  const isWorkingDownvote = downvoteWorkingValue !== undefined;
  return (
    <ProductTile
      {...{
        authUserUID,
        isMine,
        isLoaded,
        link,
        priceChange,
        viewingData,
        isWorkingPurchased,
        isWorkingUpvote,
        isWorkingDownvote,
        lastUpdated,
        isPurchased,
        showArrows,
        showReactionsBar,
        isOrganising,
        selectedCoverImage,
        choosingCoverImage,
        description,
        price,
        domain,
        isSaved,
        thisProductImage,
        loadingPopup,
        collectionItem,
        collectionUID,
        // Functions:
        deleteProduct,
        reactProduct,
        addOrRemoveProductToPurchased,
        setArrowsVisibility,
        selectCover,
        changeViewingProductImage,
        showProductModal,
        loadProductMenu,
        loadProductUpdateMenu,
        collectionUserUID,
        itemUID,
        isDeleting,
        reaction,
      }}
    />
  );
};

export { Container as ProductTile };
