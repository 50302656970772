import React from 'react';
import styled from 'styled-components';
import {
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  EarthIcon,
  PersonAddIcon,
  LockClosedOutlinedIcon,
} from '@moonsifttech/design-system';

import { modalMenuClassNames } from 'src/components/core/ModalMenu';
import { MenuContent } from 'src/components/core/MenuContent';
import { VisibilityMenuProps } from './VisibilityMenu.types';

const mapIconNameToIcon: Record<string, React.ReactNode> = {
  'earth': <EarthIcon />,
  'person-add': <PersonAddIcon />,
  'lock-closed-outlined': <LockClosedOutlinedIcon />,
};

const UnstyledVisibilityMenu = React.forwardRef<
  HTMLDivElement,
  VisibilityMenuProps
>(({ className, style, options, level: selectedLevel, onSelect }, ref) => {
  return (
    <MenuContent ref={ref} className={className} style={style}>
      <List>
        {options.map(({ level, description, icon }) => {
          return (
            <ListItem
              key={level}
              selected={selectedLevel === level}
              onClick={(event) => onSelect(level, event)}
            >
              <ListItemIcon>{mapIconNameToIcon[icon]}</ListItemIcon>
              <ListItemText>{description}</ListItemText>
            </ListItem>
          );
        })}
      </List>
    </MenuContent>
  );
});

export const VisibilityMenu = styled(UnstyledVisibilityMenu)`
  .${modalMenuClassNames.isDesktop} & {
    width: 350px;
  }
`;
