import React from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import queryString from 'query-string';

import { RootState, DBContext } from 'src/index';
import { useDBAddDoc } from 'src/hooks/useDBAddDoc';
import { ProblemDocument } from 'src/types/models/problem.model';
import {
  ReportProblemProps,
  ReportProblemContainerProps,
} from './ReportProblem.types';
import { ReportProblem } from './ReportProblem.component';

const normaliseSearchParam = (
  searchParam: string | string[] | null,
): string | undefined => {
  if (Array.isArray(searchParam)) {
    return searchParam[0];
  }

  if (searchParam !== null) {
    return searchParam;
  }

  return undefined;
};

const initDescription = (siteUID: string | undefined) => () => {
  if (siteUID) {
    return `Reporting a problem with ${siteUID}:\n`;
  }

  return '';
};

const ReportProblemContainer: React.FC<ReportProblemContainerProps> = (
  props,
) => {
  /* Route state */
  const location = useLocation();
  const searchParams = queryString.parse(location.search);
  const exVersion = normaliseSearchParam(searchParams.exversion);
  const siteUID = normaliseSearchParam(searchParams.site_uid);
  const encodedUrl = normaliseSearchParam(searchParams.url);
  // Decode URL so that it's not URI encoded when saved to DB
  const url = encodedUrl && decodeURIComponent(encodedUrl);

  /* Global state */
  const authUserUID = useSelector<RootState, string | null>(
    ({ auth }) => auth.id,
  );

  /* Local state */
  const [description, setDescription] = React.useState(
    initDescription(siteUID),
  );
  const [reported, setReported] = React.useState(false);

  /* Handle description updates */
  const handleChange = React.useCallback<ReportProblemProps['onChange']>(
    (event) => {
      setDescription(event.currentTarget.value);
    },
    [],
  );

  /* Handle problem report submissions */
  const db = React.useContext(DBContext);
  const [addProblem, working] = useDBAddDoc<ProblemDocument>(
    db,
    db.problemList(),
  );
  const handleReport = React.useCallback<ReportProblemProps['onReport']>(
    (event) => {
      event.preventDefault();

      const problemDocument: ProblemDocument = {
        created_at: db.serverTimestamp(),
        user_uid: authUserUID!,
        problem: event.currentTarget.description.value,
      };

      if (exVersion) {
        problemDocument.exversion = exVersion;
      }

      if (url) {
        problemDocument.url = url;
      }

      addProblem(problemDocument)
        .then(() => {
          setReported(true);
        })
        .catch((error) => {
          console.error('Failed to report a problem with the extension');
        });
    },
    [addProblem, authUserUID, db, exVersion, url],
  );

  return (
    <ReportProblem
      description={description}
      working={working}
      reported={reported}
      onChange={handleChange}
      onReport={handleReport}
      {...props}
    />
  );
};

export { ReportProblemContainer as ReportProblem };
