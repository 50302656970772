import React, { useEffect, useRef } from 'react';

/*

Hook that provides ability to perform a function when clicking outside an object.
Mostly used for closing modals when clicking outside of them.

*/
export const useClickOutside = (
  functionToDo: (event?: MouseEvent) => void,
): React.Ref<HTMLElement | undefined> => {
  const containerRef = useRef<HTMLElement>();
  useEffect(() => {
    const checkClick = (event: MouseEvent) => {
      if (
        containerRef.current &&
        !containerRef.current.contains(event.target as Node)
      ) {
        functionToDo(event);
      }
    };
    document.addEventListener('mousedown', checkClick, false);
    return () => document.removeEventListener('mousedown', checkClick, false);
  }, [functionToDo]);
  return containerRef;
};
