/*

This is the prompt to get signups if a non-registered user views a non-public shareable collection.

TODO: Typescript, design system

*/
// AKA The "Jeremy" Popup
import React,{useEffect} from 'react';
import {TEXTSTYLE} from 'src/mvp22/style-components/Text';
import { useSelector } from 'react-redux';
import Button from 'src/mvp22/form-components/Button';
import styled from 'styled-components';
import {M} from 'src/mvp22/constants';
import {Separator} from './ModalComponents.js';
import LogoAndText from 'src/mvp22/image-components/LogoAndText';
import {ButtonBox,ButtonSpacer} from 'src/mvp22/menu-components/SignUpPrompt';
import R from 'src/routes';

export const GeneralOverlayWithinContainer = styled.div`
  display:flex;
  position:relative;
  width: ${props => props.isDesktop ? '90%' : '100%'};
  height: ${props => props.isDesktop ? '90%' : '100%'};
  max-width: ${props => props.isDesktop ? '716px' : '100%'};
  max-height: ${props => props.isDesktop ? '576px' : '100%'};
  box-sizing:border-box;
  background-color:${M.COL.BG.WHITE};
  border-radius: ${props => props.isDesktop ? '10px' : ''};
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
  flex-direction: row;
  align-items: center;
  text-align: center;
  justify-content: space-between;
  color: ${M.COL.TEXT.BLACK};
  overflow:auto;
`;

const InnerPopup = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding-left: 5%;
  padding-right: 5%;
  margin-top: 80px;
  max-width:100%;
  box-sizing:border-box;
`;

const Text = styled(TEXTSTYLE.BODY2)`
  text-align: left;
  max-width:100%;
`;

const Heading = styled(TEXTSTYLE.HEADING3)`
  text-align: left;
`;

const ButtonWrapLink = styled(TEXTSTYLE.LINK)`
  width: 100%;
  cursor: pointer;
`;

const BottomText = styled(TEXTSTYLE.LINK)`
  color: ${M.COL.TEXT.BLACK};
  border-bottom: 1px solid ${M.COL.LINE.BLACK};
  margin-top: 42px;
  margin-bottom: 56px;
  width: 200px;
  align-self: center;
  margin-right: 18px;
`;

export const SignUpToViewModal =
(
  {
    closeFunction
  }
)=>{
  const windowWidth = useSelector((state) => state.ui.windowWidth);
  const singleCollectionDocData = useSelector((state) => state.db.single_collection.data);
  useEffect(
    ()=>{
      if (!singleCollectionDocData){
        closeFunction();
      }
    }
    ,
    [singleCollectionDocData,closeFunction]
  );
  const collectionUserUID = singleCollectionDocData?singleCollectionDocData.user_uid:"A moonsift user";
  const collectionUID = singleCollectionDocData?singleCollectionDocData.id:"unknown";
  const singleCollectionUserPublicDocData = useSelector((state) => state.db.user_public.map[collectionUserUID]);
  const collectionName = singleCollectionDocData?singleCollectionDocData.name:"";
  const userName = (singleCollectionUserPublicDocData && singleCollectionUserPublicDocData.name)?singleCollectionUserPublicDocData.name:"A moonsift user";
  const displayUsername = (singleCollectionUserPublicDocData && singleCollectionUserPublicDocData.display_username)?singleCollectionUserPublicDocData.display_username:collectionUserUID;
  const ISDESKTOP = windowWidth>=M.MOBILESWITCH;
  return(
    <GeneralOverlayWithinContainer isDesktop={ISDESKTOP} className='GeneralOverlayWithinContainer'>
      <InnerPopup>
        <LogoAndText heighttype='D'/>
        <Separator height="49px"/>
        <Heading>
          {userName} has invited you to view their collection
        </Heading>
        <Separator height="32px"/>
        <Text>
          Log in or sign up to view this private collection - <b>{collectionName}</b> by {userName}.
        </Text>
        <ButtonBox>
          <ButtonWrapLink to={R.SIGNIN+"?after="+encodeURI(window.location.pathname)}>
            <Button
              text="Log In"
              msstyle="prompt_button_light_large"
              width="100%"
              isDesktop={ISDESKTOP}
            />
          </ButtonWrapLink>
          <ButtonSpacer/>
          <ButtonWrapLink to={R.SIGNUP+`?post=redirect_path&redirect_path=/collection/${displayUsername}/${collectionUID}`}>
            <Button
              text="Sign Up"
              msstyle="prompt_button_dark_large"
              width="100%"
              isDesktop={ISDESKTOP}
            />
          </ButtonWrapLink>
        </ButtonBox>
        <BottomText to={R.LANDING}>
          <TEXTSTYLE.BODY4>
              Why’s everyone using Moonsift?
          </TEXTSTYLE.BODY4>
        </BottomText>
      </InnerPopup>
    </GeneralOverlayWithinContainer>
  );
};
