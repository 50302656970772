/*

useDBUpdate

*/
import send_error from 'src/mvp22/core-components/send_error';
export default function update_collection_public_level(collection_uid,public_level,firebase,auth_user_uid){
  return new Promise(
    (resolve)=>{
      const new_data =
      {
        public_level,
        last_updated:firebase.serverTimestamp()
      };
      firebase.user_collection(
        auth_user_uid,
        collection_uid
      ).update(
        new_data
      ).then(
        resolve(true)
      ).catch(
        (error_out)=>{
          send_error(error_out);
          resolve(false);
        }
      );
    }
  );
}
