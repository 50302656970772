// ensures we stop listening!
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import {
  baseDBGetQuery,
  baseDBListenQuery,
  baseDBUnlistenSingle,
} from 'src/store/actions/baseDB';
import { BaseDBKindNames, BaseDBKindsLookup, ParserFunction } from 'src/types';
import { DB, Query } from 'src/services/DB';

/*

This hook is used to listen to a document or collection or query from the database by initiating a saga that will place it in redux.

*/
export function useDBRetrieveQuery<N extends BaseDBKindNames>(
  dB: DB,
  dBQueryFunction: (...args: any[]) => Query, // collection OR query reference
  kind: BaseDBKindsLookup[N]['name'],
  parser: ParserFunction<BaseDBKindsLookup[N]['item']>,
  storeAs: BaseDBKindsLookup[N]['storeAs'], // For maps, the key in the map store (otherwise undefined)
  args: any[], // arguments for the ref function and that also cause the query to be re-run (can overload the query with more arguments to make it rerun on them as well)
  method: 'listen' | 'get',
  unlisten: boolean = true, // when the component the hook is in is unmounted, should the db map (& potential listener) be removed?
) {
  const runQuery = args.reduce((a, b) => a && b, true) ? true : false;
  const dispatch = useDispatch();
  useEffect(() => {
    if (runQuery) {
      if (method === 'listen') {
        dispatch(
          baseDBListenQuery<BaseDBKindsLookup[N]>(
            {
              dBQuery: dBQueryFunction(...args),
              dB,
              parser,
            },
            kind,
            storeAs,
          ),
        );
      } else if (method === 'get') {
        dispatch(
          baseDBGetQuery<BaseDBKindsLookup[N]>(
            {
              dBQuery: dBQueryFunction(...args),
              dB,
              parser,
            },
            kind,
            storeAs,
          ),
        );
      }
      return unlisten
        ? () => {
            // clears got data to save memory and clears listeners if listening...
            dispatch(baseDBUnlistenSingle<BaseDBKindsLookup[N]>(kind, storeAs));
          }
        : () => null;
    }
    // Only args will change, hence only args or runQuery will change.
    // Note, as using spread need to disable the linter, cannot just pass args because ARRAY will change every render (non-primitive type).
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    dB,
    kind,
    dispatch,
    storeAs,
    parser,
    // eslint-disable-next-line react-hooks/exhaustive-deps
    ...args,
    runQuery,
    dBQueryFunction,
    unlisten,
    method,
  ]);
}
