/*

Will become redundant

*/

// This file explains how the context behaves
// It just adds firebase object to the properties of the component.
import React, { useContext } from 'react';
import {FirebaseContext} from 'src/index';



export const withFirebase = Component => {
  return  (props) => {
    const contextValue = useContext(FirebaseContext);
    return <Component {...props} firebase={contextValue} />;
  };
};
