import React from 'react';
import styled from 'styled-components';
import clsx from 'clsx';
import Overlay from 'react-overlays/Overlay';
import { Modal, typographyClassNames } from '@moonsifttech/design-system';

import { ModalMenuProps } from './ModalMenu.types';

export const modalMenuClassNames = {
  isMobile: 'ModalMenu-isMobile',
  isDesktop: 'ModalMenu-isDesktop',
};

const UnstyledModalMenu: React.FC<ModalMenuProps> = ({
  className,
  style,
  isOpen,
  isMobile,
  onRequestClose,
  menu,
  children,
}) => {
  const triggerRef = React.useRef(null);
  const containerRef = React.useRef(null);

  if (!React.isValidElement(children)) {
    return null;
  }

  const triggerButton = React.cloneElement(children, {
    ref: triggerRef,
  });

  return (
    <div
      ref={containerRef}
      className={clsx(
        className,
        isMobile && modalMenuClassNames.isMobile,
        !isMobile && modalMenuClassNames.isDesktop,
      )}
      style={style}
    >
      {triggerButton}
      {isMobile ? (
        <Modal
          mode="drawer"
          anchor="bottom"
          isOpen={isOpen}
          onRequestClose={onRequestClose}
        >
          {menu}
        </Modal>
      ) : (
        <Overlay
          show={isOpen}
          rootClose
          onHide={() => onRequestClose && onRequestClose()}
          container={containerRef}
          target={triggerRef}
          offset={[0, 4]}
          placement="bottom"
        >
          {({ props }) => {
            if (React.isValidElement(menu)) {
              return React.cloneElement(menu, props);
            }

            return null;
          }}
        </Overlay>
      )}
    </div>
  );
};

export const ModalMenu = styled(UnstyledModalMenu)`
  position: relative;

  .${typographyClassNames.root} & {
    margin-left: 4px;
    display: inline-flex;
  }
`;
