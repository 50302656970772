import React from 'react';
import { useSelector } from 'react-redux';

import { RootState } from 'src/index';
import { UserContainerProps } from './User.types';
import { User } from './User.component';

const generateSrc = (
  userUID: string,
  profileImageCode: string,
): string | undefined => {
  // Return undefined if no user ID or profile image code have been provided
  if (!userUID || !profileImageCode) {
    return undefined;
  }

  const urlParts = [
    'https://storage.googleapis.com',
    `${process.env.REACT_APP_FIREBASE_PROJECTID}-profile-images`,
    `userprofile_${userUID}_${profileImageCode}.jpg`,
  ];

  return urlParts.join('/');
};

const UserContainer: React.FC<UserContainerProps> = (props) => {
  const { name, imageSrc, link, isPro } = useSelector<
    RootState,
    { name: string; imageSrc?: string; link: string; isPro: boolean }
  >((state) => {
    // Get collection's user public data
    const userUID = state?.db.single_collection.data?.user_uid ?? '';
    const userData = state?.db.user_public.map[userUID];

    // Extract user name and isPro flag
    const name = userData?.name ?? '';
    const isPro = Boolean(userData?.pro);

    // Generate the image source from the image code and the user ID
    // Note: if the user doesn't have a profile image we will generate an
    // image source that will fail to load
    const profileImageCode = userData?.profile_image_code ?? '';
    const imageSrc = generateSrc(userUID, profileImageCode);

    // Generate the user link from the display username
    const originalUsername = userData?.original_username ?? '';
    const displayUsername = userData?.display_username ?? '';
    const link = `/@${displayUsername || originalUsername}`;

    return { name, imageSrc, link, isPro };
  });

  return (
    <User alt={name} src={imageSrc} link={link} isPro={isPro} {...props}>
      {name[0]}
    </User>
  );
};

export { UserContainer as User };
