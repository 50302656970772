/*

This component displays collection tiles in a list (surprise surprise!)

Remember that "liked" collections are now termed "bookmarked"

It is used in the profile view.  It also has the "new collection" tile that should be moved into its own component

TODO:  make functional, use design system, use camelCase, split into container and component, convert to typescript.
Will also need to add the db hooks for the collection list (not authuser as those will already be there) but for other user + liked collections.
Will for all however need to add hooks for the display of images, but maybe this is best done in CollectionTile itself...
Also change "liked" to "bookmarked"
Should make a really nice refactoring project.

*/
import React from 'react';
import { Component } from 'react';
import {M} from 'src/mvp22/constants';
import styled from 'styled-components';
import {TEXTSTYLE} from 'src/mvp22/style-components/Text';
import {withFirebase} from 'src/mvp22/Firebase';
import CollectionTile from 'src/mvp22/tile-components/CollectionTile';
import LoadingDisplay from 'src/mvp22/image-components/LoadingDisplay';
import MEDIA from 'src/mvp22/media';
import R from 'src/routes';
import get_collection_images from 'src/mvp22/firebase-functions/get_collection_images';
import get_user_profile_public_collections from 'src/mvp22/firebase-functions/get_user_profile_public_collections';
import get_collection_likes from 'src/mvp22/firebase-functions/get_collection_likes';
import get_liked_collections from 'src/mvp22/firebase-functions/get_liked_collections';
import nested_state_assignment from 'src/mvp22/core-components/nested_state_assignment';
import remove_all_listeners from 'src/mvp22/core-components/remove_all_listeners';
import segmentEvent from "src/mvp22/segment-components/SegmentEvent";
import {modalSet} from 'src/mvp22/redux-components/actions';
import { connect } from 'react-redux';
import {EmptyMessageContainer} from './EmptyMessage';
import {CentralHeading3, CentralText3, BoldLink} from './ProductTileList';
import windowSize from 'src/mvp22/WindowSize';

const PlusIcon = () => {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M10.0002 4.16748V15.8341" stroke="black" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M4.16675 9.99976H15.8334" stroke="black" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>
  );
};

const PlusIconContainer = styled.div`
  border: 1px solid #000000;
  box-sizing: border-box;
  height:20px;
  width:20px;
  border-radius:10px;
  display:flex;
  align-items: center;
  justify-content: center;
`;

const NewCollectionTileMarginBooster = styled.div`
  padding: 21px;
`;

const CollectionTileMarginBooster = styled.div`
  margin: 7px;
  border-radius: 5px;
  &:hover{
    box-shadow:rgba(0, 0, 0, 0.25) 0px 1px 4px;
  }
  box-sizing:border-box;
`;

const EmptyCollectionTile = styled.div`
  height: 220px;
  width: 313px;
  padding: 21px;
`;

const EmptyCollectionImg = styled.img`
  display: block;
  height: 80px;
  width: 80px;
  margin: 0 auto;
`;

const Seperator = styled.div`
  height:${props=>props.height};
`;

// Used in post install page as well:
export const NewCollectionTile = styled(TEXTSTYLE.LINKA)`
  border: 1px dashed ${M.COL.LINE.MID};
  border-radius: 5px;
  height: 220px;
  width: 313px;
  box-sizing: border-box;
  display:flex;
  align-items: center;
  justify-content: center;
  cursor:pointer;
  background-color: ${M.COL.BG.WHITE};
  &:hover{
    border: 1px dashed ${M.COL.BG.BLACK};
    svg{
      path{
        stroke-width: 1.5
      }
    }
  }
`;

class CollectionTileList extends Component {
  constructor(props){
    super(props);
    this.state = {
      user_uid : null,
      auth_user_uid : null,
      type: null,
      profile_is_own : false,
    };
    this.collection_state_assign_func = this.collection_state_assign_func.bind(this);
    this.openNewCollection = this.openNewCollection.bind(this);
  }

  componentDidMount() {
    this._isMounted = true;
    this.componentDidUpdate();
  }

  componentWillUnmount() {
    this._isMounted = false;
    remove_all_listeners(this.state);
  }

  // This function runs when props / state changed so we only want to futher update the state
  // when it has been changed to a different view or profile user etc.
  componentDidUpdate(){
    this.setState(
      (prevState,props)=>{
        const user_uid_change = (props.user_uid !== prevState.user_uid)?true:false;
        const user_auth_change = (props.auth_user_uid !== prevState.auth_user_uid)?true:false;
        const type_change = (props.type !== prevState.type)?true:false;
        // Don't do anything until we know if the user is logged in or not:
        if (user_auth_change || user_uid_change || type_change){
          remove_all_listeners(this.state);
          const state_to_return = {
              auth_user_uid:this.props.auth_user_uid,
              user_uid:this.props.user_uid,
              type:this.props.type,
              is_loaded:false,
              collections:{}
          };
          // We might need to get some more state to set within this state setting function:
          // This is usually only if we don't need to fetch the data.
          var extra_state = {};
          // Populate the state with the appropraite type of data (asynchronously)
          switch(this.props.type){
            case "all_user_collections":
              // CASE is viewing own collection and is logged in:
              if (props.user_uid===props.auth_user_uid){
                extra_state = this.updateOwnCollectionDataOnce();
              }
              // CASE is viewing someone else's collections:
              else if (this.props.user_uid && this.props.user_uid !==""){
                extra_state = this.updateTheOtherUserDataOnce(this.props.user_uid,this.props.auth_user_uid);
              }
              extra_state.profile_is_own = props.user_uid===props.auth_user_uid;
              break;
            case "liked":
              extra_state = this.updateLikedCollectionsOnce(this.props.auth_user_uid);
              extra_state.profile_is_own = true;
              break;
            case "purchased":
              extra_state = this.updatedPurchasedCollectionOnce(this.props.auth_user_uid);
              extra_state.profile_is_own = true;
              break;
            default:
              console.error("Not a valid active tab",this.props.type);
          }
          // set all the state:
          return Object.assign(state_to_return,extra_state);
        }
      }
    );
  }

  // Only call after an async firebase listener updates please:
  // This adds to the dictionary state the images (or other data e,g, liked collections)
  collection_state_assign_func(new_dict){
    this._isMounted && this.setState(
      prevState=>{
        return ({
          collections:nested_state_assignment(prevState.collections,new_dict)
        });
      }
    );
  }

  // Pull the current user's collections that are stored in redux due to the ability to add to collections from all over the site (i.e. other collections):
  // Note that this list of collections will, however, update live BUT the images will not:
  // And to save having to fetch them all every time.
  updateOwnCollectionDataOnce(){
    // Convert into something this function will understand:
    const collections_dict = {};
    this.props.firestore_user_collection_list.collections_sorted.forEach(
      collection_entry=>{
        collections_dict[collection_entry.global_uid]={collection_entry:collection_entry};
      }
    );
    const image_getters = get_collection_images(collections_dict,this.props.firebase,this.collection_state_assign_func);
    return {
      is_loaded:true,
      collections:image_getters
    };
  }

  // Get the other user's data and if we like any of those collections (which is a pain) and
  // due to the need for listeners:
  // of course the images...:
  updateTheOtherUserDataOnce(profile_user_uid,auth_user_uid){
    //ASYNC
    // get the collection info JUST ONCE, hence NOT bothering with redux for now:
    get_user_profile_public_collections(profile_user_uid,this.props.firebase,auth_user_uid).then(
      response=>{
        const like_listeners_dict = get_collection_likes(
          response.collections,
          this.props.firebase,
          auth_user_uid,
          this.collection_state_assign_func // WILL ALSO CALL AN ASYNC FUNCTION AS AND WHEN LIKES DETERMINED / UPDATED!
        );
        const collections_with_like_listeners = nested_state_assignment(response.collections,like_listeners_dict);
        this._isMounted && this.setState(
          (prevState)=>{
            const image_getters = get_collection_images(collections_with_like_listeners,this.props.firebase,this.collection_state_assign_func);
            const collections_with_image_getters = nested_state_assignment(response.collections,image_getters);
            return (
              {
                collections_sorted:response.collections_sorted,
                is_loaded:true,
                collections:nested_state_assignment(prevState.collections,collections_with_image_getters)
              }
            );
          }
        );
      }
    );
    return {};
  }

  // Do NOT listen on this list for now...:
  // NOTE: Might need to change how we set is_loaded to make sure we have all the liked collections loaded (that still exist / are accessible)...
  // Should be able to do with a resolver.
  updateLikedCollectionsOnce(auth_user_uid){
    if (auth_user_uid){
      //ASYNC
      // get the collection info JUST ONCE, hence NOT bothering with redux for now:
      get_liked_collections(this.props.firebase,auth_user_uid).then(
        response=>{
          this._isMounted && this.setState(
            (prevState)=>{
              //ASYNC
              const image_getters = get_collection_images(response.collections,this.props.firebase,this.collection_state_assign_func);
              const collections_with_image_getters = nested_state_assignment(response.collections,image_getters);
              return (
                {
                  collections_sorted:response.collections_sorted,
                  is_loaded:true,
                  public_info:response.public_info,
                  collections:nested_state_assignment(prevState.collections,collections_with_image_getters)
                }
              );
            }
          );
        }
      );
    }
    return {};
  }

  updatedPurchasedCollectionOnce(auth_user_uid){
    if (auth_user_uid){
      // The deetz of the purchased collection are standardised across the users:
      const collections = {
        [auth_user_uid+"__purchased"]:{
          collection_entry:{
            collection_uid:"purchased",
            uid:"purchased",
            user_uid:auth_user_uid,
            name:"Purchased items"
          }
        }
      };
      const collections_sorted = [auth_user_uid+"__purchased"];
      const image_getters = get_collection_images(collections,this.props.firebase,this.collection_state_assign_func);
      return (
        {
          collections_sorted:collections_sorted,
          collections:nested_state_assignment(collections,image_getters),
          is_loaded:true
        }
      );
    }
    return ({});
  }

  openNewCollection(event) {
    event.preventDefault();
    segmentEvent("Clicked On Create New Collection");
    this.props.setModal(
      {
        type:"new_collection"
      }
    );
    return false;
  }

  renderAddTile(){
    // check if user is looking at their own profile and conditionally render AddNewTileContainer
    if(this.state.profile_is_own){
      return(
        <NewCollectionTileMarginBooster>
          <NewCollectionTile onClick={this.openNewCollection} href="#">
            <PlusIconContainer>
            <PlusIcon/>
            </PlusIconContainer>
            <TEXTSTYLE.BODY4>&nbsp;&nbsp;New Collection</TEXTSTYLE.BODY4>
          </NewCollectionTile>
        </NewCollectionTileMarginBooster>
      );
    }
    else{
      return("");
    }
  }

  getEmptyMessage(ISDESKTOP){
    if(this.props.type === 'liked'){
      return(
        <EmptyMessageContainer isDesktop={ISDESKTOP} className = 'EmptyMessageContainer'>
        <Seperator height='20px'/>
          <EmptyCollectionImg src = {MEDIA.NO_BOOKMARKED_COLLECTIONS}/>
          <CentralHeading3>No bookmarked collections</CentralHeading3>
          <CentralText3>When you Bookmark other people&apos;s collections, they will be saved here.</CentralText3>
          <CentralText3>
            <BoldLink to = {R.EXPLORE_FEATURED}>Click here</BoldLink> to explore public collections<br/>
          </CentralText3>
        </EmptyMessageContainer>
      );
    }
    if (this.props.type === 'all_user_collections' && this.state.profile_is_own!==true){
      return(
        <EmptyMessageContainer isDesktop={ISDESKTOP} className = 'EmptyMessageContainer'>
          <EmptyCollectionImg src = {MEDIA.NO_PUBLIC_COLLECTIONS}/>
          <CentralHeading3>No public collections.</CentralHeading3>
          <CentralText3>The collections a user shares privately will not appear on their public profile.</CentralText3>
          <CentralText3>
            <BoldLink to = {R.EXPLORE_FEATURED}>Click here</BoldLink> to explore other public collections on Moonsift.<br/>
          </CentralText3>
        </EmptyMessageContainer>
      );
    }
  }

  renderTiles(ISDESKTOP){
    if (this.state.is_loaded){
      // Switch to select list in case in future we want to reduce database calls by saving them to different lists and not refreshing...
      var this_render_list=this.state.collections_sorted;
      var this_render_dict=this.state.collections;
      // EXCEPT if we are rendering from redux:
      if (this.state.type==="all_user_collections" && this.state.profile_is_own){
        // TODO: Move this formatting into redux part to match the format here as this is expensive:
        const temp_collections_dict = {};
        this_render_list=[];
        this.props.firestore_user_collection_list.collections_sorted.filter(x=>x.uid!=="purchased").forEach(
          x=>{
            temp_collections_dict[x.global_uid] = {
              collection_entry:x
            };
            this_render_list.push(x.global_uid);
          }
        );
        this_render_dict=nested_state_assignment(this.state.collections,temp_collections_dict);
      }

      if(this_render_list && this_render_list.length===0){
        return(
          this.getEmptyMessage(ISDESKTOP)
        );
      }

      if (this_render_list && this_render_dict){
        // Hack to make things left-align on desktop in most cases:
        const EXTRA_TILES = (this_render_list.length>0)?
          <><EmptyCollectionTile key={1}/><EmptyCollectionTile key={2}/></>
        :"";
        return(
          <>
          {
            this_render_list.map(
              (general_uid) =>
                <CollectionTileMarginBooster
                  key={general_uid}
                >
                  <CollectionTile
                   onClick={this.clickedOnExistingCollection}
                   data={this_render_dict[general_uid]}
                   user_public_info={
                     (this.state.type === "liked")?
                        (this.state.public_info && this.state.collections &&
                        this.state.collections[general_uid] && this.state.collections[general_uid].collection_entry)?
                        this.state.public_info[this.state.collections[general_uid].collection_entry.user_uid]:""
                      :
                        this.props.public_info
                    }
                   display_name={(this.state.type === "liked")}
                   show_edit={(this.state.type === "all_user_collections")}
                   isLarge={false}
                  />
                </CollectionTileMarginBooster>
            )
          }
          {EXTRA_TILES}
          </>
        );
      }
    }
    return(<LoadingDisplay message = "Loading Collections..." />);
  }

  render(){
    const ISDESKTOP = this.props.windowWidth>=M.MOBILESWITCH;
    // !!check if user is looking at their own profile and conditionally render AddNewTileContainer
    return(
      <>
      {this.state.type === "all_user_collections" ? this.renderAddTile() : ""}
      {this.renderTiles(ISDESKTOP)}
      </>
    );
  }
}


// Pass the state values into the component:
const mapStateToProps = () => {
  return ({});
};

const mapDispatchToProps = dispatch => {
  return {
    setModal:(data)=>dispatch(modalSet(data))
  };
};

export default connect(mapStateToProps,mapDispatchToProps)(withFirebase(windowSize(CollectionTileList)));
