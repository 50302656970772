import React from 'react';
import clsx from 'clsx';
import {
  Stack,
  Typography,
  LoadingMoreIcon,
} from '@moonsifttech/design-system';
import styled from 'styled-components';

import { SquareButtonProps } from './SquareButton.types';

const UnstyledSquareButton = React.forwardRef<
  HTMLDivElement,
  SquareButtonProps
>(({ className, style, icon, loading, onClick, children }, ref) => {
  return (
    <Stack
      ref={ref}
      className={clsx(className, loading && 'SquareButton-loading')}
      style={style}
      direction="column"
      justifyContent="center"
      alignItems="center"
      spacing={6}
    >
      <button onClick={onClick}>{loading ? <LoadingMoreIcon /> : icon}</button>
      <Typography variant="primary.r12">{children}</Typography>
    </Stack>
  );
});

export const SquareButton = styled(UnstyledSquareButton)`
  min-width: 68px;

  button {
    padding: 8px 12px;
    background: var(--common-white);
    cursor: pointer;

    border: ${({ theme }) => theme.fns.getBorder('primary')};

    ${({ theme }) => theme.fns.getShapeStyles('main')}
  }

  svg {
    display: block;
  }

  &.SquareButton-loading button {
    cursor: wait;
  }
`;
