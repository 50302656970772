/*

Essentailly a static page for displaying the privacy policy.
TODO: typescript, make functional, non-default exports, design system

*/
import React from 'react';
import { Component } from 'react';
import {AddHelmetTitle} from "src/mvp22/core-components/helmet";
import styled from 'styled-components';
import {M} from 'src/mvp22/constants';
import {TEXTSTYLE} from 'src/mvp22/style-components/Text';
import TopMenu from 'src/mvp22/menu-components/TopMenu';
import {FullBlank} from 'src/mvp22/menu-components/TopMenu';
import BottomBar from 'src/mvp22/menu-components/BottomBar';

const Container = styled.div`
  background-color:${M.COL.BUTTON.WHITE};
  padding-left:30px;
  padding-right:30px;
  padding-top:115px;
  display:flex;
  flex-direction:column;
  align-items: center;
  justify-content: center;
  color:${M.COL.TEXT.BLACK};
  text-align:left;
  width:100%;
  box-sizing:border-box;
`;

const WithinContainer = styled.div`
  max-width:700px;
`;

const Seperator = styled.div`
  height:${props=>props.height};
`;

const FullWidthHeading2 = styled(TEXTSTYLE.HEADING2)`
  width:100%;
`;
const FullWidthOther1 = styled(TEXTSTYLE.OTHER1)`
  width:100%;
`;
const FullWidthBody3 = styled(TEXTSTYLE.BODY3)`
  width:100%;
`;

const FullWidthBody1 = styled(TEXTSTYLE.BODY1)`
  width:100%;
`;

/**
* Privacy Policy
*/
class PrivacyPolicy extends Component {
  render(){
    return(
      <FullBlank>
        <TopMenu/>
        {AddHelmetTitle("Privacy Policy")}
        <Container>
          <WithinContainer>
            <FullWidthHeading2>Privacy & Cookie Policy</FullWidthHeading2>
            <Seperator height="12px"/>
            <FullWidthOther1>Overview</FullWidthOther1>
            <FullWidthBody3>
              This policy explains what information we collect when you use Moonsift’s sites, services, applications, products, and content (“Services”). It also has information about how we store, use, transfer, and delete that information. Our aim is not just to comply with privacy law. It’s to earn and keep your trust.
              <br/><br/>
              Moonsift collects and stores personal information about its users to customize their discovery experience and enable personalized product and service recommendations. The Moonsift Service, which aims to re-orientate the internet around the personal preferences of the individual, will rely on the collection of data to make the product work as well as possible.
              <br/><br/>
            </FullWidthBody3>
            <FullWidthOther1>Information We Collect & How We Use It</FullWidthOther1>
            <FullWidthBody3>
              Please also note the specific section regarding the browser extension later in this policy.
              <br/><br/>
              In order to give you the best possible experience using Moonsift, we collect information from your interactions with the SKUs (products) on our website and the retailers you browse with the Moonsift Button. The tracking we do at Moonsift is to make the experience as good as possible and to give you the best recommendations.
              <br/><br/>
              Moonsift does not make money from ads. So we do not collect data in order to advertise to you.
              <br/><br/>
              Some of the information, you actively tell us (such as your email address) we use to track your account or communicate with you. Other information, we automatically collect based on actions you take while using Moonsift, such as your interactions with the products we present and the different parts of our website. This information includes records of those interactions, your Internet Protocol address, information about your device (such as device or browser type), and referral information.
              <br/><br/>
              We use this information to:<br/>
              - present more relevant products to you<br/>
              - provide, test, improve, promote and personalize Moonsift Services<br/>
              - generate aggregated, non-identifying information about the products people do and don’t like
              <br/><br/>
              When you create your Moonsift account, and authenticate with a third-party service (like Twitter, Facebook or Google) we may collect, store, and periodically update information associated with that third-party account, such as your lists of friends or followers. We will never publish through your third-party account without your permission.
              <br/><br/>
              </FullWidthBody3>
              <FullWidthOther1>Information Disclosure</FullWidthOther1>
              <FullWidthBody3>
                Moonsift won’t transfer information about you to third parties for the purpose of providing or facilitating third-party advertising to you.
                <br/><br/>
                We don&apos;t share information that personally identifies you (information such as your name or email address that by itself can be used to contact you or identifies who you are) unless you give us permission.
                <br/><br/>
                We may at some future date date sell information that we have aggregated, anonymized, or pseudonymised to third-parties, such as fashion designers and retailers for the purposes of:<br/>
                - better understanding the types of people that do and don’t like the products they stock and why,<br/>
                - gathering opinions on what aspects of their products or services people like and dislike,<br/>
                - gaining insight into what types of products they could launch in future that would be popular among different demographics.<br/>
                This information has the potential to help these third-parties to improve their offering in response.
                <br/><br/>
                We may provide general demographic and preference information to third parties to help them better understand their audience and improve the products they supply. For example, a recent shoe release has been popular with woman aged between 25 and 34 who live in London and shop mostly for shoes priced between £40 and £70.
                <br/><br/>
                The only other times we would share your account information with third parties would be in the following circumstances: (1) with your consent; (2) to a service provider or partner who meets our data protection standards; (3), with aggregation, anonymization, pseudonymization; (4) when we have a good faith belief it is required by law, such as pursuant to a subpoena or other legal process; (5) when we have a good faith belief that doing so will help prevent imminent harm to someone.
                <br/><br/>
                If we are going to share your information in response to legal process, we’ll give you notice so you can challenge it (for example by seeking court intervention), unless we’re prohibited by law or believe doing so may endanger others. We will object to requests for information about users of our services that we believe are improper.
                <br/><br/>
              </FullWidthBody3>
              <FullWidthOther1>Data Storage</FullWidthOther1>
              <FullWidthBody3>
                Moonsift uses third-party vendors and hosting partners, such as Google, for hardware, software, networking, storage, and related technology we need to run Moonsift. We maintain two types of logs: server logs and event logs. By using Moonsift Services, you authorize Moonsift to transfer, store, and use your information in any country where we operate.
                <br/><br/>
              </FullWidthBody3>
              <FullWidthOther1>Tracking & Cookies</FullWidthOther1>
              <FullWidthBody3>
                We use browser cookies and similar technologies to recognize you when you return to our Services. We use them in various ways, for example to log you in, remember your preferences (such as default language), evaluate email effectiveness, and personalize content and other information.
                <br/><br/>
                We track your interactions within the Moonsift network and across the sites that you visit after clicking though to the pages hosting products and services that are displayed on Moonsift.
                <br/><br/>
                Some third-party services that we use to provide the Moonsift Service, such as Google Analytics, may place their own cookies in your browser. This Privacy Policy covers use of cookies by Moonsift only and not the use of cookies by third parties.
                <br/><br/>
              </FullWidthBody3>
              <FullWidthOther1>Modifying or Deleting Your Personal Information</FullWidthOther1>
              <FullWidthBody3>
                If you have a Moonsift account, you can access, modify or export your personal information, or delete your account by making the appropriate request to alex.reed [at] moonsift.com.
                <br/><br/>
                To protect information from accidental or malicious destruction, we may maintain residual copies for a brief time period. But, if you delete your account, your information and content will be unrecoverable after that time. Moonsift may preserve and maintain copies of your information when required to do so by law.
                <br/><br/>
              </FullWidthBody3>
              <FullWidthOther1>Data Security</FullWidthOther1>
              <FullWidthBody3>
                We use encryption (HTTPS/TLS) to protect data transmitted to and from our site. However, no data transmission over the Internet is 100% secure, so we can’t guarantee security. You use the Service at your own risk, and you’re responsible for taking reasonable measures to secure your account.
                <br/><br/>
              </FullWidthBody3>
              <FullWidthOther1>Business Transfers</FullWidthOther1>
              <FullWidthBody3>
                If we are involved in a merger, acquisition, bankruptcy, reorganization or sale of assets such that your information would be transferred or become subject to a different privacy policy, we will notify you in advance so you can opt out of any such new policy by deleting your account before transfer.
                <br/><br/>
              </FullWidthBody3>
              <FullWidthOther1>Email from Moonsift</FullWidthOther1>
              <FullWidthBody3>
                Sometimes we’ll send you emails about your account, service changes or new policies. You can’t opt out of this type of “transactional” email (unless you delete your account). But, you can opt out of non-administrative emails such as product recommendations, usage summaries and sales alerts on your collections by making the appropriate request at the bottom of the email you received.
                <br/><br/>
                When your interact with an email sent from Moonsift (such as opening an email or clicking on a particular link in an email), we may receive information about that interaction. We won’t email you to ask for your password or other account information. If you receive such an email, please send it to us so we can investigate.
                <br/><br/>
              </FullWidthBody3>
              <FullWidthOther1>Changes to this Policy</FullWidthOther1>
              <FullWidthBody3>
                Moonsift may periodically update this Policy. We’ll notify you about significant changes to it.
                <br/><br/>
              </FullWidthBody3>
              <FullWidthOther1>Questions</FullWidthOther1>
              <FullWidthBody3>
                We welcome feedback about this policy at david.wood [at] moonsift.com
                <br/><br/>
              </FullWidthBody3>
              <Seperator height="12px"/>
              <FullWidthBody1>Data Protection Statement for European Union Users</FullWidthBody1>
              <Seperator height="12px"/>
              <FullWidthOther1>Description of Processing Activity</FullWidthOther1>
              <FullWidthBody3>
                Moonsift collects and stores personal information about its users to customize their discovery experience and enable personalized product and service recommendations. The Moonsift Service, which aims to re-orientate the internet around the personal preferences of the individual relies on the collection of data to make the product work as well as possible.<br/>
                Purposes of Processing<br/>
                - Provide customised recommendations<br/>
                -	Provide, test, promote, and improve the services<br/>
                - Gather usage statistics of services<br/>
                - Gather information on what products and services users are clicking through to and purchasing
                <br/><br/>
              </FullWidthBody3>
              <FullWidthOther1>Legal Bases</FullWidthOther1>
              <FullWidthBody3>
                In order to provide the services, Moonsift collects and stores personal data about its users on the legal basis of consent given when you create an account and agree to the Privacy Policy.
                <br/><br/>
                Moonsift also pursues its legitimate interests by collecting minimal data of logged out users to provide the services, as outlined above.
                <br/><br/>
              </FullWidthBody3>
              <FullWidthOther1>Public Nature of Personal Data</FullWidthOther1>
              <FullWidthBody3>
                Logged-in users may choose to interact publicly with the Moonsift Services in the form of allowing friends or the wider public to view how they have rated products and services. Where such personal data may reveal special category protected data, it is processed on the basis that it is manifestly made public by the user. Additional information on potential consequences of such processing can be found below. If you do not agree to this public usage, do not create an account or use these features of Moonsift Services.
                <br/><br/>
              </FullWidthBody3>
              <FullWidthOther1>Categories of Personal Data Collected</FullWidthOther1>
              <FullWidthBody3>
                Logged out users<br/>
                - Product and service browsing history<br/>
                - IP address<br/>
                - Browser information<br/>
                - DNT status<br/>
                <br/>
                Logged in users:<br/>
                - Username<br/>
                - Display name<br/>
                - Bio<br/>
                - Avatar image<br/>
                - Email address (non-public)<br/>
                - Session activity (security)<br/>
                - Linked social media accounts (optional)<br/>
                - IP address<br/>
                - Browser information<br/>
                - Product and service browsing history<br/>
                - Network interactions (other users preferences)<br/>
                - Number of products and services reviewed
                <br/><br/>
              </FullWidthBody3>
              <FullWidthOther1>Categories of Recipients</FullWidthOther1>
              <FullWidthBody3>
                Moonsift shares minimal personal data with third-party processors in order to provide the Services. These processors offer at least the same level of data protection as that set out in this statement. This includes the following categories of recipients:<br/>
                - Product and service providers<br/>
                - Hosting, Storage, & Other Infrastructure<br/>
                - Security<br/>
                - Analytics<br/>
                - Communication & Support
                <br/><br/>
              </FullWidthBody3>
              <FullWidthOther1>Existence of Automated Decision-making</FullWidthOther1>
              <FullWidthBody3>
                Moonsift collects and stores personal data about its users to customize the products and services displayed to them. This includes automated decision-making to rank products and services tailored to the preferences and interests indicated by the user, and to their browsing history and interactions.
                <br/><br/>
              </FullWidthBody3>
              <FullWidthOther1>Potential Consequences of Processing</FullWidthOther1>
              <FullWidthBody3>
                By creating an account on Moonsift, users may make certain personal data about themselves public and accessible to others on their profile and through network interactions. This may in some cases constitute special category protected data which is considered manifestly made public by the user.
                <br/><br/>
                Users are free to use their real name and information, or a pseudonym of their choosing, for their account. Users may also choose to use the service without enabling other users of the service access to their product preferences.
                <br/><br/>
              </FullWidthBody3>
              <FullWidthOther1>Cross-border Transfers</FullWidthOther1>
              <FullWidthBody3>
                Moonsift is hosted in the United Kingdom. By using Moonsift Services, you authorize Moonsift to transfer, store, and use your information in the United Kingdom and any other country where we operate. Where your data is disclosed to our processors, it is subject by contract to at least the same level of data protection as that set out in this statement.
                <br/><br/>
              </FullWidthBody3>
              <FullWidthOther1>Retention</FullWidthOther1>
              <FullWidthBody3>
                Moonsift retains personal data associated with your account for the lifetime of your account. If you would like to delete your personal information, you can delete your account at any time. Deleted account will become unrecoverable in our system after a period of fourteen days. It may take several additional days for your personal data to be de-indexed from search engines, depending on those search engines’ practices, over which Moonsift may have limited or no control.
                <br/><br/>
              </FullWidthBody3>
              <FullWidthOther1>Rights of Data Subjects</FullWidthOther1>
              <FullWidthBody3>
                1.   If you sign up for a Moonsift account, you may at any time request an export of your personal information by making the appropriate request to alex.reed [at] moonsift.com.<br/>
                2.   You may withdraw consent by deleting your account at any time by making the appropriate request to alex.reed [at] moonsift.com. We will erase your personal information completely within 14 days (except to the extent Moonsift is prevented by law from deleting your information).<br/>
                3.   You may object at any time to the use of your personal data by contacting alex.reed [at] moonsift.com. If your complaint relates to alleged misuse of your personal data by a third party, it may result in suspension of that post or account in keeping with relevant law, public interest, our contractual obligations, and the rights of expression and access to information of others.<br/>
                4.   You may at any time lodge a complaint regarding the processing of your personal data by Moonsift with the Supervisory Authority of your EU member state.<br/>
                <br/><br/>
              </FullWidthBody3>
              <Seperator height="12px"/>
              <FullWidthBody1>Chrome Browser Extension Privacy Statement</FullWidthBody1>
              <Seperator height="12px"/>
              <FullWidthOther1>What data the Moonsift browser extension collects and why:</FullWidthOther1>
              <FullWidthBody3>
                The browser extension has three main functions:<br/>
                1.    Enable you to save and keep track of products you are considering purchasing.<br/>
                2.    Learn your taste so it can uncover the products you will be most interested in.<br/>
                3.    Display recommendations as you browse.<br/>
                <br/><br/>
                Our extension is only activated on apparel retail sites and will only collect product data from retail sites that Moonsift has actively enabled. We do, however, need access to other retail sites that we don’t yet cover (i.e. retail sites you are not yet able to save from) to enable you to request that we add them. On these retailers, Moonsift will not collect any data, we simply provide you with the means to express that you would like us to add this site.<br/>
                <br/><br/>
                  Moonsift requires access to all tabs in order to identify retail websites and to identify products efficiently as you browse.  Specifically, we limit the collection of data to the information that describes the products you are browsing, this typically includes:<br/>
                  - Price<br/>
                  - Brand<br/>
                  - Product image<br/>
                  - Product description<br/>
                  - Product care information<br/>
                  - Delivery information<br/>
                  We report these products to Moonsift in order to update our database and ensure we have up to date product data for all users.  When you interact with a product (e.g. add it to a collection) we report this interaction to Moonsift.<br/>
                <br/><br/>
                Moonsift only links product browsing data to an individual’s Moonsift account.  Moonsift will never seek to link any individual’s browsing data to the same individual’s accounts or tracking profiles (e.g. advertiser networks) outside of Moonsift unless requested by the user.
              </FullWidthBody3>
            </WithinContainer>
          </Container>
          <BottomBar/>
        </FullBlank>
    );
  }
}

export default PrivacyPolicy;
