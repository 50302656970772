/*

Landing page to target Christmas wishlist makers.  Very similar to Main Landing page

TODO: typescript, camelCase, make functional, non-default exports, design system...
Extract list of collections useing a dbHook (get,single doc)
Extract collection from db using a dbHook. (get, doc map)
collection tiles should load images in themselves.
*/
import React from "react";
import { Component } from "react";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";
import styled, { css } from "styled-components";
import { Stack, StackRoot, Typography } from "@moonsifttech/design-system";

import { M } from "src/mvp22/constants";
import MEDIA from "src/mvp22/media";
import TopMenu from "src/mvp22/menu-components/TopMenu";
import BottomBar from "src/mvp22/menu-components/BottomBar";
import { TEXTSTYLE } from "src/mvp22/style-components/Text";
import Button from "src/mvp22/form-components/Button";
import R, { EXTERNAL } from "src/routes";
import segmentEvent from "src/mvp22/segment-components/SegmentEvent";
import windowSize from "src/mvp22/WindowSize";
import getCollectionImages from "src/mvp22/firebase-functions/get_collection_images";
import get_collection_likes from "src/mvp22/firebase-functions/get_collection_likes";
import getExploreCollections from "src/mvp22/firebase-functions/get_explore_collections";
import { connect } from "react-redux";
import { withFirebase } from "src/mvp22/Firebase";
import CollectionCarousel from "src/mvp22/tile-components/CollectionCarousel";
import ReviewsCarousel from "src/mvp22/tile-components/ReviewsCarousel";
import nested_state_assignment from "src/mvp22/core-components/nested_state_assignment";
import removeAllListeners from "src/mvp22/core-components/remove_all_listeners";
import _ from "lodash";

const Container = styled.div`
  background-color: ${M.COL.BG.WHITE};
  width: 100%;
  display: flex;
  flex-direction: column;
`;

const InnerContainer = styled.section`
  margin: 0 auto;
  width: 100%;
  max-width: 992px;

  @media (max-width: 992px) {
    margin: 0 34px;
    width: calc(100% - 68px);
  }

  @media (max-width: 500px) {
    margin: 0 16px;
    width: calc(100% - 32px);
  }
`;

const TextContainer = styled.div`
  color: ${M.COL.TEXT.BLACK};
  position: relative;
  top: 20px;
  max-width: 394px;
  @media (max-width: 500px) {
    max-width: 326px;
  }
`;

const TextContainerOuter = styled.div`
  display: flex;
  justify-content: ${(props) => (props.isDesktop ? "center" : "flex-start")};
  width: ${(props) => (props.isDesktop ? "50%" : "100%")};
  margin-left: ${(props) => (props.isDesktop ? "" : "17px")};
  margin-right: ${(props) => (props.isDesktop ? "" : "17px")};
`;

const TextContainerOuter2 = styled(TextContainerOuter)`
  position: relative;
  right: ${(props) => (props.isDesktop ? "53px" : "")};
`;

const CTAButtonTextBold = styled(TEXTSTYLE.BODY3)`
  color: ${M.COL.TEXT.WHITE};
  font-weight: bold;
  font-size: ${(props) => (props.isDesktop === true ? "26px" : "18px")};
  line-height: 38px;
`;

const CTAButtonTextLight = styled(TEXTSTYLE.BODY3)`
  color: ${M.COL.TEXT.WHITE};
  font-weight: light;
  font-size: ${(props) => (props.isDesktop === true ? "26px" : "18px")};
  line-height: 38px;
`;

const Seperator = styled.div`
  height: ${(props) => props.height};
`;

const VideoContainer = styled.div`
  overflow-x: scroll;
  overflow-y: hidden;
  padding-top: 0px;
  padding-bottom: 0px;
  ::-webkit-scrollbar {
    display: none;
  }
  font-size: 0px;
  overflow: hidden;
  width: ${(props) => (props.isDesktop ? "50%" : "100%")};
  margin-top: 20px;
`;

const Video = styled.video`
  width: 100%;
  box-sizing: border-box;
  max-width: 450px;
`;

const ItemText = styled.div`
  font-size: 20px;
`;

const BottomText = styled(ItemText)`
  max-width: 420px;
  text-align: left;
`;

const SubSubtitle = styled(TEXTSTYLE.BODY5)`
  color: ${M.COL.TEXT.MID};
  display: flex;
`;

const ChromeIcon = styled.img`
  width: 13px;
  margin-right: 6px;
`;

const FiveStars = styled(({ className, style, size = "regular" }) => {
  return (
    <div className={`${className} ${size}`} style={style}>
      {_.times(5, (i) => {
        return <img key={i} src={MEDIA.RATING_STAR}   alt=""  />;
      })}
    </div>
  );
})`
  display: inline;

  &.small img {
    width: 14px;
  }

  &.regular img {
    width: 23px;
  }
`;

const CtaButton = styled(({ className, style, children, isDesktop }) => {
  return (
    <div className={className}>
      <Link className="button" style={style} to={R.SIGNUP}>
        <Button
          isDesktop={isDesktop}
          text={children}
          onClick={() => {
            segmentEvent("Clicked Get Started Button 1 On Landing Page");
          }}
          msstyle={
            isDesktop === true
              ? "cta_alternative_desktop"
              : "cta_alternative_mobile"
          }
        />
      </Link>
      <SubSubtitle>
        <ChromeIcon src={MEDIA.CHROME_ICON} />
        Designed for Chrome, other browsers coming soon
      </SubSubtitle>
      <SubSubtitle>
        <FiveStars size="small" /> 5 star average Chrome store rating
      </SubSubtitle>
    </div>
  );
})`
  .button {
    display: block;
    text-decoration: none;
    color: inherit;
    margin-bottom: 16px;

    button {
      ${({ isDesktop }) => isDesktop ? css`padding: 0 40px;` : '' }
    }
  }

  ${FiveStars} {
    margin-right: 6px;
  }
`;

const Heading = styled(({ className, style, isDesktop }) => {
  return (
    <InnerContainer className={className} style={style}>
      <Stack justifyContent="center" alignItems="center">
        <Typography className="title" variant="primary.b68" align="center" component="h1">
          Make your free online <span className="christmas">Christmas</span>{" "}
          Wishlist.
        </Typography>
        <Typography className="subtitle" variant="primary.l20" component="p">
          Add products from any website in one click.
        </Typography>
        <Typography className="subtitle" variant="primary.l20" component="p">
          Share your wishlist with friends and family.
        </Typography>
        <CtaButton isDesktop={isDesktop}>
          <CTAButtonTextBold key="bold-button-text" isDesktop={isDesktop}>
            Create a Wishlist&nbsp;
          </CTAButtonTextBold>
          <CTAButtonTextLight isDesktop={isDesktop} key="light-button-text">
            - it&apos;s free
          </CTAButtonTextLight>
        </CtaButton>
        <Stack
          className="shopWindow"
          direction="row"
          justifyContent="center"
          alignItems="center"
          spacing={30}
        >
          <Stack spacing={40}>
            <img alt="" src={MEDIA.LANDING_IMG_CANDLES} />
            <img alt="" src={MEDIA.LANDING_IMG_HANDBAG} />
          </Stack>
          <Stack className="alignTopUpToL" spacing={40}>
            <picture>
              <source
                media="(max-width: 620px)"
                srcSet={MEDIA.LANDING_IMG_EARRINGS_SMALL}
              />
              <img alt="" src={MEDIA.LANDING_IMG_EARRINGS} />
            </picture>
            <img alt="" className="hideFromL" src={MEDIA.LANDING_IMG_HANDBAG_SMALL} />
          </Stack>
          <Stack className="alignBottom" spacing={40}>
            <picture>
              <source
                media="(max-width: 620px)"
                srcSet={MEDIA.LANDING_IMG_PILLOW_SMALL}
              />
              <img alt="" src={MEDIA.LANDING_IMG_PILLOW} />
            </picture>
            <img alt="" className="hideFromL" src={MEDIA.LANDING_IMG_CANDLES_SMALL} />
          </Stack>
          <Stack className="alignBottom alignCenterUpToL" spacing={40}>
            <picture>
              <source
                media="(max-width: 620px)"
                srcSet={MEDIA.LANDING_IMG_CUPS_SMALL}
              />
              <img alt=""  src={MEDIA.LANDING_IMG_CUPS} />
            </picture>
            <img
            alt=""
              className="hideFromL"
              src={MEDIA.LANDING_IMG_SNAKE_CANDLES_SMALL}
            />
          </Stack>
          <Stack className="alignBottom" spacing={40}>
            <picture>
              <source
                media="(max-width: 620px)"
                srcSet={MEDIA.LANDING_IMG_JUMPER_SMALL}
              />
              <img src={MEDIA.LANDING_IMG_JUMPER} alt="" />
            </picture>
            <img
              className="hideFromL"
              alt=""
              src={MEDIA.LANDING_IMG_COFFEE_MAKER_SMALL}
            />
          </Stack>
          <Stack className="alignTopUpToL" spacing={40}>
            <picture>
              <source
                media="(max-width: 620px)"
                srcSet={MEDIA.LANDING_IMG_HEADPHONES_SMALL}
              />
              <img src={MEDIA.LANDING_IMG_HEADPHONES} alt="" />
            </picture>
            <img
              className="hideFromL"
              src={MEDIA.LANDING_IMG_SMARTWATCH_SMALL}
              alt=""
            />
          </Stack>
          <Stack spacing={40}>
            <img src={MEDIA.LANDING_IMG_SMARTWATCH} alt="" />
            <img src={MEDIA.LANDING_IMG_COFFEE_MAKER}alt=""  />
          </Stack>
        </Stack>
      </Stack>
    </InnerContainer>
  );
})`
  .title {
    max-width: 680px;
    margin: 0 0 20px;

    .christmas {
      color: ${M.COL.BG.DARKGREEN};
    }
  }

  .subtitle {
    margin: 0;

    &:nth-of-type(2) {
      margin-bottom: 24px;
    }
  }

  .shopWindow {
    margin-top: -200px;
    height: 492px;
  }

  .alignBottom {
    align-self: flex-end;
  }

  @media (min-width: 620px) {
    .shopWindow img.hideFromL {
      display: none;
    }
  }

  @media (max-width: 620px) {
    .title {
      font-size: 42px;
      line-height: 50px;
      max-width: 326px;
    }

    .subtitle {
      font-size: 16px;
      line-height: 24px;
    }

    .shopWindow {
      margin-top: 36px;
      height: 366px;

      >:not(style) +:not(style) {
        margin: 0 0 0 20px;
      }

      ${StackRoot} >:not(style) +:not(style) {
        margin: 20px 0 0 0;
      }

      img {
        width: 108px;
      }
    }

    .alignTopUpToL {
      align-self: flex-start;
    }

    .alignCenterUpToL {
      align-self: center;
    }
  }

  @media (max-width: 500px) {
    .shopWindow {
      margin-top: 26px;
      height: 236px;

      >:not(style) +:not(style) {
        margin: 0 0 0 12px;
      }

      ${StackRoot} >:not(style) +:not(style) {
        margin: 12px 0 0 0;
      }

      img {
        width: 70px;
      }
    }
  }
`;

const Highlights = styled(({ className, style }) => (
  <InnerContainer className={className} style={style}>
    <Stack
      direction="row"
      wrap="wrap"
      justifyContent="center"
      alignItems="center"
      spacing={32}
    >
      <Stack
        direction="row"
        wrap="wrap"
        justifyContent="center"
        alignItems="center"
        spacing={32}
      >
        <Stack
          className="highlight"
          justifyContent="center"
          alignItems="center"
        >
          <div className="highlightIconContainer">
            <img src={MEDIA.WALLET_OUTLINE} alt="" />
          </div>
          <Typography variant="primary.b26" align="center" component="h2">
            100% Free
          </Typography>
          <Typography variant="primary.l20" align="center" component="p">
            Make and share as many wishlists as you like
          </Typography>
        </Stack>
        <Stack
          className="highlight"
          justifyContent="center"
          alignItems="center"
        >
          <div className="highlightIconContainer">
            <img src={MEDIA.GLOBE_OUTLINE} alt="" />
          </div>
          <Typography variant="primary.b26" align="center" component="h2">
            Add from any store
          </Typography>
          <Typography variant="primary.l20" align="center" component="p">
            Add items from anywhere you shop online
          </Typography>
        </Stack>
      </Stack>
      <Stack
        direction="row"
        wrap="wrap"
        justifyContent="center"
        alignItems="center"
        spacing={32}
      >
        <Stack
          className="highlight"
          justifyContent="center"
          alignItems="center"
        >
          <div className="highlightIconContainer">
            <img src={MEDIA.PEOPLE_OUTLINE} alt="" />
          </div>
          <Typography variant="primary.b26" align="center" component="h2">
            Easy to share
          </Typography>
          <Typography variant="primary.l20" align="center" component="p">
            Easily share wishlists with family & friends
          </Typography>
        </Stack>
        <Stack
          className="highlight"
          justifyContent="center"
          alignItems="center"
        >
          <div className="highlightIconContainer">
            <img src={MEDIA.GIFT_OUTLINE} alt="" />
          </div>
          <Typography variant="primary.b26" align="center" component="h2">
            Get the gifts you want
          </Typography>
          <Typography variant="primary.l20" align="center" component="p">
            Get the gifts you really want this Christmas
          </Typography>
        </Stack>
      </Stack>
    </Stack>
  </InnerContainer>
))`
  margin: 0;
  max-width: none;
  width: 100%;

  .highlight {
    width: 256px;
  }

  .highlightIconContainer {
    padding: 20px;
  }

  h2,
  p {
    margin: 0;
  }

  h2 {
    margin-bottom: 8px;
  }
`;

const WishlistIdeas = styled(({ className, style, children }) => {
  return (
    <InnerContainer className={className} style={style}>
      <Stack justifyContent="center" alignItems="center">
        <Stack className="innerContainer" alignItems="center">
          <Typography className="heading" variant="primary.b34" component="h2">
            Christmas Wishlist ideas 2021
          </Typography>
          <Typography
            className="subheading"
            variant="primary.l20"
            component="p"
          >
            Click below for Christmas wishlist ideas curated using Moonsift
          </Typography>
          {children}
        </Stack>
      </Stack>
    </InnerContainer>
  );
})`
  .innerContainer {
    width: 100%;
    max-width: 990px;
  }
`;

const Instructions = styled(({ className, style, isDesktop }) => {
  return (
    <InnerContainer className={className} style={style}>
      <div className="innerContainer">
        <Stack
          justifyContent="center"
          alignItems="center"
          direction="row"
          wrap="wrap-reverse"
        >
          <VideoContainer isDesktop={isDesktop}>
            <Video
              src={MEDIA.LANDING_VIDEO_1_FASTER}
              loop
              autoPlay
              muted
              playsInline
            />
          </VideoContainer>
          <Seperator height="44px" />
          <TextContainerOuter2
            className="TextContainerOuter2"
            isDesktop={isDesktop}
          >
            <TextContainer className="TextContainer">
              <BottomText className="BottomText">
                <Typography
                  className="heading"
                  variant="primary.b34"
                  component="h2"
                >
                  Add products from
                  <br />
                  any online store
                </Typography>
                <ol>
                  <li>
                    <a href={EXTERNAL.CHROMESTORE}>Add to Chrome</a> on your
                    desktop
                  </li>
                  <li>Browse and shop as normal</li>
                  <li>Save items with one click</li>
                </ol>
                <Typography
                  className="subheading"
                  variant="primary.l20"
                  component="p"
                >
                  <a href={R.RETAILERS}>List of most popular stores</a>
                </Typography>
                <Seperator height={"18px"} />
              </BottomText>
            </TextContainer>
          </TextContainerOuter2>
          <Seperator height="32px" />
        </Stack>
      </div>
    </InnerContainer>
  );
})`
  @media (max-width: 922px) {
    .innerContainer {
      margin: 0 auto;
      max-width: 420px;
    }
  }

  a {
    line-height: inherit;
    color: ${M.COL.TEXT.BLACK};
  }

  ol {
    font-family: ${M.FONT};
    font-style: normal;
    font-weight: 300;
    line-height: 30px;
    padding: 0 0 0 22px;

    a {
      font-weight: 400;
    }
  }
`;

const PeopleAreSaying = styled(({ className, style, isDesktop }) => {
  return (
    <InnerContainer className={className} style={style}>
      <Stack justifyContent="center" alignItems="center">
        <Stack className="innerContainer" alignItems="center">
          <Typography className="heading" variant="primary.b34" component="h2">
            Read what people are saying <FiveStars />
          </Typography>
          <ReviewsCarousel type="landing" />
          <CtaButton isDesktop={isDesktop}>
            <CTAButtonTextBold key="bold-button-text" isDesktop={isDesktop}>
              Create a Wishlist&nbsp;
            </CTAButtonTextBold>
            <CTAButtonTextLight isDesktop={isDesktop} key="light-button-text">
              - it&apos;s free
            </CTAButtonTextLight>
          </CtaButton>
        </Stack>
      </Stack>
    </InnerContainer>
  );
})`
  .innerContainer {
    width: 100%;
    max-width: 990px;
  }

  .heading {
    margin-bottom: 28px !important;
  }

  .CarouselContainer {
    margin: 0;

    .carouselSlideContainer {
      margin: 0;
    }
  }

  ${CtaButton} {
    margin-top: 68px;
  }
`;

class ChristmasLanding extends Component {
  constructor() {
    super();
    this.state = {
      isLoaded: false,
      landingCollectionList: [],
    };
  }

  componentDidMount() {
    this._isMounted = true;
    segmentEvent("Viewed Landing");
    this.updateTheDataOnce();
  }

  componentDidUpdate() {
    this.updateTheDataOnce();
  }

  componentWillUnmount() {
    this._isMounted = false;
    removeAllListeners(this.state.collections);
  }

  collectionStateAssignFunc = (newDict) => {
    this._isMounted &&
      this.setState((prevState) => {
        return {
          collections: nested_state_assignment(prevState.collections, newDict),
        };
      });
  };

  updateTheDataOnce() {
    if (this.props.firebaseState && this.props.firebaseState.loaded) {
      this.setState((prevState, props) => {
        // makes this only run once? they are equal after the first time you load the page so updateTheDataOnce doesn't end up populating new collections
        if (
          prevState.auth_user_uid !== props.authUserUID ||
          prevState.current_explore_type !== props.explore_type
        ) {
          // get the collection info JUST ONCE, hence NOT bothering with redux for now:
          getExploreCollections(
           "christmas",
            props.firebase,
            props.authUserUID
          ).then((response) => {
            // split by big and small tiles:
            const like_listeners_dict = get_collection_likes(
              response.collections,
              this.props.firebase,
              this.props.authUserUID,
              this.collectionStateAssignFunc
            ); // WILL ALSO CALL AN ASYNC FUNCTION WHEN LIKES DETERMINED!
            var collections_with_listeners = nested_state_assignment(
              response.collections,
              like_listeners_dict
            );
            const image_getters = getCollectionImages(
              response.collections,
              this.props.firebase,
              this.collectionStateAssignFunc
            );
            collections_with_listeners = nested_state_assignment(
              collections_with_listeners,
              image_getters
            );
            this._isMounted &&
              this.setState((prevState) => {
                return {
                  isLoaded: true,
                  landingCollectionList: response.collections_sorted,
                  collections: nested_state_assignment(
                    prevState.collections,
                    collections_with_listeners
                  ),
                  public_info: response.public_info,
                };
              });
          });
          return {
            auth_user_uid: this.props.authUserUID,
            isLoaded: false,
          };
        }
      });
    }
  }

  renderCarousel() {
    if (this.state.isLoaded) {
      return (
        <CollectionCarousel
          collections_list={this.state.landingCollectionList}
          collections={this.state.collections}
          public_info={this.state.public_info}
          auth_user_uid={this.state.auth_user_uid}
        />
      );
    } else {
      return "";
    }
  }

  render() {
    const { className, windowWidth } = this.props;
    const isDesktop = windowWidth >= M.MOBILESWITCH;

    return (
      <Container className={className + ' root'}>
        <Helmet>
          <title>
            Moonsift - Save, curate and share from online UK fashion retailers
          </title>
        </Helmet>
        <TopMenu showTastemaker={true} />
        <Heading isDesktop={isDesktop} />
        <Highlights />
        <WishlistIdeas>{this.renderCarousel()}</WishlistIdeas>
        <Instructions isDesktop={isDesktop} />
        <PeopleAreSaying isDesktop={isDesktop} />
        <BottomBar />
      </Container>
    );
  }
}

const StyledChristmasLanding = styled(ChristmasLanding)`
  overflow-x: hidden;
  color: ${M.COL.TEXT.BLACK};

  .heading,
  .subheading,
  .body {
    text-align: start;
    align-self: flex-start;
    margin: 0 0 16px;
  }

  .subheading {
    margin-top: -10px;
  }

  ${Heading} {
    padding-top: 124px;
    margin-bottom: calc(160px - 32px);
  }

  ${Highlights} {
    margin-bottom: calc(160px - 16px);
  }

  ${WishlistIdeas} {
    margin-bottom: calc(160px - 104px);
  }

  ${Instructions} {
    margin-bottom: calc(160px - 28px);
  }

  ${PeopleAreSaying} {
    margin-bottom: 160px;
  }

  @media (max-width: 620px) {
    ${Heading} {
      padding-top: 112px;
      margin-bottom: calc(100px - 32px);
    }

    ${Highlights} {
      margin-bottom: calc(100px - 16px);
    }

    ${WishlistIdeas} {
      margin-bottom: calc(100px - 104px);
    }

    ${Instructions} {
      margin-bottom: calc(100px - 28px);
    }

    ${PeopleAreSaying} {
      margin-bottom: 100px;
    }

    ${CtaButton} > div {
      justify-content: center;
    }
  }
`;

const mapStateToProps = (state) => {
  return {
    firebaseState: state.firebasestate,
    authUserUID:state.auth.id
  };
};

export default connect(mapStateToProps)(
  withFirebase(windowSize(StyledChristmasLanding))
);
