/*

TODO: Nofications saga inspired the database sagas used in the rest of the project.  So they should easily be replaced with them.
This is for the dropdown notification (bell) list, NOT the list on the notifications page (view) it clicks through to.

*/

import {put, takeEvery, take, all} from 'redux-saga/effects';
import {eventChannel} from 'redux-saga';
import {UPDATE_NOTIFICATIONS,LISTEN_FOR_NOTIFICATIONS,REMOVE_NOTIFICATIONS} from 'src/mvp22/redux-components/actions';
//const delay = (ms) => new Promise(res => setTimeout(()=>res(45), ms));

function* notification_list(firebase,auth_user_uid){
  // TODO: in future use the call function of SAGA in order to make this more testable...
  // https://redux-saga.js.org/docs/basics/DeclarativeEffects.html
  const notification_list_channel = eventChannel(
    emit => firebase.user_notifications_limit10(auth_user_uid)
    .onSnapshot(emit)
  );
  // Save the channel:
  yield put(
    {
      type: UPDATE_NOTIFICATIONS,
      data: {
        notification_list_channel
      }
    }
  );
  // Code for treating the above listener output:
  try {
    while (true) {
      // take from the channel...
      const snapshot = yield take(notification_list_channel);
      // closing channel will terminate entire function and just call finally
      // process the snapshot:...
      try{
        const notification_list = [];
        snapshot.forEach(
          doc=>{
            const data = doc.data();
            // Not entry uid as this was used already...
            data.uid = doc.id;
            notification_list.push(data);
          }
        );
        // Put data away:
        yield put({
          type: UPDATE_NOTIFICATIONS,
          data: {
            notification_list
          }
        });
      } catch (err) {
        console.error("Failed to process notification list!",err);
      }
    }
  } finally {
    // any code to perform after END called or error received?
  }
  // Anything below here is never called (unless there is an error in yield...?)
}

function* notification_doc(firebase,auth_user_uid){
  // TODO: in future use the call function of SAGA in order to make this more testable...
  // https://redux-saga.js.org/docs/basics/DeclarativeEffects.html
  const notification_doc_channel = eventChannel(
    emit => firebase.user_notification_doc(auth_user_uid).onSnapshot(emit)
  );
  // Save the channel:
  yield put(
    {
      type: UPDATE_NOTIFICATIONS,
      data: {
        notification_doc_channel
      }
    }
  );
  // Code for treating the above listener output:
  try {
    while (true) {
      // take from the channel...
      const snapshot = yield take(notification_doc_channel);
      // closing channel will terminate entire function and just call finally
      // process the snapshot:...
      try{
        if (snapshot.exists()){
          const notification_doc = snapshot.data();
          // Put data away:
          yield put({
            type: UPDATE_NOTIFICATIONS,
            data: {
              notification_doc
            }
          });
        }
      } catch (err) {
        console.error("Failed to process notification doc!",err);
      }
    }
  } finally {
    // any code to perform after END called or error received?
  }
  // Anything below here is never called (unless there is an error in yield...?)
}

// worker saga:
function* listenNotifications(action){
  const auth_user_uid = action.data.auth_user_uid;
  // Clear the old channel if there is one:
  yield put(
    {
      type: REMOVE_NOTIFICATIONS
    }
  );
  // If we are logged in then start a channel to firestore:
  if (auth_user_uid){
    yield all(
      [
        notification_list(action.data.firebase,auth_user_uid),
        notification_doc(action.data.firebase,auth_user_uid)
      ]
    );
  }
}

// Watching saga:
export function* watchNotificationsCall() {
  yield takeEvery(LISTEN_FOR_NOTIFICATIONS,listenNotifications);
}
