/*

This returns the username directly, only used in Collection and profile.  No need for redux?

*/

export default async function get_user_uid(username,displayUsername,firebase,auth_user_uid){
  if (username.length>24){
    return username;
  }else{
    if (displayUsername===username){
      return auth_user_uid;
    } else {
      return await firebase.username(
        username.toLowerCase()
      ).get().then(
        response=>{
          if (response.exists() && response.data().user_uid){
            return response.data().user_uid;
          }
          return username;
        }
      ).catch(
        (err)=>{
          console.log(err);
          return username;
        }
      );
    }
  }
}
