import { useEffect, useContext, useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AuthContext, RootState, MobileContext } from 'src/index';
import { UserObject } from 'src/services/Auth';
import { ActionNames, AuthAction } from 'src/types';

/*

This hook manages authentication but does not load in any other data about the user from the database:

*/
export const useAuth = () => {
  const dispatch = useDispatch();
  const [nullToken, setNullToken] = useState(false);
  const auth = useContext(AuthContext);
  const mobile = useContext(MobileContext);

  const onAuthChangeFunction = useCallback(
    (user: UserObject | null) => {
      const authUserUID = user === null ? null : user.uid;
      const authUserEmail = user === null ? null : user.email;
      const authUserProviderName =
        user !== null &&
        user.providerData &&
        user.providerData.length > 0 &&
        user.providerData[0].displayName
          ? user.providerData[0].displayName
          : null;
      dispatch<AuthAction>({
        type: ActionNames.AUTH_SET,
        payload: {
          id: authUserUID,
          email: authUserEmail,
          provider_name: authUserProviderName,
        },
      });
    },
    [dispatch],
  );

  useEffect(() => {
    return auth.auth.onAuthStateChanged(onAuthChangeFunction);
  }, [auth.auth, onAuthChangeFunction]);

  useEffect(() => {
    if (mobile.signInToken) {
      // App passes in "null" if it no longer has a token.
      if (mobile.signInToken !== 'null') {
        auth.signInWithCustomToken(mobile.signInToken).catch(() => {
          console.log('Token is not valid, received:', mobile.signInToken);
          setNullToken(true);
        });
      } else {
        // May be signed in to App or not,
        setNullToken(true);
      }
    }
  }, [auth, mobile]);

  // A way to detect that we are definitely NOT signed into the app and not going to be signed in any time soon:
  const authUserUIDSet = useSelector<RootState>((state) => state.auth.set);
  const authUserUID = useSelector<RootState>((state) => state.auth.id);
  useEffect(() => {
    if (
      window.ReactNativeWebView &&
      nullToken &&
      authUserUIDSet &&
      authUserUID === null
    ) {
      // Pass to the app the signal that we are logged out and to restart auth:
      window.ReactNativeWebView.postMessage(
        JSON.stringify({ message: 'LOGOUT' }),
      );
    }
  }, [nullToken, authUserUIDSet, authUserUID]);
};
