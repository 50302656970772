import React from 'react';
import { useDispatch } from 'react-redux';

import segmentEvent from 'src/mvp22/segment-components/SegmentEvent';
import { modalSet } from 'src/mvp22/redux-components/actions';
import { PostinstallThreeContainerProps } from './PostinstallThree.types';
import { PostinstallThree } from './PostinstallThree.component';

const PostinstallThreeContainer: React.FC<PostinstallThreeContainerProps> = (
  props,
) => {
  const dispatch = useDispatch();
  const handleCreateCollection = React.useCallback(
    (event) => {
      event.preventDefault();
      segmentEvent('Clicked Create New Collection on postinstall page');
      dispatch(
        modalSet({
          type: 'new_collection',
        }),
      );
    },
    [dispatch],
  );

  return (
    <PostinstallThree onCreateCollection={handleCreateCollection} {...props} />
  );
};

export { PostinstallThreeContainer as PostinstallThree };
