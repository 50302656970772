/*

Displays the product with larger images and more data in a modal.

Note that it fills the screen on mobile and is reachable by a URL depending on the product, though this is handled in ProductTileList.

TODO: typescript, design system...


*/
import React, {  useEffect, useRef, useState,useCallback,useContext } from 'react';
import { FirebaseContext } from 'src/index';
import { useSelector } from 'react-redux';
import {TEXTSTYLE} from 'src/mvp22/style-components/Text';
import MEDIA from 'src/mvp22/media';
import styled from 'styled-components';
import {M} from 'src/mvp22/constants';
import get_site_uid from 'src/mvp22/core-components/get_site_uid';
import VerticalCarousel from 'src/mvp22/tile-components/VerticalCarousel';
import MobileCarousel from 'src/mvp22/tile-components/MobileCarousel';
import LoadingDots from 'src/mvp22/image-components/LoadingDots';
import LoadCheck from 'src/mvp22/image-components/LoadCheck';
import {CommentTileList} from 'src/mvp22/tile-components/CommentTileList';
import {displayPrice} from 'src/mvp22/core-components/price_functions';
import { getCollectionItemStoreAs } from 'src/utils/getCollectionItemStoreAs';
import update_product_image from 'src/mvp22/firebase-functions/update_product_image';


const TopRight = styled.div`
  position: absolute;
  right: ${props => props.isDesktop ? '26px' : '20px'};
  top: ${props => props.isDesktop ? '26px' : '20px'};
  height: 30px;
  width: 30px;
  border-radius: 20px;
  background: ${M.COL.BUTTON.WHITE};
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgb(255 255 255 / 36%);
`;

const RightArrow = styled.img`
  cursor: pointer;
  position: relative;
  left: 20px;
  top: 50%;
`;

const SmallRightArrow = styled.img`
  margin-left:11px;
`;

const LeftArrow = styled.img`
  transform: scaleX(-1);
  cursor: pointer;
  position: relative;
  right: 20px;
  top: 50%;
`;

const SmallLeftArrow = styled.img`
  margin-right:11px;
  transform: scaleX(-1);
`;

const Cross = styled.img`
  height:16px;
  width:16px;
  cursor:pointer;
`;

const GeneralOverlayWithinContainer = styled.div`
  display:flex;
  position:relative;
  width: ${props => props.isDesktop ? '90%' : '100%'};
  height: ${props => props.isDesktop ? '90%' : '100%'};
  box-sizing:border-box;
  background-color:${M.COL.BG.WHITE};
  border-radius: ${props => props.isDesktop ? '5px' : '0px'};
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
  overflow-y:auto;
  flex-direction: row;
  text-align: center;
  justify-content: center;
  color: ${M.COL.TEXT.BLACK};
  max-width: 911px;
  align-items: flex-start;
  ::-webkit-scrollbar {
    background: #FFFFFF;
    border-left:1px solid #EFEFEF;
    box-sizing: border-box;
    width:15px;
  }
  ::-webkit-scrollbar-thumb {
    background: #CECFD1;
    border-radius: 8px;
    border: 3px solid transparent;
    background-clip: content-box;
    box-sizing: border-box;
    border-left: 5px solid transparent;
  }
  ::-webkit-scrollbar-thumb:hover {
    background:rgb(136, 136, 136);
    border-radius: 8px;
    border: 3px solid transparent;
    background-clip: content-box;
    box-sizing: border-box;
    border-left: 5px solid transparent;
  }
`;

const PopupBodyInner = styled.div`
  display: flex;
  padding: ${props => props.isDesktop ? '26px 42px' : '0px'};
  width: ${props => props.isDesktop ? '100%' : ''};
  @media(max-width: 420px){
    width: 100%;
  }
  height:${props => props.isDesktop ? '89%' : ''};
`;

const MainImageContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  flex-shrink: 10;
  -webkit-box-pack: center;
  justify-content: flex-start;
  align-items: flex-start;
  height: 100%;
  max-width: ${props => props.isDesktop ? '380px' : ''};
  position:relative;
`;

const DescFlow = styled(TEXTSTYLE.LINKA)`
  display:flex;
  align-items: center;
`;

const DescriptionContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: ${props => props.isDesktop ? '330px' : '100%'};
  align-items: flex-start;
  justify-content: flex-start;
  text-align: left;
  margin-left: ${props => props.isDesktop ? '42px' : ''};
  margin-top: ${props => props.isDesktop ? '26px' : ''};
  margin-bottom:8px;
  word-break: break-word;
  max-width: ${props => props.isDesktop ? '360px' : '420px'};
  @media(max-width: 450px){
    margin: 0 auto;
  }
  @media(max-width: 420px){
    width: 87%;
  }
`;

const ImageAndDescriptionContainer = styled.div`
  display: flex;
  flex-wrap: nowrap;
  width: 100%;
  flex-direction: ${props => props.isDesktop ? 'row' : 'column'};
`;

const MainImage = styled.img`
  width: 100%;
  max-height:604px;
  object-fit: contain;
`;

const Price = styled(TEXTSTYLE.BODY4)`
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 19px;
`;

const PreviousPrice = styled(Price)`
  color: ${M.COL.TEXT.LIGHT};
  text-decoration: line-through;
  font-weight: normal;
  margin-left: 12px;
`;

const PriceBox = styled.div`
  display: flex;
  margin-bottom: 14px;
  flex-direction: column;
`;

const PriceContainer = styled.div`
  display: flex;
`;

const Box = styled.span`
  display: flex;
  border: 1px solid black;
  min-height: 35px;
  align-items: center;
  cursor: pointer;
  width: 100%;
`;

const BoxText = styled(TEXTSTYLE.BODY3)`
  font-style: normal;
  font-weight: bold;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const BoxLink = styled(TEXTSTYLE.LINKA)`
  width: 100%;
  margin-bottom: ${props => props.isDesktop ? '30px': '41px'};
  margin-right:  ${props => props.isDesktop ? '10px': '0px'};
  margin-top:4px;
  display: flex;
  justify-content: center;
`;

const ImageSelectButton = styled(Box)`
  color: ${props => props.enabled ? M.COL.TEXT.WHITE : M.COL.TEXT.LIGHT};
  background-color: ${props => props.enabled ? M.COL.BUTTON.TRANSPARENT_BLACK : M.COL.BUTTON.TRANSPARENT_WHITE};
  border: ${props => props.enabled ? `1px solid ${M.COL.LINE.BLACK}` : `1px solid ${M.COL.LINE.MID}`};
  justify-content: center;
  position: absolute;
  position: sticky;
  bottom: 24px;
  margin: 0 auto;
`;

const RightIcon = styled.img`
  height: 16px;
  cursor:pointer;
  display:inline-block;
  margin-left: 11px;
  margin-right: 11px;
`;

const RightIconLarge = styled(RightIcon)`
  height: 22px;
  cursor:pointer;
  display:inline-block;
  margin-left: 10px;
  margin-right: 0px;
`;

const ShrinkableSides = styled.div`
  flex-grow: 1;
  min-width:32px;
  line-height: 0;
`;

const DescHeading = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: ${props => props.isDesktop ? '0px' : '18px'};
  margin-bottom: 13px;
`;

const ImageSelectButtonText = styled(TEXTSTYLE.BODY4)`
  font-weight: ${props => props.enabled ? 'bold' : 'normal'};
`;

const RightContainerOutsidePopup = styled.div`
  height: 100%;
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
`;

const LeftContainerOutsidePopup = styled.div`
  height: 100%;
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
`;

const PaginationContainer = styled.div`
  width: 100%;
  display: ${props => props.isDesktop ? 'none' : 'flex'};
  justify-content: space-between;
  margin-bottom: 24px;
`;

const NextItem = styled(TEXTSTYLE.LINKA)`
  display: flex;
  align-items: center;
  cursor: pointer;
`;

const PreviousItem = styled(TEXTSTYLE.LINKA)`
  display: flex;
  align-items: center;
  cursor: pointer;
`;

const CrossLink = styled(TEXTSTYLE.LINKA)`
  height: 30px;
  width: 30px;
  display: flex;
  justify-content: center;
  align-items: center;

  *, *::before, *::after {
    box-sizing: initial;
  }
`;

const ImageAndSelectButtonContainer = styled.div`
  display: block;
  flex-direction: column;
  width: 50%;
`;

const PriceChangeText = styled(TEXTSTYLE.BODY5)`
  color: ${props=>props.sign==="-"?M.COL.TEXT.GREEN:M.COL.TEXT.ERRORDARK};
`;

const ProductDescription = styled(TEXTSTYLE.BODY4)`
  margin-bottom: 13px;
`;

export const ProductModal = (
  {
    collectionUID,
    collectionUserUID,
    itemUID,
    showProductModal,
    setProductModalViewed,
  }
)=>{
  // image on collection:
  // prevents images jumping around when chaning the selected image:
  const [selectedImageIndex,setSelectedImageIndex] = useState(0); // local updates to prevent jumping / flashing of old states
  const [localImageList,setLocalImageList] = useState([]); // as above
  const [localItemUID,setLocalItemUID] = useState(undefined); // as above
  const [selectingImageWorking,setSelectingImageWorking ] = useState(false); // saving new selected image
  const [activeImageIndex,setActiveImageIndex] = useState(0); // image index being viewed currently
  const productDataStoreAs = getCollectionItemStoreAs(collectionUID,collectionUserUID,itemUID);
  const productData = useSelector((state) => state.productData?state.productData[productDataStoreAs]:undefined);
  const domRef = useRef();
  const rightRef = useRef();
  const leftRef = useRef();
  const windowWidth = useSelector((state) => state.ui.windowWidth);
  const authUserUID = useSelector(state=>state.auth.id);
  const isMine = authUserUID===collectionUserUID;
  //TODO: change these when firebase moved in and auth redone:
  const firebase = useContext(FirebaseContext);

  // On new product, go to first image
  // and report that the user has viewed that product to remove notifcation alerts on that product tile:
  useEffect(
    ()=>{
      if (localItemUID !== itemUID && productData){
        setLocalItemUID(itemUID);
        setLocalImageList(productData.imageList);
        setProductModalViewed(
          itemUID,
          collectionUID,
          collectionUserUID
        );
        // Reset active image index:
        setActiveImageIndex(0);
      }
    }
    ,
    [localItemUID, productData, setProductModalViewed,itemUID,collectionUID,collectionUserUID]
  );

  const hideModalViaURL = useCallback(
    (event)=>{
      if (event){
        event.preventDefault();
      }
      showProductModal(false,null);
      return false;
    },
    [showProductModal]
  );

  // close the modal if the product is deleted from this collection:
  useEffect(
    ()=>{
      if (productData===undefined){
        hideModalViaURL(undefined);
      }
    },
    [productData,hideModalViaURL]
  );

  // close the modal if clicked outside the modal
  const checkClick = useCallback(
    (event) => {
      if (domRef.current){
        if (
          !domRef.current.contains(event.target)
        &&
          !(leftRef.current && leftRef.current.contains(event.target))
        &&
          !(rightRef.current && rightRef.current.contains(event.target))
        ){
          hideModalViaURL(event);
        }
      }
    },
    [domRef,leftRef,rightRef,hideModalViaURL]
  );
  useEffect(
    ()=>{
      document.addEventListener("mousedown",checkClick,false);
      return ()=>{
        document.removeEventListener("mousedown",checkClick,false);
      };
    },
    [checkClick]
  );

  // Select the image to display on the product-tile:
  const activeImageURL = localImageList[activeImageIndex]??"";
  const selectImage=useCallback(
    ()=>{
      if (
        selectingImageWorking === false && authUserUID!==null
      ){
        setSelectingImageWorking(true);
        // Call firebase to set the chosen image as required:
        update_product_image(
          collectionUID,
          itemUID,
          activeImageURL,
          firebase,
          authUserUID
        ).then(
          ()=>{
            setSelectedImageIndex(activeImageIndex);
            setSelectingImageWorking(false);
          }
        ).catch(
          ()=>{
            setSelectingImageWorking(false);
          }
        );
      }
    },
    [selectingImageWorking, collectionUID, itemUID, activeImageURL, firebase, authUserUID,activeImageIndex]
  );

  /*

  RENDERS:

  */

  const DOMAIN = get_site_uid(productData?productData.pageURL:"");
  const ISDESKTOP = windowWidth>=M.MOBILESWITCH;

  const renderVerticalCarousel = ()=>{
    return(
      <VerticalCarousel
       active_image_index={activeImageIndex}
       setActiveImage={setActiveImageIndex}
       image_list={localImageList}
      />
     );
  };

  const renderMobileCarousel = ()=>{
    return(
      <MobileCarousel
       active_image_index={activeImageIndex}
       setActiveImage={setActiveImageIndex}
       image_list={localImageList}
       windowWidth={windowWidth}
      />
    );
  };

  const renderSelectImageButton = (isDesktop)=>{
    if (isMine){
      const isOnSelectedImage = activeImageIndex === selectedImageIndex;
      return(
        <ImageSelectButton
         enabled={!isOnSelectedImage}
         onClick={()=>(!isOnSelectedImage)?selectImage():null}
         className='ImageSelectButton'
         isDesktop={isDesktop}
        >
          <ImageSelectButtonText
           enabled={!isOnSelectedImage}
          >
           {  selectingImageWorking ?
                <LoadingDots/>
             :
             isOnSelectedImage ?
              "Image selected to display in collection"
             :
              "Select this image to display in my collection"
           }
          </ImageSelectButtonText>
        </ImageSelectButton>
      );
    }
  };

  const renderDescription = ()=>{
    if (productData){
      return(
        <DescriptionContainer isDesktop={ISDESKTOP}>
          <DescHeading isDesktop={ISDESKTOP}>
            <DescFlow href={productData.url} target="_blank">
              <TEXTSTYLE.HEADING4>
                {DOMAIN}
              </TEXTSTYLE.HEADING4>
              <RightIconLarge src={MEDIA.OPEN_RETAILER}/>
            </DescFlow>
          </DescHeading>
          <ProductDescription>{productData.description}</ProductDescription>
          <PriceBox>
            <PriceContainer><Price>{productData.price}</Price><PreviousPrice>{productData.priceChange===undefined?null:productData.priceChange.old_price_input}</PreviousPrice></PriceContainer>
            {
              productData.priceChange === undefined
              ?
                null
              :
              <>
                <PriceChangeText sign={productData.priceChange.sign}>{`${productData.priceChange.sign==="-"?"Down":"Up"} ${displayPrice(productData.priceChange.currency_code,Math.abs(productData.priceChange.difference))} (${productData.priceChange.percentage_difference}%) since this item was saved.`}</PriceChangeText>
              </>
            }
          </PriceBox>
          <BoxLink isDesktop={ISDESKTOP} href={productData.url} target="_blank">
            <Box className='Box'>
              <ShrinkableSides/>
              <BoxText>View on {DOMAIN}</BoxText>
              <ShrinkableSides>
                <RightIcon src={MEDIA.OPEN_RETAILER}/>
              </ShrinkableSides>
            </Box>
          </BoxLink>
          <PaginationContainer isDesktop={ISDESKTOP}>
            <PreviousItem href="#" onClick={
              (event)=>{
                event.preventDefault();
                showProductModal(true,itemUID,-1);
                return false;
              }
            }>
              <SmallLeftArrow src={MEDIA.GENERIC_ARROW_SMALL}/>
              <TEXTSTYLE.BODY4>Previous item</TEXTSTYLE.BODY4>
            </PreviousItem>
            <NextItem href="#" onClick={
              (event)=>{
                event.preventDefault();
                showProductModal(true,itemUID,1);
                return false;
              }
            }>
              <TEXTSTYLE.BODY4>Next item</TEXTSTYLE.BODY4>
              <SmallRightArrow src={MEDIA.GENERIC_ARROW_SMALL}/>
            </NextItem>
          </PaginationContainer>
          <CommentTileList
            collectionUID={collectionUID}
            collectionUserUID={collectionUserUID}
            itemUID={itemUID}
          />
        </DescriptionContainer>
      );
    }
  };

  const renderForMobile = (ISDESKTOP, DOMAIN)=>{
    return(
      <React.Fragment>
          <GeneralOverlayWithinContainer isDesktop={ISDESKTOP} className='GeneralOverlayWithinContainer'>
            <PopupBodyInner isDesktop={ISDESKTOP}>
              <ImageAndDescriptionContainer className='imageAndDescriptionContainer' isDesktop={ISDESKTOP}>
                {renderMobileCarousel()}
                {renderDescription(ISDESKTOP, DOMAIN)}
              </ImageAndDescriptionContainer>
              <TopRight isDesktop={ISDESKTOP} className="topRight">
                <CrossLink href="#" onClick={hideModalViaURL}>
                 <Cross className="cross" src={MEDIA.EXIT_CROSS_THIN}/>
                </CrossLink>
              </TopRight>
            </PopupBodyInner>
          </GeneralOverlayWithinContainer>
        );
      </React.Fragment>
    );
  };

  const renderForDesktop=(ISDESKTOP, DOMAIN)=>{
    return(
      <React.Fragment>
        <LeftContainerOutsidePopup ref={leftRef} className="LeftContainerOutsidePopup" onClick={()=>showProductModal(true,itemUID,-1)}>
          <LeftArrow src={MEDIA.GENERIC_ARROW}/>
        </LeftContainerOutsidePopup>
          <GeneralOverlayWithinContainer
           isDesktop={ISDESKTOP}
           className='GeneralOverlayWithinContainer'
           ref={domRef}
           >
            <PopupBodyInner isDesktop={ISDESKTOP}>
              {renderVerticalCarousel()}
              <ImageAndDescriptionContainer
               className='imageAndDescriptionContainer'
               isDesktop={ISDESKTOP}
              >
                <ImageAndSelectButtonContainer>
                  <MainImageContainer className='mainImageContainer' isDesktop={ISDESKTOP}>
                    <LoadCheck src={localImageList[activeImageIndex]??""}>
                      <MainImage/>
                    </LoadCheck>
                  </MainImageContainer>
                  {renderSelectImageButton(ISDESKTOP)}
                </ImageAndSelectButtonContainer>
                {renderDescription(ISDESKTOP, DOMAIN)}
              </ImageAndDescriptionContainer>
              <TopRight isDesktop={ISDESKTOP} className="topRight">
                <CrossLink href="#" onClick={hideModalViaURL}>
                 <Cross className="cross" src={MEDIA.EXIT_CROSS_THIN}/>
                </CrossLink>
              </TopRight>
            </PopupBodyInner>
          </GeneralOverlayWithinContainer>
          <RightContainerOutsidePopup ref={rightRef} className="RightContainerOutsidePopup" onClick={()=>showProductModal(true,itemUID,1)}>
          <RightArrow src={MEDIA.GENERIC_ARROW}/>
        </RightContainerOutsidePopup>
      </React.Fragment>
    );
  };

  return(
    <>
      {ISDESKTOP ? renderForDesktop(ISDESKTOP, DOMAIN) : renderForMobile(ISDESKTOP, DOMAIN)}
    </>
  );

};
