import { ProductDataAction } from '.';
import { AuthAction } from './models/auth.model';
import { BaseDBAction } from './models/basedb.model';
import { FooterPopupAction } from './models/footerPopup.model';
import { ModalAction } from './models/modal.model';
import { WorkingAction } from './models/working.model';

// All actions are here to avoid conflicts:
export enum ActionNames {
  UNSET_ALL = 'UNSET_ALL',
  AUTH_SET = 'AUTH_SET',
  UI_WINDOW_SIZE_SET = 'UI_WINDOW_SIZE_SET',
  // only used with qualifying "kind" property:
  BASEDB_SET_DOC = 'BASEDB_SET_DOC',
  BASEDB_SET_LISTENER_DOC = 'BASEDB_SET_LISTENER_DOC',
  BASEDB_LISTEN_DOC = 'BASEDB_LISTEN_DOC',
  BASEDB_GET_DOC = 'BASEDB_GET_DOC',
  BASEDB_SET_QUERY = 'BASEDB_SET_QUERY',
  BASEDB_SET_LISTENER_QUERY = 'BASEDB_SET_LISTENER_QUERY',
  BASEDB_LISTEN_QUERY = 'BASEDB_LISTEN_QUERY',
  BASEDB_GET_QUERY = 'BASEDB_GET_QUERY',
  BASEDB_UNLISTEN_MAP = 'BASEDB_UNLISTEN_MAP',
  BASEDB_UNLISTEN_SINGLE = 'BASEDB_UNLISTEN_SINGLE',
  // product data parsed from db:
  PRODUCT_DATA_ADD = 'PRODUCT_DATA_ADD',
  PRODUCT_DATA_REMOVE = 'PRODUCT_DATA_REMOVE',
  // footer popup:
  FOOTER_POPUP_SET = 'FOOTER_POPUP_SET',
  // modal:
  MODAL_SET = 'MODAL_SET',
  MODAL_CLOSE = 'MODAL_CLOSE',
  // working:
  WORKING_SET = 'WORKING_SET',
  WORKING_UNSET = 'WORKING_UNSET',
}

// All actions:
export interface ActionType<T, P> {
  type: T;
  payload: P;
}

type UnsetAllAction = ActionType<ActionNames.UNSET_ALL, undefined>;

// So that we can have the overall action type as the input to all redux functions.
export type Action =
  | BaseDBAction
  | AuthAction
  | UnsetAllAction
  | ProductDataAction
  | FooterPopupAction
  | ModalAction
  | WorkingAction;
