import React from 'react';
import { useSelector } from 'react-redux';

import { RootState } from 'src/index';
import {
  MoreCollectionOptionsMenuRef,
  MoreCollectionOptionsMenuContainerProps,
} from './MoreCollectionOptionsMenu.types';
import { MoreCollectionOptionsMenu } from './MoreCollectionOptionsMenu.component';

const MoreCollectionOptionsMenuContainer = React.forwardRef<
  MoreCollectionOptionsMenuRef,
  MoreCollectionOptionsMenuContainerProps
>((props, ref) => {
  // Global state
  const authUserUID = useSelector<RootState, string | null>(
    (state) => state.auth.id,
  );
  const collectionUserUID = useSelector<RootState, string | undefined>(
    ({ db }) => db.single_collection.data?.user_uid,
  );

  // Flag indicating whether the collection's user is the current user
  const isMine = authUserUID === collectionUserUID;

  return <MoreCollectionOptionsMenu ref={ref} isMine={isMine} {...props} />;
});

export { MoreCollectionOptionsMenuContainer as MoreCollectionOptionsMenu };
