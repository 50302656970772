/*

Constants that are used all over the project,

All colours are now in design system.

Any others still used should be moved to the root constants file at :
src/constants.ts

*/
export const M = {
  NAMECAPS:'MOONSIFT',
  MVP:'mvp22',
  HOMEPAGEURL:process.env.REACT_APP_HOMEPAGEURL,
  APIADDRESS:process.env.REACT_APP_MOONSIFTAPI_URL,
  TITLEFONT:'Josefin Sans, sans-serif',
  FONT:'Roboto, sans-serif',
  JUANA_FONT:'Juana, serif',
  MOBILESWITCH:922, // Pixels below which is mobile.
  LEFTPRODUCTSWITCH:500,
  COOKIEMESSAGEKEY:'cookie_message',
  COOKIEPATH:'/',
  REFERRAL_COOKIE_KEY:'referral_code',
  PRO_CODE_COOKIE_KEY:'pro_code',
  MENUSWITCH:768,
  MINWIDTH:'320px',
  MAXWIDTH:'1000px',
  COL:{
    BG:{
      BLACK:'#000000',
      MID:'#EFEFEF',
      GREY: '#F5F5F5',
      LIGHT:'#FBFBFB',
      WHITE:'#FFFFFF',
      DARKBLUE: '#140C40',
      DARKGREEN: '#1B4B2E',
      DARKPURPLE: '#540E3C',
      LOGIN: '#333333'
    },
    TEXT:{
      BLACK:'#000000',
      MID:'#707070',
      LIGHT:'#888888',
      WHITE:'#FFFFFF',
      YELLOW:'#FDCA47',
      ERROR:'#AE1A26',
      ERRORDARK:"#990000",
      GREEN:"#2A8539"
    },
    BUTTON:{
      BLACK:'#000000',
      DARKGREY:'#303030',
      MIDDARK:'#707070',
      MID:'#EFEFEF',
      LIGHT:'#888888',
      WHITE:'#FFFFFF',
      YELLOW:'#FDCA47',
      MIDYELLOW:'#FEDF91',
      LIGHTYELLOW:'#FEEAB5',
      TRANSPARENT_WHITE: '#FFFFFFBA',
      TRANSPARENT_BLACK: '#000000C2'
    },
    LINE:{
      MID:'#CECFD1',
      YELLOW:'#FDCA47'
    },
    UPDATECOLS:{
      GREEN:"#70a96f",
      ORANGE:"#ff7f2a",
      RED:"#c83737",
      GREY:"#888888"
    },
    UC:{
      FG:'#FF0000',
      BG:'#FFDDDD',
    }
  },
  HEIGHTS:{
    TOPMENU:'56px',
  }
};

/*
OO:'#000000',//0.22 Black
FF:'#FFFFFF',//0.22 White

SLY:'#FFF8D8',//0.22 Super light yellow
LY:'#FEDF91',//0.22 Light Yellow
DY:'#FDCA47',//0.22 Dark Yellow

LG:'#EFEFEF',//0.22 Light Grey
DG:'#7E7E7E',//0.22 Dark Grey
MG:'#CECFD1',//0.22 Medium Grey

ERC:"#AE1A26",//0.22 Error Colour

UDC:'#AA0000',//0.22 Unset Dark colour (maroon)
UC:'#FF0000',//0.22 Unset colour (red)
// Standard text box and buttons:
STBBORDER:'1px',//border amount
STBROUNDED:'2px',//rounded amount

TBHEIGHT:'35px', // Text box height
TBFONTSIZE:'14px', // Text box font size
FBTHEIGHT:'10px', // Feedback textbox feedback text height
FBHEIGHT:'26px', // Feedback textbox feedback height
*/
