import React, { useState, useEffect, useContext, useCallback } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import Cookies from 'universal-cookie';

import { Constants } from 'src/constants';
import { useSelector } from 'react-redux';
import { CollectionContainerProps } from './Collection.types';
import { Collection as CollectionComponent } from './Collection.component';
import { DBContext, RootState } from 'src/index';
import segmentEvent from 'src/mvp22/segment-components/SegmentEvent';
import {
  ItemToSubsectionMap,
  SubsectionInfoMap,
  CoverImageDict,
} from 'src/types/models/collection.model';
// Utils:
import sortCollection from 'src/utils/sortCollection';
import getUserUIDFromUsername from 'src/mvp22/firebase-functions/get_user_uid';
import update_collection_public_level from 'src/mvp22/firebase-functions/update_collection_public_level';
// Redux:
import { useOrganising } from 'src/hooks/useOrganising';
import { FirebaseContext, CloudContext } from 'src/index';
import { useDBRetrieveDoc } from 'src/hooks/useDBRetrieveDoc';
import { collectionParser } from 'src/store/parsers/collection';
import { collectionBookmarkParser } from 'src/store/parsers/collectionBookmark';
import { userPublicParser } from 'src/store/parsers/userPublic';
import { ModalTypes } from 'src/types';
import { useSetModal } from 'src/hooks/useSetModal';

type firebaseStateType = {
  loaded: boolean;
};

// Use the address bar to get a collection UID and also user name (could be an alias - a "displayUsername"):
const getCollectionUIDFromLocation = (
  pathname: string,
  authUserUID: string,
) => {
  var collectionUID: string;
  var username: string = authUserUID;
  try {
    collectionUID = pathname.split('/')[3];
    username = pathname.split('/')[2];
  } catch (error) {
    return undefined;
  }
  if (
    collectionUID &&
    collectionUID.length >= 1 &&
    username &&
    username.length >= 1
  ) {
    return { collectionUID, username };
  } else {
    return undefined;
  }
};

const cookies = new Cookies();

const CollectionContainer: React.FC<CollectionContainerProps> = () => {
  // STATE:
  const [collectionInfoValid, setCollectionInfoValid] = useState(false); // determines if we can make a DB query based on address bar return
  const [collectionUserUID, setCollectionUserUID] = useState('');
  const [collectionUID, setCollectionUID] = useState('');
  const [collectionPublicLevelWorking, setCollectionPublicLevelWorking] =
    useState(false);

  // REDUX:
  const [setModal] = useSetModal();
  const authUserUID = useSelector<RootState, string | null>(
    (state) => state.auth.id,
  );
  const views = useSelector<RootState, number>((state) => {
    return state.db.single_collection.data?.views ?? 0;
  });

  // TODO: Replace these lines with new user data redux on refactor:
  const firebaseState: firebaseStateType = useSelector<
    RootState,
    firebaseStateType
  >((state) => state.firebasestate);
  const firestore_user_owner = useSelector<
    RootState,
    { display_username: string; uid: string }
  >((state) => state.firestore_user_owner);
  const isPro = useSelector<RootState, boolean>((state) =>
    state.firestore_user_owner.pro ? true : false,
  );
  const authUserUIDLoaded = firebaseState.loaded;
  const authUserDisplayUsername = firestore_user_owner.display_username; // undefined if not loaded yet, otherwise authUserUID
  const firebase = useContext(FirebaseContext);
  /*

  Database fetches:
  - collection record,
  - collection liked by authuser,
  - collection user's public record

  */
  const dB = useContext(DBContext);
  // Fetch the single doc collection stuff:
  // Overload the arguments array that does the useEffect to make sure it only changes when authUserUIDLoaded!
  // (NOTE: Kinda a hack of the hook....!):
  useDBRetrieveDoc<'single_collection'>(
    dB,
    dB.collectionDoc,
    'single_collection',
    collectionParser,
    undefined,
    [collectionUserUID, collectionUID, authUserUIDLoaded],
    'listen',
  );
  const singleCollectionDocData = useSelector(
    (state: RootState) => state.db.single_collection.data,
  );
  // Note: User Public Profiles not removed on unmount to save calls to db (false last argument)
  useDBRetrieveDoc<'user_public'>(
    dB,
    dB.userPubicDoc,
    'user_public',
    userPublicParser,
    collectionUserUID,
    [collectionUserUID],
    'get',
    false,
  );
  const singleCollectionUserPublicDocData = useSelector(
    (state: RootState) => state.db.user_public.map[collectionUserUID],
  );
  // If logged in, Get the associated like information for the user viewing the collection:
  // fetch the collection like info if the collection uid or the collection user uid changes and if the auth user is logged in:
  // Also avoid liking in own collections:
  useDBRetrieveDoc<'single_collection_bookmark'>(
    dB,
    dB.collectionBookmarkDoc,
    'single_collection_bookmark',
    collectionBookmarkParser,
    undefined,
    [authUserUID, collectionUserUID, collectionUID],
    'listen',
  );

  // OTHER HOOKS:
  const history = useHistory();
  const location = useLocation();
  const cloud = useContext(CloudContext);
  // if invalid info data or no document:
  const collectionNotFound =
    (collectionInfoValid === false || singleCollectionDocData === null) &&
    authUserUIDLoaded; // NOT when undefined (when not yet set), null only when query finished.
  const publicLevel = singleCollectionDocData?.public_level;
  const loadedCollection =
    singleCollectionDocData !== null && singleCollectionDocData !== undefined; // Loaded collection doc info? (ignore product data)
  // EFFECTS:
  // Get the collectionUID + collectionUserUID from the address bar:
  // Only run if the location pathname changes, will set strings on collectionUID and collectionUserUID.
  useEffect(() => {
    // Gets the collection username (can be a displayUsername) and collection UID from the page URL:
    const collectionInfo = getCollectionUIDFromLocation(
      location.pathname,
      authUserUID ?? '',
    );
    if (collectionInfo) {
      // Now going to load a new collection...
      setCollectionInfoValid(true);
      setCollectionUID(collectionInfo.collectionUID);
      // blank the collectionuserUID as now it's changing async...
      setCollectionUserUID('');
      // Call the async function (db query) to get the userUID from any displayUsername in the address bar:
      getUserUIDFromUsername(
        collectionInfo.username,
        authUserDisplayUsername,
        firebase,
        authUserUID,
      ).then((result) => {
        setCollectionUserUID(result);
      });
    } else {
      setCollectionInfoValid(false);
    }
  }, [
    authUserUID,
    location.pathname,
    setCollectionInfoValid,
    setCollectionUID,
    setCollectionUserUID,
    firebase,
    authUserDisplayUsername,
  ]);

  // On loading the data:
  useEffect(() => {
    if (!collectionNotFound && publicLevel) {
      // If not public and not signed in then prompt to sign in / up:
      if (publicLevel === 7 && !authUserUID) {
        setModal({
          type: ModalTypes.sign_up_to_view,
        });
      }
      // record viewing a collection that exists:
      cloud
        .fastAPI({
          api: 'record_collection_view',
          collection_user_uid: collectionUserUID,
          collection_uid: collectionUID,
        })
        .catch((err) =>
          console.log(
            "apparently record collection view didn't work for some reason",
            err,
          ),
        );
      // Set cookie so we know which person first got this new user on to Moonsift by viewing their collection:
      if (!cookies.get(Constants.REFERRAL_COOKIE_KEY)) {
        cookies.set(Constants.REFERRAL_COOKIE_KEY, collectionUserUID, {
          maxAge: 60 * 60 * 24 * 30,
          path: Constants.COOKIEPATH,
        });
      }
      // Stats reporting:
      authUserUID === collectionUserUID
        ? segmentEvent('Viewed Own Collection')
        : segmentEvent("Viewed Another User's Collection");
    }
  }, [
    authUserUID,
    cloud,
    collectionUID,
    collectionUserUID,
    firebase,
    collectionNotFound,
    publicLevel,
    setModal,
  ]);

  // ACTIONS:
  const setPublicLevel = (newLevel: number) => {
    if (
      loadedCollection &&
      collectionPublicLevelWorking !== true &&
      collectionUID &&
      authUserUID
    ) {
      setCollectionPublicLevelWorking(true);
      // Call async db function:
      update_collection_public_level(
        collectionUID,
        newLevel,
        firebase,
        authUserUID,
      ).then(() => setCollectionPublicLevelWorking(false));
    }
  };

  // Go back button handler
  const handleBack = useCallback(() => {
    history.goBack();
  }, [history]);

  // ORGANISING:
  // for the ordering:
  const [originalOrderList, setOriginalOrderList] = useState<string[]>([]);
  // Local copies of collection data to enable oranising to update them without flashing up other values and making it look like the re-organisation did not save!
  // Alternative could perhaps be to blank out products until DB update received.
  // Also sets defaults if non yet!
  const [orderArray, setOrderArray] = useState<string[]>([]);
  const [subsectionOrder, setSubsectionOrder] = useState<number[]>([]);
  const [itemToSubsectionMap, setItemToSubsectionMap] =
    useState<ItemToSubsectionMap>({});
  const [subsectionInfo, setSubsectionInfo] = useState<SubsectionInfoMap>({});
  const [coverImageDict, setCoverImageDict] = useState<CoverImageDict>({});
  const [coverImageURL, setCoverImageURL] = useState<string | null>(null);
  // Now map these into local copies such that we can handle organising etc. nicely (no jumping around when saving ordering mainly)
  useEffect(() => {
    if (singleCollectionDocData) {
      setOrderArray(singleCollectionDocData.order_array ?? []);
      setSubsectionOrder(singleCollectionDocData.subsection_order ?? []);
      setItemToSubsectionMap(
        singleCollectionDocData.item_to_subsection_map ?? {},
      );
      setSubsectionInfo(singleCollectionDocData.subsection_info ?? {});
      setCoverImageDict(singleCollectionDocData.cover_image_dict ?? {});
      setCoverImageURL(singleCollectionDocData.cover_image_url ?? null);
    }
  }, [singleCollectionDocData]);

  // Invoke a hook that stores the functions for organising!
  // TODO?: move organising flag to redux?
  const organisingHook = useOrganising({
    collectionUID,
    authUserUID, // same as collectionUserUID as can only organise own collection.
    originalOrderList,
    setOrderArray,
    setItemToSubsectionMap,
    setSubsectionInfo,
    setSubsectionOrder,
    setCoverImageDict,
    setCoverImageURL,
    orderArray,
    itemToSubsectionMap,
    subsectionInfo,
    subsectionOrder,
    coverImageDict,
    coverImageURL,
  });

  const isOrganising = organisingHook.isOrganising;
  const setOrganisingOrder = organisingHook.setOrganisingOrder;

  const singleCollectionItemListData = useSelector(
    (state: RootState) => state.db.single_collection_item_list.data,
  );
  // effect on updates from db of order of items in collection:
  useEffect(() => {
    // I.e. the order by last added....
    const newOriginalOrder = singleCollectionItemListData
      ? singleCollectionItemListData.map((collectionItem) => collectionItem.id)
      : [];
    setOriginalOrderList(newOriginalOrder);
    // if organising at this point, we still want to add / remove items to the order so add them here:
    setOrganisingOrder((prevState) =>
      isOrganising ? sortCollection(newOriginalOrder, prevState) : prevState,
    );
  }, [isOrganising, setOrganisingOrder, singleCollectionItemListData]);

  /**
   * This forces a default browser alert informing the user will lose the
   * current changes if they leave the collection detail page when organising by
   * manually updating the browser URL.
   * There is a react-router Prompt that will inform of the same when the user leaves
   * the collection detail page by navigating using react-router links
   */
  useEffect(() => {
    if (isOrganising) {
      window.onbeforeunload = () => true;
    } else {
      window.onbeforeunload = () => null;
    }
    /**
     * This cleanup function will stop this prompt showing in the case the user has
     * received and ok-ed the prompt. Before having this cleanup function we were
     * leaving `window.onbeforeunload` as `() => true` and that made this prompt
     * reappear every time the user navigated to other URLs manually, even after
     * leaving the collection detail page.
     */
    return () => {
      window.onbeforeunload = () => null;
    };
  }, [isOrganising]);

  // Flag indicating whether the collection's user is the current user
  const isMine = authUserUID === collectionUserUID;

  // Only used to reuse the old SharePopup
  const displayUsername =
    singleCollectionUserPublicDocData &&
    singleCollectionUserPublicDocData.display_username
      ? singleCollectionUserPublicDocData.display_username
      : collectionUserUID;
  return (
    <CollectionComponent
      collectionName={singleCollectionDocData?.name ?? ''}
      collectionDescription={singleCollectionDocData?.description}
      onBack={handleBack}
      notFound={collectionNotFound}
      isLoading={!loadedCollection}
      collectionUID={collectionUID}
      collectionUserUID={collectionUserUID}
      isMine={isMine}
      views={views}
      // Organising mode controlled from this level:
      organisingHook={organisingHook}
      // Reuse of the old share button
      isPro={isPro}
      singleCollectionDocData={singleCollectionDocData}
      displayUsername={displayUsername}
      setPublicLevel={setPublicLevel}
      collectionPublicLevelWorking={collectionPublicLevelWorking}
    />
  );
};

export { CollectionContainer as Collection };
