/*

A comment Tile displays in the product modal a comment from a user about the product.

TODO: Split into container / component, typescript, export not as default, camelcase, remove withFirebase

*/
import React, {useContext} from 'react';
import { useSelector } from 'react-redux';
import { DBContext } from 'src/index';
//import { Component } from 'react';
import styled, {css} from 'styled-components';
import { useDBRetrieveDoc } from 'src/hooks/useDBRetrieveDoc';
import {M} from 'src/mvp22/constants';
import {withFirebase} from 'src/mvp22/Firebase';
import ProfileImage from 'src/mvp22/image-components/ProfileImage';
import WorkingDots from 'src/mvp22/image-components/WorkingDots';
import { userPublicParser } from 'src/store/parsers/userPublic';
//import R from 'src/routes';
import MEDIA from 'src/mvp22/media';
import {TEXTSTYLE} from 'src/mvp22/style-components/Text';
import {notification_in_past} from 'src/mvp22/core-components/date_time_functions';

const CommentContainer = styled.div`
  width:100%;
  display:flex;
  margin:24px 0px;
`;

const ProfileImageContainer = styled(TEXTSTYLE.LINK)`
  display:flex;
  flex-shrink: 0;
  margin-right:16px;
`;

const MiddleInfoContainer = styled.div`
  flex-shrink:1;
  width:100%;
  overflow:hidden;
`;

const UserInfoBarGrabADrink = styled.div`
  display:flex;
`;

const UserFullNameLink = styled(TEXTSTYLE.LINK)`
color:default;
`;

const UserFullName = styled(TEXTSTYLE.OTHER2)`
  margin-right:8px;
`;

const TimeSince = styled(TEXTSTYLE.BODY4)`
  color:${M.COL.TEXT.MID};
`;

const CommentDescriptionContainer = styled.div`
  margin-top:6px;
  display:flex;
  flex-direction:row;
  align-items:center;
`;

const CommentDescriptionText = styled(TEXTSTYLE.BODY4)`
  color:${M.COL.TEXT.BLACK};
`;

const CommentDesciptionImage = styled.img`
  margin-right:4px;
  height:17px;
`;

const BinButtonCSS = css`
  width:21px;
  height:21px;
  &:hover{
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  }
  display:flex;
  align-items:center;
  justify-content:center;
  align-content:center;
  border-radius:15px;
`;

const BinButton = styled(TEXTSTYLE.LINKA)`
  ${BinButtonCSS}
`;

const BinButtonWorking = styled(WorkingDots)`
  ${BinButtonCSS}
  background-repeat:no-repeat;
  background-position:center;
`;

const BinButtonImage = styled.img`
  height:13px;
`;

const RightContainer = styled.div`
  width:22px;
`;

const CommentDescription = (props)=>{
  switch(props.type){
    case "comment":
      return (
        <CommentDescriptionContainer>
          <CommentDescriptionText>
            {props.comment}
          </CommentDescriptionText>
        </CommentDescriptionContainer>
      );
    case "upvote":
      return (
        <CommentDescriptionContainer>
          <CommentDesciptionImage src={MEDIA.BUTTON_UPVOTE}/>
          <CommentDescriptionText>
            Liked item
          </CommentDescriptionText>
        </CommentDescriptionContainer>
      );
    case "downvote":
      return (
        <CommentDescriptionContainer>
          <CommentDesciptionImage src={MEDIA.BUTTON_DOWNVOTE}/>
          <CommentDescriptionText>
            Downvoted item
          </CommentDescriptionText>
        </CommentDescriptionContainer>
      );
    default:
      return(
        <CommentDescriptionContainer>
          <CommentDescriptionText>
          Error: Unsupported comment type!
          </CommentDescriptionText>
        </CommentDescriptionContainer>
      );
  }
};

const CommentTile = withFirebase((
  {
    data,
    time_now,
    collectionUserUID,
    deleteComment,
    workingDelete
  }
)=>{
  const TIME_SINCE = notification_in_past(
    time_now,
    data.created_at
  );
  const authUserUID = useSelector(state=>state.auth.id);
  const CAN_DELETE = (data.type==="comment" && (data.user_uid===authUserUID || collectionUserUID===authUserUID));
  const USER_UID = data.user_uid;
  // Effects:
  const dB = useContext(DBContext);
  // Ensure we have fetched the auth user public profile:
  // User Public Profiles not removed on unmount!
 //todo: ADD TYPESCRIPT "user_public" type!
  useDBRetrieveDoc(dB, dB.userPubicDoc, 'user_public', userPublicParser,USER_UID,[USER_UID],"get",false);
  const userPublicProfile = useSelector((state) => state.db.user_public.map[USER_UID]);
  const PROFILE_IMAGE_CODE = (userPublicProfile)?userPublicProfile.profile_image_code:undefined;
  const PROFILE_NAME = (userPublicProfile)?userPublicProfile.name:"";
  const PROFILE_DISPLAY_USERNAME = (userPublicProfile)?userPublicProfile.display_username:USER_UID;

  return(
    <CommentContainer>
      <ProfileImageContainer target="_blank" to={"/@"+PROFILE_DISPLAY_USERNAME}>
        <ProfileImage
          profile_image_code={PROFILE_IMAGE_CODE}
          name={PROFILE_NAME}
          user_uid={USER_UID}
          scale={27}
        />
      </ProfileImageContainer>
      <MiddleInfoContainer>
        <UserInfoBarGrabADrink>
          <UserFullNameLink target="_blank" to={"/@"+PROFILE_DISPLAY_USERNAME}>
            <UserFullName>
              {
                PROFILE_NAME
              }
            </UserFullName>
          </UserFullNameLink>
          <TimeSince>
            {
              TIME_SINCE
            }
          </TimeSince>
        </UserInfoBarGrabADrink>
          <CommentDescription
            type={data.type}
            comment={data.comment}
          />
      </MiddleInfoContainer>
      <RightContainer>
        {
          CAN_DELETE?
          workingDelete?
            <BinButtonWorking/>
          :
              <BinButton href="#" onClick={(event)=>deleteComment(event,data.uid)}>
                <BinButtonImage
                  src={MEDIA.SMALL_BIN}
                />
              </BinButton>
          :
            null
        }
      </RightContainer>
    </CommentContainer>
  );
});

export default CommentTile;
