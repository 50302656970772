import { ActionNames } from 'src/types/actions';
import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { ModalAction, ModalState } from 'src/types';

// Helper functions that set the modal in redux or close it.
export const useSetModal = (): [
  (payload: ModalState) => void,
  (event?: React.MouseEvent) => void,
] => {
  const dispatch = useDispatch();
  const setModal = useCallback(
    (payload: ModalState) => {
      dispatch<ModalAction>({
        type: ActionNames.MODAL_SET,
        payload,
      });
    },
    [dispatch],
  );
  const closeModal = useCallback(
    (event: React.MouseEvent | undefined = undefined) => {
      if (event) {
        event.preventDefault();
      }
      dispatch<ModalAction>({
        type: ActionNames.MODAL_CLOSE,
        payload: undefined,
      });
      return false;
    },
    [dispatch],
  );
  return [setModal, closeModal];
};
