/*

This page is used by pro users to get their pro code to share to others OR if a user is not pro tell them how to get it.

TODO: typescript, camelCase, make functional, non-default exports, redux hooks, design system, split container / component ...

*/
import React from 'react';
import { Component } from 'react';
import {AddHelmetTitle} from "src/mvp22/core-components/helmet";
import LoadingDisplay from 'src/mvp22/image-components/LoadingDisplay';
import {requiresAuth} from 'src/mvp22/redux-components/requiresAuth';
import R, {EXTERNAL} from 'src/routes';
import styled from 'styled-components';
import {M} from 'src/mvp22/constants';
import MEDIA from 'src/mvp22/media';
import {withFirebase} from 'src/mvp22/Firebase';
import TopMenu from 'src/mvp22/menu-components/TopMenu';
import {TEXTSTYLE} from 'src/mvp22/style-components/Text';
import { connect } from 'react-redux';
import Button from 'src/mvp22/form-components/Button';
import CopyURL from 'src/mvp22/core-components/CopyURL';
import windowSize from 'src/mvp22/WindowSize';

const Container = styled.div`
  background-color:${M.COL.BG.WHITE};
  width:100%;
  display:flex;
  flex-direction: column;
  height: 100vh;
`;

const MainContent = styled.div`
  display:flex;
  background-color:${M.COL.BG.WHITE};
  color:${M.COL.TEXT.BLACK};
  width:100%;
  justify-content: center;
  align-items: center;
  margin-top:160px;
`;

const Seperator = styled.div`
  height:${props=>props.height};
`;

const CenteredBox = styled.div`
  display: flex;
  max-width: 409px;
  flex-direction: column;
  text-align: left;
  padding-left: 15px;
  padding-right: 15px;
`;

const ProBadge = styled.img`
  width: 24px;
  height: 24px;
  position: relative;
  bottom: 9px;
  margin-left: 8px;
`;

const LogoAndProBadge = styled.div`
  display: flex;
`;

const SubtitleContainer = styled.div`
  display: flex;
`;

const BodyText = styled(TEXTSTYLE.BODY2)`
  display: flex;
  flex-direction: column;
  text-align: left;
  font-size: ${props => props.isDesktop === true ? '20px' : '16px'};
  line-height: ${props => props.isDesktop === true ? '30px' : '24px'};
`;

const BottomLink = styled(TEXTSTYLE.LINKA)`
  display: flex;
  border-bottom: 1px solid;
  align-self: flex-start;
`;

const LinkBoxContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
`;

const BottomBox = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const BackButtonContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
`;

const MoonsiftLogoText = styled(TEXTSTYLE.BRANDED1)`
  font-size: ${props => props.isDesktop === true  ? '42px' : '34px'};
  line-height: ${props => props.isDesktop === true  ? '50px' : '34px'};
`;

const SubtitleText = styled(TEXTSTYLE.BODY1)`
  font-size: ${props => props.isDesktop === true  ? '26px' : '20px'};
  line-height: ${props => props.isDesktop === true  ? '38px' : '30px'};
`;

const BottomLinkText = styled(TEXTSTYLE.BODY2)`
  font-size: ${props => props.isDesktop === true  ? '20px' : '16px'};
  line-height: ${props => props.isDesktop === true  ? '30px' : '24px'};
`;

class ProShare extends Component {

  constructor(props){
    super(props);
    this.generateMyProPass = this.generateMyProPass.bind(this);
    this.goBack = this.goBack.bind(this);
    this.state = {
      has_clicked_generate_pro_pass: false
    };
  }

  goBack(event){
    this.props.history.goBack();
    event.preventDefault();
    return false;
  }

  generateMyProPass(){
    this.setState({
      has_clicked_generate_pro_pass: true
    });
  }

  generateLink(){
    return(
      <TEXTSTYLE.BODY2>Your pro link is:<br/>
        {(process.env.REACT_APP_HOMEPAGEURL+R.PRO_LANDING+"/"+this.props.owner.pro_data.pro_code)} and you have {this.props.owner.pro_data.pro_invites_remaining} invites left
      </TEXTSTYLE.BODY2>
    );
  }

  renderProPassShareBox(){
    return(
      <BottomBox>
        <CopyURL copyText={(process.env.REACT_APP_HOMEPAGEURL+R.PRO_LANDING+"/"+this.props.owner.pro_data.pro_code)}/>
      </BottomBox>
    );
  }

  renderButtonOrLinkBox(){
    if(this.state.has_clicked_generate_pro_pass===true){
      return(
        <LinkBoxContainer>
          <TEXTSTYLE.HEADING5>Pro Pass ({this.props.owner.pro_data.pro_invites_remaining} available)</TEXTSTYLE.HEADING5>
          <Seperator height='3px'/>
          <TEXTSTYLE.BODY4>Copy and share your Pro Pass link </TEXTSTYLE.BODY4>
          <Seperator height='10px'/>
          {this.renderProPassShareBox()}
        </LinkBoxContainer>
      );
    } else {
      return(
        <Button
          text='Generate my Pro Pass'
          msstyle="cta_alternative_post_signup"
          needsMaxWidth={false}
          onClick={this.generateMyProPass}
        />
      );
    }
  }

  renderMessage(ISDESKTOP){
    if(this.props.owner.pro!==undefined){
      if(this.props.owner.pro===true){
        return(
          <CenteredBox className='centeredBox'>
            <LogoAndProBadge>
              <MoonsiftLogoText isDesktop={ISDESKTOP}>
                MOONSIFT PRO
              </MoonsiftLogoText>
              <ProBadge src={MEDIA.PRO_BADGE}/>
            </LogoAndProBadge>
            <SubtitleContainer>
              <SubtitleText isDesktop={ISDESKTOP}>Moonsift Pro is currently <b>invite only</b></SubtitleText>
            </SubtitleContainer>
            <Seperator height='18px'/>
            <BodyText isDesktop={ISDESKTOP}>
                You can invite people to get Moonsift Pro by sharing your unique ‘Pro Pass’ link
            </BodyText>
            <Seperator height={ISDESKTOP===true ? '26px' : '18px'}/>
            {this.renderButtonOrLinkBox()}
            <Seperator height={ISDESKTOP===true ? '26px' : '18px'}/>
            <BottomLink href={EXTERNAL.PRO_EXPLANATION} target="_blank">
              <TEXTSTYLE.BODY4><b>Advantages of Pro</b></TEXTSTYLE.BODY4>
            </BottomLink>
            <Seperator height={ISDESKTOP===true ? '26px' : '18px'}/>
            <BackButtonContainer>
              <Button
                text="Back"
                msstyle="option_positive"
                width="123px"
                onClick={this.goBack}
              />
            </BackButtonContainer>
          </CenteredBox>
        );
      } else {
        return(
          <CenteredBox className='centeredBox'>
            <LogoAndProBadge>
              <MoonsiftLogoText isDesktop={ISDESKTOP}>
                MOONSIFT PRO
              </MoonsiftLogoText>
              <ProBadge src={MEDIA.PRO_BADGE}/>
            </LogoAndProBadge>
            <SubtitleContainer>
              <SubtitleText isDesktop={ISDESKTOP}>Moonsift Pro is currently <b>invite only</b></SubtitleText>
            </SubtitleContainer>
            <Seperator height='18px'/>
            <BodyText isDesktop={ISDESKTOP}>
                Moonsift Pro includes additional features designed to help curators, stylists, bloggers and people using Moonsift in their work.
            </BodyText>
            <Seperator height='18px'/>
            <BodyText isDesktop={ISDESKTOP}>
                If you feel you could benefit from Moonsift Pro’s features, please ask someone with a Pro badge to share their ‘Pro Pass’ with you, or email hello@moonsift.com.
            </BodyText>
            <Seperator height={ISDESKTOP===true ? '26px' : '18px'}/>
            <BottomLink to='#'>
              <BottomLinkText isDesktop={ISDESKTOP}>Learn more about Moonsift Pro</BottomLinkText>
            </BottomLink>
            <Seperator height={ISDESKTOP===true ? '26px' : '18px'}/>
            <BackButtonContainer>
              <Button
                text="Back"
                msstyle="option_positive"
                width="123px"
                tabIndex={3}
                onClick={this.goBack}
              />
            </BackButtonContainer>
          </CenteredBox>
        );
      }
    } else {
      return(
        <LoadingDisplay message = "" />
      );
    }
  }

  render(){
    const ISDESKTOP = this.props.windowWidth>=M.MOBILESWITCH;
    return(
      <Container>
        <TopMenu/>
        {AddHelmetTitle("Pro")}
        <MainContent className='mainContent'>
          {this.renderMessage(ISDESKTOP)}
        </MainContent>
      </Container>
    );
  }
}

const mapStateToProps = (state) => {
  return ({
    owner:state.firestore_user_owner
  });
};

const mapDispatchToProps = () => {
  return {};
};

export default connect(mapStateToProps,mapDispatchToProps)(
  requiresAuth(
    withFirebase(
      windowSize(
        ProShare
      )
    )
  )
);
