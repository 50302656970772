/*

Just generates a conistent "StoreAs" value for accessing and retrieving documents
in the redux store relating to a single item in a collection.

*/
export const getCollectionItemStoreAs = (
  collectionUID: string,
  collectionUserUID: string,
  itemUID: string,
) => {
  return collectionUID + '__' + collectionUserUID + '__' + itemUID;
};
