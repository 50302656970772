import { ActionNames, ActionType } from '..';
import { CollectionItem } from './collectionItem.model';
/*

Non-db local redux storage of modal open and data for it to be open.

Note how some are in groups which can be switched between so might require data for all modals in the group when opened!

*/
export enum ModalTypes {
  new_collection = 'new_collection',
  delete_collection = 'delete_collection',
  product_does_not_exist = 'product_does_not_exist',
  collection_membership = 'collection_membership',
  collection_settings = 'collection_settings',
  new_collection_product = 'new_collection_product',
  product_updates = 'product_updates',
  product_settings = 'product_settings',
  sign_up_to_view = 'sign_up_to_view',
}

//
// New collection:
type ModalNewCollection = {
  type: ModalTypes.new_collection;
  dontRedirect?: boolean;
};

//
// Collection Settings Group (can switch between!)
type ModalCollectionPropertiesBase = {
  collectionName: string;
  collectionUID: string;
  collectionDescription: string;
};

type ModalEditCollection = {
  type: ModalTypes.collection_settings;
} & ModalCollectionPropertiesBase;

type ModalDeleteCollection = {
  type: ModalTypes.delete_collection;
} & ModalCollectionPropertiesBase;

//
// Product-Collection Membership Group (can switch between!)
type ModalProductCollectionMembershipBase = {
  collectionUID: string;
  collectionUserUID: string;
  collectionItem: CollectionItem;
  productImage: string;
};

type ModalNewCollectionProductCollectionMembership = {
  type: ModalTypes.new_collection_product;
} & ModalProductCollectionMembershipBase;

type ModalProductCollectionMembership = {
  type: ModalTypes.collection_membership;
} & ModalProductCollectionMembershipBase;

//
// Product does not exist in collection
type ModalProductDoesNotExist = {
  type: ModalTypes.product_does_not_exist;
  closeProductModal: () => void;
};

//
// Product price updates
type ModalProductUpdates = {
  type: ModalTypes.product_updates;
  collectionUID: string;
  collectionUserUID: string;
  itemUID: string;
  productImage: string;
};

//
// Product overview
type ModalProductSettings = {
  type: ModalTypes.product_settings;
  collectionUID: string;
  collectionUserUID: string;
  itemUID: string;
  showProductModal: (
    display: boolean,
    thisItemID: string | null,
    increment: number,
  ) => void;
  setProductModalViewed: (
    productUID: string,
    collectionUID: string,
    collectionUserUID: string,
  ) => void;
};

//
// Sign up prompt ("Jeremy popup")
type ModalSignUpToView = {
  type: ModalTypes.sign_up_to_view;
};

//
// No modal shown.
type ModalNull = {
  type: null;
};

export type ModalState =
  | ModalNull
  | ModalNewCollection
  | ModalEditCollection
  | ModalDeleteCollection
  | ModalNewCollectionProductCollectionMembership
  | ModalProductCollectionMembership
  | ModalProductDoesNotExist
  | ModalProductUpdates
  | ModalProductSettings
  | ModalSignUpToView;

export type ModalAction =
  | ActionType<ActionNames.MODAL_SET, ModalState>
  | ActionType<ActionNames.MODAL_CLOSE, undefined>;
