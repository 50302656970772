/*

useDBUpdateDoc

*/
import send_error from 'src/mvp22/core-components/send_error';
export default function update_collection_order(
  collection_uid,
  order_array,
  subsection_order,
  subsection_info,
  item_to_subsection_map,
  cover_image_dict,
  cover_image_url,
  firebase,
  auth_user_uid
){
  return new Promise(
    (resolve)=>{
      const new_data =
      {
        order_array,
        subsection_order,
        subsection_info,
        item_to_subsection_map,
        cover_image_dict,
        last_updated:firebase.serverTimestamp(),
        cover_image_url:cover_image_url?cover_image_url:firebase.deleteField()
      };
      firebase.user_collection(
        auth_user_uid,
        collection_uid
      ).update(
        new_data
      ).then(
        resolve(true)
      ).catch(
        (error_out)=>{
          send_error(error_out);
          resolve(false);
        }
      );
    }
  );
}
