import React from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { RootState, DBContext, CloudContext } from 'src/index';
import R from 'src/routes';
import { useDBUpdateDoc } from 'src/hooks/useDBUpdateDoc';
import {
  EditModalContentContainerProps,
  EditModalContentProps,
  HandleEdit,
} from './EditModalContent.types';
import { EditModalContent } from './EditModalContent.component';
import { EditDrawerContent } from './EditDrawerContent.component';

const editModalContentContainerFactory = (
  Component: React.FC<EditModalContentProps>,
) => {
  const EditModalContentContainer: React.FC<EditModalContentContainerProps> = ({
    onClose,
    ...otherProps
  }) => {
    const history = useHistory();

    const db = React.useContext(DBContext);
    const cloud = React.useContext(CloudContext);

    const isPro = useSelector<RootState, boolean>((state) =>
      state.firestore_user_owner.pro ? true : false,
    );
    const collection = useSelector(
      (state: RootState) => state.db.single_collection.data,
    );
    const collectionUID = collection?.id ?? '';
    const collectionUserUID = collection?.user_uid ?? '';
    const collectionName = collection?.name ?? '';
    const collectionDescription = collection?.description ?? '';
    const [updateCollection, working] = useDBUpdateDoc(
      db,
      db.collectionDoc(collectionUserUID, collectionUID),
    );

    const handleEdit = React.useCallback<HandleEdit>(
      (event) => {
        event.preventDefault();

        const name = event.currentTarget.collectionName.value;
        const description = event.currentTarget.collectionDescription?.value;

        const update: { name: string; description?: string } = { name };

        if (description !== undefined) {
          update.description = description;
        }

        updateCollection(update);
        onClose(event);
      },
      [onClose, updateCollection],
    );

    const handleDelete = React.useCallback<EditModalContentProps['onDelete']>(
      (event) => {
        event.preventDefault();

        cloud
          .fastAPI({
            api: 'delete_collection',
            collection_uid: collectionUID,
          })
          .then(() => {
            history.push(R.HOME);
          })
          .catch((error) => {
            console.error("Couldn't remove collection", error);
          });
      },
      [cloud, collectionUID, history],
    );

    // Confirmation state and toggle:
    const [showConfirmation, setShowConfirmation] = React.useState(false);

    const toggleConfirmation = React.useCallback<
      EditModalContentProps['toggleConfirmation']
    >(() => {
      setShowConfirmation((prevShowConfirmation) => !prevShowConfirmation);
    }, []);

    return (
      <Component
        name={collectionName}
        description={collectionDescription}
        working={working}
        isPro={isPro}
        showConfirmation={showConfirmation}
        onEdit={handleEdit}
        onDelete={handleDelete}
        onClose={onClose}
        toggleConfirmation={toggleConfirmation}
        {...otherProps}
      />
    );
  };

  return EditModalContentContainer;
};

const EditModalContentContainer =
  editModalContentContainerFactory(EditModalContent);

const EditDrawerContentContainer =
  editModalContentContainerFactory(EditDrawerContent);

export {
  EditModalContentContainer as EditModalContent,
  EditDrawerContentContainer as EditDrawerContent,
};
