/*

The menu across the top of the site.

TODO: typescript, camelCase, functional


*/
import React from 'react';
import { Component } from 'react';
import {M} from 'src/mvp22/constants';
import R, {EXTERNAL} from 'src/routes';
import styled from 'styled-components';
import {TEXTSTYLE} from 'src/mvp22/style-components/Text';
import LogoAndText from 'src/mvp22/image-components/LogoAndText';
import windowSize from 'src/mvp22/WindowSize';
import MEDIA from 'src/mvp22/media';
import Button from 'src/mvp22/form-components/Button';
import BurgerMenu from './BurgerMenu';
import NotificationMenu from './NotificationMenu';
import { connect } from 'react-redux';
import segmentEvent from "src/mvp22/segment-components/SegmentEvent";
/*
Menubar: height in wide mode is 56, in narrow mode is 42
*/

const ContainerDiv = styled.div`
  width:100%;

  *, *::before, *::after {
    box-sizing: initial;
  }
`;

const MenuBar = styled.div`
  height:${M.HEIGHTS.TOPMENU};
  width:100%;
  position:fixed;
  background-color:${M.COL.BG.WHITE};
  color:${M.COL.TEXT.BLACK};
  min-width:${M.MINWIDTH};
  display:flex;
  flex-direction:row;
  align-items:center;
  justify-content: center;
  z-index:100;
`;

const FlexInner = styled.div`
  z-index:1000;
  display:flex;
  justify-content:space-between;
  width:100%;
  max-width:${props=>(props.hug==="edge")?"default":"1200px"};
`;

const MenuBlock = styled.div`
  padding: 0px 16px;
  white-space: nowrap;
  display:flex;
  flex-direction:row;
  align-items:center;
`;

const InlineBody = styled(TEXTSTYLE.BODY4)`
  display:inline-block;
  display:flex;
  flex-direction:row;
  align-items:center;
  height:40px;
  color:${props=>(props.selected)?M.COL.TEXT.BLACK:M.COL.TEXT.MID};
  font-weight:bold;
  width:${props=>props.width};
  &:hover{
    text-decoration:underline;
    color:${M.COL.TEXT.BLACK};
  }
`;

const InlineBodyNoUnderline = styled(InlineBody)`
  &:hover{
    text-decoration:none;
  }
`;

const SepBlock = styled(TEXTSTYLE.BODY3)`
  margin-left:26px;
  margin-right:16px;
  display:inline-block;
  color:${M.COL.LINE.MID};
  font-size:20px;
`;

const SepBlock2 = styled(SepBlock)`
  margin-right:26px;
`;


export const FullBlank = styled.div`
  background-color:${M.COL.BUTTON.WHITE};
  width:100%;
  height:100%;
  position:absolute;
  top:0px;
  left:0px;
  box-sizing:border-box;
`;

export const BurgerRight = styled.div`
  padding:10px;
  background-image:url(${props=>(props.open===true)?MEDIA.EXIT_CROSS:MEDIA.HAMBURGER});
  background-size:18px 18px;
  background-position:50% 50%;
  background-repeat:no-repeat;
  height:18px;
  width:18px;
  &:hover{
    background-image:url(${props=>(props.open===true)?MEDIA.EXIT_CROSS_YELLOW:MEDIA.HAMBURGER_YELLOW});
  }
`;

const BurgerTabbable = styled(TEXTSTYLE.LINKA)`
  &:focus ${BurgerRight}{
    background-image:url(${props=>(props.open===true)?MEDIA.EXIT_CROSS_YELLOW:MEDIA.HAMBURGER_YELLOW});
  }
  position:relative;
`;


export const MenuPopup = styled.div`
  overflow-y:auto;
  max-height:100%;
  display:flex;
  flex-direction:column;
  background-color:${M.COL.BG.WHITE};
  position:fixed;
  top:50px;
  right:${props=>(props.hug==="edge")?"0px":props.inner_pos+"px"};
  padding: 0px 16px;
  z-index:10001;
  color:${M.COL.TEXT.BLACK};
`;

const FlexLink = styled(TEXTSTYLE.LINK)`
flex-direction:row;
display:flex;
align-items:center;
&:hover {
  text-decoration:underline;
}
`;

const MarginLeft = styled.div`
  margin-left:26px;
  flex-direction:row;
  display:flex;
  align-items:center;
`;

const NotificationBellContainer = styled(TEXTSTYLE.LINKA)`
  position:relative;
  margin-left:26px;
  cursor:pointer;

  line-height: 0;
`;

const NotificationBell = styled.img`
  height:19px;
  opacity:${props=>(props.open)?"1.0":"0.7"};
  &:hover{
    opacity:1.0;
  }
`;

export const NotificationIndicatorX = styled(TEXTSTYLE.OTHER6)`
  position:absolute;
  z-index:10;
  font-weight:bold;
  border-radius:20px;
  background-color:${M.COL.BUTTON.LIGHTYELLOW};
  border: 1px solid ${M.COL.BUTTON.YELLOW};
  padding: 0px 8px;
  top: -10px;
  left: 6px;
`;

const NotificationIndicatorBurger = styled(NotificationIndicatorX)`
  top: 0px;
  left: 18px;
`;

// Leave this in case we need it in the future if not used atm:
export const NewItem = styled(TEXTSTYLE.BODY5)`
  font-weight:bold;
  margin-left:6px;
  text-shadow: 1px 0px 7px ${M.COL.TEXT.YELLOW},  0px 1px 7px ${M.COL.TEXT.YELLOW},  -1px 0px 7px ${M.COL.TEXT.YELLOW}, 0px -1px 7px ${M.COL.TEXT.YELLOW};
`;

/**
* Main Viewer's Top Menu
*/
class TopMenu extends Component {
  constructor(){
    super();
    this.burger_toggle = this.burger_toggle.bind(this);
    this.notification_toggle = this.notification_toggle.bind(this);
    this.state={
      burger_open:false,
      notification_open:false
    };
    this.domBurgerRef = React.createRef();
    this.domBellRef = React.createRef();
  }

  burger_toggle(event){
    this.setState(
      prevState=>{
        return ({burger_open:!(prevState.burger_open)});
      }
    );
    if (event){
      event.preventDefault();
    }
    return false;
  }

  notification_toggle(event){
    this.setState(
      prevState=>{
        return ({notification_open:!(prevState.notification_open)});
      }
    );
    if (event){
      event.preventDefault();
    }
    return false;
  }

  componentDidMount(){
    this.checkBroswerAndIfExtensionInstalled();
  }

  // TODO: Should we check if they're on chrome or show the button regardless?
  // TODO: Replace with the useHasExtension hook
  checkBroswerAndIfExtensionInstalled(){
    let extension_installed = document.getElementById('ms_extension');
    this.setState({
      extension_installed: !!extension_installed
    });
  }

  get_moonsift_button(){
    if (this.props.windowWidth>M.MENUSWITCH){
      if (this.props.authUserUID === null){
        return (
          <MarginLeft>
            <TEXTSTYLE.LINK to={R.SIGNUP}>
            <Button
             msstyle="cta3rd"
             text= {
               [
                "Get Moonsift",
                <div key="gmbutex" style={{fontWeight:200}}>&nbsp;{`- it's free`}</div>
               ]
             }
            />
            </TEXTSTYLE.LINK>
          </MarginLeft>
        );
      }else if (this.state.extension_installed === false){ // LOGGED IN AND THEY DONT YET HAVE THE EXTENSION
        return (
          <MarginLeft>
            <TEXTSTYLE.LINKA href={EXTERNAL.CHROMESTORE} target="_blank" onClick={() => {segmentEvent("Clicked Get The Save Button on Toolbar");}}>
              <Button
               msstyle="cta3rd"
               text="Get the save button"
              />
            </TEXTSTYLE.LINKA>
          </MarginLeft>
        );
        }else{
        return (
          <MarginLeft>
            <FlexLink to={R.EXPLORE_FEATURED}>
            <InlineBody selected={(this.props.selected==="EXPLORE")?true:false}>
              Explore
            </InlineBody>
            </FlexLink>
          </MarginLeft>
        );
      }
    }
  }

  my_collections_link(){
    return (
      (this.props.authUserUID === null)?
        <TEXTSTYLE.LINK to={R.SIGNIN}>
          <InlineBody>
            {
              (this.props.firebaseState.loaded === true)?
               "Login"
              :
               "Loading..."
             }
           </InlineBody>
          </TEXTSTYLE.LINK>
      :
        <TEXTSTYLE.LINK to={R.HOME}>
          <InlineBody selected={(this.props.selected===R.HOME)?true:false}>
           My Collections
          </InlineBody>
        </TEXTSTYLE.LINK>
    );
  }

  curators_and_stylists(){
    if (this.props.windowWidth>500){
      return (
        (this.props.authUserUID === null)?
            <InlineBody selected={(this.props.selected===R.TASTEMAKERLANDING)?true:false}>
                <TEXTSTYLE.LINK to={R.TASTEMAKERLANDING}>
                  Curators and Stylists
                </TEXTSTYLE.LINK>
               <SepBlock2>|</SepBlock2>
             </InlineBody>
        :
        ""
      );
    }
  }

  get_notification_bell(){
    if (this.props.windowWidth>M.MOBILESWITCH && this.props.authUserUID !== null){
      return(
        <NotificationBellContainer
         href="#"
         onClick={this.notification_toggle}
        >
          <NotificationBell
           src={MEDIA.BELL}
           ref={this.domBellRef}
           open={this.state.notification_open}
          />
          {
            (this.props.notification_indicator_text)?
              <NotificationIndicatorX>
              {this.props.notification_indicator_text}
              </NotificationIndicatorX>
            :""
          }
        </NotificationBellContainer>
      );
    }
  }

  get_notification_burger(){
    if (this.state.burger_open!==true && this.props.windowWidth<=M.MOBILESWITCH && this.props.authUserUID !== null && this.props.notification_indicator_text){
      return(
        <NotificationIndicatorBurger>
          {this.props.notification_indicator_text}
        </NotificationIndicatorBurger>
      );
    }
  }

  render(){
    return(
      <ContainerDiv className={this.props.className}>
        <MenuBar>
        <FlexInner hug={this.props.hug}>
          <MenuBlock>
            <TEXTSTYLE.LINK to="/">
            <InlineBodyNoUnderline className='InlineBodyNoUnderline'>
              <LogoAndText heighttype="C"/>
            </InlineBodyNoUnderline>
            </TEXTSTYLE.LINK>
          </MenuBlock>
          {
            // don't show on post install page for example:
          }
          {(this.props.showRight!==false)?
            <MenuBlock>
              {this.curators_and_stylists()}
              {this.my_collections_link()}
              {this.get_moonsift_button()}
              {this.get_notification_bell()}
              <SepBlock>|</SepBlock>
              <BurgerTabbable
                open={this.state.burger_open}
                onClick={this.burger_toggle}
                href="#"
               >
                {this.get_notification_burger()}
                <BurgerRight
                 ref={this.domBurgerRef}
                 open={this.state.burger_open}
                />
              </BurgerTabbable>
            </MenuBlock>
          :""}
        </FlexInner>
      </MenuBar>
      {
        (this.state.notification_open)?
        <NotificationMenu
         hug={this.props.hug}
         domBellRef={this.domBellRef}
         notification_toggle={this.notification_toggle}
         window_width={this.props.windowWidth}
        />
        :""
      }
      <BurgerMenu
       hug={this.props.hug}
       domBurgerRef={this.domBurgerRef}
       open={this.state.burger_open}
       loggedin={this.props.authUserUID !== null}
       selected={this.props.selected}
       burger_toggle={this.burger_toggle}
       is_pro={this.props.pro}
       notification_indicator_text={this.props.notification_indicator_text}
      />
    </ContainerDiv>
    );
  }
}

const mapStateToProps = (state) => {
  return ({
    authUserUID:state.auth.id,
    pro:state.firestore_user_owner.pro,
    firebaseState:state.firebasestate,
    notification_indicator_text:state.firestore_notifications.new_notifications_count
  });
};

const mapDispatchToProps = () => {
  return {};
};

export default connect(mapStateToProps,mapDispatchToProps)(windowSize(TopMenu));
