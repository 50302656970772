import React from 'react';
import styled from 'styled-components';
import { Helmet } from 'react-helmet';
import { Link as RouterLink } from 'react-router-dom';
import {
  Stack,
  MoveButton,
  Typography,
  Link,
} from '@moonsifttech/design-system';

import TopMenu from 'src/mvp22/menu-components/TopMenu';
import R from 'src/routes';
import { CollectionNotFoundProps } from './CollectionNotFound.types';

const UnstyledCollectionNotFound: React.FC<CollectionNotFoundProps> = ({
  className,
  style,
  onBack,
}) => {
  return (
    <div className={className} style={style}>
      <Helmet>
        <title>Collection not found</title>
      </Helmet>
      <TopMenu className="CollectionNotFound-topMenu" hug="edge" />
      <Stack className="CollectionNotFound-controls" direction="row">
        <MoveButton variant="outlined" direction="left" onClick={onBack} />
      </Stack>
      <Stack
        className="CollectionNotFound-innerContainer"
        direction="column"
        justifyContent="center"
        alignItems="center"
      >
        <img
          className="CollectionNotFound-notFoundImage"
          src={`${process.env.REACT_APP_STATIC_URL}/home/empty.svg`}
          alt="Collection not found"
        />
        <Typography variant="primary.b26" align="center" component="p">
          Collection private or not found
        </Typography>
        <Typography variant="primary.r16" align="center" component="p">
          Click{' '}
          <Link
            className="CollectionNotFound-notFoundLink"
            to={R.HOME}
            component={RouterLink}
          >
            here
          </Link>{' '}
          to go to your collections.
        </Typography>
      </Stack>
    </div>
  );
};

export const CollectionNotFound = styled(UnstyledCollectionNotFound)`
  margin-bottom: 40px;

  .CollectionNotFound-topMenu {
    height: 56px;
    margin-bottom: 8px;
    overflow: hidden;
  }

  .CollectionNotFound-controls {
    margin: 0 16px 8px;

    & > .MoveButton-outlined .MoveButton-button {
      width: 40px;
      height: 40px;

      .BaseIcon-root {
        font-size: 20px;
      }
    }
  }

  .CollectionNotFound-innerContainer {
    height: calc(100vh - 166px);
    margin: 0 16px;
  }

  .CollectionNotFound-notFoundImage {
    display: block;
    height: 80px;
    width: 80px;
    margin-bottom: 64px;
  }

  .CollectionNotFound-notFoundLink {
    text-decoration: none;
    border-bottom: 3px solid var(--secondary-main);
  }
`;
