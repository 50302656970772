import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { RootState } from 'src';
import { ModalState, ModalTypes } from 'src/types';

// SCROLLING TO TOP when going to a new location, but NOT when switching between modals that modify the URL
// (e.g. product modal)
export const useScrollToTopOnLocationChange = () => {
  const location = useLocation();
  const modal = useSelector<RootState, ModalState>((state) => state.modal);
  const [locationPathnameLocal, setLocationPathnameLocal] = useState('');
  const [lastModalType, setLastModalType] = useState<ModalTypes | null>(null);
  useEffect(() => {
    if (location.pathname !== locationPathnameLocal) {
      setLocationPathnameLocal(location.pathname);
      if (
        lastModalType !== ModalTypes.product_settings &&
        modal.type !== ModalTypes.product_settings
      ) {
        window.scrollTo(0, 0);
      }
    }
  }, [lastModalType, location.pathname, locationPathnameLocal, modal.type]);
  useEffect(() => {
    if (lastModalType !== modal.type && modal.type !== null) {
      setLastModalType(modal.type);
    }
  }, [lastModalType, modal.type]);
};
