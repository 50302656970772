/*

Automatically scrolling review carousel.

TODO: Convert to typescript, functional, component + container, camelCase
Maybe a good idea not to have hard-coded data in future?

*/
import React from 'react';
import BasicCarousel from './BasicCarousel';
import styled from 'styled-components';
import {M} from 'src/mvp22/constants';
import windowSize from 'src/mvp22/WindowSize';
import {withFirebase} from 'src/mvp22/Firebase';
import MEDIA from 'src/mvp22/media';
import {TEXTSTYLE} from 'src/mvp22/style-components/Text';
import _ from 'lodash';
import smoothscroll from 'smoothscroll-polyfill';

const CAROUSEL_SCROLL_DISTANCE = 297;

const REVIEWS_STYLISTS = [
  {
    text:"Thanks to Moonsift I can take my shopping and dressing to a higher, more professional level.",
    star_rating:5,
    user_name:"Lynn M",
    user_handle:"@lynnmikolajczak",
    user_image: MEDIA.REVIEWERS.LYNN,
    profession: "Brand Manager"
  },
  {
    text:"I’m finding Moonsift super helpful with my clients, especially as so much of my work is now online.",
    star_rating:5,
    user_name:"Karen Miller",
    user_handle:"@in_styleconsulting",
    user_image: MEDIA.REVIEWERS.KAREN,
    profession: "Stylist"
  },
  {
    text:"As a Personal Stylist Moonsift has made my job SO much easier, especially with having to adapt my business.",
    star_rating:5,
    user_name:"Claire Jacklin",
    user_handle:"@clairejacklinstylist",
    user_image: MEDIA.REVIEWERS.CLAIRE,
    profession: "Stylist"
  },
  {
    text:"It allows me to offer professional looking, shoppable mood boards, curated exclusively for my clients all in one interactive URL link.",
    star_rating:5,
    user_name:"Lindsay Barry",
    user_handle:"@stylinglablondon",
    user_image: MEDIA.REVIEWERS.LINDSAY,
    profession: "Stylist"
  },
  {
    text:"As a Stylist, it has helped to remove a lot of the stress in the recommendation process, allowing me to view all of my...",
    star_rating:5,
    user_name:"Dani Koroma",
    user_handle:"@imagevalestyling",
    user_image: MEDIA.REVIEWERS.DANIELLE,
    profession: "Stylist"
  },
  {
    text:"Moonsift has made my virtual styling an actual dream when I’m working on wishlists for clients. Even if I wasn’t a stylist I'd...",
    star_rating:5,
    user_name:"Nicole Sweet",
    user_handle:"@nicolesweetstylist",
    user_image: MEDIA.REVIEWERS.NICOLE,
    profession: "Stylist"
  },
  {
    text:"I give you the style hack of the century... Your online shopping all saved in one place on your browser... GENIUS.",
    star_rating:5,
    user_name:"Sian Clarke",
    user_handle:"@styled_by_sian",
    user_image: MEDIA.REVIEWERS.SIAN,
    profession: "Stylist"
  },
  {
    text:"It’s really useful for having everything in one place. It’s like Pinterest you can shop from.",
    star_rating:5,
    user_name:"Abbey Booth",
    user_handle:"@storieswithclothes",
    user_image: MEDIA.REVIEWERS.ABBEY,
    profession: "Stylist"
  },
  {
    text:"I love Moonsift for my styling business. Just a fantastic tool!",
    star_rating:5,
    user_name:"Lindsay Barry",
    user_handle:"@stylinglablondon",
    user_image: MEDIA.REVIEWERS.LINDSAY,
    profession: "Stylist"
  }
];

const REVIEWS_LANDING = [
  {
    text:"This revolutionary tool gets a 10/10 from me and I suggest everyone add it to their browser.",
    star_rating:5,
    user_name:"Hillary Roberts",
    user_handle:"@hillary_roberts42",
    user_image: MEDIA.REVIEWERS.HILLARY,
    profession: ""
  },
  {
    text:"I used to buy impulsively, but now I make more considered higher quality purchases.",
    star_rating:5,
    user_name:"Nicole Sweet",
    user_handle:"@nicolesweetstylist",
    user_image: MEDIA.REVIEWERS.NICOLE,
    profession: "Stylist"
  },
  {
    text:"Love it! Such a useful tool for shopping online and keeping everything in one place.",
    star_rating:5,
    user_name:"Maria Kovacevic",
    user_handle:"@mariakovacevic1",
    user_image: MEDIA.REVIEWERS.MARIA,
    profession: ""
  },
  {
    text:"It’s so easy to create boards and share them with friends and followers.",
    star_rating:5,
    user_name:"Lynn M",
    user_handle:"@lynnmikolajczak",
    user_image: MEDIA.REVIEWERS.LYNN,
    profession: "Brand Manager"
  },
  {
    text:"Moonsift is a dream! It's such a useful tool, and it has totally transformed the way I shop online.",
    star_rating:5,
    user_name:"Shore Delano",
    user_handle:"@shoreyewande",
    user_image: MEDIA.REVIEWERS.SHORE,
    profession: ""
  },
  {
    text:"Your online shopping all saved in one place on your browser. It is genius and SO easy to use.",
    star_rating:5,
    user_name:"Sian Clarke",
    user_handle:"@styled_by_sian",
    user_image: MEDIA.REVIEWERS.SIAN,
    profession: "Stylist"
  },
  {
    text:"Moonsift is like Pinterest but for shopping! It’s an easy way of saving items you love to shoppable mood boards.",
    star_rating:5,
    user_name:"Tia Hammond",
    user_handle:"@tiabellex",
    user_image: MEDIA.REVIEWERS.TIA,
    profession: ""
  },
  {
    text:"Before Moonsift I used to flick between tabs, or copy and paste links, it was such a pain.",
    star_rating:5,
    user_name:"Shopé Delano",
    user_handle:"@shopedelano",
    user_image: MEDIA.REVIEWERS.SHOPE,
    profession: ""
  },
];

const CarouselContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  position:relative;
  max-width: 990px;
  flex-direction: column;
  margin-left: 18px;
`;

const CarouselSlideContainer = styled.div`
  overflow-x: scroll;
  display: flex;
  justify-content: center;
  ::-webkit-scrollbar {
    display: none;
  }
  scroll-snap-type: x mandatory;
  margin-left: ${props => props.isDesktop === true ? '0px' : '20px'};
  margin-right: ${props => props.isDesktop === true ? '0px' : '10px'};
`;

const CarouselSlideInner = styled.div`
  display: flex;
  width: 100%;
`;

const CarouselSlide = styled.div`
  scroll-snap-align: start;
  display: flex;
  flex-direction: column;
`;

const ReviewTile = styled.div`
  display: flex;
  position: relative;
  text-align: center;
  width: 269px;
  flex-direction: column;
  margin-right: 28px;
  color: ${M.COL.TEXT.BLACK};
`;

const SpeechBubbleImage = styled.img`
color:default;
`;

const SpeechBubbleText = styled(TEXTSTYLE.BODY3)`
  display: flex;
  max-width: 236px;
  position: absolute;
  top: 13px;
  left: 20px;
  text-align: left;
`;

const Star = styled.img`
color:default;
`;

const UserPhoto = styled.img`
  width: 61px;
  height: 61px;
  border-radius: 50%;
`;

const UserContainer = styled.div`
  display: flex;
`;

const UserDetails = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  margin-left: 10px;
`;

const UserDetailsLine = styled.span`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const RatingContainer = styled.div`
  display: flex;
  position: absolute;
  top: 120px;
  left: 20px;
  text-align: left;
`;

const StarRating = (props) => {
    let number_of_stars = props.star_rating;
    return(
      _.times(number_of_stars, (i) => {
        return(<Star key={i} src={MEDIA.RATING_STAR}/>);
      })
    );
};

class ReviewsCarousel extends BasicCarousel {

  constructor(){
    super();
    this.container = null;
    this.scrollContainerRight = this.scrollContainerRight.bind(this);
    this.stopCarousel = this.stopCarousel.bind(this);
    this.startInterval = this.startInterval.bind(this);
  }

  scrollContainer() {
    if(this.state.canScrollForward===true){
      this.container.scrollBy(
        { left: CAROUSEL_SCROLL_DISTANCE, behavior: 'smooth' }
      );
    } else {
      this.container.scrollBy(
        { left: -1*this.container.scrollWidth, behavior: 'smooth' }
      );
    }
    this.componentDidUpdate();
    return false;
  }

  scrollContainerRight() {
    this.scrollContainer();
  }

  startInterval(){
    this.interval = setInterval(() =>
      this.scrollContainerRight(), 3000
    );
  }

  stopCarousel(){
    clearInterval(this.interval);
  }

  componentDidMount() {
    super.componentDidMount();
    this.startInterval();
    smoothscroll.polyfill();
  }

  componentWillUnmount() {
    super.componentWillUnmount();
    clearInterval(this.interval);
  }

  render() {
    const ISDESKTOP = this.props.windowWidth>=M.MOBILESWITCH;
    const REVIEWS = this.props.type==="landing"?REVIEWS_LANDING:REVIEWS_STYLISTS;
    return (
      <CarouselContainer onMouseLeave={this.startInterval} onMouseEnter={this.stopCarousel} className='CarouselContainer'>
          <CarouselSlideContainer isDesktop={ISDESKTOP} ref={node =>{this.container = node;}} className='carouselSlideContainer'>
             <CarouselSlideInner className='carouselSlideInner'>
                {
                    REVIEWS.map((review, index) => {
                      return(
                        <CarouselSlide key={index}>
                          <ReviewTile className='ReviewTile'>
                            <SpeechBubbleImage src={MEDIA.REVIEW_SPEECH_BUBBLE}/>
                            <SpeechBubbleText>
                              {review.text}
                            </SpeechBubbleText>
                            <RatingContainer>
                              <StarRating star_rating={review.star_rating}/>
                            </RatingContainer>
                            <UserContainer>
                              <UserPhoto src={review.user_image}/>
                              <UserDetails>
                                <UserDetailsLine><TEXTSTYLE.HEADING5>{review.user_name}{review.profession!==""?` | ${review.profession}`:""}</TEXTSTYLE.HEADING5></UserDetailsLine>
                                <UserDetailsLine><img alt="profile-pic" src={MEDIA.INSTAGRAM}/><TEXTSTYLE.BODY3>&nbsp;{review.user_handle}</TEXTSTYLE.BODY3></UserDetailsLine>
                              </UserDetails>
                            </UserContainer>
                          </ReviewTile>
                        </CarouselSlide>
                    );
                  })
                }
              </CarouselSlideInner>
            </CarouselSlideContainer>
      </CarouselContainer>
    );
  }
}

export default withFirebase(windowSize(ReviewsCarousel));
