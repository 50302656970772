/*

View for the ability to unsubscribe from an email list (send in emails as unsubscribe link)

TODO: typescript, camelCase, make functional, non-default exports, design system, redux hooks...

*/
import React from 'react';
import {AddHelmetTitle} from "src/mvp22/core-components/helmet";
import { Component } from 'react';
import styled from 'styled-components';
import {M} from 'src/mvp22/constants';
import Button from 'src/mvp22/form-components/Button';
import { withFirebase } from 'src/mvp22/Firebase';
import R from 'src/routes';
import queryString from 'query-string';
import {TEXTSTYLE} from 'src/mvp22/style-components/Text';
import {FullBlank} from 'src/mvp22/menu-components/TopMenu';
import TopMenu from 'src/mvp22/menu-components/TopMenu';
import {withRouter} from 'react-router-dom';
import {requiresAuth} from 'src/mvp22/redux-components/requiresAuth';
import { connect } from 'react-redux';
import { SUBSCRIPTION_DICT } from './EditEmailPreferences';

const Container = styled.div`
  background-color:${M.COL.BUTTON.WHITE};
  color:${M.COL.TEXT.BLACK};
  padding-left:30px;
  padding-right:30px;
  text-align:center;
  display:flex;
  justify-content:center;
  align-items:center;
`;

const FullWidth = styled.div`
  width:100%;
  text-align:center;
  display:flex;
  justify-content:center;
  align-items:center;
`;

const FormContainer = styled.div`
  width:100%;
  max-width:368px;
  margin-top: 119px;
`;

const FormHeadingContainer = styled.div`
  display: flex;
  justify-content: center;
  text-align: center;
`;

const FormEmailContainer = styled(TEXTSTYLE.BODY4)`
color:default;
`;


const Seperator = styled.div `
  height:${props=>props.height};
`;


const CancelAndConfirmButtonsContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  padding-right: 20px;
`;

const ButtonSpacer = styled.span`
  display: inline-block;
  width: 16px;
`;

class EditEmailPreferences extends Component {

  constructor() {
    super();
    this.state = {
      submitting:false,
    };
    this.doSubmit = this.doSubmit.bind(this);
    this.close = this.close.bind(this);
  }

  componentDidMount(){
    this._isMounted = true;
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  doSubmit(unsubscribe_key){
    this.setState(
      {
        submitting:true
      }
    );
    this.props.firebase.fast_api()(
      {
        api:"users_unsubscribe_from_list",
        unsubscribe_key
      }
    ).then(
      (response)=> {
        this._isMounted && this.setState(
          {
            submitting:false,
            done_unsubscribe:true,
            completed_text:(response.data)?"Successfully unsubscribed":"Unsubscribe unsuccessful - please get in touch"
          }
        );
      }
    ).catch(
      (error)=>{
        console.error("Error saving settings",error);
        this.setState(
          {
            submitting:false,
          }
        );
      }
    );
  }

  close(event){
    this.props.history.push(R.HOMEPAGE);
    event.preventDefault();
    return false;
  }

  render(){
    const UNSUBSCRIBE_KEY = queryString.parse(this.props.location.search).key;
    const UNSUBSCRIBE_INFO = SUBSCRIPTION_DICT[UNSUBSCRIBE_KEY];
    return(
      <FullBlank className='fullBlank'>
        <TopMenu hug="edge"/>
        {AddHelmetTitle("Unsubscribe")}
          <Container className='container'>
          <FormContainer>
            <FormHeadingContainer>
              <TEXTSTYLE.HEADING2>Unsubscribe</TEXTSTYLE.HEADING2>
            </FormHeadingContainer>
            <Seperator height='10px'/>
            <FormEmailContainer>
              <TEXTSTYLE.BODY3>{this.props.authUserEmail}</TEXTSTYLE.BODY3>
            </FormEmailContainer>
            <Seperator height='10px'/>
            {
                (UNSUBSCRIBE_INFO)?
                  <>
                    <Seperator height='11px'/>
                    <TEXTSTYLE.BODY3>Unsubscribe from the email list: <b>{UNSUBSCRIBE_INFO.title}</b>?</TEXTSTYLE.BODY3>
                    <Seperator height='12px'/>
                    {(this.state.done_unsubscribe===true)?
                      <TEXTSTYLE.HEADING5>{this.state.completed_text}</TEXTSTYLE.HEADING5>
                     :
                      <FullWidth>
                        <Button
                          text="Unsubscribe"
                          working_overlay={this.state.submitting}
                          msstyle="option_positive"
                          onClick={()=>this.doSubmit(UNSUBSCRIBE_KEY)}
                          width="123px"
                          tabIndex={201}
                        />
                      </FullWidth>
                    }
                    <Seperator height='12px'/>
                    <TEXTSTYLE.BODY5>
                      <TEXTSTYLE.LINK to={R.EDIT_EMAIL_PREFERENCES}>
                        Alternatively, click here to view your email preferences
                      </TEXTSTYLE.LINK>
                    </TEXTSTYLE.BODY5>
                    <Seperator height='24px'/>
                    <CancelAndConfirmButtonsContainer>
                      <Button
                        text="Go To My Profile"
                        msstyle="option_negative"
                        tabIndex={200}
                        onClick={this.close}
                        width="150px"
                      />
                      <ButtonSpacer/>
                    </CancelAndConfirmButtonsContainer>
                    <Seperator height='12px'/>
                    <Seperator height='12px'/>
                  </>
                :
                  <>
                    <Seperator height='32px'/>
                    <TEXTSTYLE.HEADING5>Invalid unsubscribe group!</TEXTSTYLE.HEADING5>
                    <Seperator height='12px'/>
                    <TEXTSTYLE.BODY4> <TEXTSTYLE.LINK to={R.EDIT_EMAIL_PREFERENCES}>Alternatively, click here to view your email preferences</TEXTSTYLE.LINK></TEXTSTYLE.BODY4>
                  </>
            }
          </FormContainer>
          </Container>
      </FullBlank>
    );
  }
}

const mapStateToProps = (state) => {
  return ({
    authUserEmail:state.auth.email
  });
};

const mapDispatchToProps = () => {
  return {};
};

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(
    requiresAuth(
      withFirebase(
        EditEmailPreferences
      )
    )
  )
);
