/*

The agree to cookies message at the bottom of the screen


TODO: functional, typescript, camelCase

The container has been already converted to typescript in the new system:
react-app/src/components/popups/CookieMessage

*/
import React from 'react';
import { Component } from 'react';
import {M} from 'src/mvp22/constants';
import styled from 'styled-components';
import {TEXTSTYLE} from 'src/mvp22/style-components/Text';
import R from 'src/routes';

const CookieNoticeContainer = styled.div`
  width:100%;
  position:fixed;
  bottom:0px;
  background-color:${M.COL.BG.WHITE};
  color:${M.COL.TEXT.BLACK};
  min-width:${M.MINWIDTH};
  display:flex;
  flex-direction:row;
  align-items:center;
  justify-content: center;
  z-index:1000;
`;

const MainTextBlock = styled(TEXTSTYLE.BODY4)`
  padding: 12px 12px;

`;

const InlineBody = styled(TEXTSTYLE.BODY4)`
  display:inline-block;
  font-weight:bold;
  cursor:pointer;
`;

class CookieNotice extends Component {
  render(){
    return(
      <CookieNoticeContainer>
        <MainTextBlock>
          Moonsift doesn’t have ads.
          We <TEXTSTYLE.LINK to={R.PRIVACYPOLICY}><b>use cookies</b></TEXTSTYLE.LINK> to personalise and improve your discovery experience.&nbsp;
          <TEXTSTYLE.LINKA href="#" onClick={this.props.toggle}>
            <InlineBody>
              Got it.
            </InlineBody>
          </TEXTSTYLE.LINKA>
        </MainTextBlock>
      </CookieNoticeContainer>
    );
  }
}
export default CookieNotice;
