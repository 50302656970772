/*


TODO:

*/
import React from 'react';
import {M} from 'src/mvp22/constants';
import styled from 'styled-components';
import {TEXTSTYLE} from 'src/mvp22/style-components/Text';
import MEDIA from 'src/mvp22/media';
import {SHARE_OPTIONS} from './SharePopup';

const SubMenu = styled.div`
  border-radius:10px;
  background: #FFFFFF;
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.25);
  align-content: center;
  justify-content: center;
  text-align: left;
  z-index:1000;
  position:absolute;
  overflow:hidden;
`;

const SubMenuInner = styled.div`
  display:flex;
  flex-direction:column;
`;

const SubMenuOption = styled(TEXTSTYLE.LINKA)`
  padding:12px;
  display:flex;
  flex-direction:row;
  justify-items:space-between;
  align-items:center;
  cursor:pointer;
  &:hover{
    background-color:${M.COL.BG.MID};
  }
`;

const SubMenuTextNotSelected = styled(TEXTSTYLE.BODY4)`
  flex-grow:1;
`;

const SubMenuTextSelected = styled(TEXTSTYLE.OTHER2)`
  flex-grow:1;
`;

const SubMenuIcon = styled.img`
  width:18px;
  margin-right:12px;
  opacity:${props =>(props.is_selected)?1.0:0.6}
`;

const SubMenuTick = styled.img`
  width:18px;
  margin-left:12px;
`;

const ShareableOptions = (props)=>{
  return(
    <SubMenu {...props.overlay_props.props} placement={props.overlay_props.placement}>
      <SubMenuInner >
        {
          SHARE_OPTIONS.map(
            (option,index)=>
            {
              const IS_SELECTED = option.public_level===props.public_level;
              const SUB_MENU_TEXT = (IS_SELECTED)?SubMenuTextSelected:SubMenuTextNotSelected;
              return (
                <SubMenuOption href="#" key={index} onClick={(event)=>{
                  event.preventDefault();
                  props.setPublicLevel(option.public_level);
                  return false;
                }
                }>
                  <SubMenuIcon is_selected={IS_SELECTED} src={MEDIA['SHARE_LEVEL_'+option.public_level]}/>
                  <SUB_MENU_TEXT>{option.text}</SUB_MENU_TEXT>
                  <SubMenuTick src={(IS_SELECTED)?MEDIA.SHARE_TICK:undefined}/>
                </SubMenuOption>
              );
            }
          )
        }
      </SubMenuInner>
    </SubMenu>
  );
};

export default ShareableOptions;
