/*

(not really a tile component) but appears in ProductTile...
Buttons on product tile for liking / disliking a product.

TODO: camelCase, typescript, design system...

*/
import React, {useState, useRef} from 'react';
import styled, {css} from 'styled-components';
import R from 'src/routes';
import {TEXTSTYLE} from 'src/mvp22/style-components/Text';
import MEDIA from 'src/mvp22/media';
import Overlay from 'react-overlays/Overlay';

//TODO: Would really like the width to be calculated automatically on one line
const Tooltip = styled.div`
  width:${props=>(props.is_purchased)?"230px":"160px"};
  background: #FFFFFF;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 5px;
  align-content: center;
  justify-content: center;
  text-align: left;
  z-index:1000;
  position: absolute;
`;

const TooltipText = styled(TEXTSTYLE.BODY5)`
  padding: 10px;
`;

const UnderlineLink = styled(TEXTSTYLE.LINK)`
  font-weight:bold;
  &:hover {
    text-decoration:underline;
  }
`;

const IconContainer = styled(TEXTSTYLE.LINKA)`
  position: relative;
  right: 7px;
`;


const UpDownVoteContainer = styled.div`
color:default;
`;

const LeftIconCSS = css`
  height:30px;
  width:30px;
  margin:6px 0px;
  cursor:pointer;
  background-image:url("${props=>props.bg_url}");
  background-repeat:no-repeat;
  background-position:center;
  background-size:16px;
  border-radius:15px;
  &:hover {
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
  }
`;

const LeftIcon = styled.div`
  &:focus{
    outline:none;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
  }
  ${LeftIconCSS}
`;

export function UpDownVote(props) {
  const [tip_is_visible, setVisible] = useState(false);
  const triggerRef = useRef(null);
  const containerRef = useRef(null);
  //approach based on: https://react-bootstrap.github.io/react-overlays/api/Overlay
  return (
    <UpDownVoteContainer ref={containerRef}>
      <IconContainer
        ref={triggerRef}
        onClick={(props.isLoggedIn)?props.enabledClick:(event)=>{
          event.preventDefault();
          setVisible(true);
          return false;
        }}
        href="#"
      >
        <LeftIcon
         bg_url={(props.selected)?MEDIA[`BUTTON_${props.type.toUpperCase()}_SELECTED`]:MEDIA[`BUTTON_${props.type.toUpperCase()}`]}
         alt={props.alt}
        />
      </IconContainer>
    {
      (!props.isLoggedIn)?
        <Overlay
          rootClose
          show={tip_is_visible}
          offset={[0, 10]}
          onHide={() => setVisible(false)} // required!
          container={containerRef}
          target={triggerRef}
          placement={'top-end'}
        >
          {(overlay_props) => (
              <Tooltip
                {...overlay_props.props}
                placement={overlay_props.placement}
              >
                <TooltipText>
                  You need to <UnderlineLink to={R.SIGNUP}>sign up</UnderlineLink> or <UnderlineLink to={R.SIGNIN}>login</UnderlineLink> in order to do this.
                </TooltipText>
              </Tooltip>
          )}
        </Overlay>
      :
        null
      }
    </UpDownVoteContainer>
  );
}
