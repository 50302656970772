import { useCallback, useState, useRef } from 'react';
import { DB } from 'src/services/DB';

/*

This hook is used to update a document in the database, returns a function and working flag.


*/
export function useDBUpdateDoc<T>(
  dB: DB,
  dBLocation: string, // document reference
): [(data: T) => Promise<void>, boolean] {
  const promiseRef = useRef<Promise<void>>();
  const [working, setWorking] = useState(false);
  const dBUpdateDoc = useCallback(
    async (data: T) => {
      if (working) {
        return promiseRef.current!;
      }

      setWorking(true);

      const newPromise = dB.updateDoc(dBLocation, data).then((result) => {
        setWorking(false);
        return result;
      });

      promiseRef.current = newPromise;

      return newPromise;
    },
    [dB, dBLocation, working],
  );
  return [dBUpdateDoc, working];
}
