import { FirebaseApp } from 'firebase/app';
import {
  getAuth,
  Auth as FirebaseAuth,
  FacebookAuthProvider,
  GoogleAuthProvider,
  User,
  signInWithEmailAndPassword,
  signOut,
  createUserWithEmailAndPassword,
  sendPasswordResetEmail,
  signInWithCustomToken,
  signInWithPopup,
} from 'firebase/auth';

export class Auth {
  auth: FirebaseAuth;
  facebook: FacebookAuthProvider;
  google: GoogleAuthProvider;

  constructor(app: FirebaseApp) {
    this.auth = getAuth(app);
    this.facebook = new FacebookAuthProvider();
    this.google = new GoogleAuthProvider();
  }

  // Functions:
  signInWithEmailAndPassword = (email: string, password: string) =>
    signInWithEmailAndPassword(this.auth, email, password);
  createUserWithEmailAndPassword = (email: string, password: string) =>
    createUserWithEmailAndPassword(this.auth, email, password);
  signOut = () => signOut(this.auth);
  sendPasswordResetEmail = (email: string) =>
    sendPasswordResetEmail(this.auth, email);
  signInWithCustomToken = (token: string) =>
    signInWithCustomToken(this.auth, token);
  signInWithProvider = (provider_name: 'facebook' | 'google') => {
    let authProvider =
      provider_name === 'facebook' ? this.facebook : this.google;
    return signInWithPopup(this.auth, authProvider);
  };
}

export type UserObject = User;
