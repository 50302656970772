import React from 'react';
import styled from 'styled-components';
import clsx from 'clsx';
import { Prompt } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import {
  Typography,
  Stack,
  MoveButton,
  ShareIcon,
  OrganiseIcon,
  StandardButton,
  CtaButton,
  AddIcon,
} from '@moonsifttech/design-system';

import R from 'src/routes';
import { CollectionNotFound } from 'src/views/CollectionNotFound';
import TopMenu from 'src/mvp22/menu-components/TopMenu';
import LoadingDisplay from 'src/mvp22/image-components/LoadingDisplay';
import { SharePopup } from 'src/mvp22/popup-components/SharePopup';
import { ProductTileList } from 'src/components/collection/ProductTileList';
import { ViewCount } from 'src/components/collection/ViewCount';
import { User } from 'src/components/collection/User';
import { SquareButton } from 'src/components/collection/SquareButton';
import { MoreCollectionOptionsButton } from 'src/components/collection/MoreCollectionOptionsButton';
// import { SharePanel } from 'src/components/collection/SharePanel';
import { EditModal } from 'src/components/collection/EditModal';
import { VisibilityButton } from 'src/components/collection/VisibilityButton';
import { BookmarkButton } from 'src/components/collection/BookmarkButton';

import { CollectionProps, CollectionModalName } from './Collection.types';

const UnstyledCollection: React.FC<CollectionProps> = ({
  className,
  collectionName,
  collectionDescription,
  collectionUID,
  collectionUserUID,
  notFound,
  isLoading,
  isMine,
  views,
  onBack,
  organisingHook,
  // Reuse of the old share button
  isPro,
  singleCollectionDocData,
  displayUsername,
  setPublicLevel,
  collectionPublicLevelWorking,
}) => {
  const isOrganising = organisingHook.isOrganising;
  const isSavingOrganising = organisingHook.isSavingOrdering;
  const [modals, manageModals] = React.useState<
    Record<CollectionModalName, boolean>
  >({
    // Note: this will stay static until we remove the old SharePopup
    // and attend old the TODOs in this file
    share: false,
    edit: false,
  });

  const toggleModal = React.useCallback(
    (modalName: CollectionModalName) =>
      (event?: React.SyntheticEvent): void => {
        if (event) event.preventDefault();
        manageModals((prevModals) => {
          return {
            ...prevModals,
            [modalName]: !prevModals[modalName],
          };
        });
      },
    [],
  );

  // TODO: remove once we stop using SharePopup
  // `openBox` is a toggle handler to open the share modal and comes from SharePopup
  const openBoxRef = React.useRef<React.EventHandler<React.SyntheticEvent>>();

  // TODO: remove once we stop using SharePopup
  // `openBox` comes from and is passed down by SharePopup and
  // is responsible to actually opening and closing the modal
  const toggleShare = React.useCallback(
    (openBox): React.MouseEventHandler<HTMLButtonElement> => {
      openBoxRef.current = openBox;

      // Once we've saved `openBox` we can return it so that it's
      // set as the final button click handler
      return openBox;
    },
    [],
  );

  if (notFound) {
    return <CollectionNotFound />;
  }

  return (
    <div className={clsx(className, isPro && 'Collection-isPro')}>
      <TopMenu className="Collection-topMenu" hug="edge" />
      <Stack className="Collection-navControls" direction="row">
        <MoveButton variant="outlined" direction="left" onClick={onBack} />
      </Stack>
      <Stack
        className="Collection-innerContainer"
        justifyContent="center"
        alignItems="center"
      >
        <Typography
          className="Collection-collectionName"
          variant="primary.b34"
          align="center"
          component="h1"
        >
          {collectionName}
          <MoreCollectionOptionsButton
            // TODO: change to `toggleModal('share') once we've stopped
            // using the old SharePopup
            onShare={(event) => openBoxRef.current!(event)}
            onEdit={toggleModal('edit')}
          />
        </Typography>
        {collectionDescription && (
          <Typography
            className="Collection-collectionDescription"
            variant="primary.l16"
            align="center"
            component="p"
          >
            {collectionDescription}
          </Typography>
        )}
        <Stack
          className="Collection-metaInfo"
          direction="row"
          justifyContent="center"
          alignItems="center"
          spacing={16}
        >
          {views > 1 && <ViewCount count={views} />}
          <User />
        </Stack>
        <Stack
          className="Collection-controls"
          direction="row"
          justifyContent="center"
          alignItems="center"
        >
          {isOrganising ? (
            <>
              <StandardButton
                className="Collection-organisingSaveButton"
                variant="primary-black"
                onClick={organisingHook.handleOrganising}
                loading={isSavingOrganising}
              >
                Save changes
              </StandardButton>
              <StandardButton
                className="Collection-floatingOrganisingSaveButton"
                variant="primary-black"
                onClick={organisingHook.handleOrganising}
                loading={isSavingOrganising}
              >
                Save changes
              </StandardButton>
              {isPro && (
                <CtaButton
                  className="Collection-organisingSectionButton"
                  size="small"
                  variant="primary-white"
                  startIcon={<AddIcon />}
                  onClick={organisingHook.addSubsection}
                >
                  Section
                </CtaButton>
              )}
            </>
          ) : (
            <>
              <SharePopup
                shareURL={
                  process.env.REACT_APP_HOMEPAGEURL +
                  R.COLLECTION +
                  '/' +
                  displayUsername +
                  '/' +
                  collectionUID
                }
                shareText="Share this collection"
                publicLevel={singleCollectionDocData?.public_level}
                setPublicLevel={setPublicLevel}
                isMine={isMine}
                isPro={isPro}
                isWorking={collectionPublicLevelWorking}
                collectionUID={collectionUID}
                collectionUserUID={collectionUserUID}
                collectionName={collectionName}
                type="collection"
                button={
                  <SquareButton icon={<ShareIcon />} onClick={toggleShare}>
                    Share
                  </SquareButton>
                }
                disabled={undefined}
                on={undefined}
              />
              {isMine && <VisibilityButton />}
              <BookmarkButton />
              {isMine && (
                <SquareButton
                  loading={organisingHook.isSavingOrdering}
                  icon={<OrganiseIcon />}
                  onClick={organisingHook.handleOrganising}
                >
                  Organise
                </SquareButton>
              )}
            </>
          )}
        </Stack>
        {isLoading ? (
          <>
            <Helmet>
              <title>Loading Collection... - Moonsift</title>
            </Helmet>
            <LoadingDisplay message="Loading Collection..." />
          </>
        ) : (
          <>
            <Helmet>
              <title>{collectionName} - Moonsift</title>
              <meta property="og:title" content={collectionName} />
            </Helmet>
            <ProductTileList
              collectionUID={collectionUID}
              collectionUserUID={collectionUserUID}
              organisingHook={organisingHook}
            />
          </>
        )}
      </Stack>
      {/* TODO: uncomment whenever we stop using the old SharePopup
      <Modal
        mode="modal"
        isOpen={modals.share}
        onRequestClose={toggleModal('share')}
      >
        <SharePanel onClose={toggleModal('share')} />
      </Modal> */}
      <EditModal isOpen={modals.edit} onClose={toggleModal('edit')} />
      {/**
       * This Prompt will be shown in the form of a browser alert when trying
       * to leave the collection detail page when organising by navigating via
       * react-router. It won't work when manually changing the URL in your browser.
       * See non-react-router solution in the container.
       */}
      <Prompt
        when={isOrganising}
        message="Any changes you've made to the order of your collection have not been saved. Leave without saving?"
      />
    </div>
  );
};

export const Collection = styled(UnstyledCollection)`
  margin-bottom: 40px;

  .Collection-topMenu {
    height: 56px;
    margin-bottom: 8px;
    overflow: hidden;
  }

  .Collection-navControls {
    margin: 0 16px 8px;

    & > .MoveButton-outlined .MoveButton-button {
      width: 40px;
      height: 40px;

      .BaseIcon-root {
        font-size: 20px;
      }
    }
  }

  .Collection-innerContainer {
    margin: 0 16px;
  }

  .Collection-collectionName {
    ${({ theme }) => theme.fns.getMediaQuery({ minWidth: 'md' })} {
      ${({ theme }) => theme.fns.getTypographyStyles('primary.b42')}
    }
  }

  .Collection-collectionName,
  .Collection-collectionDescription {
    max-width: 536px;
    margin-bottom: 12px;

    ${({ theme }) => theme.fns.getMediaQuery({ minWidth: 'md' })} {
      margin-bottom: 16px;
    }
  }

  .Collection-metaInfo {
    margin-bottom: 28px;

    ${({ theme }) => theme.fns.getMediaQuery({ minWidth: 'md' })} {
      margin-bottom: 32px;
    }
  }

  .Collection-controls {
    margin-bottom: 32px;

    ${({ theme }) => theme.fns.getMediaQuery({ minWidth: 'md' })} {
      margin-bottom: 40px;
    }
  }

  &:not(.Collection-isPro) .Collection-organisingSaveButton {
    width: 252px;
  }

  .Collection-organisingSectionButton {
    margin-left: 16px;
  }

  .Collection-floatingOrganisingSaveButton {
    padding: 7px 10px;
    position: fixed;
    right: 26px;
    bottom: 26px;
    z-index: 100;

    ${({ theme }) => theme.fns.getMediaQuery({ maxWidth: 'md' })} {
      display: none;
    }
  }
`;
