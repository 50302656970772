import React from 'react';
import { useSelector } from 'react-redux';

import { RootState, DBContext } from 'src/index';
import { useDBAddDoc } from 'src/hooks/useDBAddDoc';
import {
  CreateCollectionContainerProps,
  CreateCollectionProps,
} from './CreateCollection.types';
import { CreateCollection } from './CreateCollection.component';
import { CollectionDocument } from 'src/types/models/collection.model';

const CreateCollectionContainer: React.FC<CreateCollectionContainerProps> = ({
  history,
  location,
}) => {
  const searchParams = new URLSearchParams(location.search);
  const isFirst = Boolean(searchParams.get('first'));

  const db = React.useContext(DBContext);
  const authUserUID = useSelector<RootState, string | null>(
    (state) => state.auth.id,
  );
  const isPro = useSelector<RootState, boolean>((state) =>
    state.firestore_user_owner.pro ? true : false,
  );
  const displayUsername = useSelector<RootState, boolean>(
    (state) => state.firestore_user_owner.display_username,
  );
  const [dBAddDoc, working] = useDBAddDoc<CollectionDocument>(
    db,
    db.userCollectionList(authUserUID ?? ''),
  );

  const handleCollectionCreation = React.useCallback<
    CreateCollectionProps['onCollectionCreation']
  >(
    (event) => {
      event.preventDefault();

      const name = event.currentTarget.name.value;
      const description = event.currentTarget.description?.value;
      const createdAt = db.serverTimestamp();

      const collectionDocument: CollectionDocument = {
        created_at: createdAt,
        last_updated: createdAt,
        public_level: 7,
        like_count: 0,
        user_uid: authUserUID!,
        name,
      };

      if (description) {
        collectionDocument.description = description;
      }

      dBAddDoc(collectionDocument).then((document) => {
        history.push(`/collection/${displayUsername}/${document.id}`);
      });
    },
    [authUserUID, dBAddDoc, db, displayUsername, history],
  );

  return (
    <CreateCollection
      isPro={isPro}
      isFirst={isFirst}
      onCollectionCreation={handleCollectionCreation}
      working={working}
    />
  );
};

export { CreateCollectionContainer as CreateCollection };
