import { FirebaseApp } from 'firebase/app';
import { getMessaging, getToken, Messaging } from 'firebase/messaging';

// Only run if messaging supported in browser:
function setUpMessaging(app: FirebaseApp) {
  try {
    return getMessaging(app);
  } catch (err) {
    console.log('Messaging not supported, sorry', err);
  }
  return null;
}

/*

Notifications provided by firebase notification service and supplemnetary scripts (not in this repo at present)

*/
export class Notifications {
  messaging: Messaging | null;

  constructor(app: FirebaseApp) {
    this.messaging = setUpMessaging(app);
  }

  getToken = () => (this.messaging ? getToken(this.messaging) : null);
}
