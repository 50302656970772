/*

Landing page to target Christmas wishlist makers.  Very similar to Christmas Landing page

TODO: typescript, camelCase, make functional, non-default exports, design system...
Extract list of collections useing a dbHook (get,single doc)
Extract collection from db using a dbHook. (get, doc map)
collection tiles should load images in themselves.
*/
import React from 'react';
import { Component } from 'react';
import {Helmet} from "react-helmet";
import styled from 'styled-components';
import { Stack, Typography } from '@moonsifttech/design-system';

import {M} from 'src/mvp22/constants';
import MEDIA from 'src/mvp22/media';
import TopMenu from 'src/mvp22/menu-components/TopMenu';
import BottomBar from 'src/mvp22/menu-components/BottomBar';
import {TEXTSTYLE} from 'src/mvp22/style-components/Text';
import Button from 'src/mvp22/form-components/Button';
import R, {EXTERNAL} from 'src/routes';
import segmentEvent from "src/mvp22/segment-components/SegmentEvent";
import windowSize from 'src/mvp22/WindowSize';
import get_collection_images from 'src/mvp22/firebase-functions/get_collection_images';
import get_collection_likes from 'src/mvp22/firebase-functions/get_collection_likes';
import get_explore_collections from 'src/mvp22/firebase-functions/get_explore_collections';
import { connect } from 'react-redux';
import {withFirebase} from 'src/mvp22/Firebase';
import CollectionCarousel from 'src/mvp22/tile-components/CollectionCarousel';
import ReviewsCarousel from 'src/mvp22/tile-components/ReviewsCarousel';
import nested_state_assignment from 'src/mvp22/core-components/nested_state_assignment';
import remove_all_listeners from 'src/mvp22/core-components/remove_all_listeners';
import _ from 'lodash';


const Container = styled.div`
  background-color:${M.COL.BG.WHITE};
  width:100%;
  display:flex;
  flex-direction: column;
`;

const FirstRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: ${props =>(props.isDesktop===true) ? 'flex-start' : 'center'};
  flex-wrap: ${props =>(props.isDesktop===true) ? 'no-wrap' : 'wrap-reverse'};
  background-color: ${M.COL.BG.LIGHT};
`;

const SecondRow = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
`;

const SecondRowInner = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  max-width: 990px;
`;

const TopImageContainer = styled.div`
  width: ${props => props.isDesktop ? '45%' : '100%'};
  overflow: hidden;
  display: flex;
`;

const BannerImage = styled.div`
  height: 668px;
  width: 1120px;
  background-image: url(${MEDIA.LANDING_IMG_1_AUG_2020});
  background-size: cover;
  background-repeat: no-repeat;
  background-position: 50% 50%;
  resize: both;
`;

const BannerImageSmall = styled.div`
  height: 353px;
  width: 500px;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: 50% 50%;
  resize: both;
  background-image: url(${MEDIA.LANDING_IMG_1_AUG_2020_SMALL});
`;

const FirstRowColumn = styled.div`
  display: flex;
  width: ${props => props.isDesktop  ? '50%' : '100%'};
  justify-content: center;
  align-items: center;
  margin-bottom: ${props => props.isDesktop  ? '0px' : '62px'};
  max-width: 500px;
  @media (min-width: 937px){
    margin-left: auto;
    margin-right: auto;
  }
  @media (min-width: 1200px){
    margin-left: 100px;
  }
  margin-left: 20px;
  margin-right: 20px;
  margin-top: 30px;
`;

const TextContainer = styled.div`
  color: ${M.COL.TEXT.BLACK};
  position: relative;
  top: 20px;
  max-width: 394px;
  @media(max-width: 500px){
    max-width: 326px;
  }
`;

const TextContainerOuter = styled.div`
  display: flex;
  justify-content: ${props => props.isDesktop  ? 'center' : 'flex-start'};
  width: ${props => props.isDesktop  ? '50%' : '100%'};
  margin-left: ${props => props.isDesktop  ? '' : '17px'};
  margin-right: ${props => props.isDesktop  ? '' : '17px'};
`;

const TextContainerOuter2 = styled(TextContainerOuter)`
  position: relative;
  right: ${props => props.isDesktop  ? '53px' : ''};
`;

const CTAButtonTextBold = styled(TEXTSTYLE.BODY3)`
  color: ${M.COL.TEXT.WHITE};
  font-weight: bold;
  font-size: ${props=>(props.isDesktop===true)?'26px':'18px'};
  line-height: 38px;
`;

const CTAButtonTextLight = styled(TEXTSTYLE.BODY3)`
  color: ${M.COL.TEXT.WHITE};
  font-weight: light;
  font-size: ${props=>(props.isDesktop===true)?'26px':'18px'};
  line-height: 38px;
`;

const ChromeIcon = styled.img`
  width: 13px;
  margin-right:6px;
`;

const Seperator = styled.div`
  height:${props=>props.height};
`;

const Section1Title = styled(TEXTSTYLE.HEADING1)`
  text-align: left;
  font-size: ${props => props.isDesktop === true ? '' : '42px' };
  line-height: ${props => props.isDesktop === true ? '' : '50px' };
  max-width: ${props => props.isDesktop === true ? '' : '295px' };
`;

const Section1Subtitle = styled(TEXTSTYLE.BODY2)`
  font-size:20px;
  line-height:30px;
  max-width: 100%;
  text-align: left;
`;

const SubSubtitle = styled(TEXTSTYLE.BODY5)`
  color: ${M.COL.TEXT.MID};
  display: flex;
`;

const SecondRowTitle = styled.div`
  color: ${M.COL.TEXT.BLACK};
  display: flex;
  justify-content: flex-start;
  text-align: left;
  margin-left: 18px;
  align-self: flex-start;
`;

const FourthRow = styled.div`
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  flex-wrap: wrap;
  width: 100%;
  background-color: ${M.COL.BG.LIGHT};
`;

const VideoContainer = styled.div`
  overflow-x: scroll;
  overflow-y: hidden;
  padding-top:0px;
  padding-bottom:0px;
  ::-webkit-scrollbar {
    display: none;
  }
  font-size:0px;
  overflow: hidden;
  width: ${props => props.isDesktop ? '50%' : '100%'};
  margin-top: 20px;
`;

const VideoContainer2 = styled(VideoContainer)`
  margin-top: ${props => props.isDesktop ? '55px' : '20px'};
`;

const Video = styled.video`
  width: 100%;
  box-sizing: border-box;
  max-width: 450px;
`;


const ItemText = styled.div`
  font-size:20px;
`;

const BottomText = styled(ItemText)`
  max-width:420px;
  text-align:left;
`;

const Strikethrough = styled(TEXTSTYLE.HEADING1)`
  text-decoration: line-through;
  text-align: left;
  font-size: ${props => props.isDesktop === true ? '' : '42px' };
  line-height: ${props => props.isDesktop === true ? '' : '50px' };
`;

const MaxWidthContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-self: center;
  width: 100%;
  max-width: ${props =>(props.isDesktop===true) ? '1116px' : '420px'};
`;

const GreyBG = styled.div`
  background-color: ${M.COL.BG.LIGHT};
  display: flex;
  justify-content: center;
`;

const FourthRowText = styled(TEXTSTYLE.BODY2)`
  max-width: 375px;
`;

const Star = styled.img`
  width: 23px;
`;

const StarContainer = styled.div`
  margin-left: 16px;
  display: inline;
`;

const InnerContainer = styled.section`
  margin: 0 auto;
  width: 100%;
  max-width: 992px;

  @media (max-width: 992px) {
    margin: 0 34px;
    width: calc(100% - 68px);
  }

  @media (max-width: 500px) {
    margin: 0 16px;
    width: calc(100% - 32px);
  }
`;

const Instructions = styled(({ className, style, isDesktop }) => {
  return (
    <InnerContainer className={className} style={style}>
      <div className="innerContainer">
        <Stack
          justifyContent="center"
          alignItems="center"
          direction="row"
          wrap="wrap-reverse"
        >
          <VideoContainer isDesktop={isDesktop}>
            <Video
              src={MEDIA.LANDING_VIDEO_1_FASTER}
              loop
              autoPlay
              muted
              playsInline
            />
          </VideoContainer>
          <Seperator height="44px" />
          <TextContainerOuter2
            className="TextContainerOuter2"
            isDesktop={isDesktop}
          >
            <TextContainer className="TextContainer">
              <BottomText className="BottomText">
                <Typography
                  className="heading"
                  variant="primary.b34"
                  component="h2"
                >
                  Add products from
                  <br />
                  any online store
                </Typography>
                <ol>
                  <li>
                    <a href={EXTERNAL.CHROMESTORE}>Add to Chrome</a> on your
                    desktop
                  </li>
                  <li>Browse and shop as normal</li>
                  <li>Save items with one click</li>
                </ol>
                <Typography
                  className="subheading"
                  variant="primary.l20"
                  component="p"
                >
                  <a href={R.RETAILERS}>List of most popular stores</a>
                </Typography>
                <Seperator height={"18px"} />
              </BottomText>
            </TextContainer>
          </TextContainerOuter2>
          <Seperator height="32px" />
        </Stack>
      </div>
    </InnerContainer>
  );
})`
  @media (max-width: 922px) {
    .innerContainer {
      margin: 0 auto;
      max-width: 420px;
    }
  }

  a {
    line-height: inherit;
    color: ${M.COL.TEXT.BLACK};
  }

  ol {
    font-family: ${M.FONT};
    font-style: normal;
    font-weight: 300;
    line-height: 30px;
    padding: 0 0 0 22px;

    a {
      font-weight: 400;
    }
  }
`;

class Landing extends Component {

  constructor(){
    super();
    this.state = {
      is_loaded:false,
      landing_collection_list:[]
    };
    this.collection_state_assign_func = this.collection_state_assign_func.bind(this);
  }

  componentDidMount() {
    this._isMounted = true;
    segmentEvent("Viewed Landing");
    this.updateTheDataOnce();
  }

  componentDidUpdate() {
    this.updateTheDataOnce();
  }

  componentWillUnmount() {
    this._isMounted = false;
    remove_all_listeners(this.state.collections);
  }

  collection_state_assign_func(new_dict){
    this._isMounted && this.setState(
      prevState=>{
        return ({
          collections:nested_state_assignment(prevState.collections,new_dict)
        });
      }
    );
  }

  updateTheDataOnce(){
    if (
      this.props.firebaseState
     &&
      this.props.firebaseState.loaded
    ){
      this.setState(
        (prevState, props) => {
          // makes this only run once? they are equal after the first time you load the page so updateTheDataOnce doesn't end up populating new collections
          if (prevState.auth_user_uid!==props.authUserUID || prevState.current_explore_type !== props.explore_type){
            // get the collection info JUST ONCE, hence NOT bothering with redux for now:
            get_explore_collections("landing",props.firebase,props.authUserUID).then(
              response=>{
                // split by big and small tiles:
                const like_listeners_dict = get_collection_likes(
                  response.collections,
                  this.props.firebase,
                  this.props.authUserUID,
                  this.collection_state_assign_func
                ); // WILL ALSO CALL AN ASYNC FUNCTION WHEN LIKES DETERMINED!
                var collections_with_listeners = nested_state_assignment(response.collections,like_listeners_dict);
                const image_getters = get_collection_images(response.collections,this.props.firebase,this.collection_state_assign_func);
                collections_with_listeners = nested_state_assignment(collections_with_listeners,image_getters);
                this._isMounted && this.setState(
                  (prevState)=>{
                    return (
                      {
                        is_loaded:true,
                        landing_collection_list:response.collections_sorted,
                        collections:nested_state_assignment(prevState.collections,collections_with_listeners),
                        public_info:response.public_info
                      }
                    );
                  }
                );
              }
            );
            return {
              auth_user_uid : this.props.authUserUID,
              is_loaded: false
            };
          }
        }
      );
    }
  }

  CtaButtonText(ISDESKTOP) {
    return[
      <CTAButtonTextBold key='bold-button-text' isDesktop={ISDESKTOP}>Get started&nbsp;</CTAButtonTextBold>,
      <CTAButtonTextLight isDesktop={ISDESKTOP}  key='light-button-text'>- it&apos;s free</CTAButtonTextLight>
    ];
  }

  renderCarousel(){
    if(this.state.is_loaded){
      return(
        <CollectionCarousel
         collections_list={this.state.landing_collection_list}
         collections={this.state.collections}
         public_info={this.state.public_info}
         auth_user_uid={this.state.auth_user_uid}
        />
      );
    } else {
      return(
        ""
      );
    }
  }

  renderStars(){
    return(
      <StarContainer>
        {
          _.times(5, (i) => {
            return(
                <Star key={i} src={MEDIA.RATING_STAR}/>
            );
          })
        }
      </StarContainer>
    );
  }

  render(){
    const ISDESKTOP = this.props.windowWidth>=M.MOBILESWITCH;
    return(
      <Container className='Container'>
        <TopMenu showTastemaker={true}/>
        <Helmet>
          <title>Moonsift - Save, curate and share from online UK fashion retailers</title>
        </Helmet>
        <Seperator height="56px"/>
        <FirstRow isDesktop={ISDESKTOP} className='FirstRow'>
          <TopImageContainer isDesktop={ISDESKTOP} className='topImageContainer'>
            {this.props.windowWidth > 500 ? <BannerImage/> : <BannerImageSmall/>}
          </TopImageContainer>
          <FirstRowColumn isDesktop={ISDESKTOP} className='firstRowColumn'>
          <TextContainer isDesktop={ISDESKTOP} className='textContainer'>
            <Section1Title isDesktop={ISDESKTOP}>
              <Strikethrough isDesktop={ISDESKTOP}>100 tabs</Strikethrough>
              One wishlist for all stores
            </Section1Title>
            <Seperator height="10px"/>
            <Section1Subtitle>
              Make all that browsing count. Discover, save and curate shoppable collections with Moonsift’s universal save button.
            </Section1Subtitle>
            <Seperator height="30px"/>
            <TEXTSTYLE.LINK to={R.SIGNUP}>
              <Button
                isDesktop={ISDESKTOP}
                text={this.CtaButtonText(ISDESKTOP)}
                onClick={() => {segmentEvent("Clicked Get Started Button 1 On Landing Page");}}
                msstyle={(ISDESKTOP===true)?"cta_alternative_desktop" : "cta_alternative_mobile"}
                >
              </Button>
            </TEXTSTYLE.LINK>
            <Seperator height="16px"/>
            <SubSubtitle>
              <ChromeIcon src={MEDIA.CHROME_ICON}/>
              Designed for Chrome, other browsers coming soon
            </SubSubtitle>
          </TextContainer>
          </FirstRowColumn>
        </FirstRow>
        <Seperator height={ISDESKTOP ? '96px' : '55px'}/>
        <MaxWidthContainer isDesktop={ISDESKTOP} className='MaxWidthContainer'>
          <SecondRow isDesktop={ISDESKTOP} className='secondRow'>
            <SecondRowInner className='secondRowInner'>
              <SecondRowTitle isDesktop={ISDESKTOP}>
                <TEXTSTYLE.HEADING3>Explore what others are saving</TEXTSTYLE.HEADING3>
              </SecondRowTitle>
              <Seperator height={"28px"}/>
              {this.renderCarousel()}
            </SecondRowInner>
          </SecondRow>
          {ISDESKTOP ? <Seperator height='89px'/> : ''}
          <Instructions isDesktop={ISDESKTOP} />
        </MaxWidthContainer>
          <GreyBG>
          <MaxWidthContainer  isDesktop={ISDESKTOP} className='MaxWidthContainer'>
          <FourthRow className='fourthRow' isDesktop={ISDESKTOP}>
            <TextContainerOuter className='TextContainerOuter' isDesktop={ISDESKTOP}>
              <TextContainer className='TextContainer'>
                <Seperator height="32px"/>
                <BottomText className='BottomText'>
                  <TEXTSTYLE.HEADING3>
                    Curate your own shoppable collections
                  </TEXTSTYLE.HEADING3>
                  <Seperator height="10px"/>
                  <FourthRowText>
                    Moonsift makes it easy to compare, organise and keep track of all your favourite products in one place.
                  </FourthRowText>
                  <Seperator height="30px"/>
                  <TEXTSTYLE.LINK to={R.SIGNUP} >
                    <Button
                      text={this.CtaButtonText(ISDESKTOP)}
                      onClick={() => {segmentEvent("Clicked Get Started Button 2 On Landing Page");}}
                      msstyle={(ISDESKTOP===true)?"cta_alternative_desktop":"cta_alternative_mobile"}>
                    </Button>
                  </TEXTSTYLE.LINK>
                  <Seperator height="16px"/>
                  <SubSubtitle>
                    <ChromeIcon src={MEDIA.CHROME_ICON}/>
                    Designed for Chrome, other browsers coming soon
                  </SubSubtitle>
                </BottomText>
                <Seperator height="32px"/>
              </TextContainer>
              </TextContainerOuter>
            <VideoContainer2 isDesktop={ISDESKTOP}>
              <Seperator height="20px"/>
              <Video src={MEDIA.LANDING_VIDEO_2} loop autoPlay muted playsInline/>
            </VideoContainer2>
          </FourthRow>
          <Seperator height={ISDESKTOP ? '90px' : '50px'} style={{backgroundColor: `${M.COL.BG.LIGHT}`}}/>
        </MaxWidthContainer>
        </GreyBG>
        <MaxWidthContainer isDesktop={ISDESKTOP} className='MaxWidthContainer'>
          <SecondRow isDesktop={ISDESKTOP} className='secondRow'>
            <SecondRowInner className='secondRowInner'>
            <Seperator height={ISDESKTOP ? "89px" : "44px"}/>
              <SecondRowTitle isDesktop={ISDESKTOP}>
                <TEXTSTYLE.HEADING3>Read what people are saying{this.renderStars()}</TEXTSTYLE.HEADING3>
              </SecondRowTitle>
              <Seperator height="28px"/>
              <ReviewsCarousel type="landing"/>
              <Seperator height={"90px"} style={{backgroundColor: `${M.COL.BG.LIGHT}`}}/>
            </SecondRowInner>
          </SecondRow>
        </MaxWidthContainer>
        <BottomBar/>
      </Container>
    );
  }

}

const mapStateToProps = (state) => {
  return ({
    firebaseState:state.firebasestate,
    authUserUID:state.auth.id
  });
};


const mapDispatchToProps = () => {
  return {};
};

export default
connect(
  mapStateToProps,
  mapDispatchToProps
)(
  withFirebase(
    windowSize(
      Landing
    )
  )
);
