/*
View shown if user logs into account that has not been set up.
Could occur if provisioning fails but more likely if they click an authprovider on the LOGIN page not JOIN page...
This is to remind them that they didn't sign in with this method before!

Can also display if login takes too long and user data is not received, so could be offline.

TODO: typescript, camelCase, make functional, non-default exports, redux hooks, design system, split container / component ...
Make sure the latter offline case in particular is compatible with the new way user data is loaded in.
*/
import React, {useState, useEffect} from 'react';
import Button from 'src/mvp22/form-components/Button';
import R from 'src/routes';
import {TEXTSTYLE} from 'src/mvp22/style-components/Text';
import {AddHelmetTitle} from "src/mvp22/core-components/helmet";
import styled from 'styled-components';
import {M} from 'src/mvp22/constants';
import LogoAndText from 'src/mvp22/image-components/LogoAndText';
import useProvisionAccount from 'src/mvp22/firebase-functions/useProvisionAccount';
import {withRouter} from 'react-router-dom';
import {withFirebase} from 'src/mvp22/Firebase';
import { connect } from 'react-redux';
import {firestore_user_set_up_redux} from 'src/mvp22/redux-components/reducers/firestore_user_set_up';

const Container = styled.div`
  background-color:${M.COL.BUTTON.WHITE};
  padding-left:30px;
  padding-right:30px;
  font-size:20px;
  height:100vh;
  display:flex;
  align-items: center;
  justify-content: center;
  color:${M.COL.TEXT.BLACK};
  flex-direction:column;
`;

const MoonsiftLogo = styled.div`
  width:200px;
`;

const ProvisioningErrorText = styled(TEXTSTYLE.BODY3)`
  color: ${M.COL.TEXT.ERROR};
  margin-top:16px;
`;

const ButtonsContainer = styled.div`
  display:flex;
  flex-direction:row;
`;

const HorizSpacer = styled.div`
  width:12px;
`;

const Description = styled(TEXTSTYLE.BODY3)`
  margin-top:12px;
  margin-bottom:12px;
`;

const Title = styled(TEXTSTYLE.HEADING4)`
  margin-top:12px;
`;

const AlternateText = styled(TEXTSTYLE.BODY3)`
  margin-top:24px;
`;

const AlternateLink = styled(TEXTSTYLE.LINK)`
  font-weight: 600;
`;

const AlternateLinkA = styled(TEXTSTYLE.LINKA)`
  font-weight: 600;
`;

/**
* Loading Page Component
*/
const NotProvisioned = (props)=>{
  const firebase = props.firebase;
  const displayName = props.displayName;
  const history = props.history;
  const login_started_timer_on = props.login_started_timer_on;
  const is_provisioned = props.provisioned===true;
  const firestore_user_set_up = props.firestore_user_set_up;
  const auth_user_uid = props.authUserUID;
  const is_logged_in_and_data_loaded = props.authuser_is_loaded===true && props.auth_user_uid!==null;

  const [displayType,setDisplayType] = useState(null);

  // other hooks:
  const {
    is_provisioning,
    provisioning_success,
    provisionAccount
  } = useProvisionAccount(
    firebase
  );

  // actions:
  const callProvisioning = ()=>{
    provisionAccount(displayName);
  };
  const goBack = ()=>{
    props.history.push(R.LOGOUT);
  };
  const forceReload = (event)=>{
    event.preventDefault();
    window.location.reload();
    return false;
  };

  // effects:
  useEffect(
    ()=>{
      // is_provisioned from db get, needs updating to match provisioning_success from api return.
      if (provisioning_success===true && is_logged_in_and_data_loaded && is_provisioned!==true){
        // redirect here but note that will probably end up back here:
        history.push(R.POSTSIGNUP);
        // reload the info if not redirected yet!
        // Have to reload as gets are employed and won't trigger otherwise as authChange not triggered!
        firestore_user_set_up(
          {
            auth_user_uid,
            firebase
          }
        );
      }
    },
    [provisioning_success,is_logged_in_and_data_loaded,firestore_user_set_up,is_provisioned,auth_user_uid,firebase,history]
  );

  useEffect(
    ()=>{
      if (displayType===null){
        setDisplayType((login_started_timer_on)?"LOGIN":"OFFLINE");
      }
    },
    [login_started_timer_on,displayType]
  );

  return(
    <Container>
      {AddHelmetTitle("Loading")}
      <MoonsiftLogo>
        <LogoAndText heighttype="A"/>
      </MoonsiftLogo>
      {
        (displayType==="LOGIN")?
          <>
            <Title>This account is not set up yet</Title>
            <Description>If you already have an account with Moonsift, try logging in with a different method.  Alternatively, you can set up a new account.</Description>
            <ButtonsContainer>
              <Button
                type='button'
                text="Logout and try again"
                msstyle="option_positive"
                onClick={goBack}
                width="160px"
                tabIndex={1}
              />
              <HorizSpacer/>
              <Button
                type='button'
                text="Set up new account"
                msstyle="option_positive"
                onClick={callProvisioning}
                width="160px"
                working={is_provisioning}
                tabIndex={2}
              />
            </ButtonsContainer>
            <AlternateText>
              You may also see this message if you are offline.  In which case check your connection and <AlternateLinkA
                href="#"
                onClick={forceReload}
              >reload this page</AlternateLinkA>.
            </AlternateText>
          </>
        :
          null
      }
      {
        (displayType==="OFFLINE")?
          <>
            <Title>You appear to be offline</Title>
            <Description>Please check your Internet connection and reload this page.</Description>
            <ButtonsContainer>
              <Button
                type='button'
                text="Reload"
                msstyle="option_positive"
                onClick={forceReload}
                width="160px"
                tabIndex={1}
              />
            </ButtonsContainer>
            <AlternateText>
              You may also see this message if your account is not set up properly.  In this case first try <AlternateLink to={R.LOGOUT}>logging out</AlternateLink> and then logging in again.
            </AlternateText>
          </>
        :
          null
      }
      {
        (provisioning_success===false)?
        <ProvisioningErrorText>
          Failed to set up account - please contact us at hello@moonsift.com
        </ProvisioningErrorText>
        :null
      }
    </Container>
  );
};


const mapStateToProps = (state) => {
  return ({
    displayName:state.auth.provider_name,
    provisioned:state.firestore_user_owner.provisioned,
    authuser_is_loaded:state.firebasestate.loaded,
    authUserUID:state.auth.id,
    login_started_timer_on:state.generalui.login_started_timer_on,
  });
};

const mapDispatchToProps = dispatch => {
  return {
    firestore_user_set_up:(data)=>dispatch(firestore_user_set_up_redux(data))
  };
};

// Link up redux to the container:
export default connect(mapStateToProps,mapDispatchToProps)(
  withFirebase(
    withRouter(
      NotProvisioned
    )
  )
);
