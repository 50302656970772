import React from 'react';
import styled from 'styled-components';
import { Paper, CloseIcon } from '@moonsifttech/design-system';

import { ModalContentProps } from './ModalContent.types';

const UnstyledModalContent: React.FC<ModalContentProps> = ({
  className,
  style,
  onClose,
  children,
}) => {
  return (
    <Paper className={className} style={style}>
      <div className="ModalContent-innerContainer">
        <div className="ModalContent-closeButton" onClick={onClose}>
          <CloseIcon />
        </div>
        {children}
      </div>
    </Paper>
  );
};

export const ModalContent = styled(UnstyledModalContent)`
  position: relative;
  padding: 32px 36px;
  width: 480px;

  .ModalContent-closeButton {
    position: absolute;
    top: 24px;
    right: 24px;
    cursor: pointer;
  }
`;
