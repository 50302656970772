/*

A service that only runs once to see if we are running in the app:

*/
import queryString from 'query-string';

export class Mobile {
  isApp: boolean;
  signInToken: string | undefined;

  constructor() {
    // default:
    this.isApp = false;
    // Get the sign in token from the URL:
    const signInToken = queryString.parse(window.location.search).sign_in_token;
    if (typeof signInToken === 'string') {
      this.isApp = true;
      this.signInToken = signInToken;
      // Set local storage for the future:
      if (window.localStorage) {
        window.localStorage.setItem('MOONSIFT_IS_MOBILE', 'YES');
      }
    }
    // see if there is a cookie:
    if (
      window.localStorage &&
      window.localStorage.getItem('MOONSIFT_IS_MOBILE') === 'YES'
    ) {
      this.isApp = true;
    }
  }
}
