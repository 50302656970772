import React from 'react';
import styled from 'styled-components';
import clsx from 'clsx';
import {
  Typography,
  TextField,
  Stack,
  StandardButton,
  DeleteIcon,
} from '@moonsifttech/design-system';

import { ModalContent } from 'src/components/core/ModalContent';
import { EditModalContentProps } from './EditModalContent.types';

export const UnstyledEditModalContent: React.FC<EditModalContentProps> = ({
  className,
  style,
  name,
  description,
  working,
  isPro,
  showConfirmation,
  onEdit,
  onDelete,
  onClose,
  toggleConfirmation,
}) => {
  return (
    <ModalContent
      className={clsx(
        className,
        showConfirmation && 'EditModalContent-confirmation',
      )}
      style={style}
      onClose={onClose}
    >
      <form onSubmit={onEdit}>
        <Stack
          className="EditModalContent-innerContainer"
          direction="column"
          justifyContent="space-between"
        >
          <div>
            {showConfirmation ? (
              <>
                <Typography
                  className="EditModalContent-confirmationHeading"
                  variant="primary.b26"
                  component="h1"
                >
                  Are you sure?
                </Typography>
                <Stack
                  direction="column"
                  justifyContent="center"
                  alignItems="center"
                >
                  <DeleteIcon className="EditModalContent-confirmationIcon" />
                  <Typography
                    variant="primary.r16"
                    align="center"
                    component="p"
                  >
                    The collection “{name}” and all items within it will be
                    deleted. You cannot undo this.
                  </Typography>
                  <StandardButton variant="primary-white" onClick={onDelete}>
                    Delete
                  </StandardButton>
                </Stack>
              </>
            ) : (
              <>
                <Typography variant="primary.b26" component="h1">
                  Edit or delete collection
                </Typography>
                <Typography variant="primary.r14" color="grey.2" component="p">
                  Rename your collection
                </Typography>
                <TextField
                  name="collectionName"
                  defaultValue={name}
                  maxLength={100}
                  fullWidth
                  autoFocus
                />
                {isPro && (
                  <TextField
                    name="collectionDescription"
                    placeholder="Add a description (optional)"
                    defaultValue={description}
                    multiline
                    maxLength={400}
                    rows={5}
                    fullWidth
                  />
                )}
              </>
            )}
          </div>
          {showConfirmation ? (
            <Stack direction="row" justifyContent="flex-end">
              <StandardButton
                variant="primary-white"
                onClick={toggleConfirmation}
              >
                Cancel
              </StandardButton>
            </Stack>
          ) : (
            <Stack direction="row" justifyContent="space-between">
              <StandardButton
                variant="primary-white"
                onClick={toggleConfirmation}
                startIcon={<DeleteIcon />}
              >
                Delete
              </StandardButton>
              <StandardButton
                type="submit"
                variant="primary-black"
                loading={working}
              >
                Save
              </StandardButton>
            </Stack>
          )}
        </Stack>
      </form>
    </ModalContent>
  );
};

export const EditModalContent = styled(UnstyledEditModalContent)`
  height: 536px;

  .ModalContent-innerContainer,
  .EditModalContent-innerContainer,
  form {
    height: 100%;
  }

  form {
    margin-bottom: 0;
  }

  &.EditModalContent-confirmation {
    .EditModalContent-confirmationHeading {
      margin-bottom: 60px;
    }

    .BaseIcon-root.EditModalContent-confirmationIcon {
      font-size: 100px;
      margin-bottom: 12px;
    }
  }

  :not(&.EditModalContent-confirmation) {
    p {
      margin-bottom: 10px;
    }

    ${TextField} {
      margin-bottom: 16px;
    }
  }
`;
