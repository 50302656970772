import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { useSelector } from 'react-redux';

import { RootState } from 'src/index';
import Loading from 'src/mvp22/view-components/Loading';
import { PrivateRouteProps } from './PrivateRoute.types';

// A wrapper for <Route> that redirects to the login
// screen if you're not yet authenticated.
export const PrivateRoute: React.FC<PrivateRouteProps> = ({
  component: Component,
  ...otherProps
}) => {
  const loaded = useSelector<RootState, boolean>(
    (state) => state.firebasestate.loaded,
  );
  const authUserUID = useSelector<RootState, string | null>(
    (state) => state.auth.id,
  );

  return (
    <Route
      {...otherProps}
      render={({ location, ...otherProps }) => {
        if (!loaded) {
          return <Loading />;
        }

        if (authUserUID) {
          return <Component location={location} {...otherProps} />;
        }

        return (
          <Redirect
            to={{
              pathname: '/signin',
              state: { from: location },
            }}
          />
        );
      }}
    />
  );
};
