/*

Essentially a static page about Moonsift.
TODO: typescript, make functional, non-default export...

*/
import React from 'react';
import { Component } from 'react';
import styled from 'styled-components';
import {M} from 'src/mvp22/constants';
import MEDIA from 'src/mvp22/media';
import TopMenu from 'src/mvp22/menu-components/TopMenu';
import BottomBar from 'src/mvp22/menu-components/BottomBar';
import {TEXTSTYLE} from 'src/mvp22/style-components/Text';
import windowSize from 'src/mvp22/WindowSize';
import segmentEvent from "src/mvp22/segment-components/SegmentEvent";
import {AddHelmetTitle} from "src/mvp22/core-components/helmet";
const Container = styled.div`
color:default;
`;

const MainContent = styled.div`
  display:flex;
  flex-direction:column;
  background-color:${M.COL.BG.WHITE};
  color:${M.COL.TEXT.BLACK};
  padding-left: 20px;
  padding-right: 20px;
`;

const Seperator = styled.div`
  height:${props=>props.height};
`;

const RowContainer = styled.div`
  display:flex;
  width:100%;
  overflow:visible;
  justify-content: center;
`;

const GreyRowBG = styled.div`
  display:flex;
  flex-direction:column;
  background: ${M.COL.BG.MID};
  color:${M.COL.TEXT.BLACK};
  padding-left: 20px;
  padding-right: 20px;
`;

const GreyRowContainer = styled(RowContainer)`
  background-color:${M.COL.BG.MID};
  flex-direction:row;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap-reverse;
  padding-bottom: 63px;
  padding-top: 63px;
  @media (min-width: 953px){
    padding-bottom: 110px;
    padding-top: 110px;
  }
  max-width: ${props =>(props.isDesktop===true) ? '1200px' : ''};
  margin:auto;
`;

const WhiteRowContainer = styled(RowContainer)`
  background-color:${M.COL.BG.WHITE};
  flex-direction:row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  @media (min-width: 953px){
    padding-top: 47px;
  }
  max-width: ${props =>(props.isDesktop===true) ? '1200px' : '420px'};
  margin:auto;
`;

const MainHeadingRow = styled(RowContainer)`
  background-color:${M.COL.BG.WHITE};
  margin: 0 auto;
  display:flex;
  flex-direction:column;
  margin-top: calc(${M.HEIGHTS.TOPMENU} + 60px);
  text-align: left;
`;

  //border:1px solid green;
const ImageContainer = styled.div`
  width: 50%;
  flex-basis: 469px;
  flex-grow: 1;
  overflow: visible;
  box-sizing: border-box;
  align-items: center;
  line-height: 0px;
`;

const ImageContainer2 = styled.div`
  width: 50%;
  flex-basis: 469px;
  flex-grow: 1;
  overflow: visible;
  box-sizing: border-box;
  align-items: center;
  line-height: 0px;
`;

const ImageContainer3 = styled.div`
  width: 50%;
  flex-basis: 469px;
  flex-grow: 1;
  overflow: visible;
  box-sizing: border-box;
  align-items: center;
  line-height: 0px;
`;

const TextContainer = styled.div`
  width: ${props =>(props.isDesktop===true) ? '50%' : ''};
  display:flex;
  flex-direction:column;
  align-items:center;
`;

const SubtitleContainer = styled.div`
  flex: 0 0 50%;
  display:flex;
`;

const RightImage = styled.img`
  padding: 0px 16px;
  width: 100%;
  box-sizing: border-box;
  max-width: 450px;

  @media (min-width: 993px){
    position: relative;
    top: 90px;
  }
 `;

 const LeftImage = styled.img`
 font-size: 20px;
  padding: 0px 16px;
  width: 100%;
  box-sizing: border-box;
  max-width: 450px;
  `;

  const BottomImage = styled.img`
    font-size: 20px;
    padding: 0px 16px;
    width: 100%;
    box-sizing: border-box;
    max-width: 450px;
   `;

const SignatureImage = styled.img`
  max-width: 100%;
  `;

//   border:5px solid green;
const ItemText = styled.div`
color:default;
`;

const LeftText = styled(ItemText)`
  max-width:450px;
  text-align:left;
`;

const RightText = styled(ItemText)`
  max-width:450px;
  text-align:left;
`;

const LeftSubtitle = styled(TEXTSTYLE.HEADING3)`
  max-width:453px;
  text-align:left;
`;

const RightSubtitle = styled(TEXTSTYLE.HEADING3)`
  max-width:274px;
  text-align:left;
`;

const BodyParagraph = styled(TEXTSTYLE.BODY2)`
  max-width:420px;
  margin-bottom:30px;
  text-align:left;
`;

const AlexAndDavidSignatureRow = styled.div`
  display: block;
  height: 50px;
  text-align: left;
  margin-top: 30px;
  margin-bottom: 30px;
`;

/**
* AboutUs Page Component
*/
class AboutUs extends Component {
  componentDidMount() {
      segmentEvent("Viewed About");
  }

  render(){
    const ISDESKTOP = this.props.windowWidth>=M.MOBILESWITCH;
    const SEPERATOR = <Seperator height="68px"/>;
    return(
      <Container className='Container'>
        <TopMenu/>
        {AddHelmetTitle("About us")}
        <MainContent className='MainContent'>
          <WhiteRowContainer isDesktop={ISDESKTOP} className='WhiteRowContainer'>
            <TextContainer isDesktop={ISDESKTOP} className='TextContainer'>
              <LeftText className='LeftText'>
              <MainHeadingRow className='MainHeadingRow'>
                  <TEXTSTYLE.HEADING1>
                    About Moonsift
                  </TEXTSTYLE.HEADING1>
                  <Seperator height='46px'/>
              </MainHeadingRow>
              <SubtitleContainer>
                <LeftSubtitle className='LeftSubtitle'>
                  Re-imagining shopping online
                </LeftSubtitle>
              </SubtitleContainer>
                  <Seperator height="10px"/>
                <BodyParagraph>
                  Moonsift was founded in London in 2018. It has been designed in collaboration with a community of people that love to shop online, but are frustrated with the current disjointed experience.
                </BodyParagraph>
                <BodyParagraph>
                  The hours spent scrolling through webpages, so many tabs open you lose track of what’s what, separate wishlists with every retailer and unhelpful ads that follow you around trying to sell you things you’ve already bought!
                </BodyParagraph>
                <BodyParagraph>
                  We are creating an online space for fashion lovers, giving them the ability to centralise and curate their most wanted items, and discover more of the things they love.
                </BodyParagraph>
                <BodyParagraph>
                  As we work to re-imagine shopping online, we welcome your ideas and feedback.
                </BodyParagraph>
                <AlexAndDavidSignatureRow className='AlexAndDavidSignatureRow'>
                  <SignatureImage alt='signature' src={MEDIA.ALEX_DAVID_SIGNATURE}/>
                </AlexAndDavidSignatureRow>
              </LeftText>
            </TextContainer>
            <ImageContainer className='ImageContainer'>
              <RightImage src={MEDIA.ABOUT_US_COMMUNITYPICTURES}/>
            </ImageContainer>
          </WhiteRowContainer>
          </MainContent>
          <Seperator height="63px"/>

        <GreyRowBG className='greyRowBG'>
          <GreyRowContainer isDesktop={ISDESKTOP} className='GreyRowContainer'>
            <ImageContainer2 className='ImageContainer2'>
              <LeftImage src={MEDIA.ABOUT_US_PRODUCTVISIONIMAGES}/>
            </ImageContainer2>
            <TextContainer isDesktop={ISDESKTOP} className='TextContainer'>
              <RightText className='RightText'>
              <RightSubtitle className='RightSubtitle'>
                Moonsift’s Product Vision
              </RightSubtitle>
                  <Seperator height="10px"/>
                <BodyParagraph>
                  18 months in the making, Moonsift’s proprietary technology makes it easier than ever before to save and keep track of all the things you’re shopping for in one place. But this is only the beginning.
                </BodyParagraph>
                <BodyParagraph>
                  Moonsift’s ultimate aim is to understand each user’s unique taste so it can automatically track down products from across the internet you most want to know about - but may not otherwise find. Think Spotify for shopping.
                </BodyParagraph>
                <BodyParagraph>
                  We have started this journey with a focus on style conscious apparel shoppers, but look forward to expanding our coverage into homeware, art and beyond. New retailers will be added weekly based on our community’s requests.
                </BodyParagraph>
              </RightText>
            </TextContainer>
          </GreyRowContainer>
        </GreyRowBG>

        <Seperator height="63px"/>
        <MainContent className='MainContent'>
        <WhiteRowContainer isDesktop={ISDESKTOP} className='WhiteRowContainer'>
          <TextContainer isDesktop={ISDESKTOP} className='TextContainer'>
            <LeftText className='LeftText'>
              <SubtitleContainer>
                <LeftSubtitle>
                  We believe your data should work for you
                </LeftSubtitle>
              </SubtitleContainer>
                <Seperator height="10px"/>
              <BodyParagraph>
                As you browse and shop online today, every interaction you make is being captured by the sites you visit. Powered by artificial intelligence (AI), advertisers often use your data to help benefit their sales agenda - rather than keeping the best interest of the consumer at heart.
              </BodyParagraph>
              <BodyParagraph>
                Moonsift believes your data should work for you. We want to put the power of AI into the hands of consumers, helping you find more of the things you genuinely want to buy.
              </BodyParagraph>
              <BodyParagraph>
                We believe in empowering individuality and we believe in a personalised product discovery experience that is unbiased.
              </BodyParagraph>
            </LeftText>
          </TextContainer>
          <ImageContainer3 className='ImageContainer3'>
            <BottomImage src={MEDIA.ABOUT_US_CONSUMERDATA}/>
          </ImageContainer3>
        </WhiteRowContainer>
        </MainContent>
          {(ISDESKTOP)?SEPERATOR:""}
          <Seperator height="68px"/>
        <BottomBar/>
      </Container>
    );
  }
}

export default windowSize(AboutUs);
