/*

Kind of a dummy page that just displays the login as it is wrapped in the requiresAuth component.
Done this way as not logged in on home page / profile is not wrapped in requiresAuth as can have view by non-logged in users.

TODO: typescript, camelCase, make functional, non-default exports, redux hooks, design system, split container / component ...

*/
import React from 'react';
import { Component } from 'react';
import styled from 'styled-components';
import {M} from 'src/mvp22/constants';
import {withRouter} from 'react-router-dom';
import {TEXTSTYLE} from 'src/mvp22/style-components/Text';
import queryString from 'query-string';
import TopMenu from 'src/mvp22/menu-components/TopMenu';
import {FullBlank} from 'src/mvp22/menu-components/TopMenu';
import {requiresAuth} from 'src/mvp22/redux-components/requiresAuth';
import { connect } from 'react-redux';

const Container = styled.div`
  background-color:${M.COL.BG.WHITE};
  color:${M.COL.TEXT.BLACK};
  padding-left:30px;
  padding-right:30px;
  flex-direction:column;
  min-width:${M.MINWIDTH};
  box-sizing:border-box;
  align-items: center;
  justify-content: center;
  display:flex;
  width:100%;
  height: 100%;
`;

/**
* Post Login Moonsift Component
*/
class PostSignIn extends Component {
  constructor(props){
    super(props);
    this.goForward = this.goForward.bind(this);
  }

  goForward(){
    const after = queryString.parse(this.props.location.search).after;
    if (this.props.authUserUID !== null){
      this.props.history.push((after)?after:"");
    }
  }

  componentDidMount(){
    this.goForward();
  }

  componentDidUpdate(){
    this.goForward();
  }

  render(){
    const after = queryString.parse(this.props.location.search).after;
    return(
      <FullBlank>
        <TopMenu/>
        <Container>
          <TEXTSTYLE.LINK to={after?after:"/"}>
            <TEXTSTYLE.HEADING4>
              Redirect to homepage...<br/>(click here if not automatic)
            </TEXTSTYLE.HEADING4>
          </TEXTSTYLE.LINK>
        </Container>
      </FullBlank>
    );
  }
}

const mapStateToProps = (state) => {
  return ({
    authUserUID:state.auth.id
  });
};

const mapDispatchToProps = () => {
  return {};
};

export default
connect(
  mapStateToProps,
  mapDispatchToProps
)(
  withRouter(
    requiresAuth(
      PostSignIn
    )
  )
);
