import React from 'react';
import { ChevronDownThinIcon } from '@moonsifttech/design-system';

import { ModalMenu } from 'src/components/core/ModalMenu';
import { SquareButton } from 'src/components/collection/SquareButton';
import { VisibilityMenu } from 'src/components/collection/VisibilityMenu';
import { VisibilityButtonProps } from './VisibilityButton.types';

export const VisibilityButton: React.FC<VisibilityButtonProps> = ({
  className,
  style,
  isMenuOpen,
  working,
  menuOptions,
  level,
  onClick,
  onClose,
  onMenuSelect,
  children,
}) => {
  return (
    <ModalMenu
      isOpen={isMenuOpen}
      onRequestClose={onClose}
      menu={
        <VisibilityMenu
          onSelect={onMenuSelect}
          options={menuOptions}
          level={level}
        />
      }
    >
      <SquareButton
        className={className}
        style={style}
        loading={working}
        icon={<ChevronDownThinIcon />}
        onClick={onClick}
      >
        {children}
      </SquareButton>
    </ModalMenu>
  );
};
