import React from 'react';
import styled from 'styled-components';
import clsx from 'clsx';
import { Typography, Stack } from '@moonsifttech/design-system';

import { NewCollectionButton } from 'src/components/onboarding/NewCollectionButton';
import { PostinstallThreeProps } from './PostinstallThree.types';

const PostinstallThree: React.FC<PostinstallThreeProps> = ({
  className,
  style,
  onCreateCollection,
}) => {
  return (
    <Stack
      className={clsx('PostinstallThree-container', className)}
      style={style}
      justifyContent="center"
      alignItems="center"
      direction="column"
    >
      <Typography
        className="PostinstallThree-title"
        variant="primary.b42"
        component="h1"
      >
        You&apos;re all set!
      </Typography>

      <Typography
        className="PostinstallThree-subtitle"
        variant="primary.l26"
        component="h2"
      >
        Create Your First Collection
      </Typography>

      <NewCollectionButton onClick={onCreateCollection} />
    </Stack>
  );
};

const StyledPostinstallThree = styled(PostinstallThree)`
  &.PostinstallThree-container {
    height: 100vh;
  }

  .PostinstallThree-title {
    margin-top: 0;
    margin-bottom: 40px;
    text-align: center;
  }

  .PostinstallThree-subtitle {
    margin-top: 0;
    margin-bottom: 20px;
  }

  .PostinstallThree-panels {
    margin-bottom: 50px;

    .PostinstallThree-panel {
      &:last-child {
        margin-right: 0;
      }

      .PostinstallThree-paper {
        height: 393px;
        background: var(--grey-7);
        padding: 30px;
      }
    }
  }

  .PostinstallThree-buttons {
    display: flex;
    justify-content: center;

    > * {
      margin-right: 12px;

      &:last-child {
        margin-right: 0;
      }
    }
  }
`;

export { StyledPostinstallThree as PostinstallThree };
