/*

Circular image for someone's profile picture or just the first letter of their name if no image is provided.

TODO: typescript, camelcase

*/
import styled from 'styled-components';
import React from 'react';
import {M} from 'src/mvp22/constants';

const ProfileImageDisplay = styled.img`
  height:${props=>props.scale+"px"};
  width:${props=>props.scale+"px"};
  border-radius:${props=>props.scale/2+"px"};
  align-content: center;
  object-fit: cover;
`;

const DefaultProfileImage = styled.div`
  height:${props=>props.scale+"px"};
  width:${props=>props.scale+"px"};
  align-content: center;
  object-fit: cover;
  border-radius:${props=>props.scale/2+"px"};
  background-color:${M.COL.BG.LIGHT};
  display: flex;
  align-items: center;
  justify-content: center;
  border: solid 1px ${M.COL.TEXT.BLACK};
`;

const Letter = styled.div`
  font-family:${M.FONT};
  font-weight: bold;
  font-style: normal;
  margin:0px;
  font-size:${props=>props.scale/2+"px"};
  color:${M.COL.TEXT.BLACK};
`;

const ProfileImage = (props)=>{
  if (props.user_uid && props.profile_image_code && props.profile_image_code!=="default"){
    return (
      <ProfileImageDisplay
        scale={props.scale}
        src={`https://storage.googleapis.com/${process.env.REACT_APP_FIREBASE_PROJECTID}-profile-images/userprofile_${props.user_uid}_${props.profile_image_code}.jpg`}
      />
    );
  }
  else{
    return (
      <DefaultProfileImage
        scale={props.scale}
      >
        <Letter
         scale={props.scale}
        >
          {props.name?props.name.slice(0,1).toUpperCase():""}
        </Letter>
      </DefaultProfileImage>
    );
  }
};

export default ProfileImage;
