import React from 'react';
import styled, { createGlobalStyle } from 'styled-components';
import clsx from 'clsx';
import {
  Typography,
  TextField,
  Stack,
  StandardButton,
  DeleteIcon,
  Paper,
  MoveButton,
  modalClassNames,
} from '@moonsifttech/design-system';

import { EditModalContentProps } from './EditModalContent.types';

// Makes drawers as tall as the screen but leaving 20px on the top
const EditDrawerContentGlobalStyle = createGlobalStyle`
  .${modalClassNames.content} {
    height: calc(100% - 20px);
  }
`;

export const UnstyledEditDrawerContent: React.FC<EditModalContentProps> = ({
  className,
  style,
  name,
  description,
  working,
  isPro,
  showConfirmation,
  onEdit,
  onDelete,
  onClose,
  toggleConfirmation,
}) => {
  return (
    <>
      <EditDrawerContentGlobalStyle />
      <Paper
        className={clsx(
          className,
          showConfirmation && 'EditDrawerContent-confirmation',
        )}
        style={style}
      >
        <form onSubmit={onEdit}>
          <Stack
            className="EditDrawerContent-controls"
            direction="row"
            justifyContent="space-between"
            alignItems="center"
          >
            <MoveButton
              className="EditDrawerContent-backButton"
              variant="outlined"
              direction="left"
              onClick={showConfirmation ? toggleConfirmation : onClose}
            />
            {!showConfirmation && (
              <Stack direction="row" spacing={12}>
                <StandardButton
                  variant="primary-white"
                  onClick={toggleConfirmation}
                  startIcon={<DeleteIcon />}
                >
                  Delete
                </StandardButton>
                <StandardButton
                  type="submit"
                  variant="primary-black"
                  loading={working}
                >
                  Save
                </StandardButton>
              </Stack>
            )}
          </Stack>
          <div className="EditDrawerContent-innerContainer">
            <div className="EditDrawerContent-transparency" />
            <div className="EditDrawerContent-content">
              {showConfirmation ? (
                <>
                  <Typography
                    className="EditDrawerContent-confirmationHeading"
                    variant="primary.b26"
                    component="h1"
                  >
                    Are you sure?
                  </Typography>
                  <Stack
                    direction="column"
                    justifyContent="center"
                    alignItems="center"
                  >
                    <DeleteIcon className="EditDrawerContent-confirmationIcon" />
                    <Typography
                      variant="primary.r16"
                      align="center"
                      component="p"
                    >
                      The collection “{name}” and all items within it will be
                      deleted. You cannot undo this.
                    </Typography>
                    <StandardButton variant="primary-white" onClick={onDelete}>
                      Delete
                    </StandardButton>
                  </Stack>
                </>
              ) : (
                <>
                  <Typography variant="primary.b26" component="h1">
                    Edit or delete collection
                  </Typography>
                  <Typography
                    className="EditDrawerContent-formHeading"
                    variant="primary.r14"
                    color="grey.2"
                    component="p"
                  >
                    Rename your collection
                  </Typography>
                  <TextField
                    name="collectionName"
                    defaultValue={name}
                    maxLength={100}
                    fullWidth
                    autoFocus
                  />
                  {isPro && (
                    <TextField
                      name="collectionDescription"
                      placeholder="Add a description (optional)"
                      defaultValue={description}
                      multiline
                      maxLength={400}
                      rows={5}
                      fullWidth
                    />
                  )}
                </>
              )}
            </div>
          </div>
        </form>
      </Paper>
    </>
  );
};

export const EditDrawerContent = styled(UnstyledEditDrawerContent)`
  padding: 24px 16px 0;
  height: 100%;

  form {
    height: 100%;
  }

  .EditDrawerContent-backButton.MoveButton-outlined {
    padding: 0;

    .MoveButton-button {
      width: 40px;
      height: 40px;

      .BaseIcon-root {
        font-size: 20px;
      }
    }
  }

  .EditDrawerContent-confirmationHeading {
    margin-bottom: 60px;
  }

  .BaseIcon-root.EditDrawerContent-confirmationIcon {
    font-size: 100px;
    margin-bottom: 12px;
  }

  .EditDrawerContent-innerContainer {
    position: relative;
    height: calc(100% - 40px);
    overflow-y: scroll;

    .EditDrawerContent-transparency {
      position: sticky;
      top: 0;
      left: 0;
      width: 100%;
      height: 32px;
      z-index: 10;
      background-image: linear-gradient(
        to top,
        rgba(255, 255, 255, 0),
        rgba(255, 255, 255, 1),
        rgba(255, 255, 255, 1)
      );
    }
  }

  .EditDrawerContent-formHeading {
    margin-bottom: 10px;
  }

  ${TextField} {
    margin-bottom: 16px;
  }
`;
