import React from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import { RouterSwitchProps } from './RouterSwitch.types';
import R from 'src/routes';

import Loading from 'src/mvp22/view-components/Loading';
import Landing from 'src/mvp22/view-components/Landing';
import ChristmasLanding from 'src/mvp22/view-components/ChristmasLanding';
import Join from 'src/mvp22/view-components/Join';
import FourOhFour from 'src/mvp22/view-components/FourOhFour';
import Retailers from 'src/mvp22/view-components/Retailers';
import PostSignUp from 'src/mvp22/view-components/PostSignUp';
import Tastemakers from 'src/mvp22/view-components/Tastemakers';
import TastemakersApply from 'src/mvp22/view-components/TastemakersApply';
import TermsAndConditions from 'src/mvp22/view-components/TermsAndConditions';
import PrivacyPolicy from 'src/mvp22/view-components/PrivacyPolicy';
import Logout from 'src/mvp22/view-components/Logout';
import ForgotPassword from 'src/mvp22/view-components/ForgotPassword';
import AboutUs from 'src/mvp22/view-components/AboutUs';
import Explore from 'src/mvp22/view-components/Explore';
import Profile from 'src/mvp22/view-components/Profile';
import EditProfile from 'src/mvp22/view-components/EditProfile';
import EditUsername from 'src/mvp22/view-components/EditUsername';
import EditEmailPreferences from 'src/mvp22/view-components/EditEmailPreferences';
import EditAccountPreferences from 'src/mvp22/view-components/EditAccountPreferences';
import PostSignIn from 'src/mvp22/view-components/PostSignIn';
import EditEmailUnsubscribeFromList from 'src/mvp22/view-components/EditEmailUnsubscribeFromList';
import ProLanding from 'src/mvp22/view-components/ProLanding';
import ProShare from 'src/mvp22/view-components/ProShare';
import AddProduct from 'src/mvp22/view-components/AddProduct';
import Notifications from 'src/mvp22/view-components/Notifications';
import NotProvisioned from 'src/mvp22/view-components/NotProvisioned';
// Refactored files:
import { PrivateRoute } from 'src/components/core/PrivateRoute';
import { Collection } from 'src/views/Collection';
import { CreateCollection } from 'src/views/CreateCollection';
import { PostinstallOne } from 'src/views/PostinstallOne';
import { PostinstallTwo } from 'src/views/PostinstallTwo';
import { PostinstallThree } from 'src/views/PostinstallThree';
import { ReportProblem } from 'src/views/ReportProblem';

export const RouterSwitch: React.FC<RouterSwitchProps> = ({
  authUserUID,
  authUserSet,
  displayUsername,
  showNotProvisioned,
  isApp,
}) => {
  const renderProfileRedirect = () => {
    var redirectPath: string | false = false;
    if (displayUsername) {
      redirectPath = '/@' + displayUsername;
    } else if (authUserUID) {
      // TODO: Make this after loading the displayusername!
      redirectPath = '/@' + authUserUID;
    }
    return redirectPath !== false ? (
      <Redirect to={redirectPath} />
    ) : (
      <Landing />
    );
  };

  const renderProfileRedirectLanding = () => {
    return authUserSet !== true || (isApp && authUserUID === null) ? (
      <Loading />
    ) : authUserUID !== null ? (
      renderProfileRedirect()
    ) : (
      <Landing />
    );
  };

  return (
    <Switch>
      <Route path={R.SIGNUP} component={Join} />
      <Route>
        {!showNotProvisioned ? (
          <Switch>
            <Route
              exact={true}
              path={'/'}
              render={renderProfileRedirectLanding}
            />
            <Route path={R.LANDING} component={Landing} />
            <Route path={R.CHRISTMAS_LANDING} component={ChristmasLanding} />
            <Route
              path={R.LIZZIEYOUTUBE}
              component={() => <Redirect to="/" />}
            />
            <Route
              path={R.LIZZIEYOUTUBEURI}
              component={() => <Redirect to="/" />}
            />
            <Route
              path={R.PRO_LANDING + '/:pro_referral_code'}
              component={ProLanding}
            />
            <Route exact={true} path={R.PRO_LANDING} component={Landing} />
            <Route path={R.PRO_SHARE} component={ProShare} />
            <Route path={R.LOGOUT} component={Logout} />
            <Route
              path={R.RETAILERS_AZ}
              render={() => <Retailers list_type="az" />}
            />
            <Route
              path={R.RETAILERS_FEATURED}
              render={() => <Retailers list_type="trending" />}
            />
            <Route
              path={R.RETAILERS_SEARCH}
              render={() => <Retailers list_type="search" />}
            />
            <Route
              path={R.RETAILERS}
              render={() => <Retailers list_type="az" />}
            />
            <PrivateRoute path={R.REPORTPROBLEM} component={ReportProblem} />
            <Route path={R.POSTSIGNUP} component={PostSignUp} />
            <Route
              exact={true}
              path={R.TASTEMAKERLANDING}
              component={Tastemakers}
            />
            <Route path={R.TASTEMAKERSAPPLY} component={TastemakersApply} />
            <Route path={R.LOADING} component={Loading} />
            <Route path={R.TERMS} component={TermsAndConditions} />
            <Route path={R.PRIVACYPOLICY} component={PrivacyPolicy} />
            <Route path={R.HOME} render={renderProfileRedirectLanding} />
            <Route path={R.SIGNIN} component={PostSignIn} />
            <Route
              exact={true}
              path={R.USER_PROFILE}
              render={renderProfileRedirect}
            />
            <Route path={R.USER_PROFILE} component={Profile} />
            <Route path={R.LIKED} render={() => <Profile type="liked" />} />
            <Route
              path={R.PURCHASED}
              render={() => <Profile type="purchased" />}
            />
            <PrivateRoute
              path={R.COLLECTIONS_CREATE}
              exact
              component={CreateCollection}
            />
            <Route
              path={`${R.COLLECTION}/:username/:collectionUID`}
              component={Collection}
            />
            <Route path={R.FORGOTPASSWORD} component={ForgotPassword} />
            <PrivateRoute
              path={R.POSTINSTALL_ONE}
              component={PostinstallOne}
              exact
            />
            <PrivateRoute
              path={R.POSTINSTALL_TWO}
              component={PostinstallTwo}
              exact
            />
            <PrivateRoute
              path={R.POSTINSTALL_THREE}
              component={PostinstallThree}
              exact
            />
            <Route path={R.ADD_PRODUCT} component={AddProduct} />
            <Route path={R.EXPLORE_FEATURED} render={() => <Explore />} />
            <Route path={R.ABOUT_US} component={AboutUs} />
            <Route path={R.NOTIFICATIONS} component={Notifications} />
            <Route path={'/@:user_map'} component={Profile} />
            <Route exact={true} path={R.EDIT_PROFILE} component={EditProfile} />
            <Route
              exact={true}
              path={R.EDIT_USERNAME}
              component={EditUsername}
            />
            <Route
              exact={true}
              path={R.EDIT_EMAIL_PREFERENCES}
              component={EditEmailPreferences}
            />
            <Route
              exact={true}
              path={R.EDIT_ACCOUNT_PREFERENCES}
              component={EditAccountPreferences}
            />
            <Route
              path={R.UNSUBSCRIBE_FROM_LIST}
              component={EditEmailUnsubscribeFromList}
            />
            <Route component={FourOhFour} />
          </Switch>
        ) : (
          <>
            <NotProvisioned />
            <Route path={R.LOGOUT} component={Logout} />
          </>
        )}
      </Route>
    </Switch>
  );
};
