/*

A button for the auth providers on the sign up / log in forms.
i.e. Facebook and Google

TODO: Replace with design system.

*/

import React from 'react';
import styled from 'styled-components';
import {M} from 'src/mvp22/constants';
import MEDIA from 'src/mvp22/media';
import {TEXTSTYLE} from 'src/mvp22/style-components/Text';

const CTA3rd = styled.button`
  cursor:pointer;
  outline-width: 0;
  box-sizing:border-box;
  display:flex;
  justify-content:center;
  align-items:center;
  width:${props => props.width};
  max-width:${props => props.maxWidth};
  position:relative;
  background-color:${M.COL.BUTTON.WHITE};
  color:${M.COL.TEXT.BLACK};
  border-radius:20px;
  border: 1px solid ${M.COL.TEXT.BLACK};
  height:42px;
  padding: 0px 26px;
  &:hover {
   box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
  }
  &:focus {
   box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
  }
  &:active {
   box-shadow: 0px 6px 12px rgba(0, 0, 0, 0.25);
  }
`;

const ProviderLogo = styled.img`
  width: 20px;
  height: 20px;
  margin-right: 10px;
`;

const ButtonText = styled(TEXTSTYLE.HEADING5)`
  color:default;
`;

const AuthProviderButton = (props) => {
  return(
    <CTA3rd onClick={props.handle_authorise}>
      <ProviderLogo src={props.provider==='Facebook' ? MEDIA.FACEBOOK_SIGNUP_LOGO : MEDIA.GOOGLE_SIGNUP_LOGO}/>
        <ButtonText>Continue with {props.provider}</ButtonText>
    </CTA3rd>
  );
};

export default AuthProviderButton;
