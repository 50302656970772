/*

This page is used to allow users to search the list of supported retailers

TODO: typescript, camelCase, make functional, non-default exports, redux hooks, design system, split container / component ...

*/
import React from 'react';
import { Component } from 'react';
import {withRouter} from 'react-router-dom';
import Fuse from 'fuse.js';
import { Typography, Link, InfoIcon } from '@moonsifttech/design-system';
import {AddHelmetTitle} from "src/mvp22/core-components/helmet";
import styled from 'styled-components';
import {M} from 'src/mvp22/constants';
import {TEXTSTYLE} from 'src/mvp22/style-components/Text';
import TopMenu from 'src/mvp22/menu-components/TopMenu';
import {FullBlank} from 'src/mvp22/menu-components/TopMenu';
import get_supported_sites from 'src/mvp22/firebase-functions/get_supported_sites';
import {withFirebase} from 'src/mvp22/Firebase';
import MEDIA from 'src/mvp22/media';
import segmentEvent from "src/mvp22/segment-components/SegmentEvent";
import R, { EXTERNAL } from 'src/routes';
import LiveSearch from 'src/mvp22/form-components/LiveSearch';
import LoadingDisplay from 'src/mvp22/image-components/LoadingDisplay';
import windowSize from 'src/mvp22/WindowSize';

const Container = styled.div`
  background-color:${M.COL.BG.WHITE};
  color:${M.COL.TEXT.BLACK};
  display:flex;
  height:100vh;
  align-items: center;
  width: 100%;
  justify-content: center;
`;

const MainContainer = styled.div`
  height:100%;
  align-items: center;
  flex-direction:column;
  display:flex;
  width: 100%;
`;

const HeaderAndTabsOuter = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  height: 280px;
  position: fixed;
  z-index: 10;
  background-color: white;
`;

const HeaderAndTabsContainer = styled.div`
    display: flex;
    margin-top: 56px;
    text-align: center;
    width: 100%;
    flex-direction: column;
    max-width: 1116px;
    position: fixed;
    background-color: ${M.COL.BG.WHITE};
    z-index: 10;
    height: ${props => props.isOnSearch ? '100%' : ''};
`;

const Seperator = styled.div`
  height:${props => props.height};
`;

const RetailerLinksContainer = styled.div`
  padding-bottom: 95px;
  width: 100%;
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  align-items: flex-start;
  margin-top: 240px
`;

export const ExternalLink = styled(TEXTSTYLE.EXTERNALLINK)`
  display: block;
  &:hover{
    color:${M.COL.TEXT.BLACK};
  }
`;

const SelectorBar = styled.div`
  width:100%;
  display:flex;
  flex-direction:row;
  align-items: center;
  margin-bottom: 29px;
  justify-content: space-between;
`;

const Tab = styled(TEXTSTYLE.LINK)`
  letter-spacing: 0.1em;
  cursor: pointer;
  &:hover {
    img{
      opacity: 1.0;
    }
    div{
      color: ${M.COL.TEXT.BLACK};
    }
  }
  color: ${props => props.active ? M.COL.TEXT.BLACK : M.COL.TEXT.LIGHT};
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
`;

const SearchTab = styled(Tab)`
  border-bottom: ${props => props.active ? `4px solid ${M.COL.TEXT.YELLOW}` : 'none'};
  display: flex;
  align-items: center;
  position: relative;
`;

const FeaturedTab = styled(Tab)`
  border-bottom: ${props => props.active ? `4px solid ${M.COL.TEXT.YELLOW}` : 'none'};
`;

const AzTab = styled(Tab)`
  border-bottom: ${props => props.active ? `4px solid ${M.COL.TEXT.YELLOW}` : 'none'};
  margin-right: 19px;
`;

const LeftTabs = styled.div`
  display: flex;
  margin-left: 20px;
`;

const SearchContainer = styled.span`
  display: flex;
  margin-right: 30px;
  position: relative;
  bottom: 2px;
`;

const SearchIcon = styled.img`
  opacity: 0.5;
  margin-left: 4px;
  position: relative;
`;

const AlphabeticalRetailersBlock = styled.div`
  display: block;
  background-color: ${props => props.odd ? M.COL.BG.WHITE : M.COL.BG.LIGHT};
  @media (min-width: 1132px){
      justify-content: center;
  }
  width: 100%;
`;

const AlphabeticalRetailersBlockInner = styled.div`
  max-width: 1116px;
  display: block;
  margin-top: 75px;
  margin-bottom: 57px;
  overflow-x: hidden;
  margin-left: auto;
  margin-right: auto;
`;

const ListWrapper = styled.div`
  display: flex;
  width: 100%;
`;

const RetailerList = styled.div`
  column-count: 1;
  @media(min-width: 716px){
    column-count: 2;
  }
  @media(min-width: 1015px){
    column-count: 3;
  }
  width: 100%;
  text-align: left;
`;

export const RetailerListItemWrapper = styled.li`
  width: 300px;
  margin-bottom: 18px;
  display: inline-block;
`;

export const RetailerName = styled(TEXTSTYLE.BODY2)`
  &:hover {
    text-decoration: underline;
  }
`;

const LetterContainer = styled(TEXTSTYLE.HEADING2)`
  text-align: left;
  width: 50px;
  margin-left: 20px;
  margin-right: 100px;
`;

const FeaturedRetailerList = styled.div`
  column-count: 1;
  @media(min-width: 716px){
    column-count: 2;
  }
  @media(min-width: 1015px){
    column-count: 3;
  }
  width: 100%;
  text-align: left;
`;

const FeaturedListOuter = styled.div`
    width: 100%;
    overflow-x: hidden;
    display: block;
  `;

const FeaturedListInner = styled.div`
  flex-direction: column;
  max-width: 1116px;
  margin-top: 75px;
  margin-bottom: 75px;
  display: block;
  margin-left: auto;
  margin-right: auto;
`;

const FeaturedListWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 20px;
  column-count: 3;
  width: 100%;
  text-align: left;
  margin-bottom:83px;
`;

const LoadingContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 50%;
`;

export const RetailerListItem = (props) => {
  const LINK_OUT = (props.retailer[2])?props.retailer[2]:"https://"+props.retailer[1];
  return(
    <RetailerListItemWrapper className='featuredListItem'>
      <ExternalLink href={LINK_OUT} target='_blank'>
        <RetailerName>{props.retailer[0] + ' '}</RetailerName>
      </ExternalLink>
    </RetailerListItemWrapper>
  );
};

/**
* Retailers Moonsift Component
*/
class Retailers extends Component {
  constructor(props){
    super(props);
    this.state =  { retailers: [], is_loading: true};
    this.goBack = this.goBack.bind(this);
  }

  componentDidMount() {
    segmentEvent("Viewed Retailers Page");
    this.setSupportedSites();
  }

  componentDidUpdate(){
    this.updateViewingState();
  }

  updateViewingState(){
    const active_tab = (this.props.list_type)?this.props.list_type:"az";
    this.setState(
      (prevState)=>{
        const active_tab_change = (active_tab !== prevState.active_tab);
        if (active_tab_change){
          return(
            {
              active_tab
            }
          );
        }
      }
    );
  }

  setSupportedSites() {
    get_supported_sites(this.props.firebase).then(response => {
      if (response.resolved===true){
        let retailers = response.data.names_array;
        // sorts the retailers alphabetically
        retailers.sort((a, b) => {
          if (a[0].toUpperCase() > b[0].toUpperCase()) {
            return 1;
          }
          if (a[0].toUpperCase() < b[0].toUpperCase()) {
            return -1;
          }
          return 0;
        });

        // to help us group the retailers by their first letters
        retailers.forEach(retailer => {
          return (retailer.firstLetter = retailer[0][0].toUpperCase());
        });

        // groups retailers by their first letter property
        const retailers_grouped_by_first_letter = {};
        let letters = '';
        for (let i = 0, length = retailers.length; i < length; i++) {
          let letter_key = retailers[i].firstLetter;
          if (letter_key.match(/[a-zA-Z]/) === null) {
            letter_key = '#'; // anything that isn't a letter should be grouped under '#'
          }
          if (letters.indexOf(letter_key) === -1) { // if the current letter isn't in letters array already (the first loop around it never is)
            letters += letter_key.toUpperCase();
            retailers_grouped_by_first_letter[letter_key] = [retailers[i]]; // add retailer to its letter group.
          } else {
            retailers_grouped_by_first_letter[letter_key].push(retailers[i]); // add retailer to its letter group.
          }
        }

        const fuse = new Fuse(retailers, {keys: ["0", "1"]});
        const retailers_lookup_by_siteuid = {};
        retailers.forEach(
          retailer=>retailers_lookup_by_siteuid[retailer[1]]=retailer
        );
        const trending_retailers = (response.data.trending_retailers)?response.data.trending_retailers:[];

        this.setState({
          retailers,
          trending_retailers,
          retailers_grouped_by_first_letter,
          retailers_lookup_by_siteuid,
          is_loading: false,
          fuse
        });
      }
    });
  }

  goBack(event){
    event.preventDefault();
    this.props.history.goBack();
    return false;
  }

  listRetailers() {
    if(this.state.active_tab==='az'){
      return(
        Object.keys(this.state.retailers_grouped_by_first_letter).map((letter_key, i) =>{
            return(
              <AlphabeticalRetailersBlock className='AlphabeticalRetailersBlock' odd={i % 2 === 0 ? true : false} key={letter_key}>
                <AlphabeticalRetailersBlockInner className='AlphabeticalRetailersBlockInner'>
                  <ListWrapper className='ListWrapper'>
                    <LetterContainer>
                      {letter_key}
                    </LetterContainer>
                    <RetailerList className='RetailerList'>
                      {
                        this.state.retailers_grouped_by_first_letter[letter_key].map((retailer, i) => {
                          return(
                            <RetailerListItem retailer={retailer} key={i} className='featuredListItem'/>
                          );
                        })
                      }
                    </RetailerList>
                  </ListWrapper>
                </AlphabeticalRetailersBlockInner>
              </AlphabeticalRetailersBlock>
            );
          }
        )
      );
    } else if(this.state.active_tab==='trending'){
      return(
        <FeaturedListOuter className='featuredListOuter'>
          <FeaturedListInner className='featuredListInner'>
          {
            this.state.trending_retailers.map(
              (trending,index)=>
              <FeaturedListWrapper key={index} className='featuredListWrapper'>
                <TEXTSTYLE.HEADING4>
                  {trending.name}
                </TEXTSTYLE.HEADING4>
                <Seperator height='24px'/>
                <FeaturedRetailerList className='FeaturedRetailerList'>
                {
                  trending.retailers.map((retailer, i) => {
                    return(this.state.retailers_lookup_by_siteuid[retailer]?
                        <RetailerListItem retailer={this.state.retailers_lookup_by_siteuid[retailer]} key={i} className='featuredListItem'/>
                      :
                        ""
                    );
                  })
                }
                </FeaturedRetailerList>
              </FeaturedListWrapper>
            )
          }
          </FeaturedListInner>
        </FeaturedListOuter>
      );
    }
  }

  renderLoadingOnPage(){
    return(
      <LoadingContainer>
        {AddHelmetTitle("Loading")}
        <LoadingDisplay message = "Loading Retailers..." />
      </LoadingContainer>
    );
  }

  renderAzFeaturedAndSearchToggle(IS_DESKTOP){
    if(this.state.active_tab==='search' && this.state.is_loading === false){
      return(
        <LiveSearch goBack={this.goBack} fuse={this.state.fuse} isDesktop={IS_DESKTOP}/>
      );
    } else {
      return(
        <SelectorBar className='selectorBar'>
          <LeftTabs>
            <AzTab active={this.state.active_tab === "az" ? 1 : 0} to={R.RETAILERS_AZ}>
              <TEXTSTYLE.BODY4>
                A-Z
              </TEXTSTYLE.BODY4>
            </AzTab>
            <FeaturedTab active={this.state.active_tab === "trending" ? 1 : 0} to={R.RETAILERS_FEATURED}>
              <TEXTSTYLE.BODY4>
                TRENDING
              </TEXTSTYLE.BODY4>
            </FeaturedTab>
          </LeftTabs>
          <SearchContainer>
              <SearchTab active={this.state.active_tab === "search" ? 1 : 0} to={R.RETAILERS_SEARCH}>
                <TEXTSTYLE.BODY4>
                  SEARCH
                </TEXTSTYLE.BODY4>
                <SearchIcon src={MEDIA.SEARCH_ICON}/>
            </SearchTab>
          </SearchContainer>
        </SelectorBar>
      );
    }
  }

  // doesn't render heading if on mobile and on search tab
  renderHeading(ISDESKTOP) {
    if (this.state.active_tab !== "search" || ISDESKTOP === true) {
      return (
        <div className="Retailers-headingContainer">
          <Typography variant="primary.b42" component="h1" align="left">
            Popular Stores
          </Typography>
          <Typography className="Retailers-subtitle" variant="primary.r16" component="p">
            <InfoIcon />
            <Link to={EXTERNAL.HOW_TO_ADD} target="_blank">How to save from any store in the world</Link>
          </Typography>
        </div>
      );
    }

    return null;
  }

  render() {
    const IS_DESKTOP = this.props.windowWidth>=M.MOBILESWITCH;
    return(
      <FullBlank className={this.props.className}>
        <TopMenu/>
        {AddHelmetTitle("Supported Retailers")}
        <Container>
          <MainContainer className='mainContainer'>
            <HeaderAndTabsOuter>
              <HeaderAndTabsContainer isOnSearch={this.state.active_tab === "search"} className='HeaderAndTabsContainer'>
                {this.renderHeading(IS_DESKTOP)}
                <Seperator height={this.state.active_tab === "search" ? '' : '28px'}/>
                {this.renderAzFeaturedAndSearchToggle(IS_DESKTOP)}
              </HeaderAndTabsContainer>
            </HeaderAndTabsOuter>
            {
              this.state.is_loading ?
                this.renderLoadingOnPage()
                :
              <RetailerLinksContainer className='RetailerLinksContainer'>
                  {this.listRetailers()}
              </RetailerLinksContainer>
            }
          </MainContainer>
        </Container>
      </FullBlank>
    );
  }
}

const StyledRetailers = styled(Retailers)`
  .Retailers-headingContainer {
    margin-left: 17px;
    margin-top: 45px;

    .Retailers-subtitle {
      display: flex;
      align-items: center;
      margin-top: -8px;

      svg.BaseIcon-root {
        font-size: 18px;
        margin-right: 8px;
      }
    }
  }
`;

export default withRouter(withFirebase(windowSize(StyledRetailers)));
