/*

State hook for sign up form, not sure why it is separate to be honest!

*/
import {useState} from 'react';

const useSignUpForm = (initialValues, callback) => {
  const [inputs, setInputs] = useState(initialValues);
  const handleSubmit = (event) => {
    if (event) {
      event.preventDefault();
      callback();
    }
  };
  const handleInputChange = (event) => {
    event.persist(); // prevents an error when event.target.name is null
    setInputs(inputs => ({...inputs, [event.target.name]: event.target.value}));
  };
  return {
    handleSubmit,
    handleInputChange,
    inputs,
  };
};
export default useSignUpForm;
