/*

This component is the sign-up component view.

TODO: typescript, camelCase, non-default exports, redux hooks, design system, split container / component ...


*/
import React, {useState, useEffect,useContext} from 'react';
import styled from 'styled-components';
import {TEXTSTYLE} from 'src/mvp22/style-components/Text';
import {withFirebase} from 'src/mvp22/Firebase';
import {withRouter} from 'react-router-dom';
import useSignUpForm from 'src/mvp22/form-components/UseSignUpForm';
import {FullBlank} from 'src/mvp22/menu-components/TopMenu';
import {AddHelmetTitle} from "src/mvp22/core-components/helmet";
import {M} from 'src/mvp22/constants';
import Button from 'src/mvp22/form-components/Button';
import TextInput from 'src/mvp22/form-components/TextInput';
import R from 'src/routes';
import { AuthContext} from 'src/index';
import { connect } from 'react-redux';
import AuthProviderButton from 'src/mvp22/form-components/AuthProviderButton';
import queryString from 'query-string';
import segmentEvent from "src/mvp22/segment-components/SegmentEvent";
import LogoAndText from 'src/mvp22/image-components/LogoAndText.js';
import useProvisionAccount from 'src/mvp22/firebase-functions/useProvisionAccount';
import {firestore_user_set_up_redux} from 'src/mvp22/redux-components/reducers/firestore_user_set_up';
var validator = require("email-validator");

const Container = styled.div`
  background-color:${M.COL.BG.LOGIN};
  color:${M.COL.TEXT.BLACK};
  display:flex;
  flex-direction:column;
  align-items:center;
  justify-content: center;
  min-height:100vh;
  box-sizing:border-box;
  width:100%;
`;

const CenteredBoxOuter = styled.div`
  display:flex;
  justify-content:center;
  align-items:center;
  background-color:${M.COL.BG.WHITE};
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 5px;
  padding:36px 12px;
  box-sizing:border-box;
  width:491px;
  max-width:100%;
`;


const CenteredBoxInner = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 332px;
`;

const TitleContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const OrDivider = styled.div`
  width: 100%;
  display: flex;
`;

const Or = styled(TEXTSTYLE.HEADING5)`
  margin-right: 21px;
  margin-left: 21px;
`;

const Line = styled.div`
  display: flex;
  align-self: center;
  height: 1px;
  background-color: ${M.COL.BUTTON.BLACK};
  width: 100%;
  top: 50%;
  position: relative;
`;

const LeftLine = styled(Line)`
color:default;
`;

const RightLine = styled(Line)`
color:default;
`;

const Seperator = styled.div`
  height:${props=>props.height};
`;

const ErrorMessage = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: flex-start;
  margin-left: 2px;
  color: ${M.COL.TEXT.ERROR}
`;

export const GeneralOverlayBG = styled.div`
  z-index:3000;
  display:block;
  position:fixed;
  width:100%;
  height:100%;
  top:0px;
  left:0px;
  box-sizing:border-box;
  background-color:#000000CC;
  justify-content:center;
  align-self:center;
  align-items:center;
`;

export const GeneralOverlayBGNext = styled.div`
  position:absolute;
  width:100%;
  height:100%;
  top:0px;
  left:0px;
  justify-content:center;
  align-self:center;
  align-items:center;
  display:flex;
  overflow:auto;
`;

const OverallFeedback = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: flex-start;
  margin-left: 2px;
  text-align: left;
  margin-bottom: 3px;
  color: ${M.COL.TEXT.ERROR};
`;

const ErrorText = styled(TEXTSTYLE.BODY3)`
  color: ${M.COL.TEXT.ERROR};
`;

const Join = (props) => {
  // Map props to constants:
  const params_post = queryString.parse(props.location.search).post;
  const redirect_path = queryString.parse(props.location.search).redirect_path;
  const history = props.history;
  const firebase = props.firebase;
  const display_name = props.displayName;
  const auth_user_uid = props.authUserUID;
  const is_logged_in_and_data_loaded = props.authuser_is_loaded===true && auth_user_uid!==null;
  const is_provisioned = props.provisioned===true;
  const firestore_user_set_up = props.firestore_user_set_up;
  // useState:
  const [form_feedback, setFormFeedback] = useState(""); // feedback text
  const [working, setWorking] = useState(false); // show loading dots on submit button
  const [disabled, setDisabled] = useState(true);
  const [name_error, setNameError] = useState(false);
  const [email_error, setEmailError] = useState(false);
  const [password_error, setPasswordError] = useState(false);
  const [submit_has_been_clicked, setSubmitHasBeenClicked] = useState(false);

  const auth = useContext(AuthContext);
  // provisioning hook:
  const {
    started_provisioning_once,
    provisioning_success,
    provisionAccount
  } = useProvisionAccount(
    firebase
  );

  // SIGN UP VIA AUTH:
  // callback:
  const handleSignupWithEmail = () => {
    if(disabled !== true){
      segmentEvent("Submitted sign up form on sign up page");
      setSubmitHasBeenClicked(true);
      setWorking(true);
      const password = inputs.password;
      const email = inputs.email;
      firebase.doCreateUserWithEmailAndPassword(
        email,
        password
      ).then(
        ()=>{
          setWorking(false);
        }
      ).catch(
        (err)=>{
          //Sanitise firebase messages:
          if (err.code==="auth/email-already-exists"){
            setFormFeedback("There is already an account for this email address - try logging in or requesting a new password.");
          }
          else{
            setFormFeedback(err.message);
          }
          setWorking(false);
        }
      );
    } else {
      setFormFeedback("Please check for errors and try again.");
    }
  };
  const {inputs, handleInputChange, handleSubmit} = useSignUpForm(
    {name: '', email: '', password: ''},
    handleSignupWithEmail //although this references inputs I think this is OK as the reference inputs does not change.  but probably there is a reload after first setup?
  );
  const chosen_name = display_name?display_name:inputs.name;


  const handleSignupWithProvider = (provider_name) => {
    segmentEvent("Clicked on Continue with Authprovider on SignUp Page");
    var provider;
    if(provider_name === "Facebook"){
      provider = "facebook";
    } else if (provider_name === "Google"){
      provider = "google";
    }
    auth.signInWithProvider(
      provider
    ).catch(
      (err)=>setFormFeedback(`Sorry, sign in via ${provider_name} failed.  Error: ${err.message}`)
    );
  };

  // useEffect:
  useEffect( // On load report event:
    () => {
      segmentEvent("Viewed Signup Page");
    },
    []
  );

  // PROVISIONING POST-AUTH SUCCESS:
  // provision an account on this page if it isn't provisioned yet:
  useEffect(
    ()=>{
      // Not changing to started_provisioning_once to is_provisioning because we don't want to flash anything prior to reirecting.
      if (is_logged_in_and_data_loaded && started_provisioning_once!==true){
        // Note: if someone is switching to a provider they may have already been set as provisioned when they are not..., but they'll just have to go back and it can be fixed
        if (is_provisioned){
          history.push(R.HOME);
        }
        else{
          provisionAccount(
            chosen_name
          );
        }
      }
    },
    [chosen_name,is_logged_in_and_data_loaded,is_provisioned,provisionAccount,history,started_provisioning_once]
  );

  // POST-PROVISIONING
  // Need to wait for the verified settings to load in to move on...:
  useEffect(
    () =>{
      // TODO: Probably don't need "is_logged_in_and_data_loaded" in condition?
      if (provisioning_success===true && is_logged_in_and_data_loaded){
        if (is_provisioned!==true){
          // reload the info if not set as provisioned yet:
          // (We can't listen to verified settings until we have created it!  Hence need to load in again):
          firestore_user_set_up(
            {
              auth_user_uid,
              firebase
            }
          );
        }else{
          // now it's all loaded in the data then redirect the page:
          if (params_post==="tastemakers"){
            history.push({pathname:R.TASTEMAKERSAPPLY,search:"?page=2&post=getcex"});
          }
          else if (params_post==="pro"){
            history.push({pathname:R.POSTSIGNUP,search:"?pro=true"});
          }
          else if (params_post==="redirect_path"){
            history.push({pathname:R.POSTSIGNUP,search:"?redirect_path="+redirect_path});
          }
          else{
            history.push(R.POSTSIGNUP);
          }
        }
      }
    },
    [provisioning_success,is_provisioned,is_logged_in_and_data_loaded,auth_user_uid,firebase,firestore_user_set_up,history,params_post,redirect_path]
  );

  // INPUT UPDATES:
  useEffect( // Determine errors:
    () => {
      if(inputs.name === ""){
        setNameError('Name cannot be blank');
      } else if (inputs.name.length > 50){
        setNameError("Max 50 characters");
      } else {
        setNameError(false);
      }
      if (inputs.email.length > 254) {
        setEmailError("Max 254 characters");
      } else if(!validator.validate(inputs.email)){
        setEmailError("Please enter a valid email address");
      }else {
        setEmailError(false);
      }
      if (inputs.password.length > 128) {
        setPasswordError("Maximum 128 characters");
      } else if (inputs.password.length < 6){
        setPasswordError("Minimum 6 characters");
      } else {
        setPasswordError(false);
      }
    },
    [inputs] // Only re-run the effect if name or email or password change
  );

  useEffect( // if the errors change determine if we have the button active
    () => {
      if(name_error || email_error || password_error){
        setDisabled(true);
      }else{
        setDisabled(false);
      }
    },
    [name_error, email_error, password_error]
  );

  return (
    <FullBlank>
    <Container className="container">
      {AddHelmetTitle("Join us")}
      <CenteredBoxOuter>
        {
          // Not changing to is_provisioning because we don't want to flash anything prior to reirecting.
          started_provisioning_once === true ?
            <CenteredBoxInner className="CenteredBox">
              <TitleContainer>
                  <Seperator height='5px'/>
                  <LogoAndText heighttype="A"/>
                  <Seperator height='36px'/>
              </TitleContainer>
              {
                provisioning_success===false?
                  <ErrorText>Account set up failed, please contact us at hello@moonsift.com</ErrorText>
                :
                  <TEXTSTYLE.BODY3>Setting up account...</TEXTSTYLE.BODY3>
              }
            </CenteredBoxInner>
          :
            <CenteredBoxInner className="CenteredBox">
              <TitleContainer>
                  <Seperator height='5px'/>
                  <LogoAndText heighttype="A"/>
                  <Seperator height='36px'/>
              </TitleContainer>
              <OverallFeedback>
                <ErrorMessage><TEXTSTYLE.BODY5>{form_feedback}</TEXTSTYLE.BODY5></ErrorMessage>
              </OverallFeedback>
                <form onSubmit={handleSubmit} noValidate autoComplete="off">
                  <TextInput
                   msstyle="sign_up"
                   width="100%"
                   placeholder='Name'
                   className="input"
                   type="text"
                   name="name"
                   onChange={handleInputChange}
                   value={inputs.name}
                   required
                  />
                  <ErrorMessage>
                    <TEXTSTYLE.BODY5>{submit_has_been_clicked ? name_error : ""}</TEXTSTYLE.BODY5>
                  </ErrorMessage>
                  <Seperator height='6px'/>
                  <TextInput
                   msstyle="sign_up"
                   width="100%"
                   placeholder='Email'
                   className="input"
                   type="email"
                   name="email"
                   onChange={handleInputChange}
                   value={inputs.email}
                   required
                  />
                  <ErrorMessage>
                    <TEXTSTYLE.BODY5>{submit_has_been_clicked ? email_error : ""}</TEXTSTYLE.BODY5>
                  </ErrorMessage>
                  <Seperator height='6px'/>
                  <TextInput
                   msstyle="sign_up"
                   width="100%"
                   placeholder='Create a password'
                   className="input"
                   type="password"
                   name="password"
                   onChange={handleInputChange}
                   value={inputs.password}
                  />
                  <ErrorMessage>
                    <TEXTSTYLE.BODY5>{submit_has_been_clicked ? password_error : ""}</TEXTSTYLE.BODY5>
                  </ErrorMessage>
                  <Seperator height='11px'/>
                  <Button
                    working_overlay={working===true}
                    msstyle={"join_page_signup_button"}
                    type="submit"
                    text="Continue"
                    disabled={disabled}
                  />
                </form>
              <Seperator height='11px'/>
              <OrDivider><LeftLine/><Or>OR</Or><RightLine/></OrDivider>
              <Seperator height='11px'/>
              <AuthProviderButton
                type="submit"
                handle_authorise={() => (handleSignupWithProvider("Facebook"))}
                provider="Facebook"
              />
              <Seperator height='6px'/>
              <AuthProviderButton
                type="submit"
                handle_authorise={() => (handleSignupWithProvider("Google"))}
                provider="Google"
              />
              <Seperator height='21px'/>
              <TEXTSTYLE.BODY5>By continuing, you agree to Moonsift’s Terms of Service, Privacy policy. </TEXTSTYLE.BODY5>
              <Seperator height='11px'/>
              <TEXTSTYLE.BODY5>Already a member? <TEXTSTYLE.LINK to={R.SIGNIN}><b>Log in</b></TEXTSTYLE.LINK>  </TEXTSTYLE.BODY5>
            </CenteredBoxInner>
          }
       </CenteredBoxOuter>
    </Container>
    </FullBlank>
  );
};

const mapStateToProps = (state) => {
  return (
    {
      displayName:state.auth.provider_name,
      authuser_is_loaded:state.firebasestate.loaded,
      provisioned:state.firestore_user_owner.provisioned,
      authUserUID:state.auth.id,
    }
  );
};

const mapDispatchToProps = dispatch => {
  return {
    firestore_user_set_up:(data)=>dispatch(firestore_user_set_up_redux(data))
  };
};

export default connect(mapStateToProps,mapDispatchToProps)(
  withFirebase(
    withRouter(
      Join
    )
  )
);
