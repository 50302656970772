/*

Various cases of the post-sign up experience.
This is a super old component and requires sorting out the cases by at least naming them

TODO: typescript, camelCase, make functional, non-default exports, redux hooks, design system, split container / component ...

*/
import React from 'react';
import { Component } from 'react';
import styled, {keyframes} from 'styled-components';
import {withRouter} from 'react-router-dom';
import queryString from 'query-string';
import { Link as RouterLink } from 'react-router-dom';
import { Typography, Link } from '@moonsifttech/design-system';

import {AddHelmetTitle} from "src/mvp22/core-components/helmet";
import {M} from 'src/mvp22/constants';
import Button from 'src/mvp22/form-components/Button';
import {EXTERNAL} from 'src/routes';
import {TEXTSTYLE} from 'src/mvp22/style-components/Text';
import LogoAndText from 'src/mvp22/image-components/LogoAndText';
import TopMenu from 'src/mvp22/menu-components/TopMenu';
import {FullBlank} from 'src/mvp22/menu-components/TopMenu';
import {CopyToClipboard} from 'react-copy-to-clipboard';
import LinkCopied from 'src/mvp22/image-components/LinkCopied';
import {requiresAuth} from 'src/mvp22/redux-components/requiresAuth';
import segmentEvent from "src/mvp22/segment-components/SegmentEvent";
import MEDIA from 'src/mvp22/media';
import { connect } from 'react-redux';
import {actionCreator,
  SET_CBCOPY,
  modalSet
} from 'src/mvp22/redux-components/actions';
import Bowser from "bowser";
import R from 'src/routes';
import windowSize from 'src/mvp22/WindowSize';
import {NewCollectionTile} from 'src/mvp22/tile-components/CollectionTileList';

const Container = styled.div`
  background-color:${M.COL.BG.WHITE};
  color:${M.COL.TEXT.BLACK};
  padding-left:30px;
  padding-right:30px;
  flex-direction:column;
  min-width:${M.MINWIDTH};
  box-sizing:border-box;
  align-items: center;
  justify-content: center;
  display:flex;
  width:100%;
  height: 100%
`;

const rotate = keyframes`
  0% {opacity: 0;}
  50% {opacity: 1;}
  100% {opacity: 0;}
`;

const CentredContainer = styled.div`
  align-items: center;
  justify-content: center;
  animation: ${rotate} 3s linear infinite;
  display:flex;
  width:100%;
`;

const PlainLink = styled.a`
  text-decoration:none;
  width: 100%;
  max-width: 358px;
  height: 42px;
`;

const MainContainer = styled.div`
  align-items: center;
  justify-content: center;
  flex-direction:column;
  display:flex;
  width:100%;
`;

const Seperator = styled.div`
  height:${props=>props.height};
`;

const LinkContainer = styled.div`
  background-color:${M.COL.BG.WHITE};
  border-radius:28px;
  border: 1px solid ${M.COL.LINE.MID};
  height:44px;
  padding: 0px 18px;
  position:relative;
  width:90%;
  max-width:500px;
  box-sizing:border-box;
`;

const ButtonContainer = styled.div`
  position:absolute;
  right:0px;
  top:0px;
  box-sizing:border-box;
`;
const TextBoxEx = styled.input`
  color:${M.COL.TEXT.MID};
  height:100%;
  box-sizing:border-box;
  outline-width: 0;
  font-family:${M.FONT};
  border:none;
  line-height:14px;
  font-size:16px;
  font-weight:normal;
  font-style:normal;
  width:${props => props.width};
  max-width:${props => props.maxWidth};
  padding:10px;
`;

const BodyText = styled(TEXTSTYLE.BODY1)`
  font-weight: 300;
  font-size: 26px;
  line-height: 38px;
  max-width: 686px;
  text-align: center;
`;

const BodyText2 = styled(TEXTSTYLE.BODY3)`
  max-width: 686px;
  border-bottom: 1px solid black;
  display: inline;
`;

const SubSubtitle = styled(TEXTSTYLE.BODY4)`
  color: ${M.COL.TEXT.MID};
  display: flex;
  align-items: flex-start;
`;

const ChromeIcon = styled.img`
  width: 13px;
  margin-right:6px;
  position: relative;
  top: 4px;
`;

const PlusIconContainer = styled.div`
  border: 1px solid ${M.COL.TEXT.BLACK};
  box-sizing: border-box;
  height:20px;
  width:20px;
  border-radius:10px;
  display:flex;
  align-items: center;
  justify-content: center;
`;

const browser = Bowser.getParser(window.navigator.userAgent);

const PlusIcon = () => {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M10.0002 4.16748V15.8341" stroke="black" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M4.16675 9.99976H15.8334" stroke="black" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>
  );
};
/**
* Post Join Moonsift Component
*/
class PostSignUp extends Component {
  constructor(props){
    super(props);
    this.getMainContent = this.getMainContent.bind(this);
    this.getCurPage = this.getCurPage.bind(this);
    this.goForward = this.goForward.bind(this);
    this.doCopy = this.doCopy.bind(this);
    this.unsetCopyVisible = this.unsetCopyVisible.bind(this);
    this.openNewCollection = this.openNewCollection.bind(this);
    this.state = {};
  }

  componentDidMount() {
    const auth_uid = this.props.authUserUID;
    segmentEvent("Viewed Postsignup Page"); // Events very important for determining users have signed up!
    segmentEvent(`signedupas_${auth_uid}`);
    const redirect_path = queryString.parse(this.props.location.search).redirect_path;
    this.setState(
      {
        redirect_path,
        show_pro_message:queryString.parse(this.props.location.search).pro==="true"
      }
    );
    this.checkBroswerAndIfExtensionInstalled();
  }

  componentWillUnmount() {
    clearInterval(this.intervalID);
  }

  // TODO: Replace with the useHasExtension hook
  checkBroswerAndIfExtensionInstalled(){
    let extension_installed = document.getElementById('ms_extension');
    this.setState({
      extension_installed: !!extension_installed,
      browser: browser.parsedResult.browser
    });
  }

  doCopy(){
    this.props.setCBCopy(true);
    clearInterval(this.intervalIDCV);
    this.intervalIDCV = setInterval(
      () => this.unsetCopyVisible(),
      1000
    );
  }

  unsetCopyVisible(){
    this.props.setCBCopy(false);
    clearInterval(this.intervalIDCV);
  }

  goForward(event, eventName){
    segmentEvent(eventName);
    const newPage = this.getCurPage()+1;
    this.props.history.push({search:"?page="+newPage});
    event.preventDefault();
    return false;
  }

  getCurPage() {
    return parseInt(queryString.parse(this.props.location.search).page,10);
  }

  tick(){
    this.props.history.push({search:"?page=2"});
    clearInterval(this.intervalID);
  }

  openNewCollection(event) {
    event.preventDefault();
    segmentEvent("Clicked Create New Collection on postinstall page");
    this.props.setModal(
      {
        type:"new_collection"
      }
    );
    return false;
  }

  // Use params for ease of moving a user on within same page...:
  getMainContent(){
    var curpage=this.getCurPage();
    if(isNaN(curpage) || curpage<1){
      curpage=1;
    }
    const COPYTEXT = M.HOMEPAGEURL;//(this.props.userinfo.isReceived===true)?M.HOMEPAGEURL+"/"+this.props.userinfo.userData.refURL:M.HOMEPAGEURL;
    switch(curpage){
      case 1:
        // Start a timer for going to the next page...:
        // This is probably a potential problem, as not atomised....:S
        clearInterval(this.intervalID);
        this.intervalID = setInterval(
          () => this.tick(),
          1000
        );
        return(
          <CentredContainer>
            <LogoAndText heighttype="A"/>
          </CentredContainer>
        );
      case 3:
        return(
          <MainContainer>

            <TEXTSTYLE.HEADING3>
            Share with someone you think would make great collections
            </TEXTSTYLE.HEADING3>

            <LinkCopied visible={this.props.showCopyMsg}/>
            <LinkContainer>
              <TextBoxEx defaultValue={COPYTEXT}/>
              <ButtonContainer>
                  <CopyToClipboard text={COPYTEXT} onCopy={this.doCopy}>
                  <Button msstyle="cta2nd" text="Copy Link" onClick={()=>segmentEvent("Clicked Copy Link on Post Sign Up Page")} />
                </CopyToClipboard>
              </ButtonContainer>
            </LinkContainer>
            <Seperator height="42px"/>
            <Button width="90%" maxWidth="500px" msstyle="cta3rd" text="Skip" onClick={(e) => {this.goForward(e, "Clicked Skip on Post Sign Up Page");}}/>
          </MainContainer>
        );
      case 2:
        if (this.state.redirect_path){
          this.props.history.push(this.state.redirect_path);
        }else{
          const ISDESKTOP = this.props.windowWidth>=M.MOBILESWITCH;
          if(this.state.browser && this.state.browser.name === 'Chrome'){
            if(ISDESKTOP===true){
              return(
              <MainContainer className='MainContainer'>
                <TEXTSTYLE.HEADING3>
                  Add Moonsift to Chrome
                </TEXTSTYLE.HEADING3>
                <Seperator height="18px"/>
                <BodyText>
                  Add the universal save button to Chrome so you can begin saving products from all your favourite stores.
                </BodyText>
                <Seperator height="26px"/>
                <PlainLink href={EXTERNAL.CHROMESTORE} target="_blank" onClick={() => {segmentEvent("Clicked Add To Chrome on PostSignup Page");}}>
                <Button
                  needsMaxWidth={true}
                  isDesktop={ISDESKTOP}
                  text='Add to Chrome'
                  msstyle="cta_alternative_post_signup"
                  />
                </PlainLink>
                <Seperator height="18px"/>
                <SubSubtitle>
                <ChromeIcon src={MEDIA.CHROME_ICON}/>
                  Designed for Chrome, other browsers coming soon
                </SubSubtitle>
                <Seperator height="32px"/>
                {
                  (this.state.show_pro_message && this.props.is_pro!==true)?
                  <TEXTSTYLE.BODY2>
                    PS: Your Pro code was unfortunately not valid, please email hello@moonsift.com if you believe it should have been.
                  </TEXTSTYLE.BODY2>
                  :""
                }
                {
                  (this.state.show_pro_message && this.props.is_pro===true)?
                  <TEXTSTYLE.BODY2>
                    PS: We have successfully added Pro capabilities to your account.
                  </TEXTSTYLE.BODY2>
                  :""
                }
              </MainContainer>
            );
            } else {
              return(
                <MainContainer className='MainContainer'>
                  <Typography
                    className="PostSignUp-title"
                    variant="primary.b34"
                    align="center"
                    component="h1"
                  >
                    Add Moonsift to your desktop
                  </Typography>
                  <Typography
                    className="PostSignUp-body"
                    variant="primary.l26"
                    align="center"
                    component="p"
                  >
                    Get the universal save button now to save items on your laptop later.
                  </Typography>
                  <PlainLink
                    href={EXTERNAL.CHROMESTORE}
                    target="_blank"
                    onClick={() => segmentEvent("Clicked Add To Chrome on Empty Collection Page")}
                  >
                    <Button
                      needsMaxWidth={true}
                      isDesktop={ISDESKTOP}
                      text='Add to Chrome'
                      msstyle="cta_alternative_post_signup"
                    />
                  </PlainLink>
                  <Seperator height="16px"/>
                  <SubSubtitle>
                    <ChromeIcon src={MEDIA.CHROME_ICON} />
                    Designed for Chrome, other browsers coming soon
                  </SubSubtitle>
                  <Seperator height="24px"/>
                  <Typography variant="primary.r16" align="center" component="p">
                    <Link to={R.EXPLORE_FEATURED} component={RouterLink}>Skip this step</Link>
                    {' '}to explore and save items from other people’s collections
                  </Typography>
                </MainContainer>
              );
            }
          } else {
            // redirects them to their profile page if they already have a collection. is > 1 because of the invisible collection
            if(this.props.collection_list.length > 1){
              this.props.history.push(R.USER_PROFILE);
            } else {
              return(
                <MainContainer className='MainContainer'>
                  <TEXTSTYLE.HEADING3>Create Your First Moonsift Collection</TEXTSTYLE.HEADING3>
                    <Seperator height="36px"/>
                    <NewCollectionTile href="#" onClick={this.openNewCollection}>
                      <PlusIconContainer><PlusIcon src={MEDIA.BLACK_PLUS}/></PlusIconContainer>
                      <TEXTSTYLE.BODY4>&nbsp;&nbsp;New Collection</TEXTSTYLE.BODY4>
                    </NewCollectionTile>
                    <Seperator height="36px"/>
                    <TEXTSTYLE.LINK to={R.EXPLORE_FEATURED}>
                      <BodyText2>
                        Skip for now and explore popular collections
                      </BodyText2>
                    </TEXTSTYLE.LINK>
                  <Seperator height='45px'/>
                </MainContainer>
              );
            }
          }
        }
      break;
      default:{
        return ("");
      }
    }
  }

  render(){
    const MAINCONTENT = this.getMainContent();
    return(
      <FullBlank>
        <TopMenu showRight={false} />
        {AddHelmetTitle("Success!")}
        <Container>
          {MAINCONTENT}
        </Container>
      </FullBlank>
    );
  }
}

const StyledPostSignUp = styled(PostSignUp)`
  .PostSignUp-title {
    margin-bottom: 24px;
  }
`;

const mapStateToProps = (state) => {
  return ({
    showCopyMsg:state.generalui.showCopyMsg,
    is_pro:state.firestore_user_owner.pro,
    collection_list: state.firestore_user_collection_list.collections_sorted,
    authUserUID:state.auth.id
  });
};

const mapDispatchToProps = dispatch => {
  return {
    setCBCopy:(value)=>dispatch(actionCreator(SET_CBCOPY,value)),
    setModal:(data)=>dispatch(modalSet(data))
  };
};

export default connect(mapStateToProps,mapDispatchToProps)(requiresAuth(withRouter(windowSize(StyledPostSignUp)),false));
