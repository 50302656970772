/*

The main menu popup from the burger icon

TODO: functional, typescript, camelCase

*/
import React from 'react';
import { Component } from 'react';
import {M} from 'src/mvp22/constants';
import R, {EXTERNAL} from 'src/routes';
import styled from 'styled-components';
import {TEXTSTYLE} from 'src/mvp22/style-components/Text';
//import {NewItem} from './TopMenu';
import MEDIA from 'src/mvp22/media';
import windowSize from 'src/mvp22/WindowSize';

const InlineBody = styled(TEXTSTYLE.BODY4)`
  display:inline-block;
  display:flex;
  flex-direction:row;
  align-items:center;
  height:40px;
  padding: 0px 16px;
  color:${M.COL.TEXT.BLACK};
  &:hover{
    background-color:${M.COL.BG.MID};
  }
`;

export const MenuPopup = styled.div`
  min-width:280px;
  overflow-y:auto;
  max-height:100%;
  display:flex;
  flex-direction:column;
  background-color:${M.COL.BG.WHITE};
  position:fixed;
  top:60px;
  right:${props=>(props.hug==="edge")?"0px":props.inner_pos+"px"};
  z-index:10001;
  color:${M.COL.TEXT.BLACK};
  margin-right:22px;
  border-radius:6px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.25);
`;

const SeparatorLine = styled.div`
  height:1px;
  background-color:${M.COL.LINE.MID};
  width:100%;
`;

const TopOfTheMenuContainer = styled.div`
  display:flex;
  align-items:center;
  padding:16px;

  a {
    line-height: 0;
  }
`;

const BackArrow = styled.img`
  height:16px;
  padding-right:8px;
  cursor:pointer;
`;

const NotificationIndicatorInMenu = styled(TEXTSTYLE.OTHER6)`
  font-weight:bold;
  border-radius:20px;
  background-color:${M.COL.BUTTON.LIGHTYELLOW};
  border: 1px solid ${M.COL.BUTTON.YELLOW};
  padding: 0px 8px;
  margin-left:10px;
`;

/**
* Burger Menu
*/
class BurgerMenu extends Component {
  constructor(){
    super();
    this.checkclick = this.checkclick.bind(this);
    this.toggle_preferences_menu = this.toggle_preferences_menu.bind(this);
    this.domRef = React.createRef();
    this.state = {
      preferences_block:false,
      open:false
    };
  }

  componentDidMount(){
    document.addEventListener("mousedown",this.checkclick,false);
  }

  componentWillUnmount(){
    document.removeEventListener("mousedown",this.checkclick,false);
  }

  // click out to hide but not on the burger icon as this toggles already!
  checkclick(event){
    if (this.domRef.current && this.props.domBurgerRef.current){
      if (!this.domRef.current.contains(event.target) && !this.props.domBurgerRef.current.contains(event.target)){
        // Set back to main menu
        this.props.burger_toggle();
        this.setState(
          {
            preferences_block:false
          }
        );
      }
    }
  }

  toggle_preferences_menu(event){
    event.preventDefault();
    this.setState(
      (prevState)=>{
        return (
          {
            preferences_block:!(prevState.preferences_block)
          }
        );
      }
    );
    return false;
  }

 componentDidUpdate(){
    // Ensure that it always reopens to the main menu not submenus:.
    this.setState(
      (prevState,props)=>{
        if (prevState.open !== props.open){
          return (
            {
              preferences_block:false,
              open:props.open
            }
          );
        }
      }
    );
  }

  // TODO: refactor to avoid defining everything each time we render...
  render(){
    const LOGOUT_BLOCK =
      <TEXTSTYLE.LINK key="logout" to={R.LOGOUT}>
        <InlineBody selected={false}>Log out</InlineBody>
      </TEXTSTYLE.LINK>;
    const SIGNUP_BLOCK =
      <TEXTSTYLE.LINK key="signup" to={R.SIGNUP}>
        <InlineBody selected={this.props.selected===R.SIGNUP}>Sign up</InlineBody>
      </TEXTSTYLE.LINK>;
    const EXPLORE_BLOCK =
       <TEXTSTYLE.LINK key="explore" to={R.EXPLORE_FEATURED}>
          <InlineBody selected={this.props.selected==="EXPLORE"} >Explore Collections</InlineBody>
       </TEXTSTYLE.LINK>;
    const NOTIFICATIONS_BLOCK=(this.props.windowWidth<=M.MOBILESWITCH)?
      <TEXTSTYLE.LINK key="notifications" to={R.NOTIFICATIONS}>
         <InlineBody selected={this.props.selected==="NOTIFICATIONS"} >
         Notifications
         {
           (this.props.notification_indicator_text)?
           <NotificationIndicatorInMenu>{this.props.notification_indicator_text}</NotificationIndicatorInMenu>
           :""
         }
         </InlineBody>
      </TEXTSTYLE.LINK>
    :"";
    const TASTEMAKER_BLOCK =
     <TEXTSTYLE.LINK key="tastemakers" to={R.TASTEMAKERLANDING}>
        <InlineBody selected={this.props.selected===R.TASTEMAKERLANDING} >Curators and Stylists</InlineBody>
     </TEXTSTYLE.LINK>;
    const ABOUT_BLOCK =
      <TEXTSTYLE.LINK key="about" to={R.ABOUT_US}>
        <InlineBody selected={this.props.selected===R.ABOUT_US}>About Moonsift</InlineBody>
      </TEXTSTYLE.LINK>;
    const WEBSTORE_BLOCK =
      <TEXTSTYLE.LINKA key="webstore" href={EXTERNAL.CHROMESTORE} target="_blank">
        <InlineBody selected={false}>Add Moonsift to Chrome</InlineBody>
      </TEXTSTYLE.LINKA>;
    const PRO_SHARE_BLOCK = (this.props.is_pro===true)?
        <TEXTSTYLE.LINK key="mypropasses" to={R.PRO_SHARE}>
          <InlineBody selected={this.props.selected===R.PRO_SHARE}>My Pro Passes</InlineBody>
        </TEXTSTYLE.LINK>
      :"";
    const RETAILERS_BLOCK =
      <TEXTSTYLE.LINK key="retailers" to={R.RETAILERS}>
        <InlineBody selected={this.props.selected===R.RETAILERS}>List of Stores</InlineBody>
      </TEXTSTYLE.LINK>;
    const TERMS_BLOCK =
      <TEXTSTYLE.LINK key="terms" to={R.TERMS}>
        <InlineBody selected={this.props.selected===R.TERMS}>Terms & Privacy</InlineBody>
      </TEXTSTYLE.LINK>;
    const PREFERENCES_BLOCK =
      <TEXTSTYLE.LINKA key="preferences" href="#" onClick={this.toggle_preferences_menu}>
        <InlineBody selected={this.props.selected===R.TERMS}>Settings & Preferences</InlineBody>
      </TEXTSTYLE.LINKA>;
    const PREFERENCES_HEADER =
      <TopOfTheMenuContainer key="pref_header">
        <TEXTSTYLE.LINKA key="preferences" href="#" onClick={this.toggle_preferences_menu}>
          <BackArrow src={MEDIA.BACK_ARROW}/>
        </TEXTSTYLE.LINKA>
        <TEXTSTYLE.HEADING5>
          Settings & Preferences
        </TEXTSTYLE.HEADING5>
      </TopOfTheMenuContainer>;
    const PREFERENCES_PROFILE_BLOCK =
      <TEXTSTYLE.LINK key="preferences_profile" to={R.EDIT_PROFILE}>
        <InlineBody selected={this.props.selected===R.EDIT_PROFILE}>Edit profile</InlineBody>
      </TEXTSTYLE.LINK>;
    const PREFERENCES_EMAIL_BLOCK =
      <TEXTSTYLE.LINK key="preferences_email" to={R.EDIT_EMAIL_PREFERENCES}>
        <InlineBody selected={this.props.selected===R.EDIT_EMAIL_PREFERENCES}>Emails & Notifications</InlineBody>
      </TEXTSTYLE.LINK>;
    const PREFERENCES_ACCOUNT_BLOCK =
      <TEXTSTYLE.LINK key="preferences_account" to={R.EDIT_ACCOUNT_PREFERENCES}>
        <InlineBody selected={this.props.selected===R.EDIT_ACCOUNT_PREFERENCES}>Account settings</InlineBody>
      </TEXTSTYLE.LINK>;

    const INNER_POS = ((this.props.windowWidth-1200)>0)?(this.props.windowWidth-1200)/2:0;
    //        <BGCover zIndex="10000" onClick={this.burger_toggle}/>
    return(
      (this.props.open===true)?
        <MenuPopup ref={this.domRef} hug={this.props.hug} inner_pos={INNER_POS}>
        {
          (this.state.preferences_block===true)?
            [PREFERENCES_HEADER,PREFERENCES_PROFILE_BLOCK,PREFERENCES_EMAIL_BLOCK,PREFERENCES_ACCOUNT_BLOCK]
          :

              (this.props.loggedin===true)?
                [EXPLORE_BLOCK,NOTIFICATIONS_BLOCK,RETAILERS_BLOCK,<SeparatorLine key={"sep1"}/>,WEBSTORE_BLOCK,PRO_SHARE_BLOCK,ABOUT_BLOCK,TASTEMAKER_BLOCK,<SeparatorLine key={"sep2"}/>,PREFERENCES_BLOCK,TERMS_BLOCK,LOGOUT_BLOCK]
              :
                [ABOUT_BLOCK,EXPLORE_BLOCK,TASTEMAKER_BLOCK,RETAILERS_BLOCK,SIGNUP_BLOCK]

        }
        </MenuPopup>
      :""
    );
  }
}

export default windowSize(BurgerMenu);
