/*

useDBUpdate

*/
/*
All we want to do is copy the current entry to another collection if we need to OR delete it!
*/
import send_error from 'src/mvp22/core-components/send_error';
// rawMembershipInfo is the list of collection items for this collection which will allow us
// to see if that page_url is already present or not!
// prevent multiple products being added.
// We will need an error message OR perhaps we disable this as a restriction.
async function get_query_result(firebase,rawMembershipInfo,authUserUID,collectionUID,page_url){
  const this_query_result = [];
  if (rawMembershipInfo){
    for (let this_entry of rawMembershipInfo){
      if (this_entry.collection_uid===collectionUID){
        this_query_result.push(this_entry.uid);
      }
    }
  }else{
    await firebase.user_product_collection_list_match_page_url(
      authUserUID,
      collectionUID,
      page_url
    ).get().then(
      snapshot=>{
        snapshot.forEach(
          this_entry=>{
            this_query_result.push(this_entry.id);
          }
        );
      }
    );
  }
  return this_query_result;
}
// price is at this moment in time for adding to other collections this is what will be the "savedPrice" and product will no longer show as "price updated"
export default function update_product_opinion(
  opinion,
  collectionUID,
  collectionItem,
  price,
  rawMembershipInfo,
  firebase,
  authUserUID
){
  return new Promise(
    (resolve,reject)=>{
      // For now we just use one page_url:
      if (
        collectionUID !== undefined
        && opinion !==undefined
        && collectionItem.page_url !== undefined
      ){
        // first do a query to check to see if this page_url is in the collection already OR get the ID for deleting
        // getting the id prior to deleting probably overkill, could remove?
        get_query_result(firebase,rawMembershipInfo,authUserUID,collectionUID,collectionItem.page_url).then(
          (this_query_result)=>{
            // if create mode:
            if (opinion === true){
              // First ensure that we do not already have this item in this collection:
              if (this_query_result.length===0){
                const created_at = firebase.serverTimestamp();
                // Product MUST be scraped already...
                // for adding to the DB:
                const new_data = {
                  page_url: collectionItem.page_url,
                  scrape_uid: collectionItem.scrape_uid,
                  product_image: collectionItem.product_image,
                  created_at,
                  collection_uid:collectionUID, // THE NEW COLLECTION!
                  user_uid:authUserUID,
                  scrape_user_uid:collectionItem.scrape_user_uid, // User that origionally saved this product to moonsift!
                  saved_price:price
                };
                if (collectionItem.product_uid){
                  new_data.product_uid = collectionItem.product_uid;
                }
                // Add as a new doc:
                firebase.user_product_collection_list(
                  authUserUID,
                  collectionUID
                ).add(
                  new_data
                ).then(
                  ()=>{
                    resolve();
                  }
                ).catch(
                  (err)=>{
                    send_error(err);
                    reject();
                  }
                );
              }
              else{
                send_error("Already part of this collection...!");
                reject();
              }
            }
            else if (opinion === false){
              // Get ids of docs to delete:
              // THERE SHOULD ONLY BE ONE....!
              const ids_to_remove = new Set(
                this_query_result
              );
              if (ids_to_remove.size>0){
                ids_to_remove.forEach(
                  this_doc_uid => firebase.user_product_collection_doc(
                    authUserUID,
                    collectionUID,
                    this_doc_uid
                  ).delete().then(
                    ()=>{
                      resolve();
                    }
                  ).catch(
                    (err)=>{
                      send_error(err);
                      reject();
                    }
                  )
                );
              }
              else {
                send_error("Does not seem to be part of this collection...!");
                reject();
              }
            }
            else{
              send_error("Unconventional opinion input - must be true or false...!");
              reject();
            }
          }
        );
      }
      else{
        send_error("Missing 'page_url' and/or 'collection_uid' and/or 'opinion' and/or 'product' in input!");
        reject();
      }
    }
  );
}
