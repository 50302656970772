/*

Ability to add products manually.  Mostly people doing this will use the extension in future.

TODO: typescript, camelCase, make functional, non-default exports, design system...

*/
import React from 'react';
import { Component } from 'react';
import {AddHelmetTitle} from "src/mvp22/core-components/helmet";
import styled from 'styled-components';
import {M} from 'src/mvp22/constants';
import MEDIA from 'src/mvp22/media';
import queryString from 'query-string';
import TextInputFeedback from 'src/mvp22/form-components/TextInputFeedback';
import Button from 'src/mvp22/form-components/Button';
import { withFirebase } from 'src/mvp22/Firebase';
import {EXTERNAL} from 'src/routes';
import {TEXTSTYLE} from 'src/mvp22/style-components/Text';
import {FullBlank} from 'src/mvp22/menu-components/TopMenu';
import TopMenu from 'src/mvp22/menu-components/TopMenu';
import {withRouter} from 'react-router-dom';
import {requiresAuth} from 'src/mvp22/redux-components/requiresAuth';
import {modalSet
} from 'src/mvp22/redux-components/actions';
import { connect } from 'react-redux';
import segmentEvent from "src/mvp22/segment-components/SegmentEvent";
import {CollectionListContainer,CollectionEntry,CollectionNameFlex,PlusSquare,ButtonRemove,ButtonAdd,CollectionSquare} from "src/mvp22/modal-components/CollectionMembershipModal";

const Container = styled.div`
  background-color:${M.COL.BUTTON.WHITE};
  color:${M.COL.TEXT.BLACK};
  padding-left:30px;
  padding-right:30px;
  text-align:center;
  display:flex;
  justify-content:center;
  align-items:center;
  box-sizing:border-box;
`;

const FormContainer = styled.div`
  margin-top: 125px;
  display:flex;
  flex-direction:column;
  box-sizing:border-box;
`;

const FormHeadingContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  text-align:left;
  box-sizing:border-box;
`;

const Seperator = styled.div `
  height:${props=>props.height};
`;

const InputTitle = styled(TEXTSTYLE.BODY4)`
  color: ${M.COL.TEXT.MID};
  margin-bottom:8px;
`;

const CancelAndConfirmButtonsContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
`;

const UnderlineLinkA = styled(TEXTSTYLE.LINKABOLD)`
color:default;
`;

const ColumnTitle = styled(TEXTSTYLE.HEADING4)`
  padding: 8px 0px;
  text-align:left;
`;


const ProBadge = styled.img`
  width: 24px;
  position: relative;
  bottom: 9px;
  margin-left: 8px;
`;

const ProductImagesBox = styled.div`
  display:flex;
  flex-wrap: wrap;
`;

const ProductImage = styled.img`
  width:100px;
`;

const ProductImageContainer = styled(TEXTSTYLE.LINKA)`
  margin:2px;
  position:relative;
  min-width:100px;
  min-height:30px;
  background-color:${M.COL.BG.LIGHT};
`;

const ProductImageSelectedTick = styled.img`
  position:absolute;
  top:0px;
  left:0px;
`;

const ColumnBlock = styled.div`
  display:flex;
  flex-direction:column;
  width:351px;
  margin:12px;
`;

const ColumnRow = styled.div`
  display:flex;
  flex-direction:row;
  box-sizing:border-box;
  flex-wrap:wrap;
  align-items: flex-start;
  justify-content: flex-start;
`;

const HeadingPadding = styled.div`
  padding:0px 8px;
`;

const CollectionListContainerAddProduct = styled(CollectionListContainer)`
  max-height:60vh;
  border:solid 1px ${M.COL.LINE.MID};
`;

const ErrorText = styled(TEXTSTYLE.BODY3)`
  color:${M.COL.TEXT.ERROR};
  margin:8px 0px;
`;

const ImageDims = styled(TEXTSTYLE.BODY5)`
color:default;
`;

const DEFAULT_STATE = {
  image_add_url:"",
  description:"",
  link:"",
  price:"",
  image_urls:[],
  valid_link:true,
  valid_price:true,
  valid_description:true,
  valid_image_urls:true,
  valid_image_add_url:false,
  image_add_url_modified:false,
  collections_to_add_to:{},
  done_successfully:false,
  formFeedback:"",
  dims_dict:{}
};

class AddProduct extends Component {
  constructor(props){
    super(props);
    this.state = DEFAULT_STATE;
    this.handleChange = this.handleChange.bind(this);
    this.doSubmit = this.doSubmit.bind(this);
    this.add_image = this.add_image.bind(this);
    this.set_collection_to_add_to = this.set_collection_to_add_to.bind(this);
    this.open_new_collection = this.open_new_collection.bind(this);
    this.add_another = this.add_another.bind(this);
  }

  componentDidMount(){
    segmentEvent("Viewed Add Product Page");
    const parsed_query = queryString.parse(this.props.location.search);
    // As is protected by requiredAuth, should always be loaded when mount done:
    this._isMounted = true;
    this.setState(
      {
        description:parsed_query.description?parsed_query.description:"",
        link:parsed_query.link?parsed_query.link:"",
        price:parsed_query.price?parsed_query.price:"",
        image_urls:parsed_query.images?parsed_query.images.split("m_o_o_n").map(x=>[x,false]):[]
      }
    );
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  priceCheck(price_text){
    return(price_text.length>0 && price_text.length<=15);
  }

  linkCheck(link_text){
    return(link_text.length>=10 && link_text.length<=1000);
  }

  descriptionCheck(){
    return true;
  }

  image_add_urlCheck(link_text){
    return this.linkCheck(link_text);
  }

  imageUrlsCheck(image_url_array){
    return(image_url_array.filter(x=>x[1]===true).length>0);
  }

  // controlls the form
  handleChange(event) {
    const name = event.target.name;
    const value = event.target.value;
    this.setState({
        [name]: value,
        ["valid_"+name]: this[name+"Check"](value)
    });
    if (name==="image_add_url"){
      this.setState({
        image_add_url_modified:true
      });
    }
  }

  include_image(index,event){
    event.preventDefault();
    this.setState(
      (prevState)=>{
        if (prevState.image_urls[index]){
          const image_urls = [...prevState.image_urls];
          image_urls[index][1]=!image_urls[index][1];
          return {
            image_urls
          };
        }
      }
    );
    return false;
  }

  add_image(){
    this.setState(
      (prevState)=>{
        if (this.image_add_urlCheck(prevState.image_add_url)){
          return {
            image_urls:[...prevState.image_urls,[prevState.image_add_url,true]],
            image_add_url:"",
            valid_image_add_url:false,
            image_add_url_modified:false
          };
        }
      }
    );
  }

  set_collection_to_add_to(collection_uid){
    this.setState(
      (prevState)=>{
        return{
          collections_to_add_to:Object.assign(
            {},
            prevState.collections_to_add_to,
            {
              [collection_uid]:!(prevState.collections_to_add_to[collection_uid]===true)
            }
          )
        };
      }
    );
  }

  add_another(event){
    event.preventDefault();
    this.setState(
      DEFAULT_STATE
    );
    return false;
  }

  open_new_collection(event){
    event.preventDefault();
    segmentEvent("Clicked On Create New Collection");
    this.props.setModal(
      {
        type:"new_collection",
        dontRedirect:true
      }
    );
    return false;
  }

  set_dims(key,width,height){
    this.setState(
      prevState=>{
        return(
          {
            dims_dict : Object.assign(
              {},
              prevState.dims_dict,
              {
                [key]:[width,height]
              }
            )
          }
        );
      }
    );
  }

  collectionCheck(collection_object){
    return Object.values(collection_object).filter(x=>x===true).length > 0;
  }

  checkAllValid(){
    return this.priceCheck(this.state.price)
        &&
         this.linkCheck(this.state.link)
        &&
         this.descriptionCheck(this.state.description)
        &&
         this.imageUrlsCheck(this.state.image_urls)
        &&
         this.collectionCheck(this.state.collections_to_add_to);
  }

  // ASYNC
  doSubmit(event){
   segmentEvent("Submitted Product For Manual Addition");
   event.preventDefault();
   if (!this.imageUrlsCheck(this.state.image_urls)){
     this.setState(
       {
         formFeedback:"At least one image is required for the product."
       }
     );
   }
   else if (!this.collectionCheck(this.state.collections_to_add_to)){
     this.setState(
       {
         formFeedback:"At least one collection is required to add the product to."
       }
     );
   }
   else if (
     this.checkAllValid()
   ){
     this._isMounted && this.setState(
       {
         working:true
       }
     );
     this.props.firebase.fast_api()(
       {
         api:"add_product_manually",
         description:this.state.description,
         link:this.state.link,
         price:this.state.price,
         image_urls:this.state.image_urls.filter(x=>x[1]===true).map(x=>x[0]),
         collections_to_add_to:Object.keys(this.state.collections_to_add_to).filter(x=>this.state.collections_to_add_to[x]===true)
       }
     ).then(
       // Add to collections specified:
       (result)=>{
         if (result.data.success===true){
           // Also add it to all the collections:
           this._isMounted && this.setState(
             {
               done_successfully:true
             }
           );
         }
         else{
           this._isMounted && this.setState(
             {
               formFeedback:"Very sorry but adding a new product appears to have failed due to an internal error.  Check your internet connection and try again or send us feedback.",
               working:false
             }
           );
         }
       }
     ).catch(
       (err)=>{
         console.error("Failure",err);
         this._isMounted && this.setState(
           {
             formFeedback:"Very sorry but adding a new product appears to have failed due to an internal error.  Check your internet connection and try again or send us feedback.",
             working:false
           }
         );
       }
     );
   }
   else{
     this.setState(
       {
         formFeedback:"Please check for errors and try again."
       }
     );
   }
  }


  render(){
    return(
      <FullBlank className='fullBlank'>
        <TopMenu hug="edge"/>
          {AddHelmetTitle("Add Product")}
          <Container className='container'>
          <FormContainer>
          {(this.state.done_successfully!==true)?
            <HeadingPadding>
              <FormHeadingContainer>
                <TEXTSTYLE.HEADING2>Add Product</TEXTSTYLE.HEADING2><ProBadge src={MEDIA.PRO_BADGE}/>
              </FormHeadingContainer>
              <Seperator height='12px'/>
              <FormHeadingContainer>
                <TEXTSTYLE.BODY3>
                  This Moonsift Pro tool allows you to add products from sites the <UnderlineLinkA href={EXTERNAL.CHROMESTORE}>Moonsift Chrome Extension</UnderlineLinkA> does not yet support.
                </TEXTSTYLE.BODY3>
              </FormHeadingContainer>
            </HeadingPadding>
            :""
          }
              <Seperator height='12px'/>
              {
                  (this.state.done_successfully===true)?
                    <TEXTSTYLE.BODY3>
                      Successfully added new product.<br/><UnderlineLinkA href="#" onClick={this.add_another}>Add another product</UnderlineLinkA>
                    </TEXTSTYLE.BODY3>
                  :
                    <ColumnRow>
                      <ColumnBlock>
                      <ColumnTitle>Collections</ColumnTitle>
                      <FormHeadingContainer>
                        <InputTitle>
                          Select one or more collections to add this product to:
                        </InputTitle>
                      </FormHeadingContainer>
                        <CollectionListContainerAddProduct>
                          <CollectionEntry href="#" onClick={this.open_new_collection}>
                            <PlusSquare/>
                            <CollectionNameFlex>New collection</CollectionNameFlex>
                          </CollectionEntry>
                            {
                              (this.props.firestore_user_collection_list.collections_sorted !== undefined)?
                                this.props.firestore_user_collection_list.collections_sorted.map(
                                  (data,index)=>{
                                    const current_collection_uid = data.uid;
                                    const added = this.state.collections_to_add_to[current_collection_uid]===true;
                                    return(
                                      <CollectionEntry
                                       index={index}
                                       added={added}
                                       key={index}
                                       onClick={
                                        (event)=>{
                                          this.set_collection_to_add_to(
                                            current_collection_uid
                                          );
                                          event.preventDefault();
                                          return false;
                                        }
                                       }
                                       href="#"
                                       >
                                        <CollectionSquare
                                          circle_url={data.circle_url}
                                        />
                                        <CollectionNameFlex>{data.name}</CollectionNameFlex>
                                          {
                                            (added)?
                                              <ButtonRemove>
                                                Deselect
                                              </ButtonRemove>
                                            :
                                              <ButtonAdd>
                                                Select
                                              </ButtonAdd>
                                          }
                                      </CollectionEntry>
                                    );
                                  }
                                )
                              :""
                            }
                        </CollectionListContainerAddProduct>
                      </ColumnBlock>
                      <ColumnBlock>
                        <ColumnTitle>Images</ColumnTitle>
                        {
                          (this.state.image_urls.length>0)?
                            <>
                              <FormHeadingContainer>
                                <InputTitle>
                                {
                                  (this.state.image_urls.length>0)?
                                    "Select images of the product to include or add more"
                                  :
                                    ""
                                }
                                </InputTitle>
                              </FormHeadingContainer>
                              <ProductImagesBox className="ProductImagesBox">
                                {
                                  this.state.image_urls.map(
                                    (image_url,index)=>
                                    <ProductImageContainer href="#" onClick={event=>this.include_image(index,event)} key={index}>
                                      {
                                        (image_url[1]===true)?
                                          <ProductImageSelectedTick src={MEDIA.TICK}/>
                                        :""
                                      }
                                      <ProductImage src={image_url[0]} onLoad={(event)=>this.set_dims(image_url[0],event.target.naturalWidth,event.target.naturalHeight)}/>
                                      <ImageDims>
                                        {
                                          (this.state.dims_dict[image_url[0]])?this.state.dims_dict[image_url[0]][0]+" x "+this.state.dims_dict[image_url[0]][1]+" pixels":""
                                        }
                                      </ImageDims>
                                    </ProductImageContainer>
                                  )
                                }
                              </ProductImagesBox>
                            </>
                          :""
                        }
                        <FormHeadingContainer>
                          <InputTitle>{
                            (this.state.image_urls.length>0)?"Add another image:":"Add an image:"
                          }
                          </InputTitle>
                        </FormHeadingContainer>
                        <TextInputFeedback
                          focusOnMount={true}
                          placeholder="Paste in external image URL"
                          msstyle="standard"
                          onChange={this.handleChange}
                          feedbackText={this.state.valid_image_add_url || this.state.image_add_url_modified===false?"":"Image URL must be between 10 and 1000 characters"}
                          value={this.state.image_add_url}
                          name="image_add_url"
                          width="100%"
                          onEnter={this.doSubmit}
                        />
                        <Button
                          type='button'
                          text="Add Image"
                          msstyle="option_positive"
                          onClick={this.add_image}
                          width="150px"
                          enabled={this.state.valid_image_add_url}
                        />
                      <Seperator height='24px'/>
                    </ColumnBlock>
                    <ColumnBlock>
                      <ColumnTitle>Details</ColumnTitle>
                      <FormHeadingContainer>
                        <InputTitle>Price</InputTitle>
                      </FormHeadingContainer>
                      <TextInputFeedback
                        focusOnMount={true}
                        placeholder="A price is required"
                        msstyle="standard"
                        onChange={this.handleChange}
                        feedbackText={this.state.valid_price?"":"Price information must be between 1 and 15 characters"}
                        value={this.state.price}
                        name="price"
                        width="100%"
                        onEnter={this.doSubmit}
                      />
                      <FormHeadingContainer>
                        <InputTitle>Description</InputTitle>
                      </FormHeadingContainer>
                      <TextInputFeedback
                        placeholder=""
                        msstyle="standard"
                        value={this.state.description}
                        onChange={this.handleChange}
                        name="description"
                        width="100%"
                        onEnter={this.doSubmit}
                      />
                      <FormHeadingContainer>
                        <InputTitle>Link</InputTitle>
                      </FormHeadingContainer>
                      <TextInputFeedback
                        placeholder=""
                        msstyle="standard"
                        value={this.state.link}
                        onChange={this.handleChange}
                        feedbackText={this.state.valid_link?"":"Link URL must be between 10 and 1000 characters"}
                        name="link"
                        width="100%"
                        onEnter={this.doSubmit}
                      />
                      <Seperator height='46px'/>
                      <CancelAndConfirmButtonsContainer>
                        <Button
                          type='button'
                          text="Add Product"
                          msstyle="option_positive"
                          onClick={this.doSubmit}
                          working={this.state.working}
                          width="150px"
                          enabled={this.priceCheck(this.state.price)
                              &&
                               this.linkCheck(this.state.link)
                              &&
                               this.descriptionCheck(this.state.description)}
                        />
                      </CancelAndConfirmButtonsContainer>
                      <ErrorText>{(this.state.formFeedback)?this.state.formFeedback:""}</ErrorText>
                    </ColumnBlock>
                  </ColumnRow>
              }
          </FormContainer>
        </Container>
      </FullBlank>
    );
  }
}

const mapStateToProps = (state) => {
  return ({
    pro:state.firestore_user_owner.pro,
    firestore_user_collection_list:state.firestore_user_collection_list,
  });
};

const mapDispatchToProps = dispatch => {
  return {
    setModal:(data)=>dispatch(modalSet(data)),
  };
};

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(
    requiresAuth(
      withFirebase(
        AddProduct
      )
    )
  )
);
