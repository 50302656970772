import { useEffect, useContext, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  RootState,
  FirebaseContext,
  CloudContext,
  NotificationsContext,
} from 'src';
import { firestore_user_set_up_redux } from 'src/mvp22/redux-components/reducers/firestore_user_set_up';

/*

This hook loads in any other data about the user from the database:

TIP: Ask David about it since how we store the data should be refactored.

*/
export const useAuthUserData = () => {
  const dispatch = useDispatch();
  const authUserUID = useSelector<RootState, string | null>(
    (state) => state.auth.id,
  );
  const authUserEmail = useSelector<RootState, string | null>(
    (state) => state.auth.email,
  );
  const authUserSet = useSelector<RootState, boolean>(
    (state) => state.auth.set,
  );
  const cloud = useContext(CloudContext);
  const notification = useContext(NotificationsContext);

  // TODO: Remove this in next refactor:
  const firebase = useContext(FirebaseContext);
  const firestore_user_set_up = useCallback(
    (data: any) => dispatch(firestore_user_set_up_redux(data)),
    [dispatch],
  );

  useEffect(() => {
    // Analytics:
    if (authUserEmail !== null) {
      const identifierEmail = authUserEmail.includes('moonsift')
        ? 'test@test.test'
        : undefined;
      window.analytics.identify(authUserUID, { email: identifierEmail });
    } else {
      window.analytics.identify(authUserUID, {});
    }
    // TODO: Replace this in next refactor:
    if (authUserSet) {
      firestore_user_set_up({
        firebase,
        auth_user_uid: authUserUID,
      });
    }
  }, [
    firestore_user_set_up,
    firebase,
    authUserEmail,
    authUserUID,
    authUserSet,
  ]);

  useEffect(() => {
    if ('Notification' in window && Notification.permission === 'granted') {
      const notificationTokenPromise = notification.getToken();
      if (notificationTokenPromise !== null) {
        notificationTokenPromise
          .then(
            (
              token: string, // Now update the notification token:
            ) =>
              cloud.fastAPI({
                api: 'update_notification_token',
                token,
              }),
          )
          .catch((err: any) => console.log(err));
      }
    }
  }, [cloud, notification]);
};
