import { FirebaseApp } from '@firebase/app';
import {
  getFirestore,
  Firestore,
  connectFirestoreEmulator,
  DocumentSnapshot,
  QueryDocumentSnapshot,
  getDoc,
  addDoc,
  updateDoc,
  deleteDoc,
  doc,
  getDocs,
  collection,
  CollectionReference,
  where,
  query,
  Query as FirebaseQuery,
  onSnapshot,
  DocumentData,
  QuerySnapshot,
  Timestamp,
  limit,
  collectionGroup,
  orderBy,
  FirestoreError,
} from 'firebase/firestore';

export type Query = FirebaseQuery | CollectionReference;

/*

The main database, NoSQL.

*/
export class DB {
  fs: Firestore;

  constructor(app: FirebaseApp) {
    this.fs = getFirestore(app);
    // Development Settings:
    if (process.env.REACT_APP_USE_LOCAL_FIRESTORE === 'TRUE') {
      connectFirestoreEmulator(this.fs, 'localhost', 8383);
    }
  }

  // Add functions here to get db docs + collection refs + queries:
  // Collections + Queries return query objects:
  collectionDoc = (collectionUserUID: string, collectionUID: string) =>
    `/databases/user_product_collection/${collectionUserUID}/${collectionUID}`;
  collectionBookmarkDoc = (
    authUserUID: string,
    collectionUserUID: string,
    collectionUID: string,
  ) => {
    const docID = collectionUserUID + '_____' + collectionUID;
    return `/databases/user_likes/${authUserUID}/likes/liked_collections/${docID}`;
  };
  collectionViewsDoc = (
    authUserUID: string,
    collectionUserUID: string,
    collectionUID: string,
  ) => {
    return `/databases/user_product_collection/${collectionUserUID}/${collectionUID}/view_by_user/${authUserUID}`;
  };
  collectionItemsQuery = (collectionUserUID: string, collectionUID: string) =>
    query(
      collection(
        this.fs,
        `/databases/user_product_collection/${collectionUserUID}/${collectionUID}/list`,
      ),
      orderBy('created_at', 'desc'),
    );
  userPubicDoc = (userUID: string) => `/databases/user/${userUID}/public`;
  userOwnerDoc = (userUID: string) => `/databases/user/${userUID}/owner`;
  userScrape = (userUID: string, docUID: string) =>
    `/databases/user_scrape_opinions/${userUID}/${docUID}`;
  itemIsSaved = (authUserUID: string, pageURL: string) =>
    query(
      collectionGroup(this.fs, `list`),
      where('user_uid', '==', authUserUID),
      where('page_url', '==', pageURL),
      limit(1),
    );
  itemIsPurchased = (authUserUID: string, pageURL: string) =>
    query(
      collection(
        this.fs,
        `/databases/user_product_collection/${authUserUID}/purchased/list`,
      ),
      where('page_url', '==', pageURL),
      limit(1),
    );
  itemIsLikedOrDisliked = (
    collectionUserUID: string,
    collectionUID: string,
    authUserUID: string,
    itemUID: string,
  ) =>
    query(
      collection(
        this.fs,
        `/databases/user_product_collection/${collectionUserUID}/${collectionUID}/list/${itemUID}/reactions_and_comments`,
      ),
      where('user_uid', '==', authUserUID),
      where('type', 'in', ['upvote', 'downvote']),
    );
  product = (productUID: string) => `/databases/product/products/${productUID}`;
  userCollectionList = (userUID: string) =>
    collection(this.fs, `/databases/user_product_collection/${userUID}`);
  problemList = () => collection(this.fs, '/databases/problem/problems');

  // Add functions here to perform actions on collections and docs:
  // TODO: Consider whether we still need this after removing old firebase service from mvp22;
  getDoc = (docRef: string) => getDoc(doc(this.fs, docRef));
  getQuery = (queryIn: Query) => getDocs(queryIn);
  listenDoc = (
    docRef: string,
    callback: (x: DocumentSnapshot<DocumentData>) => void,
    onError: (error: FirestoreError) => void,
  ) => onSnapshot(doc(this.fs, docRef), callback, onError);
  listenQuery = (
    queryIn: Query,
    callback: (x: QuerySnapshot<DocumentData>) => void,
    onError: (error: FirestoreError) => void,
  ) => onSnapshot(queryIn, callback, onError);
  deleteDoc = (docRef: string) => deleteDoc(doc(this.fs, docRef));
  addDoc = (collectionRef: CollectionReference, data: any) =>
    addDoc(collectionRef, data);
  updateDoc = (docRef: string, data: any) =>
    updateDoc(doc(this.fs, docRef), data);
  serverTimestamp = () => {
    return Timestamp.now();
  };
}

// Types required elsewhere will be imported from here NOT from firebase-firestore so firebase is not
// an explicitly hard-coded dependency!
export type {
  DocumentData,
  QuerySnapshot,
  QueryDocumentSnapshot,
  DocumentSnapshot,
  Timestamp,
  FirestoreError as DBError,
} from 'firebase/firestore';
export type ParserInput = DocumentSnapshot | QueryDocumentSnapshot;
