import React, { useState, useEffect } from 'react';
import { CookieMessageContainerProps } from './CookieMessage.types';
import { Constants } from 'src/constants';
import Cookies from 'universal-cookie';
import CookieNotice from 'src/mvp22/menu-components/CookieNotice';
const cookies = new Cookies();

const Container: React.FC<CookieMessageContainerProps> = () => {
  const [showCookieMessage, setShowCookieMessage] = useState(false);
  // Read local cookie to see if user has hidden cookie message:
  useEffect(() => {
    var newShowCookieMessage = cookies.get(Constants.COOKIEMESSAGEKEY);
    if (newShowCookieMessage === undefined) {
      newShowCookieMessage = 'yes';
      cookies.set(Constants.COOKIEMESSAGEKEY, 'yes', {
        path: Constants.COOKIEPATH,
      });
    }
    setShowCookieMessage(newShowCookieMessage !== 'no');
  }, []);
  // Function to do the hiding:
  const toggleCookieMessage = (event: React.MouseEvent) => {
    event.preventDefault();
    const newTickState =
      cookies.get(Constants.COOKIEMESSAGEKEY) === 'yes' ? 'no' : 'yes';
    cookies.set(Constants.COOKIEMESSAGEKEY, newTickState, {
      maxAge: 60 * 60 * 24 * 365,
      path: Constants.COOKIEPATH,
    });
    setShowCookieMessage(newTickState === 'yes');
    return false;
  };
  return showCookieMessage ? (
    <CookieNotice toggle={toggleCookieMessage} />
  ) : (
    <></>
  );
};

export { Container as CookieMessage };
