import { combineReducers } from 'redux';
import { RootDB } from 'src/types';

// TODO: Replace all these with template reducers:
import firebasestate from 'src/mvp22/redux-components/reducers/firebasestate';
import generalui from 'src/mvp22/redux-components/reducers/generalui';
import firestore_user_owner from 'src/mvp22/redux-components/reducers/firestore_user_owner';
import firestore_user_collection_list from 'src/mvp22/redux-components/reducers/firestore_user_collection_list';
import firestore_product_membership from 'src/mvp22/redux-components/reducers/firestore_product_membership';
import firestore_notifications from 'src/mvp22/redux-components/reducers/firestoreNotifications';

import { auth } from './auth';
import { ui } from './ui';
import { footerPopup } from './footerPopup';
import { modal } from './modal';
import { working } from './working';
import {
  getBaseDBReducer,
  DEFAULT_QUERY_STATE,
  DEFAULT_DOC_STATE,
  DEFAULT_DOC_MAP_STATE,
  DEFAULT_QUERY_MAP_STATE,
} from './baseDB';
import { productData } from './productData';

// Add DB states here:!
const DEFAULT_STATE: RootDB = {
  single_collection: DEFAULT_DOC_STATE,
  single_collection_item_list: DEFAULT_QUERY_STATE,
  single_collection_bookmark: DEFAULT_DOC_STATE,
  single_collection_views: DEFAULT_DOC_STATE,
  user_public: DEFAULT_DOC_MAP_STATE,
  single_collection_scrape: DEFAULT_DOC_MAP_STATE,
  single_collection_product: DEFAULT_DOC_MAP_STATE,
  single_collection_product_is_saved: DEFAULT_QUERY_MAP_STATE,
  single_collection_product_is_purchased: DEFAULT_QUERY_MAP_STATE,
  single_collection_product_is_liked_or_disliked: DEFAULT_QUERY_MAP_STATE,
};

const reducers = combineReducers({
  auth,
  db: getBaseDBReducer(DEFAULT_STATE),
  productData,
  ui,
  footerPopup,
  modal,
  working,
  // TODO: replace these:
  firebasestate,
  generalui,
  firestore_user_collection_list,
  firestore_user_owner,
  firestore_product_membership,
  firestore_notifications,
});

export default reducers;
