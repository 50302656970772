/*

The overlay over the product tile images that show a summary of reactions + comments to the product.

TODO: camelCase, typescript

*/
import styled from 'styled-components';
import React,{useState,useContext} from 'react';
import { useSelector } from 'react-redux';
import { DBContext } from 'src/index';
import {M} from 'src/mvp22/constants';
import { useDBRetrieveDoc } from 'src/hooks/useDBRetrieveDoc';
import MEDIA from 'src/mvp22/media';
import {TEXTSTYLE} from 'src/mvp22/style-components/Text';
import condense_number from 'src/mvp22/core-components/condense_number';
import ProfileImage from 'src/mvp22/image-components/ProfileImage';
import { userPublicParser } from 'src/store/parsers/userPublic';

const OverlayContainer = styled.div`
  width: 100%;
  display:flex;
  height:48px;
  padding-left:48px;
  box-sizing:border-box;
  background:${props=>props.open?M.COL.BG.WHITE:"auto"};
`;

const FlexDiv = styled.div`
  flex-direction:row;
  display:${props=>props.open?"flex":"none"};
`;

const ReportContainer = styled.div`
  display:flex;
  align-items: center;
  padding: 8px;
`;

const ReportImage = styled.img`
  margin-right:4px;
`;

const ReportText = styled(TEXTSTYLE.OTHER4)`
  position:relative;
`;

const CornerImagesContainer = styled.div`
  position:absolute;
  left:0px;
  top:0px;
  width:48px;
  height:48px;
  border-radius:24px;
  box-sizing:border-box;
  background-color: ${M.COL.BG.WHITE};
  display:flex;
  justify-content:center;
  align-items:center;
`;

const ProfileImageContainer = styled.div`
  display:block;
  background-color:${M.COL.BUTTON.LIGHT};
  box-shadow: 0px 0.9px 1.8px rgba(0, 0, 0, 0.25);
  border-radius:14px;
  height:27px;
  width:27px;
  border: solid 2px ${M.COL.TEXT.WHITE};
  position:relative;
  box-sizing:border-box;
  z-index:10;
`;

const SupCornerImage = styled(ProfileImageContainer)`
  position: absolute;
  left: 5px;
  z-index:5;
`;

const YellowDotBase = styled.div`
  width: 10px;
  height: 10px;
  background: #FDCA47;
  border: 1px solid #FFFFFF;
  box-sizing: border-box;
  border-radius:5px;
`;

const YellowDotCorner = styled(YellowDotBase)`
  position:absolute;
  top: -2px;
  right: -4px;
`;

const YellowDotReport = styled(YellowDotBase)`
  position:absolute;
  top: -2px;
  right: -10px;
`;

const get_array_entry = (type,collection_entry,auth_user_uid)=>{
  return {
    image:MEDIA[`REPORT_${type.toUpperCase()}S`],
    count:(collection_entry[`${type}_count`])?collection_entry[`${type}_count`]:0,
    last_updated_seconds:(collection_entry[`${type}_last_updated`])?collection_entry[`${type}_last_updated`].seconds:0,
    last_user_uid:(collection_entry[`${type}_last_user_uid`])?collection_entry[`${type}_last_user_uid`]:false,
    last_updated_by_someone_else_seconds:(collection_entry[`${type}_last_user_uid`]===auth_user_uid)?
      (collection_entry[`${type}_prev_updated`])?collection_entry[`${type}_prev_updated`].seconds:0
     :
      (collection_entry[`${type}_last_updated`])?collection_entry[`${type}_last_updated`].seconds:0
  };
};

const ReactionsInfo = (props)=>{
  const [mouse_over_corner,setMouseOverCorner] = useState(false);
  // extract the relevant timing data:
  const LAST_VIEWED_SECONDS = props.viewing_data?props.viewing_data.seconds:0;
  const NOTIFICATION_ARRAY = [
    get_array_entry("comment",props.collection_entry,props.auth_user_uid),
    get_array_entry("upvote",props.collection_entry,props.auth_user_uid),
    get_array_entry("downvote",props.collection_entry,props.auth_user_uid)
  ];
  const LAST_VIEWED_USER_UID = [...NOTIFICATION_ARRAY].sort((a,b)=>(a.last_updated_seconds>b.last_updated_seconds?-1:1))[0].last_user_uid;
  const NOTIFICATION_ON = props.auth_user_uid && props.auth_user_uid === props.collection_entry.user_uid && NOTIFICATION_ARRAY.filter(x=>x.last_updated_by_someone_else_seconds>LAST_VIEWED_SECONDS).length>0;
  const most_recent_user_uids = [...(new Set(
    [
      props.collection_entry.comment_last_user_uid,
      props.collection_entry.upvote_last_user_uid,
      props.collection_entry.downvote_last_user_uid
    ].filter(x=>x!==undefined)
  ))];
  // If the only new comments are by the current user, this incorrectly shows another circle, but otherwise it should be correct.
  const SHOW_TWO_CIRCLES = most_recent_user_uids.length>1||NOTIFICATION_ARRAY.filter(x=>x.count>1).length>0;
  const SHOW_ANYTHING = NOTIFICATION_ARRAY.map(x=>x.count).reduce((x,y)=>x+y)>0;
  const SHOW_CORNER = NOTIFICATION_ON || props.mouseover_tile;
  const OPEN = (NOTIFICATION_ON && props.mouseover_tile) || mouse_over_corner===true;
  const dB = useContext(DBContext);
  // Ensure we have fetched the auth user public profile:
  // User Public Profiles not removed on unmount!
  // todo: ADD TYPESCRIPT "user_public" type!
  useDBRetrieveDoc(dB,dB.userPubicDoc,'user_public',userPublicParser,LAST_VIEWED_USER_UID,[LAST_VIEWED_USER_UID],"get",false);
  const userPublicProfile = useSelector((state) => state.db.user_public.map[LAST_VIEWED_USER_UID]);
  const PROFILE_IMAGE_CODE = userPublicProfile?userPublicProfile.profile_image_code:undefined;
  const PROFILE_NAME = userPublicProfile?userPublicProfile.name:undefined;

  return (
    // Don't create the components if there are no comments / reactions:
    (SHOW_ANYTHING)?
      <OverlayContainer open={OPEN} style={{display:SHOW_CORNER?"flex":"none"}}>
        <CornerImagesContainer onMouseOver={()=>setMouseOverCorner(true)} onMouseOut={()=>setMouseOverCorner(false)}>
          <ProfileImageContainer
            two_circles={SHOW_TWO_CIRCLES}
          >
            <ProfileImage
              profile_image_code={PROFILE_IMAGE_CODE}
              name={PROFILE_NAME}
              user_uid={LAST_VIEWED_USER_UID}
              scale={23}
            />
            {
              (NOTIFICATION_ON)?
                <YellowDotCorner/>
              :
                null
            }
          </ProfileImageContainer>
          {
            (SHOW_TWO_CIRCLES)?
              <SupCornerImage/>
            :
              null
          }
        </CornerImagesContainer>
        <FlexDiv open={OPEN}>
          {
            NOTIFICATION_ARRAY.map(
              (arr,index)=> {
                if (arr.count>0){
                  return (
                    <ReportContainer key={index}>
                      <ReportImage src={arr.image}/>
                      <ReportText>
                        {condense_number(arr.count)}
                        {
                          props.auth_user_uid && props.auth_user_uid === props.collection_entry.user_uid && arr.last_updated_by_someone_else_seconds>LAST_VIEWED_SECONDS?
                            <YellowDotReport/>
                          :
                            null
                        }
                      </ReportText>
                    </ReportContainer>
                  );
                }
                return null;
              }
            )
          }
        </FlexDiv>
      </OverlayContainer>
    :
      null
  );
};

export default ReactionsInfo;
