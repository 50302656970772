/*

Displays things realted to organise mode within a collection view.

TODO: typescript, design-system...

*/
import {useRef,useCallback,useState,useContext} from 'react';
import {useSelector} from 'react-redux';
import styled from 'styled-components';

import { getCollectionItemStoreAs } from 'src/utils/getCollectionItemStoreAs';
import MEDIA from 'src/mvp22/media';
import {getCustomUrl} from "src/mvp22/core-components/image_url";
import LoadingDots from 'src/mvp22/image-components/LoadingDots';
import {M} from 'src/mvp22/constants';
import {TEXTSTYLE} from 'src/mvp22/style-components/Text';
import prepareFileForUpload from "src/mvp22/core-components/prepareFileForUpload";
import {CloudContext} from 'src/index';

const OrganiseMessageBlockContainer = styled.div`
  width:100%;
  box-sizing:border-box;
  display:flex;
  flex-direction:column;
`;

const OrganiseMessageContainer = styled.div`
  width:100%;
  display: flex;
  align-items: center;
  justify-content: center;
  box-sizing:border-box;
  margin-bottom: 12px;

  &:last-child {
    margin-bottom: 28px;

    ${({ theme }) => theme.fns.getMediaQuery({ minWidth: 'md' })} {
      margin-bottom: 40px;
    }
  }
`;

const OrganiseMessage = styled(TEXTSTYLE.BODY4)`
  padding:12px;
  background-color:${M.COL.BG.MID};
  color:${M.COL.TEXT.BLACK};
  width:auto;
  text-align:left;
  border-radius: 5px;
`;

const OrganiseClickable = styled(TEXTSTYLE.LINKA)`
  text-decoration:underline;
`;

const CoverBoxCrossImage=styled.img`
  height:9px;
  width:9px;
`;

const OganiseMessageCoverContainer = styled.div`
  display:flex;
  align-items:center;
  flex-wrap:wrap;
  row-gap:6px;
`;

const LoadingDotsContainer = styled.div`
  margin-left:12px;
`;

const UploadingError = styled(TEXTSTYLE.BODY4)`
  color:${M.COL.TEXT.ERROR};
  margin-left:12px;
`;

const FileUploadButton = styled.input`
  display:none;
`;

const CoverBox=styled.div`
  width:42px;
  height:42px;
  background-color:${M.COL.BG.MID};
  background-image:${props=>props.image_to_show?`url(${props.image_to_show})`:"none"};
  background-size: cover;
  background-position:center;
  position:relative;
  display:flex;
  align-items:center;
  justify-content:center;
  margin-left:12px;
`;

const CoverBoxCross=styled(TEXTSTYLE.LINKA)`
  background-color:${M.COL.BUTTON.MIDDARK};
  border-radius:50%;
  height:16px;
  width:16px;
  display:flex;
  align-items:center;
  justify-content: center;
  position:absolute;
  right:0px;
  top:0px;
`;

export const OrganiseMessageBlock = ({
    thisOrderInfo,
    isOrganising,
    isPro,
    collectionUID,
    collectionUserUID,
    setOrganisingCoverImageURL,
    deleteCoverByIndex,
    setChoosingCoverImage,
    choosingCoverImage, // mode of choosing from collection products (true) OR uploading an image (false)
  })=>{
  const cloud = useContext(CloudContext);
  const uploadButtonReference = useRef();
  const [coverUploading,setCoverUploading] = useState(false);
  const [coverUploadingError,setCoverUploadingError] = useState("");
  // Ideally this would not be in here but legacy structure dictates it should be.
  const productDataMap = useSelector((state) => state.productData?state.productData:undefined);

  const uploadCover = useCallback(
    async (event)=>{
      event.preventDefault();
      setCoverUploading(true);
      setChoosingCoverImage(false);
      try{
        const image_url = await prepareFileForUpload(event.target);
        const response = await cloud.call('collections_upload_cover_image-call')(
          {
            collection_uid:collectionUID,
            image:image_url
          }
        );
        if (response.data.success===true){
          setCoverUploadingError("");
          setOrganisingCoverImageURL(response.data.image_code);
        }else{
          setCoverUploadingError("Unexpected Error uploading - check you are connected to the Interwebs.");
        }
      }catch(error_message){
        setCoverUploadingError(error_message);
        return false;
      }finally{
        setCoverUploading(false);
      }
    },
    [setOrganisingCoverImageURL,setCoverUploadingError,setCoverUploading,cloud,collectionUID,setChoosingCoverImage]
  );

  const deleteCover = useCallback(
    (event,item_index)=>{
      event.preventDefault();
      if (item_index===null){
        setChoosingCoverImage(true);
        setOrganisingCoverImageURL(null);
      }else{
        deleteCoverByIndex(item_index);
      }
      return false;
    },
    [setChoosingCoverImage,setOrganisingCoverImageURL,deleteCoverByIndex]
  );

  if (!isOrganising) {
    return null;
  }

  return (
    <OrganiseMessageBlockContainer>
      {isPro ? (
        <OrganiseMessageContainer key="CoverMessage">
          <OganiseMessageCoverContainer>
            <OrganiseMessage>
              <FileUploadButton
                type="file"
                name="upload_picture"
                ref={uploadButtonReference}
                onChange={uploadCover}
                accept="image/*"
              />
              <OrganiseClickable
                onClick={
                  (event) => {
                    event.preventDefault();
                    setChoosingCoverImage(true);
                    setOrganisingCoverImageURL(null);
                    setCoverUploadingError("");
                    return false;
                  }
                }
                href='#'
              >
                Choose
              </OrganiseClickable>
              {' '}which products to display on the cover of this collection. Or{' '}
              <OrganiseClickable
                href='#'
                onClick={
                  (event) => {
                    event.preventDefault();
                    uploadButtonReference.current.click();
                    return false;
                  }
                }
              >
                upload
              </OrganiseClickable>
              {' '}your own image.
            </OrganiseMessage>
            {choosingCoverImage ?
              [1,2,3].map(this_index => {
                const item_uid = getCollectionItemStoreAs(collectionUID,collectionUserUID,thisOrderInfo.coverImageDict[this_index]);
                const image_to_show = (item_uid && productDataMap && productDataMap[item_uid] && productDataMap[item_uid].imageList)
                  ? productDataMap[item_uid].imageList[0]
                  :false;
                return(
                  <CoverBox key={this_index} image_to_show={image_to_show}>
                    {image_to_show ? (
                      <CoverBoxCross
                        href="#"
                        onClick={(event)=>deleteCover(event,this_index)}
                      >
                        <CoverBoxCrossImage src={MEDIA.EXIT_CROSS_WHITE} />
                      </CoverBoxCross>
                    ) : (
                      <TEXTSTYLE.HEADING5>{this_index}</TEXTSTYLE.HEADING5>
                    )
                    }
                  </CoverBox>
                );
              }) : (coverUploading === true) ? (
                <LoadingDotsContainer>
                  <LoadingDots msstyle="dark"/>
                </LoadingDotsContainer>
              ) : thisOrderInfo.coverImageURL ? (
                <CoverBox
                  key={'uploaded'}
                  image_to_show={getCustomUrl(thisOrderInfo.coverImageURL)}
                >
                  <CoverBoxCross
                    href="#"
                    onClick={(event)=>deleteCover(event,null)}
                  >
                    <CoverBoxCrossImage src={MEDIA.EXIT_CROSS_WHITE} />
                  </CoverBoxCross>
                </CoverBox>
              ) :
              null
            }
            {!choosingCoverImage && coverUploadingError ? (
              <UploadingError>
                {coverUploadingError}
              </UploadingError>
            ) :
              null
            }
          </OganiseMessageCoverContainer>
        </OrganiseMessageContainer>
      ) : (
        <OrganiseMessageContainer>
          <OrganiseMessage>
            Upon saving, the first three products will be used to update the preview for this collection. This may take a few moments.
          </OrganiseMessage>
        </OrganiseMessageContainer>
      )}
    </OrganiseMessageBlockContainer>
  );
};
