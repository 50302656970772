import { DB, DocumentSnapshot } from 'src/services/DB';
import { all, put, takeEvery } from 'redux-saga/effects';
import { baseDBSetDoc } from 'src/store/actions/baseDB';
import {
  ActionNames,
  ParserFunction,
  BaseDBActionTypes,
  BaseDBKindsUnion,
  BaseDBFetchingDict,
} from 'src/types';
import {
  addToFetchingDict,
  checkFetchingDict,
  deleteFromFetchingDict,
} from 'src/utils/fetchingDict';

const fetchingDict: BaseDBFetchingDict = {};

/*

The saga for getting documents from the database in a single instance (not listening).

*/
function* getItems<K extends BaseDBKindsUnion>(
  kind: K['name'],
  dB: DB,
  dbRef: string,
  parser: ParserFunction<K['item']>,
  storeAs: string | undefined,
) {
  if (!checkFetchingDict(kind, storeAs, fetchingDict)) {
    try {
      addToFetchingDict(kind, storeAs, fetchingDict);
      const prom = dB
        .getDoc(dbRef)
        .then((result) => result)
        .catch((err) => {
          console.log('useDB error for kind', kind, err);
          return null;
        });
      const snapshot: DocumentSnapshot | null = yield prom;
      // process the snapshot:...
      const parsedData = snapshot !== null ? parser(snapshot) : null;
      // Put data away:
      yield put<BaseDBActionTypes<K>[ActionNames.BASEDB_SET_DOC]>(
        baseDBSetDoc<K>(parsedData, kind, storeAs),
      );
    } finally {
      //Nothing
    }
    // Anything below here is never called (unless there is an error in yield...?)
  }
}

// worker saga:
function* doGet<K extends BaseDBKindsUnion>(
  action: BaseDBActionTypes<K>['BASEDB_GET_DOC'],
) {
  yield getItems<K>(
    action.kind,
    action.payload.dB,
    action.payload.dBRef,
    action.payload.parser,
    action.storeAs,
  );
}

function clearFetching<K extends BaseDBKindsUnion>(
  action:
    | BaseDBActionTypes<K>['BASEDB_UNLISTEN_SINGLE']
    | BaseDBActionTypes<K>['BASEDB_UNLISTEN_MAP'],
) {
  deleteFromFetchingDict(action.kind, undefined, fetchingDict);
}

// Watching saga:
export function* baseDocGet() {
  yield all([
    takeEvery(ActionNames.BASEDB_GET_DOC, doGet),
    takeEvery(ActionNames.BASEDB_UNLISTEN_SINGLE, clearFetching),
    takeEvery(ActionNames.BASEDB_UNLISTEN_MAP, clearFetching),
  ]);
}
