import React from 'react';
import { useSelector } from 'react-redux';

import { RootState, DBContext } from 'src/index';
import { CollectionDocument } from 'src/types/models/collection.model';
import { useDBUpdateDoc } from 'src/hooks/useDBUpdateDoc/useDBUpdateDoc';
import {
  VisibilityButtonProps,
  VisibilityButtonContainerProps,
} from './VisibilityButton.types';
import { VisibilityButton } from './VisibilityButton.component';

const mapPublicLevelToText: Record<number, string> = {
  10: 'Public',
  7: 'Private',
  0: 'Hidden',
};

const menuOptions = [
  {
    level: 10,
    description: 'Public: Anyone can view the collection',
    icon: 'earth',
  },
  {
    level: 7,
    description: 'Private: Only people with the link can view',
    icon: 'person-add',
  },
  {
    level: 0,
    description: 'Hidden: No-one but you can view',
    icon: 'lock-closed-outlined',
  },
];

const VisibilityButtonContainer: React.FC<VisibilityButtonContainerProps> = (
  props,
) => {
  // Context
  const db = React.useContext(DBContext);

  // Global state
  const collection = useSelector(
    ({ db }: RootState) => db.single_collection.data,
  );
  const collectionUID = collection?.id ?? '';
  const collectionUserUID = collection?.user_uid ?? '';
  const publicLevel = collection?.public_level ?? -1;

  // Local state
  const [isMenuOpen, setIsMenuOpen] = React.useState(false);

  // Handlers
  const handleClick = React.useCallback<
    VisibilityButtonProps['onClick']
  >(() => {
    setIsMenuOpen((prevIsMenuOpen) => !prevIsMenuOpen);
  }, []);

  const handleClose = React.useCallback<
    VisibilityButtonProps['onClose']
  >(() => {
    setIsMenuOpen(false);
  }, []);

  const [updateCollection, working] = useDBUpdateDoc<
    Pick<CollectionDocument, 'public_level' | 'last_updated'>
  >(db, db.collectionDoc(collectionUserUID, collectionUID));

  const handleMenuSelect = (newLevel: number, _event: React.SyntheticEvent) => {
    updateCollection({
      public_level: newLevel,
      last_updated: db.serverTimestamp(),
    })
      .then(() => {
        setIsMenuOpen(false);
      })
      .catch((error) => {
        console.error('Could not update collection public level', error);
      });
  };

  return (
    <VisibilityButton
      isMenuOpen={isMenuOpen}
      working={working}
      menuOptions={menuOptions}
      onClick={handleClick}
      onClose={handleClose}
      onMenuSelect={handleMenuSelect}
      level={publicLevel}
      {...props}
    >
      {mapPublicLevelToText[publicLevel] ?? 'Unknown'}
    </VisibilityButton>
  );
};

export { VisibilityButtonContainer as VisibilityButton };
