/*

Instead of a vertical carousel on the product modal, on mobile it is a large, horizontal one.

TODO: Convert to typescript, functional, component + container, camelCase

ALSO: check it is no longer opening at random positions, but instead always on the first image.  If not needs to be fixed.

*/

import React from 'react';
import styled from 'styled-components';
import {M} from 'src/mvp22/constants';
import MEDIA from 'src/mvp22/media';
import BasicCarousel from './BasicCarousel';
import LoadCheck from 'src/mvp22/image-components/LoadCheck';
import {TEXTSTYLE} from 'src/mvp22/style-components/Text';

const CarouselContainer = styled.div`
  display: flex;
  width: 100%;
  position:relative;
  overflow-x: hidden;
`;

const CarouselSlideContainer = styled.div`
  overflow-x: scroll;
  display: flex;
  ::-webkit-scrollbar {
    display: none;
  }
  scroll-snap-type: x mandatory;
  width: 420px;
  @media (max-width: 420px){
    width: ${props => props.width + 'px'}
  }
  position:relative;
  align-items:flex-start;
  align-content:flex-start;
  justify-content:flex-start;
  justify-items:flex-start;
`;

// Set width to ensure that it loads at the start!
const CarouselSlideInner = styled.div`
  display: flex;
  flex-direction: row;
  width:${props => props.number_of_items*100+"%"};
  min-height:100px;
`;

const CarouselSlide = styled.div`
  display: flex;
  width: 420px;
  @media (max-width: 420px){
    width: ${props => props.width + 'px'}
  }
  position: relative;
`;

const CarouselImage = styled.img`
  scroll-snap-align: start;
  width: 100%;
  object-fit: contain;
`;

const CarouselArrow = styled(TEXTSTYLE.LINKA)`
  display: ${props => props.visible ? 'block' : 'none'};
  position: absolute;
  color: #111;
  cursor: pointer;
  opacity: .75;
  transition: opacity .15s cubic-bezier(.4, 0, 1, 1);
  z-index: 10;
  &:focus {
    outline: 0;
  }

  &:hover {
    opacity: 1;
  }

  &--left {
    left: 32px;
  }

  &--right {
    right: 32px;
  }
  top: 44%;
`;

const CarouselArrowLeft = styled(CarouselArrow)`
  transform: scaleX(-1);
  left: 0px;
`;

const CarouselArrowRight = styled(CarouselArrow)`
  right: 0px;
`;


const CarouselArrowImg = styled.img`
  width:40px;
`;

class MobileCarousel extends BasicCarousel {

  constructor(){
    super();
    this.scrollContainerLeft = this.scrollContainerLeft.bind(this);
    this.scrollContainerRight = this.scrollContainerRight.bind(this);
  }

  scrollContainer(event,multiplier) {
    event.preventDefault();
    this.container.scrollBy(
      { left: multiplier*this.container.clientWidth, behavior: 'smooth' }
    );
    return false;
  }

  scrollContainerLeft(event) {
    this.scrollContainer(event,-1);
  }

  scrollContainerRight(event) {
    this.scrollContainer(event,1);
  }

  render() {
    const ISDESKTOP = this.props.windowWidth>=M.MOBILESWITCH;
      return (
        <CarouselContainer className='CarouselContainer'>
            <CarouselArrowLeft href="#" onClick={this.scrollContainerLeft} visible={this.state.canScrollBack  && this.props.image_list.length>1} className='CarouselArrowLeft'>
              <CarouselArrowImg src={MEDIA.CROPPED_CAROUSEL_ARROW}/>
            </CarouselArrowLeft>
            <CarouselArrowRight href="#" onClick={this.scrollContainerRight} visible={this.state.canScrollForward && this.props.image_list.length>1} className='CarouselArrowRight'>
              <CarouselArrowImg src={MEDIA.CROPPED_CAROUSEL_ARROW}/>
            </CarouselArrowRight>
            <CarouselSlideContainer width={this.props.windowWidth} isDesktop={ISDESKTOP} ref={node =>{this.container = node;}} className='carouselSlideContainer'>
               <CarouselSlideInner isDesktop={ISDESKTOP} className='carouselSlideInner' number_of_items={this.props.image_list.length}>
                   {
                     this.props.image_list.map((image, index) => {
                       return(
                         <CarouselSlide key={index} width={this.props.windowWidth}>
                           <LoadCheck src={image}>
                             <CarouselImage isDesktop={ISDESKTOP} className='carouselImage'/>
                           </LoadCheck>
                         </CarouselSlide>
                       );
                     })
                   }
                </CarouselSlideInner>
              </CarouselSlideContainer>
        </CarouselContainer>
      );
    }
}

export default MobileCarousel;
