/*
Button and Modal to set sharing settings and copy link for sharing.

Also lets users generate videos of their collections.

ShareButton AND SharePopup is in here.

TODO: typescript, camelCase, design system...
Probabaly split into sharePopup/Modal and shareButton

NOTE: also includes tooltip as dropdown overlay for sharing type.

*/
import React,{useState,useRef,useEffect,useContext} from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import {M} from 'src/mvp22/constants';
import {EXTERNAL} from 'src/routes';
import {TEXTSTYLE} from 'src/mvp22/style-components/Text';
import MEDIA from 'src/mvp22/media';
import BGCover from 'src/mvp22/image-components/BGCover';
import CopyURL from 'src/mvp22/core-components/CopyURL';
import Overlay from 'react-overlays/Overlay';
import ShareableOptions from './ShareableOptions';
import WorkingDots from 'src/mvp22/image-components/WorkingDots';
import LoadingDots from 'src/mvp22/image-components/LoadingDots';
import Button from 'src/mvp22/form-components/Button';
import { CloudContext } from 'src/index';

const ShareCollectionButtonAndBoxContainer = styled.div`
  display:flex;
`;

const ShareCollectionPopupBox = styled.div`
  min-width:300px;
  position:fixed;
  background-color:${M.COL.BG.WHITE};
  border-radius: 10px;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
  z-index: 6000;
  color:${M.COL.TEXT.BLACK};
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: ${props => props.isDesktop ? '460px' : '90%'};
`;

const VideoToCollectionPopupBox = styled(ShareCollectionPopupBox)`
  border-radius: ${props => props.isDesktop ? '10px' : '0px'};
  width: ${props => props.isDesktop ? '460px' : '100%'};
  height: ${props => props.isDesktop ? '' : '100%'};
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 312px;
`;

const ShareButton = styled(TEXTSTYLE.LINKA)`
  display: flex;
  align-items: center;
  align-content: center;
  width: 81px;
  height: 36px;
  background: ${M.COL.BUTTON.WHITE};
  border: 1px solid ${M.COL.BUTTON.BLACK};
  box-sizing: border-box;
  border-radius: 2px;
  justify-content: space-evenly;
  cursor:pointer;
  opacity:${props=>props.disabled===true?0.5:1.0};
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.25);
  &:hover{
    box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.25);
  }
`;

const ShareButtonText = styled(TEXTSTYLE.OTHER4)`
  display: inline-block;
`;

const ShareIcon = styled.img`
  display: inline-block;
`;

const TopBox = styled.div`
  height: 68px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  text-align: center;
`;

const MiddleBox = styled.div`
  display: flex;
  align-self: center;
  justify-content: center;
`;

const MiddleBoxInner = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  max-width: 280px;
  text-align: left;
  margin-left: 10px;
`;


const TopBoxText = styled(TEXTSTYLE.HEADING4)`
 margin-left: 24px;
`;

const BottomBox = styled.div`
  display: flex;
  flex-direction: column;
  padding-left: 26px;
  padding-right: 26px;
`;

const BottomBoxText = styled(TEXTSTYLE.BODY4)`
  color: ${props=>props.disabled?M.COL.TEXT.ERROR:M.COL.TEXT.MID};
  text-align:left;
  font-weight: lighter;
`;

const Seperator = styled.div`
  height:${props=>props.height};
`;

const CloseShareBox = styled.img`
  position:absolute;
  height:16px;
  width:16px;
  right:26px;
  top:26px;
  cursor: pointer;
`;

const CloseContainer = styled(TEXTSTYLE.LINKA)`
color:default;
`;

const WorkingDotsIcon = styled(WorkingDots)`
  background-repeat:no-repeat;
  background-position:center;
  background-size:16px;
  height:16px;
  width:16px;
`;

const SelectedOptionText = styled(TEXTSTYLE.OTHER2)`
  text-align:left;
  flex-grow:1;
`;

const SelectedOptionIcon = styled.img`
  width:13px;
  margin-right:6px;
`;

const DropDownArrow = styled.img`
  width:10px;
  margin-left:12px;
`;

const SelectedOptionRow = styled.div`
  padding:5px 5px;
  display:flex;
  flex-direction:row;
  justify-items:space-between;
  align-items:center;
  cursor:pointer;
  width:270px;
`;

const BelowSelectedOptionExplanation = styled(TEXTSTYLE.BODY4)`
  color:${M.COL.TEXT.MID};
  text-align:left;
  margin-bottom: 26px;
`;

const WithinPos = styled.div`
color:default;
`;

const IconForSharing = styled.img`
  height:16px;
  padding:3px;
  opacity:0.6;
  &:hover{
    opacity:1.0;
  }
`;

const ProgressHolder = styled.div`
  display: flex;
  align-items: center;
`;

const CentralTab = styled(TEXTSTYLE.LINKA)`
  display:flex;
  flex-direction: column;
  align-items: flex-start;
`;

const VideoProgress = styled(TEXTSTYLE.OTHER5)`
  margin-right: 13px;
`;

const ButtonBox = styled.div`
  display: flex;
  width: 176px;
`;

const UnderlinedText = styled(TEXTSTYLE.LINKA)`
  border-bottom: 1px solid black
`;

const VideoGeneratorCollectionName = styled(TEXTSTYLE.HEADING3)`
  height:44px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  width: 100%;
`;

const GenerateVideoCollectionButton = styled(TEXTSTYLE.LINKA)`
  display: flex;
  width: 343px;
  height: 36px;
  background: ${M.COL.BG.WHITE};
  border: 1px solid ${M.COL.BUTTON.BLACK};
  box-sizing: border-box;
  border-radius: 2px;
  align-items: center;
  justify-content: center;
  &:hover{
    box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.25);
  }
  cursor:pointer;
`;

export const SHARE_OPTIONS = [
  {public_level:10,text:"Anyone can view this collection",explanation:"Collection visible to anyone that views my profile"},
  {public_level:7,text:"Only people with the link can view",explanation:"Only accessible to people I share the link with"},
  {public_level:0,text:"Private, no one else can view",explanation:"No one else can view, even if they have the link"}
];

// Sharing types dropdown (publicLevels)
const AddSharingDropDown = (props) => {
  const containerRef = useRef(null);
  const triggerRef = useRef(null);
  const [show_popup, set_popup] = useState(false);
  const setPublicLevel = (new_level)=>{
    set_popup(false);
    props.setPublicLevel(new_level);
  };
  if (props.is_mine){
    //approach based on: https://react-bootstrap.github.io/react-overlays/api/Overlay
    return (
      <WithinPos ref={containerRef}>
      <CentralTab
        href="#"
        ref={triggerRef}
        onClick={
          (event)=>{
            event.preventDefault();
            set_popup(true);
            return false;
          }
        }
      >
      {props.children}
      </CentralTab>
        <Overlay
          rootClose
          show={show_popup}
          offset={[0,0]}
          placement={'auto-end'}
          flip
          onHide={() => set_popup(false)} // required!
          container={containerRef}
          target={triggerRef}
        >
        {
          (overlay_props)=>
            <ShareableOptions
              public_level={props.public_level}
              setPublicLevel={setPublicLevel}
              overlay_props={overlay_props}
              set_popup={set_popup}
            />
        }
        </Overlay>
      </WithinPos>
    );
  }else{
    return(
      <CentralTab>
        {props.children}
      </CentralTab>
    );
  }
};

export const SharingStatusIcon = (props)=>{
  return(
    <AddSharingDropDown
      setPublicLevel={props.setPublicLevel}
      public_level={props.public_level}
      is_mine={props.is_mine}
    >
      {
        (props.is_working===true)?
          <WorkingDotsIcon/>
        :
          <IconForSharing
           src={MEDIA['SHARE_LEVEL_'+props.public_level]}
          />
      }
    </AddSharingDropDown>
  );
};

const InfoIcon = styled.img`
  &:focus{
    outline:none;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
  }
  cursor:pointer;
  margin-left: 20px;
  width: 20px;
`;

const Tooltip = styled.div`
  width:${props=>(props.is_purchased)?"230px":"160px"};
  background: #FFFFFF;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 5px;
  align-content: center;
  justify-content: center;
  text-align: left;
  z-index:1000;
  position: absolute;
`;

const TooltipText = styled(TEXTSTYLE.BODY5)`
  padding: 10px;
`;

const InfoIconContainer = styled(TEXTSTYLE.LINKA)`
  position:relative;
  bottom: 2px;
`;

const RedErrorText = styled(TEXTSTYLE.BODY5)`
  color:${M.COL.TEXT.ERROR};
`;

const ButtonAndInfoTooltipContainer = styled.div`
  display: flex;
  align-items: center;
`;

function RenderInfoIcon() {
  const [show_popup, set_icon_mo_visible] = useState(false);
  const triggerRef = useRef(null);
  const containerRef = useRef(null);
  //approach based on: https://react-bootstrap.github.io/react-overlays/api/Overlay
  return (
    <InfoIconContainer href="#" ref={containerRef} >
      <InfoIcon
        // Sorry for the double ternary...:S
        src={MEDIA.INFO_ICON}
        alt={"Click here to generate a video of this collection"}
        ref={triggerRef}
        onMouseOver={() => set_icon_mo_visible(true)}
        onMouseOut={() => set_icon_mo_visible(false)}
      />
      <Overlay
        show={show_popup}
        offset={[0, 10]}
        onHide={() => set_icon_mo_visible(false)} // required!
        container={containerRef}
        target={triggerRef}
      >
        {({ overlay_props }) => (
            <Tooltip
              {...overlay_props}
            >
              <TooltipText>
                We can automatically generate videos of your collection to share. We recommend sharing these on Instagram Stories and Instagram Reels. Click here to generate a video of this collection.
              </TooltipText>
            </Tooltip>
        )}
      </Overlay>
    </InfoIconContainer>
  );
}

export const SharePopup = (
  {
    disabled,
    on,
    type,
    shareURL,
    shareText,
    collectionUID,
    collectionUserUID,
    collectionName,
    publicLevel,
    setPublicLevel,
    isMine,
    isWorking,
    isPro,
    button
  }
)=>{
  // We can just use local state (not redux)
  // since this component is quite self-contained and separate to the rest and always mounted.
  const [videoHasBeenGenerated,setVideoHasBeenGenerated] = useState(false);
  const [videoGeneratorPopupIsOpen,setVideoGeneratorPopupIsOpen] = useState(false);
  const [videoHasFailed,setVideoHasFailed] = useState(false);
  const [mP4Name,setMP4Name] = useState('');
  const cloud = useContext(CloudContext);
  const windowWidth = useSelector((state) => state.ui.windowWidth);
  const isDesktop = windowWidth>=M.MOBILESWITCH;

  // As includes share button can use a local state:
  const [open,setOpen] = useState(false);
  const openBox = (event)=>{
    event.preventDefault();
    if (disabled!==true){
      setOpen(true);
    }
    return false;
  };
  const closeBox = (event)=>{
    if (event){
      event.preventDefault();
    }
    setOpen(false);
    return false;
  };
  // Close if not valid data:
  useEffect(
    ()=>{
      if (type==="collection" && (!collectionUID || !collectionUserUID)){
        closeBox();
      }
    },
    [type,collectionUID,collectionUserUID]
  );

  // VIDEO GENERATION:
  const closeVideoGeneratorBox = (event)=>{
    event.preventDefault();
    setVideoHasBeenGenerated(false);
    setVideoGeneratorPopupIsOpen(false);
    setVideoHasFailed(false);
    return false;
  };
  const openVideoGeneratorPopupAndStartCreatingVideo = (event)=>{
    event.preventDefault();
    setVideoGeneratorPopupIsOpen(true);
    setOpen(false);
    cloud.call('generate_collection_video-call')(
      {
        collection_uid:collectionUID,
        collection_user_uid:collectionUserUID
      }
    ).then(
      (response)=> {
        if (response.data.success===true){
          setVideoHasBeenGenerated(true);
          setMP4Name(response.data.download_filename);
        }
        else{
          setVideoHasFailed(true);
        }
      }
    ).catch(
      ()=>{
        setVideoHasFailed(true);
      }
    );
    return false;
  };

  // RENDERS:
  const renderVideoGeneratorPopupBox = ()=>{
    const SHARE_POPUP_SWITCH = windowWidth > 500;
    if(videoGeneratorPopupIsOpen === true){
      return(
        <ShareCollectionButtonAndBoxContainer>
          <BGCover/>
          <VideoToCollectionPopupBox isDesktop={SHARE_POPUP_SWITCH}>
            <CloseContainer href="#" onClick={closeVideoGeneratorBox}>
              <CloseShareBox src={MEDIA.EXIT_CROSS_THIN}/>
            </CloseContainer>
            <MiddleBox className='MiddleBox'>
              <MiddleBoxInner className='MiddleBoxInner'>
                <VideoGeneratorCollectionName>{collectionName}</VideoGeneratorCollectionName>
                <Seperator height='13px'/>
                {
                  videoHasBeenGenerated === true ?
                  <>
                    <ProgressHolder>
                      <VideoProgress>VIDEO CREATED</VideoProgress>
                    </ProgressHolder>
                    <Seperator height='10px'/>
                    <ButtonBox>
                      <TEXTSTYLE.LINKA href={`https://storage.googleapis.com/${process.env.REACT_APP_FIREBASE_PROJECTID}-user-videos/${mP4Name}`} download>
                        <Button
                          isDesktop={isDesktop}
                          text="Download"
                          msstyle="download_button_dark"
                          width="100%"
                        />
                      </TEXTSTYLE.LINKA>
                    </ButtonBox>
                  </>
                  :
                  null
                }
                {
                  videoHasBeenGenerated === true
                  ?
                  <>
                    <Seperator height='13px'/>
                    <TEXTSTYLE.OTHER2>How to upload to Instagram</TEXTSTYLE.OTHER2>
                    <Seperator height='7px'/>
                    <TEXTSTYLE.BODY5><UnderlinedText target="_blank" href={EXTERNAL.VIDEO_EXPLANATION}>Click here</UnderlinedText> for quick steps on how to upload your video to Instagram</TEXTSTYLE.BODY5>
                  </>
                  :
                    videoHasFailed === true?
                      <>
                        <Seperator height='13px'/>
                        <RedErrorText>Sorry, video generation failed.  Please go back and try again and contact us if this persists.</RedErrorText>
                      </>
                    :
                      <>
                        <ProgressHolder>
                          <VideoProgress>CREATING YOUR VIDEO. THIS MAY TAKE UP TO A MINUTE..</VideoProgress>
                          <LoadingDots msstyle="dark"/>
                        </ProgressHolder>
                        <Seperator height='13px'/>
                        <TEXTSTYLE.BODY5>Moonsift allows you to generate videos of your collections for you to share</TEXTSTYLE.BODY5>
                      </>
                }
              </MiddleBoxInner>
            </MiddleBox>
            </VideoToCollectionPopupBox>
        </ShareCollectionButtonAndBoxContainer>
      );
    } else {
      return null;
    }
  };

  const renderShareCollectionPopupBox= ()=>{
    return(
      <BottomBox>
        <Seperator height='22px'/>
        {
          (publicLevel<7)?
            <BottomBoxText disabled={true}>
              Collection cannot be shared while set to private
            </BottomBoxText>
          :
            <BottomBoxText>
              Copy and share with friends
            </BottomBoxText>
        }
        <Seperator height='10px'/>
        <CopyURL copyText={shareURL} disabled={publicLevel<7}/>
        {
          SHARE_OPTIONS.filter(option=>option.public_level===publicLevel).map(
            (option,index)=>
            <React.Fragment key={index}>
              <Seperator height='12px'/>
              <AddSharingDropDown
                setPublicLevel={setPublicLevel}
                public_level={publicLevel}
                is_mine={isMine}
              >
                <SelectedOptionRow>
                  <SelectedOptionIcon src={MEDIA['SHARE_LEVEL_'+option.public_level]}/>
                  <SelectedOptionText>{option.text}</SelectedOptionText>
                  <DropDownArrow src={(isMine)?MEDIA.SHARE_DOWN_ARROW:undefined}/>
                </SelectedOptionRow>
              </AddSharingDropDown>
              <Seperator height='8px'/>
              <BelowSelectedOptionExplanation>
              {(isWorking)?"Saving sharing settings...":(isMine)?option.explanation:""}
              </BelowSelectedOptionExplanation>
            </React.Fragment>
          )
        }
        {
          isPro?
            <>
              <ButtonAndInfoTooltipContainer>
                <GenerateVideoCollectionButton onClick={openVideoGeneratorPopupAndStartCreatingVideo}>
                  <SelectedOptionIcon src={MEDIA.VIDEO_ICON}/>
                  <TEXTSTYLE.BODY5>Generate Video Collection</TEXTSTYLE.BODY5>
                </GenerateVideoCollectionButton>
                <RenderInfoIcon/>
              </ButtonAndInfoTooltipContainer>
              <Seperator height='26px'/>
            </>
          :
            null
        }
      </BottomBox>
    );
  };

  const renderShareProfilePopupBox= ()=>{
    return(
      <BottomBox>
        <Seperator height='22px'/>
        <BottomBoxText>
          Copy link and share with friends
        </BottomBoxText>
        <Seperator height='10px'/>
        <CopyURL copyText={shareURL}/>
        <Seperator height='22px'/>
      </BottomBox>
    );
  };

  // TODO: remove once we've stopped using SharePopup in the new pages
  const hackyButtonFromNewSystem = () => {
    /**
     * The original onClick in the button instance receives the openBox toggle
     * and returns the actual click handler that will, both, call openBox and
     * the parent share modal toggle.
     */
    return React.cloneElement(button, {
      onClick: button.props.onClick(openBox)
    });
  };

  const displayShareButtonAndBox=()=>{
    const SHARE_POPUP_SWITCH = windowWidth > 500;
    const BUTTONSOURCE = (publicLevel!==undefined)?MEDIA['SHARE_LEVEL_'+publicLevel]:MEDIA.BLACK_SHARE_ICON;
    return(
      <>
        {
          (open)?
            <ShareCollectionButtonAndBoxContainer>
              <BGCover onClick={closeBox}/>
              <ShareCollectionPopupBox isDesktop={SHARE_POPUP_SWITCH}>
                <CloseContainer href="#" onClick={closeBox}>
                  <CloseShareBox src={MEDIA.EXIT_CROSS_THIN}/>
                </CloseContainer>
                <TopBox>
                  <TopBoxText>
                    {shareText}
                  </TopBoxText>
                </TopBox>
                {
                  (type==="collection")?
                    renderShareCollectionPopupBox()
                  :
                    renderShareProfilePopupBox()
                }
                </ShareCollectionPopupBox>
            </ShareCollectionButtonAndBoxContainer>
          :""
        }
        {button ?
          hackyButtonFromNewSystem() : (
          <ShareCollectionButtonAndBoxContainer>
            <ShareButton
              onClick={openBox}
              href="#"
            >
            {
              (isWorking===true) ?
                <WorkingDotsIcon/>
              :
                <ShareIcon
                  src={BUTTONSOURCE}
                />
            }
              <ShareButtonText>
                Share
              </ShareButtonText>
            </ShareButton>
          </ShareCollectionButtonAndBoxContainer>
        )}
      </>
    );
  };

  return(
    (on===false)?
      null
    :
      videoGeneratorPopupIsOpen ? renderVideoGeneratorPopupBox() : displayShareButtonAndBox()
  );
};

