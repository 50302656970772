import React from 'react';
import styled from 'styled-components';
import clsx from 'clsx';
import {
  CtaButton,
  Typography,
  Stack,
  Paper,
} from '@moonsifttech/design-system';

import { PostinstallTwoProps } from './PostinstallTwo.types';

const PostinstallTwo: React.FC<PostinstallTwoProps> = ({
  className,
  style,
  onBack,
  onForth,
}) => {
  return (
    <Stack
      className={clsx('PostinstallTwo-container', className)}
      style={style}
      direction="row"
      justifyContent="center"
      alignItems="center"
    >
      <Stack className="PostinstallTwo-innerContainer" direction="column">
        <div>
          <Typography
            className="PostinstallTwo-title"
            variant="primary.b42"
            component="h1"
          >
            How to save items from any store
          </Typography>
        </div>
        <Stack
          className="PostinstallTwo-panels"
          direction="row"
          justifyContent="center"
          alignItems="center"
          spacing={40}
        >
          <div className="PostinstallTwo-panel">
            <Typography
              className="PostinstallTwo-subtitle"
              variant="primary.r20"
              component="h2"
            >
              You’ll find <b>‘Quick-save’ buttons</b> on 1,000s of enabled
              stores
            </Typography>
            <Paper className="PostinstallTwo-paper" variant="outlined">
              <Stack
                spacing={30}
                direction="row"
                justifyContent="center"
                alignItems="center"
                style={{ height: '100%' }}
              >
                <div>
                  <img
                    src={`${process.env.REACT_APP_STATIC_URL}/postinstall/onboarding2-saving-from-tile-view.png`}
                    alt="Saving from tile view"
                    width={300}
                    height={314}
                  />
                </div>
                <div>
                  <img
                    src={`${process.env.REACT_APP_STATIC_URL}/postinstall/onboarding2-saving-from-detail-view.png`}
                    alt="Saving from detail view"
                    width={300}
                    height={318}
                  />
                </div>
              </Stack>
            </Paper>
          </div>
          <div className="PostinstallTwo-panel">
            <Typography
              className="PostinstallTwo-subtitle"
              variant="primary.r20"
              component="h2"
            >
              Save via your <b>toolbar</b> on any other store
            </Typography>
            <Paper className="PostinstallTwo-paper" variant="outlined">
              <img
                src={`${process.env.REACT_APP_STATIC_URL}/postinstall/onboarding2-saving-from-toolbar.png`}
                alt="Saving from toolbar"
                width={296}
                height={324}
              />
            </Paper>
          </div>
        </Stack>
        <Stack
          className="PostinstallTwo-buttons"
          spacing={12}
          direction="row"
          justifyContent="flex-end"
        >
          <CtaButton variant="primary-white" onClick={onBack}>
            Back
          </CtaButton>
          <CtaButton onClick={onForth}>Got it</CtaButton>
        </Stack>
      </Stack>
    </Stack>
  );
};

const StyledPostinstallTwo = styled(PostinstallTwo)`
  &.PostinstallTwo-container {
    height: 100vh;
  }

  .PostinstallTwo-title {
    margin-bottom: 40px;
    text-align: center;
  }

  .PostinstallTwo-subtitle {
    margin-top: 0;
    margin-bottom: 16px;
  }

  .PostinstallTwo-panels {
    margin-bottom: 50px;

    .PostinstallTwo-panel {
      &:last-child {
        margin-right: 0;
      }

      .PostinstallTwo-paper {
        height: 393px;
        background: var(--grey-7);
        padding: 30px;
      }
    }
  }

  .PostinstallTwo-buttons button {
    width: 145px;
  }
`;

export { StyledPostinstallTwo as PostinstallTwo };
