/*
Logout page.

Redirects you after logging out so just renders loading while doing that.

TODO: typescript, camelCase, make functional, non-default exports, redux hooks

*/
import React from 'react';
import { Component } from 'react';
import Loading from './Loading';
import { withFirebase } from 'src/mvp22/Firebase';
import {withRouter} from 'react-router-dom';
import { connect } from 'react-redux';
import {actionCreator,
  FIRESTORE_REDUX_REMOVE_ALL
} from 'src/mvp22/redux-components/actions';

/**
* Logout Page Component (Special)
*/
class Logout extends Component {
  componentDidMount(){
    this._isMounted = true;
    // SYNC!
    // unset all the listeners FIRST:
    this.props.logout();
    this.props.firebase.doSignOut().then(
      ()=>{
        // ASYNC!
        if (window && window.ReactNativeWebView){
          window.ReactNativeWebView.postMessage(JSON.stringify({message:'LOGOUT'}));
        }
        this.props.history.push("/");
      }
    );
  }

  render(){
    return(<Loading/>
    );
  }
}

const mapStateToProps = () => {
  return ({});
};

const mapDispatchToProps = dispatch => {
  return {
    logout:(event)=>dispatch(actionCreator(FIRESTORE_REDUX_REMOVE_ALL,event)),
  };
};

export default withRouter(connect(mapStateToProps,mapDispatchToProps)(withFirebase(Logout)));
