// This file contains the route names for the main pages:
const R = {
  HOMEPAGE: '/',
  TEST: '/test',
  LANDING: '/landing',
  CHRISTMAS_LANDING: '/christmas-wishlist',
  LOADING: '/loading',
  LOGOUT: '/logout',
  SIGNUP: '/signup',
  SIGNIN: '/signin',
  SIGNINFROMEXTENSION: '/signin/extension',
  POSTLOGIN: '/home',
  POSTSIGNUP: '/postsignup',
  RETAILERS_AZ: '/retailers/az',
  RETAILERS_FEATURED: '/retailers/trending',
  RETAILERS_SEARCH: '/retailers/search',
  RETAILERS: '/retailers',
  DESIGNSYSTEM: '/test/designsystem',
  TASTEMAKERLANDING: '/stylists',
  TASTEMAKERSAPPLY: '/stylistsapply',
  PRIVACYPOLICY: '/privacy',
  TERMS: '/terms',
  USERHOMEPAGE: '/home',
  HOME: '/home',
  HOME_WELCOME: 'home?message=welcome',
  HOME_POSTINSTALL: 'home?message=postinstall',
  PRO_LANDING: '/getpro',
  PRO_SHARE: '/givepro',
  FORGOTPASSWORD: '/forgotpassword',
  COLLECTION: '/collection',
  REPORTPROBLEM: '/reportsite',
  ABOUT_US: '/about',
  USER_PROFILE: '/user/',
  LIKED: '/liked',
  PURCHASED: '/purchased',
  EXPLORE_FEATURED: '/featured/',
  EDIT_PROFILE: '/profile/edit',
  EDIT_USERNAME: '/profile/username',
  EDIT_EMAIL_PREFERENCES: '/profile/email',
  EDIT_ACCOUNT_PREFERENCES: '/profile/account',
  UNSUBSCRIBE_FROM_LIST: '/profile/unsubscribe-list',
  ADD_PRODUCT: '/addproduct',
  NOTIFICATIONS: '/notifications',
  LIZZIEYOUTUBE: `/${decodeURI('%C3%A2%C2%80%C2%8B')}`, // popular youtube video link included this extra for some reason.
  LIZZIEYOUTUBEURI: `/%C3%A2%C2%80%C2%8B`,
  COLLECTIONS_CREATE: '/collections/create',
  POSTINSTALL_ONE: '/postinstall',
  POSTINSTALL_TWO: '/postinstall/2',
  POSTINSTALL_THREE: '/postinstall/3',
};

export const EXTERNAL = {
  FACEBOOK: 'https://www.facebook.com/Moonsift/',
  INSTAGRAM: 'https://www.instagram.com/moonsift/',
  MEDIUM: 'https://medium.com/@alex.reed',
  LINKEDIN: 'https://www.linkedin.com/company/moonsift/',
  CHROMESTORE:
    'https://chrome.google.com/webstore/detail/moonsift-browser-extensio/hgbbfkafecdbocpcdkoahfkbkoodcaon',
  RETAILER_ASOS: 'http://www.asos.com/',
  RETAILER_SELFRIDGES: 'http://www.selfridges.com/',
  RETAILER_HM: 'http://www.hm.com/',
  RETAILER_STORIES: 'http://www.stories.com/',
  RETAILER_TOPSHOP: 'http://www.topshop.com/',
  RETAILER_TROUVA: 'http://www.trouva.com/',
  ORLY: 'https://en.wikipedia.org/wiki/O_RLY%3F',
  JOBS: 'https://medium.com/@david.wood_24999/moonsift-job-advertisement-8cb3de0e585c',
  PRO_EXPLANATION:
    'https://medium.com/@moonsift/an-upgraded-experience-17713bba4669?source=friends_link&sk=ef7d6d43851c6ccfc5ffe09f28e58d75',
  VIDEO_EXPLANATION:
    'https://moonsift.notion.site/How-to-generate-a-video-from-your-Moonsift-collection-669326e267d34a15917464080b25f7f4',
  SUBMIT_COLLECTION:
    'https://moonsift.notion.site/Featuring-Collections-on-Moonsift-8a1d95e64f8e4360aa3f7f7acc22ca63',
  HOW_TO_ADD:
    'https://moonsift.notion.site/How-to-add-items-to-your-Collection-c7a890258757415a848a88da8b428583',
};

export default R;
